const formatDate = (date) => {
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
};

const formatStringDate = (dateString) =>{
  const [year, month, day] = dateString.split('-');

  const formattedDate = `${day}-${month}-${year}`;

  return formattedDate;
}

const formatUtcDate = (dateString) => {
  const date = new Date(dateString);
  const day = date.getDate();
  const month = date.getMonth() + 1; // Months are zero-based
  const year = date.getFullYear();

  // Pad day and month with leading zeros if needed
  const formattedDay = day < 10 ? `0${day}` : day;
  const formattedMonth = month < 10 ? `0${month}` : month;

  // Format the date as "dd/mm/yyyy"
  return `${formattedDay}/${formattedMonth}/${year}`;
}

export { formatDate, formatStringDate, formatUtcDate };
