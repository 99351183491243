/* eslint-disable */
import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-select';

//import translation function for language translation
import { useTranslation } from 'react-i18next';

/*import url and gateway methods */
import { getData, postData, putData } from 'utils/Gateway';
import * as url from 'urlhelpers/UrlHelper';

//import context
import { GlobalProvider } from 'context/GlobalContext';

const ViewMailTemplatePopup = () => {
  const token = localStorage.getItem('token'); // token

  const { t } = useTranslation(); //for translation

  const [showModal, setShowModal] = useState(false);

  const { viewMailTemplateData, setViewMailTemplateData } =
    useContext(GlobalProvider);

  /*======== form requirement start ==========*/
  const [userInputs, setUserInputs] = useState({
    subject: '',
    description: '',
  });

  /* reset */
  const handleClose = () => {
    setUserInputs({
      subject: '',
      description: '',
    });
    setViewMailTemplateData(null);

    setShowModal(false);
  };

  /* use effect hook to assign edit data */
  useEffect(() => {
    if (viewMailTemplateData) {
      console.log('viewMailTemplateData', viewMailTemplateData);

      setShowModal(true);

      setUserInputs(() => ({
        subject: viewMailTemplateData.subject || '',
        description: viewMailTemplateData.description || '',
      }));
    }
  }, [viewMailTemplateData]);

  return (
    <div
      className={`modal fade ${showModal ? 'show d-block' : ''}`}
      tabIndex="-1"
      role="dialog"
    >
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{userInputs.subject}</h5>

            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={handleClose}
            ></button>
          </div>
          <div className="modal-body">
            <div dangerouslySetInnerHTML={{ __html: userInputs.description }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewMailTemplatePopup;
