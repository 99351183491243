/* eslint-disable */
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

//import translation function for language translation
import { useTranslation } from 'react-i18next';

const SurveyQuestionHeader = props => {
  const { t } = useTranslation(); //for translation
  const [showQuestionTab, setshowQuestionTab] = useState(false);
  const [showSurveyTab, setshowSurveyTab] = useState(false);

  useEffect(() => {
    props.page == 'question'
      ? setshowQuestionTab(true)
      : setshowSurveyTab(true);
  }, [props.page]);

  return (
    <div className="tab-menu bg-dark mb-3">
      <div className="container-fluid px-4">
        <ul className="nav text-center">
          <li className="nav-item flex-fill">
            <Link
              to="/questions"
              className={`d-block text-white px-3 py-2 ${
                showQuestionTab ? 'active' : ''
              }`}
              onClick={() => {
                setshowQuestionTab(true);
                setshowSurveyTab(false);
              }}
            >
              {t('Question')}
            </Link>
          </li>
          <li className="nav-item flex-fill">
            <Link
              to="/survey"
              className={`d-block text-white px-3 py-2 ${
                showSurveyTab ? 'active' : ''
              }`}
              onClick={() => {
                setshowSurveyTab(true);
                setshowQuestionTab(false);
              }}
            >
              {t('Survey')}
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default SurveyQuestionHeader;
