import React from "react";
//import translation function for language translation
import { useTranslation } from "react-i18next";
import { currencySymbol } from "Config/Config";

const SalesOrderItemTableRow = () => {
  const { t } = useTranslation(); //for translation
  return (
    <tr className="salesorder_header">
      <th className="slno">
        Sl.
      </th>
      <th className="reserveid">
        {t("Reserve ID")}
      </th>
      {/* <th className="beneficiary">
        {t("Beneficiary")}
      </th> */}
      <th className="description">
        {t("Description")}
      </th>
      <th className="unit">
        {t("Unit")}
      </th>
      <th className="price">
        
          {t("Price")} {currencySymbol}
        
      </th>
      <th className="discount">
        {t("Discount")} %
      </th>
      <th className="taxpercent">
        {t("Tax")} %
      </th>
      <th className="paybleamount">
        
          {t("Amount")} {currencySymbol}
        
      </th>
      <th className="action">
        {t("Action")}
      </th>
    </tr>
  );
};

export default SalesOrderItemTableRow;
