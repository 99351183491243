/* eslint-disable */
import React, { useEffect, useContext, useState } from "react";
import Select from "react-select";

//import translation function for language translation
import { useTranslation } from "react-i18next";

/*import url and gateway methods */
import { postData, putData, getData } from "utils/Gateway";
import * as url from "urlhelpers/UrlHelper";

//import context
import { GlobalProvider } from "context/GlobalContext";

const AddUpdateRolePriviledgePopup = () => {
  const token = localStorage.getItem("token"); // token
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const { t } = useTranslation(); //for translation

  const {
    setReloadRolePriviledgeList,
    rolePriviledgeData,
    setRolePriviledgeData,
    setError,
    setSuccess,
    setNotificationString,
  } = useContext(GlobalProvider);

  const [rolesList, setRolesList] = useState([]); //for roles
  const [moduleList, setModuleList] = useState([]);

  const [roleValue, setRoleValue] = useState(null);
  const [selectedRole, setSelectedRole] = useState(null);
  const [moduleValue, setModuleValue] = useState(null);
  const [selectedModules, setSelectedModules] = useState([]);

  const [isSending, setIsSending] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  /* hook for validation */
  const [validation, setValidation] = useState({
    roleWarning: false,
    moduleWarning: false,
  });

  //function for get all role
  const getAllRoles = async () => {
    try {
      let requestUrl =
        url.API_BASEURL + url.API_GET_ALL_ROLES + `?token=${token}`;

      const response = await getData(requestUrl);

      if (response.status) {
        const filteredData = response.data.filter(
          (item) => item.label !== "admin"
        );
        setRolesList(filteredData);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all modules
  const getAllModules = async () => {
    try {
      let requestUrl =
        url.API_BASEURL + url.API_GET_ALL_MODULES + `?token=${token}`;

      const response = await getData(requestUrl);

      if (response.status) {
        setModuleList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for role selection
  const roleSelectionHandler = (val) => {
    setRoleValue(val);
    setSelectedRole(val.value);
  };

  //function for module selection
  const moduleSelectionHandler = (val) => {
    setModuleValue(val);
    setSelectedModules(val.map((item) => item.value));
  };

  //function for get rolePreviledge details
  const getRolePreviledgeDetails = async () => {
    try {
      let requestUrl =
        url.API_BASEURL +
        url.API_GET_ROLE_PRIVILEDGE_DETAILS +
        `/${rolePriviledgeData}?token=${token}`;

      console.log("Url of previledge details----->", requestUrl);

      const response = await getData(requestUrl);

      // console.log(response);

      if (response.data) {
        roleSelectionHandler(response.data.roledata);

        moduleSelectionHandler(response.data.moduledata);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  /* function to validate form */
  const validate = () => {
    let isValid = true;

    if (selectedRole === null) {
      setValidation((prevState) => ({ ...prevState, roleWarning: true }));
      isValid = false;
    }

    if (selectedModules.length === 0) {
      setValidation((prevState) => ({ ...prevState, moduleWarning: true }));
      isValid = false;
    }

    return isValid;
  };

  /* function to add label */
  const saveUpdatePreviledgeHandler = async () => {
    let previledgeData = {
      roleid: selectedRole,
      modules: selectedModules,
    };

    if (validate()) {
      setIsSending(true);
      try {
        let requestUrl = url.API_BASEURL;

        let response = {};

        if (rolePriviledgeData) {
          requestUrl =
            requestUrl +
            url.API_UPDATE_ROLE_PRIVILEDGE +
            `/${rolePriviledgeData}` +
            `?token=${token}`;

          response = await putData(requestUrl, previledgeData);
        } else {
          requestUrl =
            requestUrl + url.API_ADD_ROLE_PRIVILEDGE + `?token=${token}`;

          response = await postData(requestUrl, previledgeData);
        }

        console.log(response);

        setIsSending(false);

        if (response.status) {
          resetHandler();
          setReloadRolePriviledgeList(true);
          setSuccess(true);
          setNotificationString(t("RolePriviledge added successfully"));
          bootstrap.Offcanvas.getInstance(
            document.querySelector("#addUpdateRolePrevPopup")
          ).hide();
        } else {
          setErrorMessage(t(response.message));
        }
      } catch (error) {
        console.error(error.message);
        setError(true);
        setNotificationString(
          t("Something went wrong, please try again later")
        );
      }
    }
  };

  /* reset */
  const resetHandler = () => {
    setRoleValue(null);
    setSelectedRole(null);
    setModuleValue(null);
    setSelectedModules([]);
    setRolePriviledgeData(null);
    setErrorMessage("");
    setValidation({
      roleWarning: false,
      moduleWarning: false,
    });
  };

  useEffect(() => {
    if (rolePriviledgeData) {
      getRolePreviledgeDetails();
    }
  }, [rolePriviledgeData]);

  useEffect(() => {
    getAllRoles();
    getAllModules();
  }, []);

  return (
    <div
      className="offcanvas offcanvas-end border-0"
      tabIndex="-1"
      id="addUpdateRolePrevPopup"
      aria-labelledby="addLabelPopupLabel"
    >
      <div className="offcanvas-header p-4">
        <h3 className="text-secondary mb-0" id="addLabelPopupLabel">
          {rolePriviledgeData
            ? t("Edit RolePriviledge")
            : t("Add RolePriviledge")}
        </h3>

        {/* ------- pop up close button start ------- */}
        <button
          type="button"
          id="closeCreateMail"
          className="btn-close p-2 bg-white shadow-none shadow-sm m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={resetHandler}
        ></button>
        {/* ------- pop up close button end ------- */}
      </div>

      <div className="offcanvas-body p-4 pt-0">
        {/* ------- label form section start ------ */}
        <form onSubmit={(e) => e.preventDefault()}>
          {/* ------- role section start ------- */}
          <div className="form-group mb-4">
            <label htmlFor="name">{t("Select Role")}</label>
            <Select
              options={rolesList}
              value={roleValue}
              onChange={(val) => {
                roleSelectionHandler(val);
                setErrorMessage("");
                setValidation((prevState) => ({
                  ...prevState,
                  roleWarning: false,
                }));
              }}
            />
            {/* role warning */}
            {validation.roleWarning && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>{t("Please select role")}!</span>
                </p>
              </div>
            )}
          </div>
          {/* ------- role section end ------- */}

          {/* ------- module select section start ------- */}
          <div className="form-group mb-4">
            <label htmlFor="slug">{t("Module Previliege")}</label>
            <Select
              isMulti
              options={moduleList}
              value={moduleValue}
              onChange={(val) => {
                moduleSelectionHandler(val);
                setErrorMessage("");
                setValidation((prevState) => ({
                  ...prevState,
                  moduleWarning: false,
                }));
              }}
            />
            {/* module warning */}
            {validation.moduleWarning && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>{t("Please select role")}!</span>
                </p>
              </div>
            )}
          </div>
          {/* ------- module select section end ------- */}

          {errorMessage == "" ? null : (
            <div className="error-message mb-3">
              <p className="d-flex align-items-center gap-1 text-danger">
                <span className="material-symbols-outlined">warning</span>
                <span>{errorMessage}!</span>
              </p>
            </div>
          )}

          {/* ------- label submit button start ------- */}
          <div className="action">
            <button
              type="button"
              className="btn btn-primary mw-unset"
              disabled={isSending ? true : false}
              style={{ cursor: isSending ? "not-allowed" : "pointer" }}
              onClick={saveUpdatePreviledgeHandler}
            >
              {rolePriviledgeData ? t("Update") : t("Save")}
              {isSending && (
                <div
                  className="mx-2 spinner-border spinner-border-sm"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              )}
            </button>
          </div>
          {/* ------- label submit button end ------- */}
        </form>
        {/* ------- label form section end ------ */}
      </div>
    </div>
  );
};

export default AddUpdateRolePriviledgePopup;
