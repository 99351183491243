import React, { useEffect } from 'react';
import Header from 'components/common/Header';

import EmailVerificationLeftPanel from 'components/EmailVerificationComponents/LeftPanel/LeftPanel';
import EmailVerificationRightPanel from 'components/EmailVerificationComponents/RightPanel/RightPanel';

const EmailVerification = () => {
  useEffect(() => {
    document.title = 'Email verification'; // Update the title when Register component mounts
  }, []);

  return (
    <div id="site_wrapper">
      <Header />
      <section className="login_wrapper verify_wrapper">
        <EmailVerificationLeftPanel />
        <EmailVerificationRightPanel />
      </section>
    </div>
  );
};

export default EmailVerification;
