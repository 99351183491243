/* eslint-disable */
import { useEffect, useState } from 'react';
import LeftMenu from 'components/common/LeftMenu';
import UserMenu from 'components/common/UserMenu';
import CourseNav from 'components/CourseComponents/Header/CourseNav';
import CourseListArea from 'components/CourseComponents/CourseListArea';
import AddCoursePopup from 'components/CourseComponents/Popup/AddCoursePopup';
import ToggleColumn from 'components/CourseComponents/ToggleColumns/ToggleColumn';

const Course = () => {
  /* useState hook to download excel file */
  const [downloadExcel, setDownloadExcel] = useState(false);
  useEffect(() => {
    document.title = 'FunBrain | Course'; // Update the title when Register component mounts
  }, []);

  return (
    <>
      <UserMenu />
      <LeftMenu />
      <section className="batch-wrapper py-4">
        <div className="container-fluid px-4">
          <CourseNav setDownloadExcel={setDownloadExcel} />
          <ToggleColumn />
          <CourseListArea downloadExcel={downloadExcel} />
        </div>
      </section>
      <AddCoursePopup />
    </>
  );
};

export default Course;
