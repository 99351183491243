/* eslint-disable */
import React, { useState, useEffect, useContext, createRef } from "react";
import { Link } from "react-router-dom";
// kendo react modules ==========================
import "@progress/kendo-theme-material/dist/all.css";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";
// impoting excel colums
import {
  ExcelExport,
  ExcelExportColumn,
} from "@progress/kendo-react-excel-export";
//api url requirement
import * as url from "urlhelpers/UrlHelper";
import { getData } from "utils/Gateway";
//import context
import { GlobalProvider } from "context/GlobalContext";
//import translation function for language translation
import { useTranslation } from "react-i18next";
const ProjectList = ({
  downloadExcel,
  search,
  setRecords,
  checkedRecordCount,
}) => {
  const token = localStorage.getItem("token"); // token
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const { t } = useTranslation(); //for translation
  const {
    reloadProjectList,
    setreloadProjectList,
    seteditProjectId,
    filterDateRange,
    filterTitle,
    filterTags,
    screenHeight,
  } = useContext(GlobalProvider);
  const [projectList, setProjectList] = useState([]);
  const [responseReceived, setResponseReceived] = useState(false);
  // without pagination ------------------------------------
  const initialDataState = {};
  const [dataState, setDataState] = useState();
  const [result, setResult] = useState(process(projectList, initialDataState));
  const onDataStateChange = (event) => {
    setDataState(event.dataState);
    setResult(process(projectList, event.dataState));
  };
  /* Handle the button's onClick event and set it to export the data by using the ExcelExport save method. */
  const _exporter = createRef();
  const exportExcel = () => {
    if (_exporter.current) {
      _exporter.current.save();
    }
  };
  /* function to search */
  const filterData = () => {
    if (!result || !result.data) {
      return []; // Return an empty array if result or result.data is undefined
    }
    const filteredData = result.data.filter((item) => {
      const title = item?.title?.toLowerCase() || "";
      const owner = item?.owner?.toLowerCase() || "";
      const startdate = item?.startdate;
      const enddate = item?.enddate;
      return (
        (title && title.includes(search.toLowerCase())) ||
        (owner && owner.includes(search.toLowerCase())) ||
        (startdate && startdate.includes(search)) ||
        (enddate && enddate.includes(search))
      );
    });
    setRecords(filteredData.length);
    return filteredData;
  };
  /* function to get all label data */
  const getAllProjectList = async () => {
    try {
      let requestUrl =
        url.API_BASEURL +
        url.API_GET_ALL_PROJECTS +
        `?token=${token}&userrolestring=${userInfo.role.name}`;
      requestUrl +=
        filterDateRange != "" ? `&filterdates=${filterDateRange}` : "";
      if (filterTitle != "") {
        requestUrl = requestUrl + `&filtertitle=${filterTitle}`;
      }
      if (filterTags.length > 0) {
        requestUrl = requestUrl + `&filtertags=${filterTags}`;
      }
      console.log("project list url =====>>>", requestUrl);
      const response = await getData(requestUrl);
      console.log("project list response ========>>>", response);
      if (response.data) {
        setProjectList(response.data);
        setResponseReceived(true);
        dataState
          ? setResult(process(response.data, dataState))
          : setResult(process(response.data, initialDataState));
        setRecords(response.data.length);
      }
    } catch (error) {
      console.error(error.message);
    }
  };
  // check the selected count and show the count in top and also selected download xls ------------------------
  const handelSelectedCount = () => {
    let checkboxes = document.querySelectorAll(
      'input[name="selectProjectRecord"]'
    );
    let totalSelected = 0;
    let filteredData = [];
    checkboxes.forEach((checkbox) => {
      if (checkbox.checked) {
        totalSelected++;
        const filteredUser = projectList.find(
          (user) => user._id === checkbox.value
        );
        filteredUser && filteredData.push(filteredUser);
      }
    });
    // Update the content of the block
    checkedRecordCount(totalSelected);
    return filteredData;
  };
  // mark all checkbox as selected ----------------
  const selectAllRecords = (e) => {
    document
      .querySelectorAll('.k-master-row input[name="selectProjectRecord"]')
      .forEach((checkbox) => {
        checkbox.checked = e.target.checked;
      });
    handelSelectedCount();
  };
  // blocks to replace the Grid HTML ==============================================
  const SelectRecordHeader = () => {
    return (
      <div className="d-flex align-items-center">
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            id="selectAllMain"
            onClick={selectAllRecords}
          />
        </div>
      </div>
    );
  };
  /* Select single row */
  const SelectRecordRow = (props) => {
    return (
      <td>
        <div className="form-check">
          <input
            className="form-check-input "
            type="checkbox"
            value={props.dataItem._id}
            filtername="selectedrecord"
            name="selectProjectRecord"
            onClick={handelSelectedCount}
          />
        </div>
      </td>
    );
  };
  /* custom title html */
  const titleHtml = ({ dataItem }) => {
    return (
      <td className="title">
        <div className="d-flex align-items-center gap-2">
          <span>{dataItem.title}</span>
          <Link
            to="#addUpdateProjectPopup"
            data-bs-toggle="offcanvas"
            className="ml-auto edit_icon"
          >
            <span
              className="d-block material-symbols-outlined icon-md"
              onClick={() => {
                seteditProjectId(dataItem._id);
              }}
            >
              edit
            </span>
          </Link>
        </div>
      </td>
    );
  };
  useEffect(() => {
    if (filterDateRange != "" || filterTitle != "" || filterTags.length > 0) {
      getAllProjectList();
    }
  }, [filterDateRange, filterTitle, filterTags]);
  /* hook to reload list */
  useEffect(() => {
    if (reloadProjectList || !responseReceived) {
      getAllProjectList();
      setResult(process(projectList, initialDataState));
      if (reloadProjectList) {
        setreloadProjectList(false);
      }
    }
  }, [reloadProjectList, responseReceived]);
  /* hook to hit  exportExcel() function*/
  useEffect(() => {
    downloadExcel && exportExcel();
  }, [downloadExcel]);
  /* hook to hit filterData function on search */
  useEffect(() => {
    filterData();
  }, [search]);
  /* set records based on onDataStateChange */
  useEffect(() => {
    setRecords(result.data.length);
  }, [result]);
  return (
    <div className="table-responsive">
      {/* ============ download xls start ========== */}
      <ExcelExport
        data={
          handelSelectedCount().length > 0 ? handelSelectedCount() : projectList
        }
        fileName="Project.xlsx"
        ref={_exporter}
      >
        <ExcelExportColumn field="title" title={t("Title")} />
        <ExcelExportColumn field="owner" title={t("Moderator")} />
        <ExcelExportColumn field="projectstartdate" title={t("Start Date")} />
        <ExcelExportColumn field="projectenddate" title={t("End Date")} />
      </ExcelExport>
      {/* ============ download xls end ========== */}
      {search ? (
        <Grid
          style={{ height: screenHeight }}
          data={filterData()}
          filterable={true}
          sortable={true}
          groupable={true}
          onDataStateChange={onDataStateChange}
          {...dataState}
          //pageable = {true}
          //total = {products.length}
          reorderable
          // resizable
        >
          <GridColumn
            field="id"
            headerCell={SelectRecordHeader}
            cell={SelectRecordRow}
            filterable={false}
            sortable={false}
            width="70px"
          />
          <GridColumn
            cell={titleHtml}
            field="title"
            title={t("Title")}
            width="200px"
          />
          <GridColumn field="owner" title={t("Moderator")} width="200px" />
          <GridColumn
            field="projectstartdate"
            title={t("Start Date")}
            width="200px"
          />
          <GridColumn
            field="projectenddate"
            title={t("End Date")}
            width="200px"
          />
          <GridColumn field="tagstring" title={t("Labels")} width="200px" />
        </Grid>
      ) : (
        <Grid
          style={{ height: screenHeight }}
          data={result}
          filterable={true}
          sortable={true}
          groupable={true}
          onDataStateChange={onDataStateChange}
          {...dataState}
          //pageable = {true}
          //total = {products.length}
          reorderable
          // resizable
        >
          <GridColumn
            field="id"
            headerCell={SelectRecordHeader}
            cell={SelectRecordRow}
            filterable={false}
            sortable={false}
            width="70px"
          />
          <GridColumn
            cell={titleHtml}
            field="title"
            title={t("Title")}
            width="200px"
          />
          <GridColumn field="owner" title={t("Moderator")} width="200px" />
          <GridColumn
            field="projectstartdate"
            title={t("Start Date")}
            width="200px"
          />
          <GridColumn
            field="projectenddate"
            title={t("End Date")}
            width="200px"
          />
          <GridColumn field="tagstring" title={t("Labels")} width="200px" />
        </Grid>
      )}
    </div>
  );
};
export default ProjectList;
