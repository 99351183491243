/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
//import translation function for language translation
import { useTranslation } from "react-i18next";
/* Importing react select */
import Select from "react-select";
//import options from json file
import ReserveStatus from "data/Prod/ReserveStatus.json";
//import context
import { GlobalProvider } from "context/GlobalContext";
/*import url and gateway methods */
import { getData } from "utils/Gateway";
import * as url from "urlhelpers/UrlHelper";
const CostFilter = () => {
  const token = localStorage.getItem("token"); // token
  const { t } = useTranslation(); //for translation
  const todayValue = new Date().toISOString().split("T")[0];
  const {
    setReloadCostList,
    setSearchCostDate,
    setSearchCostEducators,
    setSearchCostClients,
  } = useContext(GlobalProvider);
  const [selectedChecked, setSelectedChecked] = useState("");
  const [fromDate, setFromDate] = useState(todayValue);
  const [toDate, setToDate] = useState(todayValue);
  const [selectedDate, setSelectedDate] = useState(todayValue);
  const [clientList, setClientList] = useState([]); //client option
  const [educatorList, setEducatorList] = useState([]); //educator option
  const [educatorValue, setEducatorValue] = useState(null);
  const [selectedEducators, setSelectedEducators] = useState([]);
  const [clientValue, setClientValue] = useState(null);
  const [selectedClients, setSelectedClients] = useState([]);
  //function for select radio
  const radioCheckHandler = (event) => {
    setSelectedChecked(event.target.value);
  };
  /* function to get all client list */
  const getALlClientList = async () => {
    try {
      let clientRequestUrl =
        url.API_BASEURL +
        url.API_GET_ALL_USER +
        `?token=${token}&isselect=${true}&slug=client`;
      let benificiaryRequestUrl =
        url.API_BASEURL +
        url.API_GET_ALL_USER +
        `?token=${token}&isselect=${true}&slug=beneficiary`;
      const clientResponse = await getData(clientRequestUrl);
      const beneficiaryResponse = await getData(benificiaryRequestUrl);
      if (clientResponse.status && beneficiaryResponse.status) {
        let clientArr = [];
        clientArr = [...clientResponse.data, ...beneficiaryResponse.data];
        setClientList(clientArr);
      }
    } catch (error) {
      console.error(error.message);
    }
  };
  /* function to get all client list */
  const getALlEducatorList = async () => {
    try {
      let requestUrl =
        url.API_BASEURL +
        url.API_GET_ALL_USER +
        `?token=${token}&isselect=${true}&slug=educator`;
      const response = await getData(requestUrl);
      if (response.status) {
        setEducatorList(response.data);
      }
    } catch (error) {
      console.error(error.message);
    }
  };
  /* function to select educators */
  const educatorsSelectionHandler = (val) => {
    setEducatorValue(val);
    setSelectedEducators(val.map((educator) => educator.value));
  };
  /* function to select client */
  const clientSelectionHandler = (val) => {
    setClientValue(val);
    setSelectedClients(val.map((client) => client.value));
  };
  // Hook for date compare validation
  const [dateWarning, setDateWarning] = useState(false);
  //function for search
  const searchHandler = () => {
    /*===== switch statement for date input radio start ======*/
    switch (selectedChecked) {
      case "daterange":
        if (fromDate < toDate) {
          setSearchCostDate(fromDate + "," + toDate);
        } else {
          setDateWarning(true);
        }
        break;
      case "todayradio":
        setSearchCostDate(todayValue);
        break;
      case "salesdate":
        setSearchCostDate(selectedDate);
        break;
      default:
        setSearchCostDate("");
        break;
    }
    /*===== switch statement for date input radio end ======*/
    //assign clients
    selectedClients.length > 0 && setSearchCostClients(selectedClients);
    //assign educators
    selectedEducators.length > 0 && setSearchCostEducators(selectedEducators);
  };
  //function for reset
  const resetHandler = () => {
    setSelectedChecked("");
    setFromDate(todayValue);
    setToDate(todayValue);
    setSelectedDate(todayValue);
    setClientValue(null);
    setSelectedClients([]);
    setEducatorValue(null);
    setSelectedEducators([]);
    setSearchCostDate("");
    setSearchCostEducators([]);
    setSearchCostClients([]);
    setReloadCostList(true);
  };
  useEffect(() => {
    getALlClientList();
    getALlEducatorList();
  }, []);
  return (
    <div className="collapse mt-3" id="filterCollapse">
      <div className="p-3 bg-gray-200">
        <h4 className="mb-3">{t("Filter")}</h4>
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="form-group mb-3">
            <div className="row align-items-center">
              {/* -------- date range section start -------- */}
              <div className="col-lg mb-3 mb-lg-0">
                <div className="d-flex align-items-center">
                  {/* -------- date input radio section start -------- */}
                  <div className="form-check mb-0 me-3">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="daterange"
                      id="dateRange"
                      value="daterange"
                      checked={selectedChecked === "daterange"}
                      onChange={radioCheckHandler}
                    />
                  </div>
                  {/* -------- date input radio section end -------- */}
                  {/* -------- date selection section start -------- */}
                  <div className="field-container d-sm-flex border flex-fill">
                    {/* ------- from date input start ------- */}
                    <input
                      type="date"
                      id="from_date"
                      className="form-control bg-white border-0 mb-3 mb-sm-0"
                      placeholder={t("From date")}
                      value={fromDate}
                      onChange={(e) => {
                        setFromDate(e.target.value), setDateWarning(false);
                      }}
                    />
                    {/* ------- from date input end ------- */}
                    {/* ------- to date input start ------- */}
                    <input
                      type="date"
                      id="to_date"
                      className="form-control bg-white border-0"
                      placeholder={t("To date")}
                      value={toDate}
                      onChange={(e) => {
                        setToDate(e.target.value), setDateWarning(false);
                      }}
                    />
                    {/* ------- to date input end ------- */}
                  </div>
                  {/* -------- date selection section end -------- */}
                </div>
                {/* date compare warning */}
                {dateWarning && (
                  <div className="error-message mt-2">
                    <p className="d-flex align-items-center gap-1 text-danger">
                      <span className="material-symbols-outlined">warning</span>
                      <span>
                        {t("End date should be greater than start date")}!
                      </span>
                    </p>
                  </div>
                )}
              </div>
              {/* -------- date range section end -------- */}
              {/* --------- today section start --------- */}
              <div className="col-lg-auto mb-3 mb-lg-0">
                <div className="form-check mb-0">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="todayradio"
                    id="today"
                    value="todayradio"
                    checked={selectedChecked === "todayradio"}
                    onChange={radioCheckHandler}
                  />
                  <label className="form-check-label mb-0" htmlFor="today">
                    {t("Today")}
                  </label>
                </div>
              </div>
              {/* --------- today section end --------- */}
              {/* --------- date section start --------- */}
              <div className="col-lg">
                <div className="d-flex align-items-center">
                  <div className="form-check mb-0 me-3">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="salesdate"
                      id="multipleDates"
                      value="salesdate"
                      checked={selectedChecked === "salesdate"}
                      onChange={radioCheckHandler}
                    />
                  </div>
                  <input
                    type="date"
                    id="to_date"
                    className="form-control bg-white"
                    placeholder={t("Select Multiple Dates")}
                    value={selectedDate}
                    onChange={(e) => setSelectedDate(e.target.value)}
                  />
                </div>
              </div>
              {/* --------- date section end --------- */}
            </div>
          </div>
          {/* ------ client and educator section start ------ */}
          <div className="form-group mb-3">
            <div className="row align-items-center">
              <div className="col">
                <Select
                  isMulti
                  options={clientList}
                  placeholder={t("Select clients")}
                  value={clientValue}
                  onChange={(val) => {
                    clientSelectionHandler(val);
                  }}
                />
              </div>
              <div className="col">
                <Select
                  isMulti
                  options={educatorList}
                  placeholder={t("Select Educator")}
                  value={educatorValue}
                  onChange={(val) => {
                    educatorsSelectionHandler(val);
                  }}
                />
              </div>
            </div>
          </div>
          {/* ------ client and educator section end ------ */}
          <div className="form-group">
            <div className="row">
              <div className="col">
                <div className="action d-flex gap-3 align-items-center justify-content-end">
                  <Link
                    to="#"
                    onClick={resetHandler}
                    className="d-flex gap-1 align-items-center text-black"
                  >
                    <span className="d-block material-symbols-outlined icon-md">
                      sync
                    </span>
                    <span className="d-block fw-medium">{t("Refresh")}</span>
                  </Link>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={searchHandler}
                  >
                    {t("Filter")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
export default CostFilter;
