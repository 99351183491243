/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import UserMenu from "components/common/UserMenu";
import LeftMenu from "components/common/LeftMenu";
import ConceptBreadCrumb from "components/ConceptComponents/BreadCrumb/ConceptBreadCrumb";
import ConceptHeader from "components/ConceptComponents/Header/ConceptHeader";
import ConceptList from "components/ConceptComponents/ConceptList/ConceptList";
import AddUpdateConceptPopup from "components/ConceptComponents/Popup/AddUpdateConceptPopup";
import CustomNotification from "components/common/CustomNotification";

const Concept = () => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  /* useState hook to download excel file */
  const [downloadExcel, setDownloadExcel] = useState(false);

  /* hook to search */
  const [search, setSearch] = useState("");
  /* hook to get number of records */
  const [records, setRecords] = useState(0);

  const blockRefSelected = useRef(null);

  const checkedRecordCount = (selectCount) => {
    const blockElementSelect = ReactDOM.findDOMNode(blockRefSelected.current);
    if (blockElementSelect) {
      blockElementSelect.innerHTML = selectCount;
    }
  };

  useEffect(() => {
    document.title = "Concept"; // Update the title when Register component mounts
  }, []);

  return (
    <>
      <UserMenu />
      <LeftMenu />
      {userInfo?.role?.name == "admin" || userInfo?.role?.name == "manager" ? (
        <section className="mail-wrapper pt-2">
          <div className="container-fluid px-4">
            <ConceptBreadCrumb />
            <ConceptHeader
              setDownloadExcel={setDownloadExcel}
              setSearch={setSearch}
              records={records}
              blockRefSelected={blockRefSelected}
            />
            <ConceptList
              downloadExcel={downloadExcel}
              search={search}
              setRecords={setRecords}
              checkedRecordCount={checkedRecordCount}
            />
          </div>
        </section>
      ) : null}
      <AddUpdateConceptPopup />
      {/*----------- custom notification component start -----------*/}
      <CustomNotification />
      {/*----------- custom notification component end -----------*/}
    </>
  );
};

export default Concept;
