import React, { useContext } from "react";
/* importing kendo react notification */
import {
  Notification,
  NotificationGroup,
} from "@progress/kendo-react-notification";

//import context
import { GlobalProvider } from "context/GlobalContext";

/* Add animation to the component by importing the Fade animation. */
import { Fade } from "@progress/kendo-react-animation";

const CustomNotification = () => {
  const {
    success,
    setSuccess,
    error,
    setError,
    notificationString,
    setNotificationString,
  } = useContext(GlobalProvider);

  setTimeout(() => {
    setSuccess(false);
    setError(false);
    setNotificationString("");
  }, 4000);

  return (
    <NotificationGroup
      style={{
        left: 0,
        bottom: 0,
        alignItems: "flex-start",
        flexWrap: "wrap-reverse",
      }}
    >
      <Fade>
        {success && (
          <Notification
            type={{ style: "success", icon: true }}
            closable={true}
            onClose={() => setSuccess(false)}
          >
            <span>{notificationString}</span>
          </Notification>
        )}
        {error && (
          <Notification
            type={{ style: "error", icon: true }}
            closable={true}
            onClose={() => setSuccess(false)}
          >
            <span>{notificationString}</span>
          </Notification>
        )}
      </Fade>
    </NotificationGroup>
  );
};

export default CustomNotification;
