import * as url from "urlhelpers/UrlHelper";

const downloadFileHandler = async (file) => {
  try {

    console.log('download from frontend',file);
    
    const actualFilePath = url.SERVER_URL + file.path
    //const actualFilePath = file.path
    const response = await fetch(actualFilePath);
    const blob = await response.blob();

    const fileName = file.name;

    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(blob, fileName);

    } else {
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = fileName;
      link.click();
      window.URL.revokeObjectURL(url);
    }

  } catch (error) {
    console.error("Error occurred while downloading the file:", error);
  }
};

export default downloadFileHandler;
