export const getUserBrowser = () => {
  const userAgent = navigator.userAgent;

  const browserPatterns = {
    Chrome: /Chrome/i,
    Firefox: /Firefox/i,
    Safari: /Safari/i,
    Edge: /Edg/i,
    IE: /MSIE|Trident\//i,
    Opera: /Opera|OPR\//i,
    Brave: /Brave/i,
    Vivaldi: /Vivaldi/i,
    UCBrowser: /UCBrowser/i,
    SamsungBrowser: /SamsungBrowser/i,
    // Add more browser patterns as needed
  };

  let detectedBrowser = "Unknown Browser";

  for (const browser in browserPatterns) {
    if (userAgent.match(browserPatterns[browser])) {
      detectedBrowser = browser;
      break;
    }
  }

  return detectedBrowser;
};

export const getPublicIP = async () => {
  try {
    const response = await fetch("https://api.ipify.org?format=json");
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    return data.ip;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getTimeStamp = () => {
  const now = new Date();

  const loginDate = now.toISOString().split("T")[0];

  const hours = String(now.getHours()).padStart(2, "0");
  const minutes = String(now.getMinutes()).padStart(2, "0");

  const loginTime = `${hours}:${minutes}`;

  const loginTimeStamp = now.toISOString().replace("Z", "+00:00");

  return {
    loginDate,
    loginTime,
    loginTimeStamp,
  };
};
