/* eslint-disable */
import React, { useState, useEffect, createRef, useContext } from "react";

// kendo react modules ==========================
import "@progress/kendo-theme-material/dist/all.css";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";
// impoting excel colums
import {
  ExcelExport,
  ExcelExportColumn,
} from "@progress/kendo-react-excel-export";
//import translation function for language translation
import { useTranslation } from "react-i18next";

/*import url and gateway methods */
import { postData, getData, putData } from "utils/Gateway";
import * as url from "urlhelpers/UrlHelper";

//import context
import { GlobalProvider } from "context/GlobalContext";
import { Link, useParams } from "react-router-dom";

const FeedbackResponseList = ({
  downloadExcel,
  search,
  checkedRecordCount,
  setRecords,
}) => {
  const token = localStorage.getItem("token"); // to get the token

  const params = useParams();

  const {
    setEditSurveyData,
    screenHeight,
    setReloadFeedbackResponse,
    reloadFeedbackResponse,
  } = useContext(GlobalProvider);

  /* hook to get feedback response data */
  const [feedbackResponseData, setFeedbackResponseData] = useState([]);
  const [responseReceived, setResponseReceived] = useState(false);

  // without pagination ------------------------------------
  const initialDataState = {};
  const [dataState, setDataState] = useState();
  const [result, setResult] = useState(
    process(feedbackResponseData, initialDataState)
  );

  const onDataStateChange = (event) => {
    setDataState(event.dataState);
    setResult(process(feedbackResponseData, event.dataState));
  };

  /* Handle the button's onClick event and set it to export the data by using the ExcelExport save method. */
  const _exporter = createRef();
  const exportExcel = () => {
    if (_exporter.current) {
      _exporter.current.save();
    }
  };

  // check the selected count and show the count in top and also selected download xls ------------------------
  const handelSelectedCount = () => {
    let checkboxes = document.querySelectorAll(
      'input[name="selectFeedbackRecord"]'
    );

    let totalSelected = 0;
    let filteredData = [];
    checkboxes.forEach((checkbox) => {
      if (checkbox.checked) {
        totalSelected++;
        const filteredUser = feedbackResponseData.find(
          (user) => user._id === checkbox.value
        );
        filteredUser && filteredData.push(filteredUser);
      }
    });

    // Update the content of the block
    checkedRecordCount(totalSelected);
    return filteredData;
  };
  // mark all checkbox as selected ----------------
  const selectAllRecords = (e) => {
    document
      .querySelectorAll('.k-master-row input[name="selectFeedbackRecord"]')
      .forEach((checkbox) => {
        checkbox.checked = e.target.checked;
      });
    handelSelectedCount();
  };

  // blocks to replace the Grid HTML ==============================================
  const SelectRecordHeader = () => {
    return (
      <div className="d-flex align-items-center">
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            id="selectAllMain"
            name="selectAllFeedbackRecord"
            onClick={selectAllRecords}
          />
        </div>
      </div>
    );
  };

  /* Select single row */
  const SelectRecordRow = (props) => {
    return (
      <td className="form-check py-3">
        <input
          className="form-check-input "
          type="checkbox"
          value={props.dataItem._id}
          name="selectFeedbackRecord"
          onClick={handelSelectedCount}
        />
      </td>
    );
  };

  const { t } = useTranslation(); //for translation

  // console.log(`editSurveyData=============>>>`, editSurveyData);

  //function for get feedback responses
  const getAllFeedBackResponse = async (e) => {
    try {
      let requestUrl =
        url.API_BASEURL +
        url.API_GET_ALL_SURVEY_FEEDBACK +
        `?token=${token}&surveyid=${params.id}`;

      console.log("question list url =====>>>", requestUrl);

      const response = await getData(requestUrl);

      console.log("feedback responses list response ========>>>", response);

      if (response.data) {
        setFeedbackResponseData(response.data);
        setResponseReceived(true);
        dataState
          ? setResult(process(response.data, dataState))
          : setResult(process(response.data, initialDataState));
        setRecords(response.data.length);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  /* -------- global search function ---------------- */
  const filterData = () => {
    if (!result || !result.data) {
      return []; // Return an empty array if result or result.data is undefined
    }

    const filteredData = result.data.filter((item) => {
      const survey = item?.survey?.name?.toLowerCase() || "";
      const reservationID = item?.reservation?.reserveid.toString() || "";
      const user = item?.user?.toLowerCase() || "";
      const label = item?.user?.role?.name?.toLowerCase() || "";
      const status = item?.surveyresponse?.toLowerCase() || "";
      const requestDate = item?.surveydate || "";
      const responseDate = item?.responsedate || "";

      return (
        (survey && survey.includes(search.toLowerCase())) ||
        (reservationID && reservationID.includes(search)) ||
        (user && user.includes(search.toLowerCase())) ||
        (label && label.includes(search.toLowerCase())) ||
        (status && status.includes(search.toLowerCase())) ||
        (requestDate && requestDate.includes(search)) ||
        (responseDate && responseDate.includes(search))
      );
    });
    setRecords(filteredData.length);
    return filteredData;
  };

  /* ---------- custom name html  ---------------- */
  const surveyNameHtml = ({ dataItem }) => {
    return (
      <td className="kendoCustomColumn overflow-visible">
        <div className="d-flex align-items-center">
          <p>{dataItem.survey.name}</p>
          <Link
            to="#view_all_survey_feedback_popup"
            data-bs-toggle="offcanvas"
            className="ml-auto edit_icon"
            onClick={() => setEditSurveyData(dataItem)}
          >
            <span className="material-icons-outlined">chevron_right</span>
          </Link>
        </div>
      </td>
    );
  };

  //custom html for status
  const statusHTML = (props) => {
    // const textColor =
    //   props.dataItem.surveyresponsestatus == '1' ? 'green_text' : 'text-danger';

    return (
      <td>
        <span>{props.dataItem.surveyresponse}</span>
      </td>
    );
  };

  useEffect(() => {
    if (reloadFeedbackResponse) {
      getAllFeedBackResponse();
      setReloadFeedbackResponse(false);
      setResult(process(feedbackResponseData, initialDataState));
    }
  }, [reloadFeedbackResponse]);

  /* hook to hit  exportExcel() function*/
  useEffect(() => {
    downloadExcel && exportExcel();
  }, [downloadExcel]);

  useEffect(() => {
    getAllFeedBackResponse();
  }, [params.id]);

  // console.log(`filterData`, filterData());

  /* hook to hit filterData function on search */
  useEffect(() => {
    filterData();
  }, [search]);

  return (
    <div className="table-responsive">
      {/* ============ download xls start ========== */}
      <ExcelExport
        data={
          handelSelectedCount().length > 0
            ? handelSelectedCount()
            : feedbackResponseData
        }
        fileName="Feedback Responses.xlsx"
        ref={_exporter}
      >
        <ExcelExportColumn field="survey.name" title={t("Survey")} />
        <ExcelExportColumn
          field="customreserveid"
          title={t("Reservation ID")}
        />
        <ExcelExportColumn field="user" title={t("User")} />
        <ExcelExportColumn field="user.role.name" title={t("Label")} />
        <ExcelExportColumn
          field="surveyresponse"
          title={t("Status")}
          cell={statusHTML}
        />
        <ExcelExportColumn field="surveydate" title={t("Request Date")} />
        <ExcelExportColumn field="responsedate" title={t("Response Date")} />
      </ExcelExport>

      {/* ============ download xls end ========== */}

      {/* ------------------- grid view start -------------------- */}
      {search ? (
        <Grid
          style={{ height: screenHeight - 50 }}
          data={filterData()}
          filterable={true}
          sortable={true}
          groupable={true}
          onDataStateChange={onDataStateChange}
          {...dataState}
          //pageable = {true}
          //total = {products.length}
          reorderable
          // resizable
        >
          <GridColumn
            field="id"
            headerCell={SelectRecordHeader}
            cell={SelectRecordRow}
            filterable={false}
            sortable={false}
            width="50px"
          />
          <GridColumn
            field="survey.name"
            title={t("Survey")}
            width="200px"
            cell={surveyNameHtml}
          />
          <GridColumn
            field="customreserveid"
            title={t("Reservation ID")}
            width="200px"
          />
          <GridColumn field="user" title={t("User")} width="200px" />
          <GridColumn field="user.role.name" title={t("Label")} width="200px" />
          <GridColumn
            field="surveyresponse"
            title={t("Status")}
            width="200px"
            cell={statusHTML}
          />
          <GridColumn
            field="surveydate"
            title={t("Request Date")}
            width="200px"
          />
          <GridColumn
            field="responsedate"
            title={t("Response Date")}
            width="200px"
          />
        </Grid>
      ) : (
        <Grid
          style={{ height: screenHeight - 50 }}
          data={result}
          filterable={true}
          sortable={true}
          groupable={true}
          onDataStateChange={onDataStateChange}
          {...dataState}
          //pageable = {true}
          //total = {products.length}
          reorderable
          // resizable
        >
          <GridColumn
            field="id"
            headerCell={SelectRecordHeader}
            cell={SelectRecordRow}
            filterable={false}
            sortable={false}
            width="50px"
          />
          <GridColumn
            field="survey.name"
            title={t("Survey")}
            width="200px"
            cell={surveyNameHtml}
          />
          <GridColumn
            field="customreserveid"
            title={t("Reservation ID")}
            width="200px"
          />
          <GridColumn field="user" title={t("User")} width="200px" />
          <GridColumn field="user.role.name" title={t("Label")} width="200px" />
          <GridColumn
            field="surveyresponse"
            title={t("Status")}
            width="200px"
            cell={statusHTML}
          />
          <GridColumn
            field="surveydate"
            title={t("Request Date")}
            width="200px"
          />
          <GridColumn
            field="responsedate"
            title={t("Response Date")}
            width="200px"
          />
        </Grid>
      )}
      {/* ------------------- grid view end -------------------- */}
    </div>
  );
};

export default FeedbackResponseList;
