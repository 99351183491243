/* eslint-disable */
import { useState, useEffect } from "react";

//import translation function for language translation
import { useTranslation } from "react-i18next";

/*import url and gateway methods */
import { getData } from "utils/Gateway";
import * as url from "urlhelpers/UrlHelper";
import { useLocation } from "react-router-dom";

const ReservationDetailsSection = () => {
  const { t } = useTranslation(); //for translation

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const reserveid = searchParams.get("reserveid");

  //use states for reserv data
  const [reserveData, setReserveData] = useState({
    ambid: null,
    date: "",
    starttime: "",
    endtime: "",
    activitycode: "",
    activitname: "",
    activitytype: "",
    tagstring: "",
    educator1: null,
    educator2: null,
    client: null,
    benificiary: null,
    observer: null,
    participantscount: "",
    educatorcount: "",
    comments: "",
    location: "",
    reservestatus: "",
  });

  const getReservationDetails = async () => {
    try {
      let requestUrl =
        url.API_BASEURL +
        url.API_GET_RESERVE_DETAILS +
        `?reserveid=${reserveid}`;

      const response = await getData(requestUrl);

      console.log(response);

      if (response.status) {
        setReserveData({
          ambid: response.data.ambreservationid,
          date: response.data.startdate,
          starttime: response.data.starttime,
          endtime: response.data.endtime,
          activitycode: response.data.activitycode.code,
          activitname: response.data.activitycode.name,
          activitytype: response.data.activitytype,
          tagstring: response.data.tagstring,
          educator1: response.data.educator1,
          educator2: response.data.educator2,
          client: response.data.client,
          beneficiary: response.data.beneficiary,
          observer: response.data.observer,
          participantscount: response.data.participantcount,
          educatorcount: response.data.educatorcount,
          comments: response.data.comments,
          location: response.data.location,
          reservestatus: response.data.reservestatus,
        });
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getReservationDetails();
  }, []);

  return (
    <section className="reservation_registration_sec">
      <div className="container-fluid">
        {/* ------ activity title section start ------ */}
        <div className="registration_subhdng mt-3">
          <h3>
            {t("Activity")}: {reserveData.activitname}
          </h3>
        </div>
        {/* ------ activity title section end ------ */}

        <div className="booking_status_area">
          {/* --------- reserve id section start ---------- */}
          <div className="row">
            <div className="col-md-3">
              <div className="status_label">
                <label>{t("ID")}:</label>
              </div>
            </div>
            <div className="col-md-9">
              <div className="status_info">
                <p>{reserveid}</p>
              </div>
            </div>
          </div>
          {/* --------- reserve id section end ---------- */}

          {/* --------- amb reserve id section start -------- */}
          <div className="row">
            <div className="col-md-3">
              <div className="status_label">
                <label>{t("AMB ID")}:</label>
              </div>
            </div>
            <div className="col-md-9">
              <div className="status_info">
                <p>{reserveData.ambid}</p>
              </div>
            </div>
          </div>
          {/* --------- amb reserve id section end -------- */}

          {/* ------- reserve date section start --------- */}
          <div className="row">
            <div className="col-md-3">
              <div className="status_label">
                <label>{t("Date of Reservation")}:</label>
              </div>
            </div>
            <div className="col-md-9">
              <div className="status_info">
                <p>{reserveData.date}</p>
              </div>
            </div>
          </div>
          {/* ------- reserve date section end --------- */}

          {/* ------- reserve start time section start --------- */}
          <div className="row">
            <div className="col-md-3">
              <div className="status_label">
                <label>{t("From time")}:</label>
              </div>
            </div>
            <div className="col-md-9">
              <div className="status_info">
                <p>{reserveData.starttime}</p>
              </div>
            </div>
          </div>
          {/* ------- reserve start time section end --------- */}

          {/* ------- reserve start time section start --------- */}
          <div className="row">
            <div className="col-md-3">
              <div className="status_label">
                <label>{t("Until time")}:</label>
              </div>
            </div>
            <div className="col-md-9">
              <div className="status_info">
                <p>{reserveData.endtime}</p>
              </div>
            </div>
          </div>
          {/* ------- reserve start time section end --------- */}

          {/* ------- reserve activity code section start --------- */}
          <div className="row">
            <div className="col-md-3">
              <div className="status_label">
                <label>{t("Activity Code")}</label>
              </div>
            </div>
            <div className="col-md-9">
              <div className="status_info">
                <p>{reserveData.activitycode}</p>
              </div>
            </div>
          </div>
          {/* ------- reserve activity code section end --------- */}

          {/* --------- reserve type of activity section start --------- */}
          <div className="row">
            <div className="col-md-3">
              <div className="status_label">
                <label>{t("Type of activity")}:</label>
              </div>
            </div>
            <div className="col-md-9">
              <div className="status_info">
                <p>{reserveData.activitytype}</p>
              </div>
            </div>
          </div>
          {/* --------- reserve type of activity section start --------- */}

          {/* ------- reserve tags section start --------- */}
          <div className="row">
            <div className="col-md-3">
              <div className="status_label">
                <label>{t("Tags")}:</label>
              </div>
            </div>
            <div className="col-md-9">
              <div className="status_info">
                <p>{reserveData.tagstring}</p>
              </div>
            </div>
          </div>
          {/* ------- reserve tags section end --------- */}

          {/* ------- reserve educator one section start --------- */}
          <div className="row">
            <div className="col-md-3">
              <div className="status_label">
                <label>{t("Educator one")}:</label>
              </div>
            </div>
            <div className="col-md-9">
              <div className="status_info">
                <p>{reserveData.educator1}</p>
              </div>
            </div>
          </div>
          {/* ------- reserve educator one section end --------- */}

          {/* ------- reserve clinet section start --------- */}
          <div className="row">
            <div className="col-md-3">
              <div className="status_label">
                <label>{t("Client")}:</label>
              </div>
            </div>
            <div className="col-md-9">
              <div className="status_info">
                <p>{reserveData.client}</p>
              </div>
            </div>
          </div>
          {/* ------- reserve clinet section end --------- */}

          {/* ------- reserve observer section start --------- */}
          <div className="row">
            <div className="col-md-3">
              <div className="status_label">
                <label>{t("Observer")}:</label>
              </div>
            </div>
            <div className="col-md-9">
              <div className="status_info">
                <p>{reserveData.observer}</p>
              </div>
            </div>
          </div>
          {/* ------- reserve observer section end --------- */}

          {/* ------- reserve benificiary section start --------- */}
          <div className="row">
            <div className="col-md-3">
              <div className="status_label">
                <label>{t("Beneficiary")}:</label>
              </div>
            </div>
            <div className="col-md-9">
              <div className="status_info">
                <p>{reserveData.benificiary}</p>
              </div>
            </div>
          </div>
          {/* ------- reserve benificiary section end --------- */}

          {/* ------- reserve no of participants section start --------- */}
          <div className="row">
            <div className="col-md-3">
              <div className="status_label">
                <label>{t("No of participants")}:</label>
              </div>
            </div>
            <div className="col-md-9">
              <div className="status_info">
                <p>{reserveData.participantscount}</p>
              </div>
            </div>
          </div>
          {/* ------- reserve no of participants section end --------- */}

          {/* ------- reserve no of educators section start --------- */}
          <div className="row">
            <div className="col-md-3">
              <div className="status_label">
                <label>{t("No of educators")}:</label>
              </div>
            </div>
            <div className="col-md-9">
              <div className="status_info">
                <p>{reserveData.educatorcount}</p>
              </div>
            </div>
          </div>
          {/* ------- reserve no of educators section end --------- */}

          {/* ------- reserve comment section start --------- */}
          <div className="row">
            <div className="col-md-3">
              <div className="status_label">
                <label>{t("Comment")}:</label>
              </div>
            </div>
            <div className="col-md-9">
              <div className="status_info">
                <p>{reserveData.comments}</p>
              </div>
            </div>
          </div>
          {/* ------- reserve comment section end --------- */}

          {/* ------- reserve location section start --------- */}
          <div className="row">
            <div className="col-md-3">
              <div className="status_label">
                <label>{t("Location")}:</label>
              </div>
            </div>
            <div className="col-md-9">
              <div className="status_info">
                <p>{reserveData.location}</p>
              </div>
            </div>
          </div>
          {/* ------- reserve location section end --------- */}

          {/* ------- reserve status section start --------- */}
          <div className="row">
            <div className="col-md-3">
              <div className="status_label">
                <label>{t("Status")}:</label>
              </div>
            </div>
            <div className="col-md-9">
              <div className="status_info">
                <p>
                  {reserveData.reservestatus == "1"
                    ? "Confirmed"
                    : reserveData.reservestatus == "0"
                    ? "Delete reserve"
                    : reserveData.reservestatus == "0"
                    ? "Cancelled at the moment"
                    : ""}
                </p>
              </div>
            </div>
          </div>
          {/* ------- reserve status section end --------- */}
        </div>
      </div>
    </section>
  );
};

export default ReservationDetailsSection;
