import React from "react";
import { assetImages } from "constants";

//import translation function for language translation
import { useTranslation } from "react-i18next";
import DashboardCards from "./Cards/DashboardCards";

const DashboardBody = () => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const { t } = useTranslation(); //for translation

  return (
    <section className="dashboard_sec">
      <div className="container-fluid">
        <div className="dashboard_outer">
          <div className="row">
            {/* ------ user management section start ------ */}
            {userInfo?.role?.name === "admin" ||
            userInfo?.role?.name === "manager" ? (
              <DashboardCards
                cardImage={assetImages.userMangement}
                cardTitle={t("Users")}
                navigateTo="/usermanagement"
              />
            ) : null}
            {/* ------ user management section end ------ */}

            {/* ------ reservation section start ------ */}
            <DashboardCards
              cardImage={assetImages.reservation}
              cardTitle={t("Reservations")}
              navigateTo="/reservation"
            />
            {/* ------ reservation section end ------ */}

            {/* ------ conversation section start ------ */}
            <DashboardCards
              cardImage={assetImages.conversation}
              cardTitle={t("Conversations")}
              navigateTo="/conversation"
            />
            {/* ------ conversation section end ------ */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default DashboardBody;
