/* eslint-disable */
import React, { useEffect, useState, useContext, createRef } from "react";

// kendo react modules ==========================
import "@progress/kendo-theme-material/dist/all.css";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";
// impoting excel colums
import {
  ExcelExport,
  ExcelExportColumn,
} from "@progress/kendo-react-excel-export";

/*import url and gateway methods */
import { getData } from "utils/Gateway";
import * as url from "urlhelpers/UrlHelper";

//import context
import { GlobalProvider } from "context/GlobalContext";

//import translation function for language translation
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const ActivityList = ({
  downloadExcel,
  search,
  setRecords,
  checkedRecordCount,
}) => {
  const {
    reloadActivityList,
    setReloadActivityList,
    showActivityNameColumn,
    showActivityCourseColumn,
    showActivityCodeColumn,
    showActivityStartDateColumn,
    showActivityEndDateColumn,
    showActivityAudienceColumn,
    setEditActivityData,
    showActivityObservationColumn,
    showActivityDurationColumn,
    showActivityPreparationTimeColumn,
    showActivityMountingTimeColumn,
    showActivityDismantleTimeColumn,
    searchActivityName,
    searchActivityCode,
    searchActivityCourse,
    searchActivityLot,
    screenHeight,
  } = useContext(GlobalProvider);

  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const { t } = useTranslation(); //for translation

  // default user data ------------------------------------
  const [activityData, setActivityData] = useState([]);
  const [responseReceived, setResponseReceived] = useState(false);

  // without pagination ------------------------------------
  const initialDataState = {};
  const [dataState, setDataState] = useState();
  const [result, setResult] = useState(process(activityData, initialDataState));

  const onDataStateChange = (event) => {
    console.log("event -->", event);
    setDataState(event.dataState);
    setResult(process(activityData, event.dataState));
  };

  /* function to search */
  const filterData = () => {
    if (!result || !result.data) {
      return []; // Return an empty array if result or result.data is undefined
    }

    const filteredData = result.data.filter((item) => {
      const ActivityName = item?.name?.toLowerCase() || "";
      const course = item?.course?.name?.toLowerCase() || "";
      const code = item?.code?.toLowerCase() || "";
      const startdeadline = item?.startdate || "";
      const duration = item?.duration || "";
      const preparationtime = item?.preparationtime || "";
      const mountingtime = item?.mountingtime || "";
      const dismantletime = item?.dismantletime || "";
      const endDeadline = item?.enddate || "";
      const specialaudience = item?.specialaudience?.toLowerCase() || "";
      const observation = item?.observation?.toLowerCase() || "";

      return (
        (ActivityName && ActivityName.includes(search.toLowerCase())) ||
        (course && course.includes(search.toLowerCase())) ||
        (code && code.includes(search.toLowerCase())) ||
        (startdeadline && startdeadline.includes(search)) ||
        (duration && duration.includes(search)) ||
        (preparationtime && preparationtime.includes(search)) ||
        (mountingtime && mountingtime.includes(search)) ||
        (dismantletime && dismantletime.includes(search)) ||
        (endDeadline && endDeadline.includes(search)) ||
        (specialaudience && specialaudience.includes(search.toLowerCase())) ||
        (observation && observation.includes(search.toLowerCase()))
      );
    });
    setRecords(filteredData.length);
    return filteredData;
  };

  /* Handle the button's onClick event and set it to export the data by using the ExcelExport save method. */
  const _exporter = createRef();
  const exportExcel = () => {
    if (_exporter.current) {
      _exporter.current.save();
    }
  };

  //function for get all activity
  const getAllActivityList = async () => {
    try {
      let requestUrl =
        url.API_BASEURL + url.API_GET_ALL_ACTIVITY + `?token=${token}`;

      //search by name
      if (searchActivityName != "") {
        requestUrl = requestUrl + `&activityname=${searchActivityName}`;
      }

      //search by code
      if (searchActivityCode != "") {
        requestUrl = requestUrl + `&activitycode=${searchActivityCode}`;
      }

      //search by courses
      if (searchActivityCourse.length > 0) {
        requestUrl = requestUrl + `&activitycourse=${searchActivityCourse}`;
      }

      //search by lot
      if (searchActivityLot.length > 0) {
        requestUrl = requestUrl + `&activitylot=${searchActivityLot}`;
      }

      console.log("requestUrl of activity list=========>", requestUrl);

      const response = await getData(requestUrl);

      console.log("response activity list========>", response);

      if (response.status) {
        setActivityData(response.data);
        setResponseReceived(true);
        dataState
          ? setResult(process(response.data, dataState))
          : setResult(process(response.data, initialDataState));
        setRecords(response.data.length);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  // check the selected count and show the count in top and also selected download xls ------------------------
  const handelSelectedCount = () => {
    let checkboxes = document.querySelectorAll(
      'input[name="selectActivityRecord"]'
    );

    let totalSelected = 0;
    let filteredData = [];
    checkboxes.forEach((checkbox) => {
      if (checkbox.checked) {
        totalSelected++;
        const filteredUser = activityData.find(
          (activity) => activity._id === checkbox.value
        );
        filteredUser && filteredData.push(filteredUser);
      }
    });

    // Update the content of the block
    checkedRecordCount(totalSelected);
    return filteredData;
  };

  // mark all checkbox as selected ----------------
  const selectAllRecords = (e) => {
    document
      .querySelectorAll('.k-master-row input[name="selectActivityRecord"]')
      .forEach((checkbox) => {
        checkbox.checked = e.target.checked;
      });
    handelSelectedCount();
  };

  // blocks to replace the Grid HTML ==============================================
  const SelectRecordHeader = () => {
    return (
      <div className="d-flex align-items-center">
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            id="selectAllMain"
            onClick={selectAllRecords}
          />
        </div>
      </div>
    );
  };

  /* Select single row */
  const SelectRecordRow = (props) => {
    return (
      <td>
        <div className="form-check">
          <input
            className="form-check-input "
            type="checkbox"
            value={props.dataItem._id}
            name="selectActivityRecord"
            onClick={handelSelectedCount}
          />
        </div>
      </td>
    );
  };

  /* custom name html */
  const nameHTML = ({ dataItem }) => {
    return (
      <td className="kendoCustomColumn overflow-visible">
        <div className="d-flex align-items-center justify-content-between">
          <span>{dataItem.name}</span>
          <div className="d-flex align-items-center gap-2">
            <Link to="#" className="ml-auto edit_icon">
              <span
                className={`d-block material-symbols-outlined icon-md ${
                  dataItem.zipcode ? "text-green" : "text-danger"
                }`}
              >
                location_on
              </span>
            </Link>
            <Link
              to="#saveActivityPopup"
              data-bs-toggle="offcanvas"
              className="ml-auto edit_icon"
            >
              <span
                className="d-block material-symbols-outlined icon-md"
                onClick={() => {
                  setEditActivityData(dataItem._id);
                }}
              >
                edit
              </span>
            </Link>
          </div>
        </div>
      </td>
    );
  };

  //search use effect
  useEffect(() => {
    if (
      searchActivityName != "" ||
      searchActivityCode != "" ||
      searchActivityCourse.length > 0 ||
      searchActivityLot.length > 0
    ) {
      getAllActivityList();
    }
  }, [
    searchActivityName,
    searchActivityCode,
    searchActivityCourse,
    searchActivityLot,
  ]);

  /* hook to reload list */
  useEffect(() => {
    if (reloadActivityList) {
      getAllActivityList();
      setReloadActivityList(false);
      setResult(process(activityData, initialDataState));
    }
  }, [reloadActivityList]);

  useEffect(() => {
    if (responseReceived === false) {
      getAllActivityList();
    }
  }, [responseReceived]);

  /* hook to hit  exportExcel() function*/
  useEffect(() => {
    downloadExcel && exportExcel();
  }, [downloadExcel]);

  /* hook to hit filterData function on search */
  useEffect(() => {
    filterData();
  }, [search]);

  /* set records based on onDataStateChange */
  useEffect(() => {
    setRecords(result.data.length);
  }, [result]);

  return (
    <div className="activity_table">
      <div className="table-responsive">
        {/* ============ download xls start ========== */}
        <ExcelExport
          data={
            handelSelectedCount().length > 0
              ? handelSelectedCount()
              : activityData
          }
          fileName="Activity.xlsx"
          ref={_exporter}
        >
          <ExcelExportColumn field="name" title={t("Name of the Activity")} />
          <ExcelExportColumn field="course.name" title={t("Course")} />
          <ExcelExportColumn field="code" title={t("Code")} />
          <ExcelExportColumn field="startdate" title={t("Start Deadline")} />
          <ExcelExportColumn field="duration" title={t("Duration")} />
          <ExcelExportColumn
            field="preparationtime"
            title={t("Preparation time")}
          />
          <ExcelExportColumn field="mountingtime" title={t("Mounting time")} />
          <ExcelExportColumn
            field="dismantletime"
            title={t("Dismantle time")}
          />
          <ExcelExportColumn field="enddate" title={t("End Deadline")} />
          <ExcelExportColumn
            field="specialaudience"
            title={t("Specific Audience")}
          />
          <ExcelExportColumn field="observation" title={t("Observation")} />
        </ExcelExport>

        {/* ============ download xls end ========== */}

        {/* ====================== grid view start  ========================= */}
        {search ? (
          <Grid
            style={{ height: screenHeight }}
            data={filterData()}
            filterable={true}
            sortable={true}
            groupable={true}
            onDataStateChange={onDataStateChange}
            {...dataState}
            //pageable = {true}
            //total = {products.length}
            reorderable
            // resizable
          >
            <GridColumn
              field="id"
              headerCell={SelectRecordHeader}
              cell={SelectRecordRow}
              filterable={false}
              sortable={false}
              width="70px"
            />
            {showActivityNameColumn && (
              <GridColumn
                field="name"
                title={t("Name of the Activity")}
                width="380px"
                cell={nameHTML}
              />
            )}
            {showActivityCourseColumn && (
              <GridColumn
                field="course.name"
                title={t("Course")}
                width="200px"
              />
            )}
            {showActivityCodeColumn && (
              <GridColumn
                field="activitycode"
                width="150px"
                title={t("Code")}
              />
            )}

            {showActivityStartDateColumn && (
              <GridColumn
                field="activitystartdate"
                title={t("Start Deadline")}
                width="150px"
              />
            )}
            {showActivityDurationColumn && (
              <GridColumn
                field="activityduration"
                title={t("Duration")}
                width="150px"
              />
            )}
            {showActivityPreparationTimeColumn && (
              <GridColumn
                field="preparationtime"
                title={t("Preparation time")}
                width="150px"
              />
            )}

            {showActivityMountingTimeColumn && (
              <GridColumn
                field="mountingtime"
                title={t("Mounting time")}
                width="150px"
              />
            )}
            {showActivityDismantleTimeColumn && (
              <GridColumn
                field="dismantletime"
                title={t("Dismantle time")}
                width="150px"
              />
            )}
            {showActivityEndDateColumn && (
              <GridColumn
                field="activityenddate"
                title={t("End Deadline")}
                width="150px"
              />
            )}
            {showActivityAudienceColumn && (
              <GridColumn
                field="specialaudience"
                title={t("Specific Audience")}
                width="150px"
              />
            )}
            {showActivityObservationColumn && (
              <GridColumn
                field="observation"
                title={t("Observation")}
                width="150px"
              />
            )}
          </Grid>
        ) : (
          <Grid
            style={{ height: screenHeight }}
            data={result}
            filterable={true}
            sortable={true}
            groupable={true}
            onDataStateChange={onDataStateChange}
            {...dataState}
            //pageable = {true}
            //total = {products.length}
            reorderable
            // resizable
          >
            <GridColumn
              field="id"
              headerCell={SelectRecordHeader}
              cell={SelectRecordRow}
              filterable={false}
              sortable={false}
              width="70px"
            />

            {showActivityNameColumn && (
              <GridColumn
                field="name"
                title={t("Name of the Activity")}
                width="380px"
                cell={nameHTML}
              />
            )}

            {showActivityCourseColumn && (
              <GridColumn
                field="course.name"
                title={t("Course")}
                width="200px"
              />
            )}

            {showActivityCodeColumn && (
              <GridColumn
                field="activitycode"
                title={t("Code")}
                width="200px"
              />
            )}

            {showActivityStartDateColumn && (
              <GridColumn
                field="activitystartdate"
                title={t("Start Deadline")}
                width="200px"
              />
            )}

            {showActivityDurationColumn && (
              <GridColumn
                field="activityduration"
                title={t("Duration")}
                width="200px"
              />
            )}

            {showActivityPreparationTimeColumn && (
              <GridColumn
                field="preparationtime"
                title={t("Preparation time")}
                width="150px"
              />
            )}

            {showActivityMountingTimeColumn && (
              <GridColumn
                field="mountingtime"
                title={t("Mounting time")}
                width="150px"
              />
            )}

            {showActivityDismantleTimeColumn && (
              <GridColumn
                field="dismantletime"
                title={t("Dismantle time")}
                width="150px"
              />
            )}

            {showActivityEndDateColumn && (
              <GridColumn
                field="activityenddate"
                title={t("End Deadline")}
                width="150px"
              />
            )}

            {showActivityAudienceColumn && (
              <GridColumn
                field="specialaudience"
                title={t("Specific Audience")}
                width="150px"
              />
            )}

            {showActivityObservationColumn && (
              <GridColumn
                field="observation"
                title={t("Observation")}
                width="200px"
              />
            )}
          </Grid>
        )}

        {/* ====================== grid view end  ========================= */}
      </div>
    </div>
  );
};

export default ActivityList;
