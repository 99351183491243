import React from 'react';

/* importing react select */
import Select from 'react-select';

//import translation function for language translation
import { useTranslation } from 'react-i18next';

const AddSpecificAudiencePopup = () => {
  const { t } = useTranslation(); //for translation
  /* select batch data starts */
  const batchData = [
    { label: 'batch 1', value: 'batch 1' },
    { label: 'batch 2', value: 'batch 2' },
    { label: 'batch 3', value: 'batch 3' },
    { label: 'batch 4', value: 'batch 4' },
  ];

  const [selectBatchdata, setSelectBatchdata] = React.useState(null);

  /* select batch data ends */
  return (
    <div
      className="offcanvas offcanvas-end border-0"
      tabIndex="-1"
      id="addSpecificAudiencePopup"
      aria-labelledby="addSpecificAudiencePopupLabel"
    >
      <div className="offcanvas-header p-4">
        <h3 className="text-secondary mb-0" id="addSpecificAudiencePopupLabel">
          {t('Add Specific Audience')}
        </h3>
        <button
          type="button"
          id="closeCreateMail"
          className="btn-close p-2 bg-white shadow-none shadow-sm m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div className="offcanvas-body p-4 pt-0">
        <form action="">
          <div className="form-group mb-4">
            <label htmlFor="name">{t('Name')}</label>
            <input
              type="text"
              id="name"
              placeholder={t('Enter Here')}
              className="form-control"
            />
          </div>
          <div className="form-group mb-4">
            <label htmlFor="batch">{t('Batch')}</label>
            <Select
              className="nosearch_select"
              options={batchData}
              value={selectBatchdata}
              onChange={val => setSelectBatchdata(val)}
            />
          </div>
          <div className="action">
            <button type="submit" className="btn btn-primary mw-unset">
              {t('Save')}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddSpecificAudiencePopup;
