/* eslint-disable */
import { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-select';

//import context
import { GlobalProvider } from 'context/GlobalContext';

//import translation function for language translation
import { useTranslation } from 'react-i18next';

const ToggleColumn = () => {
  const { t } = useTranslation(); //for translation
  const {
    setShowUserNameColumn,
    setShowUserSurnameColumn,
    setShowUserRoleColumn,
    setShowUserEmailColumn,
    setShowUserTagColumn,
    setShowCompanyColumn,
    setShowUserPhoneColumn,
    setShowUserCityColumn,
    setShowUserStateColumn,
    setShowUserCountryColumn,
  } = useContext(GlobalProvider);

  //use state for select field/columns
  const [selectedColumnValue, setSelectedColumnValue] = useState(null);
  const [columns, setColumns] = useState([]);

  //option for select dropdown
  const columnOption = [
    { label: t('Name'), value: 'Name' },
    { label: t('Surname'), value: 'Surname' },
    { label: t('Role'), value: 'Role' },
    { label: t('Email'), value: 'Email' },
    { label: t('Tags'), value: 'Tags' },
    { label: t('Company'), value: 'Company' },
    { label: t('Phone'), value: 'Phone' },
    { label: t('City'), value: 'City' },
    { label: t('State'), value: 'State' },
    { label: t('Country'), value: 'Country' },
  ];

  //function for select column by dropdown
  const fieldSelectionHandler = val => {
    setSelectedColumnValue(val);
    const columnValaues = val.map(column => {
      return column.value;
    });
    setColumns(columnValaues);
  };

  //function for display selected columns
  const columnSelectionHandler = e => {
    e.preventDefault();

    if (columns.length == 0) {
      return;
    } else {
      setShowUserNameColumn(false);
      setShowUserSurnameColumn(false);
      setShowUserRoleColumn(false);
      setShowUserEmailColumn(false);
      setShowUserTagColumn(false);
      setShowCompanyColumn(false);
      setShowUserPhoneColumn(false);
      setShowUserCityColumn(false);
      setShowUserStateColumn(false);
      setShowUserCountryColumn(false);

      columns.map(column => {
        console.log(column);
        if (column == 'Name') {
          setShowUserNameColumn(true);
        }

        if (column == 'Surname') {
          setShowUserSurnameColumn(true);
        }

        if (column == 'Role') {
          setShowUserRoleColumn(true);
        }

        if (column == 'Email') {
          setShowUserEmailColumn(true);
        }

        if (column == 'Tags') {
          setShowUserTagColumn(true);
        }

        if (column == 'Company') {
          setShowCompanyColumn(true);
        }

        if (column == 'Phone') {
          setShowUserPhoneColumn(true);
        }

        if (column == 'City') {
          setShowUserCityColumn(true);
        }

        if (column == 'State') {
          setShowUserStateColumn(true);
        }

        if (column == 'Country') {
          setShowUserCountryColumn(true);
        }
      });
    }
  };

  //function for reset select
  const resetColumn = () => {
    setShowUserNameColumn(true);
    setShowUserSurnameColumn(true);
    setShowUserRoleColumn(true);
    setShowUserEmailColumn(true);
    setShowUserTagColumn(true);
    setShowUserPhoneColumn(true);
    setShowUserCityColumn(true);
    setShowUserStateColumn(true);
    setShowUserCountryColumn(true);
    setSelectedColumnValue(null);
    setColumns([]);
  };

  return (
    <div className="collapse mt-3" id="usercolumnCollapse">
      <div className="p-3 bg-gray-200">
        <div className="d-flex gap-3 align-items-center justify-content-between mb-3">
          <h4 className="mb-0">{t('Select Columns')}</h4>

          {/* ----- return button start ------ */}
          <Link
            to="#"
            className="d-flex gap-1 align-items-center text-black"
            onClick={resetColumn}
          >
            <span className="d-block material-symbols-outlined icon-md">
              sync
            </span>
            <span className="d-block fw-medium">{t('Refresh')}</span>
          </Link>
          {/* ----- return button end ------ */}
        </div>
        <form onSubmit={columnSelectionHandler}>
          <div className="form-container d-flex flex-wrap gap-2">
            <div className="form-group flex-fill">
              <Select
                className="nosearch_select"
                isMulti
                options={columnOption}
                value={selectedColumnValue}
                onChange={val => fieldSelectionHandler(val)}
              />
            </div>
            <div className="action">
              <button type="submit" className="btn btn-primary mw-unset">
                {t('Show')}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ToggleColumn;
