/* eslint-disable */

/* --------------- importing Images here --------------- */
import authenticationBG from "../assets/img/authentication-bg.jpg";
import logo from "../assets/img/logo.png";
import logo2 from "../assets/img/logo-colour.png";
import logosvg from "../assets/svg/logo.svg";
import profilePic from "../assets/img/upload-pic.jpg";
import managerPic from "../assets/img/usr-profile.png";
import linkedin from "../assets/svg/ci_linkedin.svg";
import gmail from "../assets/svg/google-gmail.svg";
import link from "../assets/svg/link.svg";
import pinterest from "../assets/svg/social-pinterest.svg";
import faceboof from "../assets/svg/twotone-facebook.svg";
import userMangement from "../assets/img/user-management.png";
import reservation from "../assets/img/reservation.png";
import conversation from "../assets/img/conversation.png";
import defaultUser from "assets/img/default-user.png";
import pendingFeedback from "../assets/img/pending-feedback-response.png";
import submitSurvey from "../assets/img/submit-survey.png";
import whiteArrow from "../assets/svg/white_arw.svg";

/* --------------- importing SVGs here --------------- */

export default {
  // ------------------ Exporting Images here ------------------
  authenticationBG,
  logo,
  logo2,
  profilePic,
  managerPic,
  userMangement,
  reservation,
  conversation,
  defaultUser,
  pendingFeedback,
  submitSurvey,
  // ------------------ Exporting SVGs here ------------------
  logosvg,
  linkedin,
  gmail,
  link,
  pinterest,
  faceboof,
  whiteArrow,
};
