import UserMenu from 'components/common/UserMenu';
import LeftMenu from 'components/common/LeftMenu';

import UserProfileMenu from 'components/UserProfileComponents/ProfileMenu/UserProfileMenu';
import UserProfileArea from 'components/UserProfileComponents/ProfileContent/UserProfileArea/UserProfileArea';
import { useEffect } from 'react';

const UserProfile = () => {
  useEffect(() => {
    document.title = 'Userprofile'; // Update the title when Register component mounts
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <UserMenu />
      <LeftMenu />
      <UserProfileMenu />
      <UserProfileArea />
    </>
  );
};

export default UserProfile;
