/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";

//import translation function for language translation
import { useTranslation } from "react-i18next";

/*import url and gateway methods */
import { putData } from "utils/Gateway";
import * as url from "urlhelpers/UrlHelper";

//import context
import { GlobalProvider } from "context/GlobalContext";

const UserManagementHeader = ({
  setDownloadExcel,
  setSearch,
  blockRefSelected,
  records,
  setSelectedEmails,
}) => {
  const {
    setShowUserList,
    setUserFormReset,
    setSuccess,
    setError,
    setNotificationString,
  } = useContext(GlobalProvider);

  const token = localStorage.getItem("token"); // token

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const { t } = useTranslation(); //for translation

  /* hook to get the search value */
  const [searchValue, setSearchValue] = useState("");

  //function for deactivate/update user
  const deleteUser = () => {
    const checkboxes = document.querySelectorAll(
      'input[name="selectUserRecord"]'
    );

    let responseStatus = [];

    checkboxes.forEach(async (checkbox) => {
      if (checkbox.checked) {
        let userData = {
          photoimage: "657a91f214c49f8586b17f22",
        };
        try {
          let requestUrl =
            url.API_BASEURL +
            url.API_UPDATE_USER +
            `/${checkbox.value}` +
            `?token=${token}`;
          const response = await putData(requestUrl, userData);

          console.log(response);
          if (response.status) {
            responseStatus.push(response.data._id);
          }
        } catch (error) {
          console.error(error.message);
        }
      }
    });

    if (responseStatus.length > 0) {
      setShowUserList(true);
      setSuccess(true);
      setNotificationString("User has been deleted successfully");
    } else {
      setError(true);
      setNotificationString("Something went wrong, please try again later");
    }
    setUserFormReset(true);
  };

  /* function to click download xls*/
  const handleDownloadClick = () => {
    setDownloadExcel(true);

    setTimeout(() => setDownloadExcel(false), 500);
  };

  //function for update select emails
  const updateSelectedEmails = () => {
    const checkboxes = document.querySelectorAll(
      'input[name="selectUserRecord"]'
    );

    let selectedEmails = [];
    checkboxes.forEach((checkbox) => {
      if (checkbox.checked) {
        const email = checkbox.getAttribute("data-emailstring");
        selectedEmails.push(email);
      }
    });

    setSelectedEmails(selectedEmails);
  };

  useEffect(() => {
    !searchValue && setSearch("");
  }, [searchValue]);

  return (
    <div className="filter-container d-flex flex-wrap align-items-center gap-2 mb-1">
      {/* ------ filter column icon start ------ */}
      <Link
        to="#usercolumnCollapse"
        className="btn btn-primary mw-unset p-2"
        data-bs-toggle="collapse"
        role="button"
        aria-expanded="false"
        aria-controls="columnCollapse"
        data-bs-placement="top"
        title="Show Columns"
      >
        <span className="d-block material-symbols-outlined icon-fill">
          visibility
        </span>
      </Link>
      {/* ------ filter column icon end ------ */}

      {/* ------ download in excel icon start ------ */}
      <Link
        to="#"
        className="btn btn-primary mw-unset p-2"
        onClick={handleDownloadClick}
        // onClick={selectDownloadXLS}
        data-bs-placement="top"
        title="Download XLS"
      >
        <span className="d-block material-symbols-outlined">download</span>
      </Link>
      {/* ------ download in excel icon end ------ */}

      {/* ----- search section start ------ */}
      <div className="search-container position-relative d-none d-md-block">
        <form onSubmit={(e) => e.preventDefault()}>
          <input
            type="search"
            placeholder={t("Search here")}
            className="form-control bg-transparent py-2 border-0"
            onChange={(e) => setSearchValue(e.target.value)}
            onKeyDown={(e) => {
              e.key === "Enter" && (e.preventDefault(), setSearch(searchValue));
            }}
          />
          <button
            onClick={() => setSearch(searchValue)}
            type="button"
            className="bg-transparent text-dark p-0 border-0 position-absolute top-50 translate-middle-y"
          >
            <span className="d-block material-symbols-outlined">search</span>
          </button>
        </form>
      </div>
      {/* ----- search section end ------ */}

      {/* ------------- showing records start ----------------- */}
      <p className="text-gray fw-medium ms-auto me-3">
        {t("Showing")}
        <span className="text-black ms-1">{records}</span> {t("Records")} -
        <span className="text-black ms-1" ref={blockRefSelected}>
          {" "}
          0{" "}
        </span>{" "}
        {t("Selected")}
      </p>
      {/* ------------- showing records end ----------------- */}
      {/* ----- refresh icon start ------ */}
      <Link
        to="#"
        className="filter-btn btn btn-primary icon-btn mw-unset"
        data-bs-placement="top"
        title="Refresh"
        onClick={() => {
          setShowUserList(true);
          setSuccess(true);
          setNotificationString("The list has been refreshed successfully");
        }}
      >
        <span className="d-block material-symbols-outlined">refresh</span>
      </Link>
      {/* ----- refresh icon end ------ */}

      {/* ----- filter icon start ------ */}
      {userInfo?.role?.name == "admin" || userInfo?.role?.name == "manager" ? (
        <Link
          to="#userfilterCollapse"
          className="filter-btn btn btn-primary icon-btn mw-unset"
          data-bs-toggle="collapse"
          role="button"
          aria-expanded="false"
          aria-controls="filterCollapse"
          data-bs-placement="top"
          title="Filter"
        >
          <span className="d-block material-symbols-outlined">filter_alt</span>
        </Link>
      ) : null}
      {/* ----- filter icon end ------ */}

      {/* ----- add new user popup icon start ------ */}

      {userInfo?.role?.name == "admin" || userInfo?.role?.name == "manager" ? (
        <Link
          to="#saveUserPopup"
          data-bs-toggle="offcanvas"
          role="button"
          aria-controls="saveReservationPopup"
          className="filter-btn btn btn-primary icon-btn mw-unset"
          onClick={() => {
            setUserFormReset(true);
          }}
          data-bs-placement="top"
          title="Add user"
        >
          <span className="d-block material-symbols-outlined">add</span>
        </Link>
      ) : null}

      {/* ----- add new user popup icon end ------ */}

      {/* ----- action dropdown button start ------ */}
      {userInfo?.role?.name == "admin" || userInfo?.role?.name == "manager" ? (
        <div className="dropdown">
          <button
            className="btn btn-primary dropdown-toggle mw-unset"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {t("Actions")}
          </button>
          <ul className="dropdown-menu border-0 shadow-lg">
            {/* ----- add tag button start ------ */}
            <li>
              <Link
                to="#tags_pnl"
                data-bs-toggle="offcanvas"
                role="button"
                className="dropdown-item"
              >
                {t("Add/Remove Label")}
              </Link>
            </li>
            {/* ----- add tag button end ------ */}

            {/* ------ send mail button start ------- */}
            {userInfo?.role?.name == "admin" ||
            userInfo?.role?.name == "manager" ? (
              <li>
                <Link
                  to="#sendBulkMailPopup"
                  data-bs-toggle="offcanvas"
                  role="button"
                  aria-controls="sendBulkMailPopup"
                  className="dropdown-item"
                  onClick={updateSelectedEmails}
                >
                  {t("Send Mail")}
                </Link>
              </li>
            ) : null}
            {/* ------ send mail button end ------- */}

            {/* ----- delete user button start ------ */}
            <li>
              <Link className="dropdown-item" to="#" onClick={deleteUser}>
                {t("Delete selected")}
              </Link>
            </li>
            {/* ----- delete user button end ------ */}
          </ul>
        </div>
      ) : null}
      {/* ----- action dropdown button end ------ */}
    </div>
  );
};

export default UserManagementHeader;
