/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";

/* Importing react select */
import Select from "react-select";

//import translation function for language translation
import { useTranslation } from "react-i18next";

/*import url and gateway methods */
import { postData, getData, putData, uploadMultipleFile } from "utils/Gateway";
import * as url from "urlhelpers/UrlHelper";

//import options from json file
import typeOfActivity from "data/Prod/ActivityType.json";
import scopeOption from "data/Prod/Scope.json";
import batchLotOption from "data/Prod/Batch.json";
import specificAudience from "data/Prod/SpecialAudience.json";
import levelOption from "data/Prod/Level.json";
import CoordinatingEntity from "data/Prod/CoordinatingEntity.json";
import StatusOptions from "data/Prod/Status.json";

//import context
import { GlobalProvider } from "context/GlobalContext";
import { Link } from "react-router-dom";

//import file download function
import downloadFileHandler from "helpers/DownloadFile";
import { currencySymbol } from "Config/Config";

const ActivitySaveRecord = () => {
  const {
    setReloadActivityList,
    setEditActivityData,
    editActivityData,
    activityFormReset,
    setActivityFormReset,
    setSuccess,
    setError,
    setNotificationString,
  } = useContext(GlobalProvider);

  const dateOfToday = new Date().toISOString().split("T")[0];

  const token = localStorage.getItem("token");
  const { t } = useTranslation(); //for translation

  const [courseList, setCourseList] = useState([]); //course option
  const [materialKitList, setMaterialKitList] = useState([]); //materila kit option
  const [educatorList, setEducatorList] = useState([]); //educator option

  //  Hooks used for React select start
  const [selectCourses, setSelectCourses] = useState(null);
  const [selectTypeOfActivity, setSelectTypeOfActivity] = useState(null);
  const [selectedScope, setSelectedScope] = useState(null);
  const [selectedEntity, setSelectedEntity] = useState(null);
  const [selectedLot, setSelectedLot] = useState(null);
  const [selectedLevel, setSelectedLevel] = useState(null);
  const [selectedEducators, setSelectedEducators] = useState(null);
  const [selectedAudience, setSelectedAudience] = useState(null);
  const [selectedMaterialKit, setSelectedMaterialKit] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);

  //  Hooks used for React select end

  /* hooks for input fields start */
  const [courseValue, setCourseValue] = useState(null);
  const [name, setName] = useState("");
  const [code, setCode] = useState("");
  const [typeOfActivityValue, setTypeOfActivityValue] = useState(null);
  const [duration, setDuration] = useState("");
  const [scopeValues, setScopeValues] = useState([]);
  const [coordinatingEntity, setCoordinatingEntity] = useState([]);
  const [batchLots, setBatchLots] = useState([]);
  const [levels, setLevels] = useState([]);
  const [noOfEducators, setNoOfEducators] = useState("");
  const [educators, setEducators] = useState([]);
  const [specificAudienceValue, setSpecificAudienceValue] = useState(null);
  const [activityNumberPerCourse, setActivityNumberPerCourse] = useState("");
  const [preparationTime, setPreparationTime] = useState("");
  const [mountingTime, setMountingTime] = useState("");
  const [dismantleTime, setDismantleTime] = useState("");
  const [combinedOrder, setCombinedOrder] = useState("");
  const [minParticipants, setMinParticipants] = useState("");
  const [maxParticipants, setMaxParticipants] = useState("");
  const [groups, setGroups] = useState("");
  const [groupRatio, setGroupRatio] = useState("");
  const [materialKits, setMaterialKits] = useState([]);
  const [observations, setObservations] = useState("");
  const [educatorFileUploadIds, setEducatorFileUploadIds] = useState([]);
  const [beneficiaryFileUploadIds, setBeneficiaryFileUploadIds] = useState([]);
  const [startDate, setStartDate] = useState(dateOfToday); // for start date
  const [endDate, setEndDate] = useState(dateOfToday); // for end date
  const [activityCost, setActivityCost] = useState("");
  const [statusValue, setStatusValue] = useState(null);
  const [isEducatorFileUploading, setIsEducatorFileUploading] = useState(false);
  const [uploadedEducatorFiles, setUploadedEducatorFiles] = useState([]);
  const [isBeneficiaryFileUploading, setIsBeneficiaryFileUploading] =
    useState(false);
  const [uploadedBeneficiaryFiles, setUploadedBeneficiaryFiles] = useState([]);

  const [requiredFiledPending, setRequiredFiledPending] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  /* hooks for input fields end */

  /* Hook for validation start */
  const [validation, setValidation] = useState({
    courseWarning: false,
    nameWarning: false,
    codeWarning: false,
    CostWarning: false,
    statusWarning: false,
    durationWarning: false,
    dateWarning: false,
  });
  /* Hook for validation end */

  /* function for validation starts */
  const formValidation = () => {
    let isValid = true;

    if (selectCourses === null) {
      setValidation((prevState) => ({ ...prevState, courseWarning: true }));
      isValid = false;
    }
    if (name === "") {
      setValidation((prevState) => ({ ...prevState, nameWarning: true }));
      isValid = false;
    }
    if (code === "") {
      setValidation((prevState) => ({ ...prevState, codeWarning: true }));
      isValid = false;
    }
    if (activityCost === "" || activityCost === 0 || activityCost < 0) {
      setValidation((prevState) => ({ ...prevState, CostWarning: true }));
      isValid = false;
    }
    if (selectedStatus === null) {
      setValidation((prevState) => ({ ...prevState, statusWarning: true }));
      isValid = false;
    }

    if (duration === 0 || duration == "" || duration < 0) {
      setValidation((prevState) => ({ ...prevState, durationWarning: true }));
      isValid = false;
    }

    // if (startDate > endDate) {
    //   setValidation((prevState) => ({
    //     ...prevState,
    //     dateWarning: true,
    //   }));
    //   isValid = false;
    // }

    return isValid;
  };

  /* function for validation ends */

  //function for get activity details
  const getActivityDetails = async () => {
    try {
      let requestUrl =
        url.API_BASEURL +
        url.API_GET_ACTIVITY_DETAILS +
        `/${editActivityData}` +
        `?token=${token}`;

      const response = await getData(requestUrl);

      console.log(response);

      if (response.status) {
        /* ======== assign course start ========*/
        if (response.data?.course) {
          let courseData = {
            label: response.data?.course.name,
            value: response.data?.course._id,
          };
          courseSelectionHandler(courseData);
        }
        /* =========== assign course end ============*/

        /*========= assign name start ===================  */
        response.data?.name && setName(response.data?.name);
        /*========= assign name end ===================  */

        /* ========== assign code start ================ */
        response.data?.code && setCode(response.data?.code);
        /* ========== assign code end ================ */

        /* ============ Assign type of activity start =============== */
        if (response.data?.activitytype) {
          let activityData = {
            label: response.data?.activitytype,
            value: response.data?.activitytype,
          };
          typeActivitySelectionHandler(activityData);
        }
        /* ============ Assign type of activity end =============== */

        /* ============= assign duration starts ================ */
        response.data?.duration && setDuration(response.data?.duration);
        /* ============= assign duration ends ================ */

        /* =============== assign scope starts ================= */
        if (response.data?.scope) {
          let scopeValue = response.data?.scope.map((scopeitem) => {
            let scopeData = {
              label: scopeitem,
              value: scopeitem,
            };
            return scopeData;
          });
          scopeSelectionHandler(scopeValue);
        }
        /* =============== assign scope ends ================= */

        /* =========== assign coordination entity start ============= */
        if (response.data?.coordinatingentity) {
          let entityValue = response.data?.coordinatingentity.map(
            (entityItem) => {
              let entityData = {
                label: entityItem,
                value: entityItem,
              };
              return entityData;
            }
          );
          entitySelectionHandler(entityValue);
        }
        /* =========== assign coordination entity ends ============= */

        /* ========== assign lot starts ============== */
        if (response.data?.batch) {
          let batchValue = response.data?.batch.map((lotItem) => {
            let lotBatchData = {
              label: lotItem,
              value: lotItem,
            };
            return lotBatchData;
          });
          lotSelectionHandler(batchValue);
        }
        /* ========== assign lot ends ============== */

        /* =========== assign level starts =============== */
        if (response.data?.levels) {
          let levelValue = response.data?.levels.map((levelItems) => {
            let levelData = {
              label: levelItems,
              value: levelItems,
            };
            return levelData;
          });
          levelSelectionHandler(levelValue);
        }
        /* =========== assign level ends =============== */

        /*------ assing no of educators --------*/
        response.data?.educatorcount &&
          setNoOfEducators(response.data?.educatorcount);

        /* =============== assign educators starts ============= */
        if (response.data?.educators) {
          let educatorValue = response.data?.educators.map((educator) => {
            let educatorData = {
              label: educator.name + " " + educator.surname,
              value: educator._id,
            };
            return educatorData;
          });
          educatorSelectionHandler(educatorValue);
        }
        /* =============== assign educators ends ============= */

        /*============== assign specific audience starts =============== */
        if (response.data?.specialaudience) {
          let audienceData = {
            label: response.data?.specialaudience,
            value: response.data?.specialaudience,
          };
          audienceSelectionHandler(audienceData);
        }
        /*============== assign specific audience ends =============== */

        /* ============= assign limits per activities start ================= */
        response.data?.activitypercourse &&
          setActivityNumberPerCourse(response.data?.activitypercourse);
        /* ============= assign limits per activities ends ================= */

        /* =========== assign combine order starts ================= */
        response.data?.combinedorder &&
          setCombinedOrder(response.data?.combinedorder);
        /* =========== assign combine order ends ================= */

        /* =======assign min number of participants starts ============*/
        response.data?.minparticipantspergroup &&
          setMinParticipants(response.data?.minparticipantspergroup);
        /* =======assign min number of participants ends ============*/

        /* =========== assign max number of participants starts ============== */
        response.data?.maxparticipantspergroup &&
          setMaxParticipants(response.data?.maxparticipantspergroup);
        /* =========== assign max number of participants ends ============== */

        /* ========== assign max group starts ================== */
        response.data?.maxgroup && setGroups(response.data?.maxgroup);
        /* ========== assign max group ends ================== */

        /* ================= assign responsible ratio starts ================= */
        response.data?.ratiopergroup &&
          setGroupRatio(response.data?.ratiopergroup);
        /* ================= assign responsible ratio ends ================= */

        /* ================ assign material kit starts =================== */
        if (response.data?.materialkits) {
          let kitValue = response.data?.materialkits.map((kit) => {
            let kitData = {
              label: kit.name,
              value: kit._id,
            };
            return kitData;
          });
          materialSelectionHandler(kitValue);
        }
        /* ================ assign material kit ends =================== */

        /* ==================== assign observation starts ========================= */
        response.data?.observation &&
          setObservations(response.data?.observation);
        /* ==================== assign observation ends ========================= */

        /* ===================== assign start date starts ======================== */
        response.data?.startdate && setStartDate(response.data?.startdate);
        /* ===================== assign start date ends ======================== */

        /* ================== assign end date starts ======================== */
        response.data?.enddate && setEndDate(response.data?.enddate);
        /* ================== assign end date ends ======================== */

        /* ========== assign code start ================ */
        response.data?.cost && setActivityCost(response.data?.cost);
        /* ========== assign code end ================ */

        /* ============ assign status starts ================== */
        for (let status of StatusOptions) {
          if (status.value == response.data?.status) {
            statusSelectionHandler(status);
          }
        }
        /* ============ assign status ends ================== */

        /* =============== assign beneficairy uploads starts ================= */
        if (response.data?.beneficiaryuploads) {
          setUploadedBeneficiaryFiles(
            response.data?.beneficiaryuploads.map(
              ({ name, path, _id, ...other }) => ({
                name,
                path,
                _id,
              })
            )
          );
          const editedFilesIds = response.data?.beneficiaryuploads.map(
            ({ _id, ...other }) => ({ _id })
          );
          setBeneficiaryFileUploadIds(editedFilesIds);
        }
        /* =============== assign uploads ends ================= */

        /* =============== assign uploads starts ================= */
        if (response.data?.uploads) {
          setUploadedEducatorFiles(
            response.data?.uploads.map(({ name, path, _id, ...other }) => ({
              name,
              path,
              //path: url.SERVER_URL + path,
              _id,
            }))
          );
          const editedFilesIds = response.data?.uploads.map(
            ({ _id, ...other }) => ({ _id })
          );
          setEducatorFileUploadIds(editedFilesIds);
        }
        /* =============== assign uploads ends ================= */
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get course list
  const getAllCourseList = async () => {
    try {
      let requestUrl =
        url.API_BASEURL +
        url.API_GET_ALL_COURSE +
        `?token=${token}&isselect=${true}`;

      const response = await getData(requestUrl);

      if (response.status) {
        setCourseList(response.data);
        courseSelectionHandler(response.data[response.data.length - 1]);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  //function for get material kit list
  const getAllMaterialKitList = async () => {
    try {
      let requestUrl =
        url.API_BASEURL +
        url.API_GET_ALL_MATERIAL_KIT +
        `?token=${token}&isselect=${true}`;

      const response = await getData(requestUrl);

      if (response.status) {
        setMaterialKitList(response.data);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  //function for get user/educator list
  const getAllEducatorList = async () => {
    try {
      let requestUrl =
        url.API_BASEURL +
        url.API_GET_ALL_USER +
        `?token=${token}&isselect=${true}&slug=educator`;

      const response = await getData(requestUrl);

      if (response.status) {
        setEducatorList(response.data);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  //function for file upload
  const educatorFileUploadHandler = async (e) => {
    try {
      setIsEducatorFileUploading(true);
      let requestUrl =
        url.API_BASEURL + url.API_FILE_UPLOAD_MULTIPLE + `?token=${token}`;

      const response = await uploadMultipleFile(requestUrl, e.target.files);

      if (response.status) {
        console.log(`response`, response.data);

        /*----- assign uploaded files with path for display start ------*/
        const uploadedFileValues = response.data.map(
          ({ name, path, _id, ...other }) => ({
            name,
            path,
            _id,
          })
        );

        //check old file exist or not if exist then new file will concat with old file
        if (uploadedEducatorFiles.length == 0) {
          setUploadedEducatorFiles(uploadedFileValues);
        } else {
          let fileArr = uploadedEducatorFiles.concat(uploadedFileValues);
          setUploadedEducatorFiles(fileArr);
        }
        /*----- assign uploaded files with path for display end ------*/

        setIsEducatorFileUploading(false);

        /*----- assign uploaded file ids start ------*/
        const fileIds = response.data.map(({ _id, ...other }) => ({ _id }));

        //check old file ids are exist or not.If exist then new id will concat with old id
        if (educatorFileUploadIds.length == 0) {
          setEducatorFileUploadIds(fileIds);
        } else {
          let fileArr = educatorFileUploadIds.concat(fileIds);
          setEducatorFileUploadIds(fileArr);
        }
        /*----- assign uploaded file ids end ------*/

        // setValidation(prevState => ({
        //   ...prevState,
        //   uploadsWarning: false,
        // }));
        //setWarningIndex(1);
      }
      resetEducatorFile();
    } catch (error) {
      console.error(error.message);
    }
  };

  //function for file upload
  const beneficiaryFileUploadHandler = async (e) => {
    try {
      setIsBeneficiaryFileUploading(true);
      let requestUrl =
        url.API_BASEURL + url.API_FILE_UPLOAD_MULTIPLE + `?token=${token}`;

      const response = await uploadMultipleFile(requestUrl, e.target.files);

      if (response.status) {
        console.log(`response`, response.data);

        /*----- assign uploaded files with path for display start ------*/
        const uploadedFileValues = response.data.map(
          ({ name, path, _id, ...other }) => ({
            name,
            path,
            _id,
          })
        );

        //check old file exist or not if exist then new file will concat with old file
        if (uploadedBeneficiaryFiles.length == 0) {
          setUploadedBeneficiaryFiles(uploadedFileValues);
        } else {
          let fileArr = uploadedBeneficiaryFiles.concat(uploadedFileValues);
          setUploadedBeneficiaryFiles(fileArr);
        }

        /*----- assign uploaded files with path for display end ------*/

        setIsBeneficiaryFileUploading(false);

        /*----- assign uploaded file ids start ------*/
        const fileIds = response.data.map(({ _id, ...other }) => ({ _id }));

        //check old file ids are exist or not.If exist then new id will concat with old id
        if (beneficiaryFileUploadIds.length == 0) {
          setBeneficiaryFileUploadIds(fileIds);
        } else {
          let fileArr = beneficiaryFileUploadIds.concat(fileIds);
          setBeneficiaryFileUploadIds(fileArr);
        }
        /*----- assign uploaded file ids end ------*/

        // setValidation(prevState => ({
        //   ...prevState,
        //   uploadsWarning: false,
        // }));
        //setWarningIndex(1);
      }
      resetBeneficiaryFile();
    } catch (error) {
      console.error(error.message);
    }
  };

  //function for delete file from upload stack
  const closeEducatorFileHandler = (index) => {
    const closedFile = [...uploadedEducatorFiles];
    const removedFileId = [...educatorFileUploadIds];

    closedFile.splice(index, 1);
    removedFileId.splice(index, 1);

    setUploadedEducatorFiles(closedFile);
    setEducatorFileUploadIds(removedFileId);

    resetEducatorFile();
  };

  //function for delete file from upload stack
  const closeBeneficiaryFileHandler = (index) => {
    const closedFile = [...uploadedBeneficiaryFiles];
    const removedFileId = [...beneficiaryFileUploadIds];

    closedFile.splice(index, 1);
    removedFileId.splice(index, 1);

    setUploadedBeneficiaryFiles(closedFile);
    setBeneficiaryFileUploadIds(removedFileId);

    resetBeneficiaryFile();
  };

  //function for course selection
  const courseSelectionHandler = (val) => {
    setSelectCourses(val);
    setCourseValue(val.value);
    setRequiredFiledPending(false);
  };

  //function for activity type selection
  const typeActivitySelectionHandler = (val) => {
    setSelectTypeOfActivity(val);
    setTypeOfActivityValue(val.value);
  };

  //function for scope selection
  const scopeSelectionHandler = (val) => {
    setSelectedScope(val);
    const scopeValue = val.map((item) => {
      return item.value;
    });
    setScopeValues(scopeValue);
  };

  //function for entity selection
  const entitySelectionHandler = (val) => {
    setSelectedEntity(val);
    const entityValue = val.map((item) => {
      return item.value;
    });
    setCoordinatingEntity(entityValue);
  };

  //function for lot selcetion
  const lotSelectionHandler = (val) => {
    setSelectedLot(val);
    const lotValue = val.map((item) => {
      return item.value;
    });
    setBatchLots(lotValue);
  };

  //function for level selection
  const levelSelectionHandler = (val) => {
    setSelectedLevel(val);
    const levelValue = val.map((item) => {
      return item.value;
    });
    setLevels(levelValue);
  };

  //function for educator selection
  const educatorSelectionHandler = (val) => {
    setSelectedEducators(val);
    const educatorsValue = val.map((item) => {
      return item.value;
    });
    setEducators(educatorsValue);
  };

  //function for audience selection
  const audienceSelectionHandler = (val) => {
    setSelectedAudience(val);
    setSpecificAudienceValue(val.value);
  };

  //function for select material kit
  const materialSelectionHandler = (val) => {
    setSelectedMaterialKit(val);
    const kitValue = val.map((item) => {
      return item.value;
    });
    setMaterialKits(kitValue);
  };

  //function for select status
  const statusSelectionHandler = (val) => {
    setSelectedStatus(val);
    setStatusValue(val.value);
  };

  //function for save activity
  const saveUpdateActivityHandler = async () => {
    if (formValidation()) {
      setRequiredFiledPending(false);

      try {
        setIsSaving(true);

        let acitvityData = {
          name,
          course: courseValue,
          code,
          activitytype: typeOfActivityValue,
          activitypercourse: activityNumberPerCourse,
          scope: scopeValues,
          batch: batchLots,
          coordinatingentity: coordinatingEntity,
          levels,
          // Installation,
          duration,
          educatorcount: noOfEducators,
          educators,
          specialaudience: specificAudienceValue,
          preparationtime: preparationTime,
          mountingtime: mountingTime,
          dismantletime: dismantleTime,
          combinedorder: combinedOrder,
          maxparticipantspergroup: maxParticipants,
          minparticipantspergroup: minParticipants,
          maxgroup: groups,
          ratiopergroup: groupRatio,
          materialkits: materialKits,
          observation: observations,
          startdate: startDate,
          enddate: endDate,
          beneficiaryuploads: beneficiaryFileUploadIds,
          uploads: educatorFileUploadIds,
          status: statusValue,
          cost: activityCost,
        };
        console.log("acitvityData------->", acitvityData);

        let requestUrl = url.API_BASEURL;

        let response = null;

        if (editActivityData) {
          //console.log('editActivityData', editActivityData);

          requestUrl =
            requestUrl +
            url.API_UPDATE_ACTIVITY +
            `/${editActivityData}` +
            `?token=${token}`;

          response = await putData(requestUrl, acitvityData);
        } else {
          requestUrl = requestUrl + url.API_ADD_ACTIVITY + `?token=${token}`;

          response = await postData(requestUrl, acitvityData);
        }

        if (response.status) {
          setIsSaving(false);
          resetHandler();
          setReloadActivityList(true);
          setSuccess(true);
          setNotificationString(t("Activity added successfully"));
        }
      } catch (error) {
        console.error(error.message);
        setError(true);
        setNotificationString(
          t("Something went wrong, please try again later")
        );
      }
      let offCanvasPopup = document.querySelector("#saveActivityPopup");
      let offCanvas = bootstrap.Offcanvas.getInstance(offCanvasPopup);
      offCanvas.hide();
    } else {
      setRequiredFiledPending(true);
    }
  };

  //function for clear educator file value
  const resetEducatorFile = () => {
    const educatorFile = document.getElementById(
      "uploadedEducatorActivityFile"
    );
    if (educatorFile) {
      educatorFile.value = null;
    } else {
      return;
    }
  };

  //function for clear benificiary file value
  const resetBeneficiaryFile = () => {
    const beneficiaryFile = document.getElementById(
      "uploadedBeneficiaryActivityFile"
    );
    if (beneficiaryFile) {
      beneficiaryFile.value = null;
    } else {
      return;
    }
  };

  //function for reset all field
  const resetHandler = () => {
    setRequiredFiledPending(false);
    setEditActivityData(null);
    resetEducatorFile();
    resetBeneficiaryFile();
    setValidation({
      courseWarning: false,
      nameWarning: false,
      codeWarning: false,
      activitytypeWarning: false,
      durationWarning: false,
      scopeWarning: false,
      entityWarning: false,
      lotWarning: false,
      levelWarning: false,
      educatorsWarning: false,
      audienceWarning: false,
      activitiesPerCourseWarning: false,
      preparationTimeWarning: false,
      mountingTimeWarning: false,
      dismantleTimeWarning: false,
      combinedOrderWarning: false,
      minParticipantsWarning: false,
      maxParticipantsWarning: false,
      maxGroupWarning: false,
      ratioWarning: false,
      materialKitWarning: false,
      observationsWarning: false,
      startDateWarning: false,
      endDateWarning: false,
      statusWarning: false,
      uploadsWarning: false,
      CostWarning: false,
      dateWarning: false,
    });
    setSelectCourses(null);
    setSelectTypeOfActivity(null);
    setSelectedScope(null);
    setSelectedEntity(null);
    setSelectedLot(null);
    setSelectedLevel(null);
    setSelectedEducators(null);
    setSelectedAudience(null);
    setSelectedMaterialKit(null);
    for (let status of StatusOptions) {
      if (status.value == "1") {
        statusSelectionHandler(status);
      }
    }
    setCourseValue(null);
    setName("");
    setCode("");
    setTypeOfActivityValue(null);
    setDuration("");
    setScopeValues([]);
    setCoordinatingEntity([]);
    setBatchLots([]);
    setLevels([]);
    setNoOfEducators("");
    setEducators([]);
    setSpecificAudienceValue(null);
    setActivityNumberPerCourse("");
    setCombinedOrder("");
    setMinParticipants("");
    setMaxParticipants("");
    setGroups("");
    setGroupRatio("");
    setMaterialKits([]);
    setObservations("");
    setEducatorFileUploadIds([]);
    setBeneficiaryFileUploadIds([]);
    setStartDate(dateOfToday); // for start date
    setEndDate(dateOfToday); // for end date
    setStatusValue(null);
    setActivityCost("");
    setUploadedEducatorFiles([]);
    setUploadedBeneficiaryFiles([]);
  };

  //use effect for get
  useEffect(() => {
    getAllCourseList();
    getAllMaterialKitList();
    getAllEducatorList();
    for (let status of StatusOptions) {
      if (status.value == "1") {
        statusSelectionHandler(status);
      }
    }
  }, []);

  /* hook for edit */

  useEffect(() => {
    if (editActivityData) {
      getActivityDetails();
    }
  }, [editActivityData]);

  useEffect(() => {
    if (activityFormReset) {
      resetHandler();
      setActivityFormReset(false);
    }
  }, [activityFormReset]);

  return (
    <div
      className="offcanvas offcanvas-end border-0"
      tabIndex="-1"
      id="saveActivityPopup"
      aria-labelledby="saveReservationPopupLabel"
    >
      <div className="offcanvas-header position-relative align-items-start p-4">
        <h3 className="text-secondary mb-0" id="saveReservationPopupLabel">
          <span className="d-block">{t("Activity Save Record")}</span>
        </h3>
        <button
          type="button"
          className="btn-close p-2 bg-white shadow-none shadow-sm m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={resetHandler}
        ></button>
      </div>
      <div className="offcanvas-body position-relative p-4 pt-0">
        <form onSubmit={(e) => e.preventDefault()}>
          {/* select courses start */}
          <div className="form-group mb-4">
            <label>{t("Course")}</label>

            <Select
              className="nosearch_select"
              options={courseList}
              value={selectCourses}
              onChange={(val) => {
                courseSelectionHandler(val);
                setValidation((prevState) => ({
                  ...prevState,
                  courseWarning: false,
                }));
                //setWarningIndex(1);
              }}
            />
            {/* course warning */}
            {validation.courseWarning && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>{t("Please enter course")}!</span>
                </p>
              </div>
            )}
          </div>
          {/* select courses end */}

          {/* description start */}
          <div className="form-group mb-4">
            <label>{t("Name")}</label>
            <input
              type="text"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
                setValidation((prevState) => ({
                  ...prevState,
                  nameWarning: false,
                }));
                setRequiredFiledPending(false);
                //setWarningIndex(1);
              }}
              className="form-control"
              placeholder={t("Enter Here")}
            />
            {/* name warning */}
            {validation.nameWarning && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>{t("Please enter name")}!</span>
                </p>
              </div>
            )}
          </div>
          {/* description end */}

          {/* Select Code start */}
          <div className="form-group mb-4">
            <label>{t("Code")}</label>

            <input
              type="text"
              value={code}
              onChange={(e) => {
                setCode(e.target.value);
                setValidation((prevState) => ({
                  ...prevState,
                  codeWarning: false,
                }));
                setRequiredFiledPending(false);
                //setWarningIndex(1);
              }}
              className="form-control"
              placeholder={t("Enter Here")}
            />
            {/* code warning */}
            {validation.codeWarning && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>{t("Please enter code")}!</span>
                </p>
              </div>
            )}
          </div>

          {/* Select Code end */}

          {/* Type of Activity Start */}
          <div className="form-group mb-4">
            <label>{t("Type of Activity")}</label>
            <Select
              className="nosearch_select"
              options={typeOfActivity}
              value={selectTypeOfActivity}
              onChange={(val) => {
                typeActivitySelectionHandler(val),
                  setValidation((prevState) => ({
                    ...prevState,
                    activitytypeWarning: false,
                  }));
                //setWarningIndex(1);
              }}
            />
            {/* Type of activity warning */}
            {/* {validation.activitytypeWarning && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>{t('Please enter type of Activity')}!</span>
                </p>
              </div>
            )} */}
          </div>
          {/* Type of Activity end */}

          {/* ========== Duration start ========== */}
          <div className="form-group mb-4">
            <label>{t("Duration in Minutes")}</label>
            <input
              type="number"
              value={duration}
              onChange={(e) => {
                setDuration(e.target.value);
                setValidation((prevState) => ({
                  ...prevState,
                  durationWarning: false,
                }));
                setRequiredFiledPending(false);
                //setWarningIndex(1);
              }}
              className="form-control"
              placeholder={t("Enter")}
            />
            {/* duration warning */}
            {validation.durationWarning && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>{t("Please enter valid duration in minutes")}!</span>
                </p>
              </div>
            )}
          </div>
          {/* ========== Duration end ========== */}

          {/* =========== Preparation time start ============ */}
          <div className="form-group mb-4">
            <label>{t("Preparation time")}</label>
            <input
              type="text"
              value={preparationTime}
              onChange={(e) => {
                setPreparationTime(e.target.value),
                  setValidation((prevState) => ({
                    ...prevState,
                    preparationTimeWarning: false,
                  }));
                //setWarningIndex(1);
              }}
              className="form-control"
              placeholder={t("Enter")}
            />
            {/* preparationtime warning */}
            {/* {validation.combinedOrderWarning && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>{t('Please enter combined Order')}!</span>
                </p>
              </div>
            )} */}
          </div>
          {/* =========== preparation time end ============ */}

          {/* =========== Mounting time start ============ */}
          <div className="form-group mb-4">
            <label>{t("Mounting time")}</label>
            <input
              type="text"
              value={mountingTime}
              onChange={(e) => {
                setMountingTime(e.target.value),
                  setValidation((prevState) => ({
                    ...prevState,
                    mountingTimeWarning: false,
                  }));
                //setWarningIndex(1);
              }}
              className="form-control"
              placeholder={t("Enter")}
            />
            {/* preparationtime warning */}
            {/* {validation.combinedOrderWarning && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>{t('Please enter combined Order')}!</span>
                </p>
              </div>
            )} */}
          </div>
          {/* =========== Mounting time end ============ */}

          {/* =========== Dismantle time start ============ */}
          <div className="form-group mb-4">
            <label>{t("Dismantle time")}</label>
            <input
              type="text"
              value={dismantleTime}
              onChange={(e) => {
                setDismantleTime(e.target.value),
                  setValidation((prevState) => ({
                    ...prevState,
                    dismantleTimeWarning: false,
                  }));
                //setWarningIndex(1);
              }}
              className="form-control"
              placeholder={t("Enter")}
            />
            {/* preparationtime warning */}
            {/* {validation.combinedOrderWarning && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>{t('Please enter combined Order')}!</span>
                </p>
              </div>
            )} */}
          </div>
          {/* =========== Dismantle time end ============ */}

          {/* =========== Select scope start =========== */}
          <div className="form-group mb-4">
            <label htmlFor="scope">{t("Scope")}</label>
            <Select
              isMulti
              className="nosearch_select"
              options={scopeOption}
              value={selectedScope}
              onChange={(val) => {
                scopeSelectionHandler(val),
                  setValidation((prevState) => ({
                    ...prevState,
                    scopeWarning: false,
                  }));
                //setWarningIndex(1);
              }}
            />
            {/* Scope warning */}
            {/* {console.log(`scopeWarning========>>>`, validation.scopeWarning)} */}
            {/* {validation.scopeWarning && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>{t('Please enter Scope')}!</span>
                </p>
              </div>
            )} */}
          </div>
          {/* =========== Select scope end =========== */}

          {/* ========== Select Coordinating Entity starts ===========  */}
          <div className="form-group mb-4">
            <label htmlFor="entity">{t("Coordinating Entity")}</label>
            <Select
              isMulti
              className="nosearch_select"
              options={CoordinatingEntity}
              value={selectedEntity}
              onChange={(val) => {
                entitySelectionHandler(val),
                  setValidation((prevState) => ({
                    ...prevState,
                    entityWarning: false,
                  }));
                //setWarningIndex(1);
              }}
            />
            {/* coordinating Entity warning */}

            {/* {validation.entityWarning && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>{t('Please enter coordinating Entity')}!</span>
                </p>
              </div>
            )} */}
          </div>
          {/* ========== Select Coordinating Entity ends ===========  */}

          {/* ========== select lot starts ========= */}
          <div className="form-group mb-4">
            <label htmlFor="lot">{t("Lot")}</label>
            <Select
              isMulti
              className="nosearch_select"
              options={batchLotOption}
              value={selectedLot}
              onChange={(val) => {
                lotSelectionHandler(val),
                  setValidation((prevState) => ({
                    ...prevState,
                    lotWarning: false,
                  }));
                //setWarningIndex(1);
              }}
            />
            {/* lot warning */}
            {/* {validation.lotWarning && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>{t('Please enter Lot')}!</span>
                </p>
              </div>
            )} */}
          </div>
          {/* ========== select lot ends ========= */}

          {/* select Level starts */}
          <div className="form-group mb-4">
            <label htmlFor="level">{t("Level")}</label>
            <Select
              isMulti
              className="nosearch_select"
              options={levelOption}
              value={selectedLevel}
              onChange={(val) => {
                levelSelectionHandler(val),
                  setValidation((prevState) => ({
                    ...prevState,
                    levelWarning: false,
                  }));
                //setWarningIndex(1);
              }}
            />
            {/* level warning */}
            {/* {validation.levelWarning && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>{t('Please enter Level')}!</span>
                </p>
              </div>
            )} */}
          </div>
          {/* select Level ends */}

          {/* ========= no of educator section start ========= */}
          <div className="form-group mb-4">
            <label htmlFor="numberOfEducators">
              {t("Number of Educators")}
            </label>
            <input
              type="text"
              id="numberOfEducators"
              className="form-control"
              value={noOfEducators}
              onChange={(e) => {
                setNoOfEducators(e.target.value);
                // setValidation((prevState) => ({
                //   ...prevState,
                //   noOfEducatorsWarning: false,
                // }));
              }}
            />

            {/* {validation.noOfEducatorsWarning && (
              <p className="mt-2 d-flex align-items-center gap-1 text-danger">
                <span className="material-symbols-outlined">warning</span>
                <span>{t("Please enter number of educators")}!</span>
              </p>
            )} */}
          </div>
          {/* ========= no of educator section end ========= */}

          {/* select educators start */}
          <div className="form-group mb-4">
            <label htmlFor="user">{t("Educators")}</label>
            <Select
              isMulti
              className="nosearch_select"
              options={educatorList}
              value={selectedEducators}
              onChange={(val) => {
                educatorSelectionHandler(val),
                  setValidation((prevState) => ({
                    ...prevState,
                    educatorsWarning: false,
                  }));
                //setWarningIndex(1);
              }}
            />
            {/* educator warning */}
            {/* {validation.educatorsWarning && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>{t('Please enter Educators')}!</span>
                </p>
              </div>
            )} */}
          </div>
          {/* select educators end */}

          {/* =========== select Specific Audience starts =========== */}
          <div className="form-group mb-4">
            <label htmlFor="audience">{t("Specific Audience")}</label>
            <Select
              className="nosearch_select"
              options={specificAudience}
              value={selectedAudience}
              onChange={(val) => {
                audienceSelectionHandler(val),
                  setValidation((prevState) => ({
                    ...prevState,
                    audienceWarning: false,
                  }));
                //setWarningIndex(1);
              }}
            />
            {/* Specific Audience warning */}
            {/* {validation.audienceWarning && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>{t('Please enter Specific Audience')}!</span>
                </p>
              </div>
            )} */}
          </div>
          {/* =========== select Specific Audience ends =========== */}

          {/* =============  Limit Number of Activities per Course (Leave blank for an
              unlimited session) starts========  */}
          <div className="form-group mb-4">
            <label>
              {t(
                "Limit Number of Activities per Course (Leave blank for an unlimited session)"
              )}
            </label>
            <input
              type="number"
              value={activityNumberPerCourse}
              onChange={(e) => {
                setActivityNumberPerCourse(e.target.value),
                  setValidation((prevState) => ({
                    ...prevState,
                    activitiesPerCourseWarning: false,
                  }));
                //setWarningIndex(1);
              }}
              className="form-control"
              placeholder={t("Enter")}
            />
            {/* Number of Activities per Course warning */}
            {/* {validation.activitiesPerCourseWarning && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>{t('Please enter Activities per Course')}!</span>
                </p>
              </div>
            )} */}
          </div>
          {/* =============  Limit Number of Activities per Course (Leave blank for an
              unlimited session) ends========  */}

          {/* =========== Combined Order starts ============ */}
          <div className="form-group mb-4">
            <label>{t("Combined Order")}</label>
            <input
              type="number"
              value={combinedOrder}
              onChange={(e) => {
                setCombinedOrder(e.target.value),
                  setValidation((prevState) => ({
                    ...prevState,
                    combinedOrderWarning: false,
                  }));
                //setWarningIndex(1);
              }}
              className="form-control"
              placeholder={t("Enter")}
            />
            {/* Combined Order warning */}
            {/* {validation.combinedOrderWarning && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>{t('Please enter combined Order')}!</span>
                </p>
              </div>
            )} */}
          </div>
          {/* =========== Combined Order ends ============ */}

          {/* =============== Minimum Number of Participants per Group starts======= */}
          <div className="form-group mb-4">
            <label>{t("Minimum Number of Participants per Group")}</label>
            <input
              type="number"
              value={minParticipants}
              onChange={(e) => {
                setMinParticipants(e.target.value),
                  setValidation((prevState) => ({
                    ...prevState,
                    minParticipantsWarning: false,
                  }));
                //setWarningIndex(1);
              }}
              className="form-control"
              placeholder={t("Enter")}
            />
            {/* Minimum Number of Participants warning */}
            {/* {validation.minParticipantsWarning && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>
                    {t('Please enter minimum Number of Participants per Group')}
                    !
                  </span>
                </p>
              </div>
            )} */}
          </div>
          {/* =============== Minimum Number of Participants per Group ends======= */}

          {/* ========= Maximum Number of Participants per Group starts ========== */}
          <div className="form-group mb-4">
            <label>{t("Maximum Number of Participants per Group")}</label>
            <input
              type="number"
              value={maxParticipants}
              onChange={(e) => {
                setMaxParticipants(e.target.value),
                  setValidation((prevState) => ({
                    ...prevState,
                    maxParticipantsWarning: false,
                  }));
                //setWarningIndex(1);
              }}
              className="form-control"
              placeholder={t("Enter")}
            />
            {/* Maximum Number of Participants warning */}
            {/* {validation.maxParticipantsWarning && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>
                    {t('Please enter maximum Number of Participants per Group')}
                    !
                  </span>
                </p>
              </div>
            )} */}
          </div>
          {/* ========= Maximum Number of Participants per Group ends ========== */}

          {/* ============= Maximun Number of Group starts============ */}
          <div className="form-group mb-4">
            <label>{t("Maximum Number of Group")}</label>
            <input
              type="number"
              value={groups}
              onChange={(e) => {
                setGroups(e.target.value),
                  setGroups(e.target.value),
                  setValidation((prevState) => ({
                    ...prevState,
                    maxGroupWarning: false,
                  }));
                //setWarningIndex(1);
              }}
              className="form-control"
              placeholder={t("Enter")}
            />
            {/* Maximun Number of Group warning */}
            {/* {validation.maxGroupWarning && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>{t('Please enter maximun Number of Group')}!</span>
                </p>
              </div>
            )} */}
          </div>
          {/* ============= Maximun Number of Group ends========= */}

          {/* ========== Responsible Ratio per Group starts ============*/}
          <div className="form-group mb-4">
            <label>{t("Responsible Ratio per Group")}</label>
            <input
              type="number"
              value={groupRatio}
              onChange={(e) => {
                setGroupRatio(e.target.value),
                  setValidation((prevState) => ({
                    ...prevState,
                    ratioWarning: false,
                  }));
                //setWarningIndex(1);
              }}
              className="form-control"
              placeholder={t("Enter")}
            />
            {/* Responsible Ratio per Group warning */}
            {/* {validation.ratioWarning && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>{t('Please enter responsible Ratio per Group')}!</span>
                </p>
              </div>
            )} */}
          </div>
          {/* ========== Responsible Ratio per Group ends ============*/}

          {/* ========= Select material kit starts */}
          <div className="form-group mb-4">
            <label htmlFor="kit">{t("Material kit")}</label>
            <Select
              isMulti
              className="nosearch_select"
              options={materialKitList}
              value={selectedMaterialKit}
              onChange={(val) => {
                materialSelectionHandler(val),
                  setValidation((prevState) => ({
                    ...prevState,
                    materialKitWarning: false,
                  }));
                //setWarningIndex(1);
              }}
            />
            {/* Material kit warning */}
            {/* {validation.materialKitWarning && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>{t('Please enter material kit')}!</span>
                </p>
              </div>
            )} */}
          </div>
          {/* ========= Select material kit ends */}

          {/* =========== observation starts  ==============*/}
          <div className="form-group mb-4">
            <label htmlFor="observations">{t("Observations")}</label>
            <textarea
              name="observations"
              id="observations"
              cols="30"
              rows="4"
              className="form-control"
              placeholder={t("Enter Comments")}
              value={observations}
              onChange={(e) => {
                setObservations(e.target.value),
                  setValidation((prevState) => ({
                    ...prevState,
                    observationsWarning: false,
                  }));
                //setWarningIndex(1);
              }}
            ></textarea>
            {/* Observations warning */}
            {/* {validation.observationsWarning && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>{t('Please enter observations')}!</span>
                </p>
              </div>
            )} */}
          </div>

          {/* =========== observation ends  ==============*/}

          {/* date section starts */}
          <div className="form-group mb-4">
            <div className="row">
              {/* <div className="col-lg-6">
                <label htmlFor="date">{t("Start Date")}</label>
                <input
                  type="date"
                  className="form-control"
                  placeholder="DD/MM/YYYY"
                  value={startDate}
                  onChange={(e) => {
                    setStartDate(e.target.value);
                    setValidation((prevState) => ({
                      ...prevState,
                      dateWarning: false,
                    }));
                  }}
                />
              </div> */}

              {/* <div className="col-lg-6">
                <label htmlFor="date">{t("End Date")}</label>
                <input
                  type="date"
                  id="date"
                  className="form-control"
                  placeholder="DD/MM/YYYY"
                  value={endDate}
                  onChange={(e) => {
                    setEndDate(e.target.value),
                      setValidation((prevState) => ({
                        ...prevState,
                        dateWarning: false,
                      }));
                  }}
                />
              </div> */}

              {/*  date compare warning */}
              {/* {validation.dateWarning && (
                <div className="error-message mt-2">
                  <p className="d-flex align-items-center gap-1 text-danger">
                    <span className="material-symbols-outlined">warning</span>
                    <span>
                      {t("End date should be greater than start date")}!
                    </span>
                  </p>
                </div>
              )} */}
            </div>
          </div>
          {/* date section ends */}

          {/* ======= Cost section start ======= */}
          <div className="form-group mb-4">
            <label>
              {t("Cost")} {currencySymbol}
            </label>
            <input
              type="number"
              value={activityCost}
              onChange={(e) => {
                setActivityCost(e.target.value);
                setValidation((prevState) => ({
                  ...prevState,
                  CostWarning: false,
                }));
                setRequiredFiledPending(false);
                //setWarningIndex(1);
              }}
              className="form-control"
              placeholder={t("Enter zipcode")}
            />

            {/* Cost warning */}
            {validation.CostWarning && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>{t("Please enter cost")}!</span>
                </p>
              </div>
            )}
          </div>
          {/* ======= Cost section end ======= */}

          {/* ========= select status start ======== */}
          <div className="form-group mb-4">
            <label>{t("Status")}</label>
            <Select
              className="nosearch_select"
              // options={StatusOptions}
              options={StatusOptions.map((option) => ({
                ...option,
                label: t(option.label), // Translate the label using the t function
              }))}
              value={selectedStatus}
              onChange={(val) => {
                {
                  statusSelectionHandler(val),
                    setValidation((prevState) => ({
                      ...prevState,
                      statusWarning: false,
                    }));
                  //setWarningIndex(1);
                }
              }}
            />
            {/* Status warning */}
            {validation.statusWarning && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>{t("Please enter status")}!</span>
                </p>
              </div>
            )}
          </div>
          {/* ========= select status end ======== */}

          {/* ========= Educator file upload start ========== */}
          <div className="form-group mb-4">
            {isEducatorFileUploading ? (
              <div className="d-flex align-items-center gap-1 mb-1">
                <p className="fw-bold">{t("Please wait while uploading")}</p>{" "}
                {[1, 2, 3, 4, 5, 6, 7, 8].map((count, index) => {
                  return (
                    <div
                      key={index}
                      className="spinner-grow spinner-grow-sm"
                      role="status"
                      style={{ height: "6px", width: "6px" }}
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  );
                })}
              </div>
            ) : (
              <label>{t("Educator Uploads")}</label>
            )}

            <input
              type="file"
              className="form-control"
              id="uploadedEducatorActivityFile"
              multiple
              onChange={educatorFileUploadHandler}
            />

            {uploadedEducatorFiles.length > 0 && (
              <ul className="uploaded-files d-flex flex-wrap gap-3 p-0 m-0 mt-3">
                {uploadedEducatorFiles.map((file, index) => {
                  return (
                    <li
                      className="file d-flex gap-3 align-items-center px-3 py-2 border border-gray rounded-3"
                      key={index}
                    >
                      <Link to="#" onClick={() => downloadFileHandler(file)}>
                        <span className="d-block material-symbols-outlined">
                          download
                        </span>
                      </Link>
                      <p className="fw-medium">{file.name}</p>
                      <Link
                        to="#"
                        onClick={() => closeEducatorFileHandler(index)}
                      >
                        <span className="d-block material-symbols-outlined">
                          close
                        </span>
                      </Link>
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
          {/* ========= Educator file upload end ===========*/}

          {/* ========= Beificiary file upload start ========== */}
          <div className="form-group mb-4">
            {isBeneficiaryFileUploading ? (
              <div className="d-flex align-items-center gap-1 mb-1">
                <p className="fw-bold">{t("Please wait while uploading")}</p>{" "}
                {[1, 2, 3, 4, 5, 6, 7, 8].map((count, index) => {
                  return (
                    <div
                      key={index}
                      className="spinner-grow spinner-grow-sm"
                      role="status"
                      style={{ height: "6px", width: "6px" }}
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  );
                })}
              </div>
            ) : (
              <label>{t("Beneficiary Uploads")}</label>
            )}

            <input
              type="file"
              className="form-control"
              id="uploadedBeneficiaryActivityFile"
              multiple
              onChange={beneficiaryFileUploadHandler}
            />

            {uploadedBeneficiaryFiles.length > 0 && (
              <ul className="uploaded-files d-flex flex-wrap gap-3 p-0 m-0 mt-3">
                {uploadedBeneficiaryFiles.map((file, index) => {
                  return (
                    <li
                      className="file d-flex gap-3 align-items-center px-3 py-2 border border-gray rounded-3"
                      key={index}
                    >
                      <Link to="#" onClick={() => downloadFileHandler(file)}>
                        <span className="d-block material-symbols-outlined">
                          download
                        </span>
                      </Link>
                      <p className="fw-medium">{file.name}</p>
                      <Link
                        to="#"
                        onClick={() => closeBeneficiaryFileHandler(index)}
                      >
                        <span className="d-block material-symbols-outlined">
                          close
                        </span>
                      </Link>
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
          {/* ========= Beificiary file upload end ===========*/}

          {/* ============= save button start =============== */}
          <div className="action d-flex align-items-center gap-2">
            <button
              type="submit"
              className="btn btn-primary"
              disabled={isSaving ? true : false}
              style={{ cursor: isSaving ? "not-allowed" : "pointer" }}
              onClick={saveUpdateActivityHandler}
            >
              {editActivityData ? t("Update") : t("Save")}
              {isSaving && (
                <div
                  className="mx-2 spinner-border spinner-border-sm"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              )}
            </button>
            {requiredFiledPending && (
              <p className="d-flex align-items-center gap-1 text-danger">
                <span className="material-symbols-outlined">warning</span>
                <span>{t("Please fill the required input fields")}!!!</span>
              </p>
            )}
          </div>
          {/* ============= save button end =============== */}
        </form>
      </div>
    </div>
  );
};

export default ActivitySaveRecord;
