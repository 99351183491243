/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";

import Select from "react-select";
//import translation function for language translation
import { useTranslation } from "react-i18next";
/*import url and gateway methods */
import { postData, putData, getData } from "utils/Gateway";
import * as url from "urlhelpers/UrlHelper";

/* import status from json */
import statusData from "../../../../data/Prod/Status.json";
import UserProfileTabBreadCrumb from "../BreadCrumb/UserProfileTabBreadCrumb";

const PasswordTab = (props) => {
  const { t } = useTranslation(); //for translation
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const params = useParams(); //for get the user id

  const [rolesList, setRolesList] = useState([]); //for roles

  const [userPassword, setUserPassword] = useState(""); //for passowrd

  const [isUpdating, setIsUpdating] = useState(false); //for updating status

  /*----- status requirements start ------*/
  const [statusValue, setStatusValue] = useState(null);
  const [userStatus, setUserStatus] = useState(null);
  /*----- status requirements end ------*/

  /*----- role requirements start ------*/
  const [roleValue, setRoleValue] = useState(null);
  const [userRole, setUserRole] = useState(null);
  /*----- role requirements end ------*/

  const [surname, setsurname] = useState("");
  const [position, setposition] = useState("");
  const [zipcode, setzipcode] = useState("");
  const [latitude, setlatitude] = useState("");
  const [longitude, setlongitude] = useState("");
  const [mapcoordinate, setmapcoordinate] = useState("");
  const [address, setaddress] = useState("");
  const [currency, setcurrency] = useState("");
  const [phone, setphone] = useState("");
  const [city, setcity] = useState("");
  const [state, setstate] = useState("");
  const [country, setcountry] = useState("");
  const [language, setlanguage] = useState("");

  // custom style for category
  const customSelectStyle = {
    // options style
    option: (provided, state) => ({
      ...provided,
      borderBottom: "1px dotted pink",
      color: "#666666",
      fontSize: "16px",
      fontFamily: "sans-serif",
    }),

    // Value style
    control: (styles) => ({
      ...styles,
      fontSize: "16px",
      padding: "5px",
      fontFamily: "sans-serif",
      border: "1px solid #D6D6D6",
      borderRadius: "8px",
      backgroundColor: "#fff",
      height: 50,
      boxShadow: "none",
    }),

    // placeholder style
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: "#8E8E8E",
        padding: "5px",
        fontSize: "16px",
        marginVertical: "4px",
        fontFamily: "sans-serif",
      };
    },

    // Indicator style
    dropdownIndicator: (base, state) => {
      let changes = { color: "#505050" };
      return Object.assign(base, changes);
    },
  };

  //function for get all role
  const getAllRoles = async () => {
    let requestUrl =
      url.API_BASEURL + url.API_GET_ALL_ROLES + `?token=${token}`;

    const response = await getData(requestUrl);

    if (response.status) {
      if (userInfo?.role?.name == "manager") {
        const filteredData = response.data.filter(
          (item) => item.label !== "admin" && item.label !== "manager"
        );
        setRolesList(filteredData);
      } else {
        setRolesList(response.data);
      }
    }
  };

  //function for select status
  const statusSelectionHandler = (val) => {
    setStatusValue(val);
    setUserStatus(val.value);
  };

  //function for select status
  const roleSelectionHandler = (val) => {
    setRoleValue(val);
    setUserRole(val.value);
  };

  //function for update user info
  const updateUserHandler = async (e) => {
    e.preventDefault();
    setIsUpdating(true);
    try {
      let userData = {
        status: userStatus,
        role: userRole,
        surname,
        position,
        zipcode,
        latitude,
        longitude,
        mapcoordinate,
        address,
        currency,
        phone,
        city,
        state,
        country,
        language,
      };

      if (userPassword != "") {
        userData.password = userPassword;
      }

      console.log("pass tab userdata----->", userData);

      let requestUrl =
        url.API_BASEURL +
        url.API_UPDATE_USER +
        `/${params.id}` +
        `?token=${token}`;

      console.log("pass tab url----->", requestUrl);

      const response = await putData(requestUrl, userData);

      console.log("response pass tab---->", response);

      if (response.status) {
        props.afterUpdatePasswordTab();
        setUserPassword("");
      }
      setIsUpdating(false);
    } catch (error) {
      console.error(error.message);
    }
  };

  useEffect(() => {
    if (props.userData) {
      // console.log("userdata------>", props.userData);
      /*-------- assign status to status select start --------*/
      if (props.userData.status) {
        if (props.userData.status == "1") {
          let statusData = {
            label: t("enable"),
            value: "1",
          };
          statusSelectionHandler(statusData); //set status value
          setUserStatus(props.userData.status);
        } else {
          let statusData = {
            label: t("disable"),
            value: "0",
          };
          statusSelectionHandler(statusData); //set status value
          setUserStatus(props.userData.status);
        }
        // else {
        //   let statusData = {
        //     label: "block",
        //     value: "2",
        //   };
        //   statusSelectionHandler(statusData); //set status value
        //   setUserStatus(props.userData.status);
        // }
      }
      /*-------- assign status to status select end --------*/

      /*-------- assign role to role select start --------*/
      if (props.userData.role) {
        let roleData = {
          label: props.userData.role.name,
          value: props.userData.role._id,
        };
        roleSelectionHandler(roleData);
        setUserRole(props.userData.role._id);
      }
      /*-------- assign role to role select end --------*/

      setsurname(props.userData?.surname ?? "");
      setposition(props.userData?.position ?? "");
      setzipcode(props.userData?.zipcode ?? "");
      setlatitude(props.userData?.latitude ?? "");
      setlongitude(props.userData?.longitude ?? "");
      setmapcoordinate(props.userData?.mapcoordinate ?? "");
      setaddress(props.userData?.address ?? "");
      setcity(props.userData?.city ?? "");
      setcurrency(props.userData?.currency ?? "");
      setstate(props.userData?.state ?? "");
      setphone(props.userData?.phone ?? "");
      setcountry(props.userData?.country ?? "");
      setlanguage(props.userData?.language ?? "");
    }
  }, [props.userData]);

  useEffect(() => {
    getAllRoles();
  }, []);

  return (
    <div className="profile_tabInnr">
      {/* ----- breadcrumb section start ------ */}
      <UserProfileTabBreadCrumb
        moduleName="Change Password"
        userData={props?.userData}
      />
      {/* ----- breadcrumb section end ------ */}

      {/* ----- heading section start ------ */}
      <div className="interest_hdng">
        <h4>{t("Change Password")}</h4>
      </div>
      {/* ----- heading section end ------ */}

      <div className="profile_graybx_Outer">
        <div className="profile_graybx_item">
          <div className="profile_fillOuter wd_260">
            {/* ----- New Password area start ------ */}
            <div className="profile_fill_row">
              <label>{t("New Password")}</label>
              <div className="input_fill">
                <input
                  type="password"
                  className="form-control"
                  placeholder={t("Enter New Password")}
                  value={userPassword}
                  onChange={(e) => setUserPassword(e.target.value)}
                />
              </div>
            </div>
            {/* ----- New Password area end ------ */}

            {/* ------- status area start ------ */}
            {userInfo?.role?.name == "admin" ||
            userInfo?.role?.name == "manager" ? (
              <div className="profile_fill_row">
                <label>{t("Status")}</label>
                <div className="input_fill">
                  <Select
                    options={statusData}
                    value={statusValue}
                    onChange={(val) => {
                      statusSelectionHandler(val);
                    }}
                    styles={customSelectStyle}
                  />
                </div>
              </div>
            ) : null}
            {/* ------- status area end ------ */}

            {/* ------- role area start ------ */}
            {userInfo?.role?.name == "admin" ||
            userInfo?.role?.name == "manager" ? (
              <div className="profile_fill_row">
                <label>{t("User Role")}</label>
                <div className="input_fill">
                  <Select
                    options={rolesList}
                    value={roleValue}
                    onChange={(val) => {
                      roleSelectionHandler(val);
                    }}
                    styles={customSelectStyle}
                  />
                </div>
              </div>
            ) : null}
            {/* ------- role area end ------ */}
          </div>

          {/* ----- update button section start ------ */}
          <div className="prfile_btnOuter">
            <div className="profile_rightBtn">
              <button
                className="btn btn-primary"
                disabled={isUpdating ? true : false}
                style={{ cursor: isUpdating ? "not-allowed" : "pointer" }}
                onClick={updateUserHandler}
              >
                {t("Update")}
                {isUpdating && (
                  <div
                    className="mx-2 spinner-border spinner-border-sm"
                    role="status"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                )}
              </button>
            </div>
          </div>
          {/* ----- update button section end ------ */}
        </div>
      </div>
    </div>
  );
};

export default PasswordTab;
