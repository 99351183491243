/* eslint-disable */
import React, { useState, useContext, useEffect } from "react";

//import translation function for language translation
import { useTranslation } from "react-i18next";

// kendo react modules ==========================
import "@progress/kendo-theme-material/dist/all.css";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";

//import api requiremnts
import * as url from "urlhelpers/UrlHelper";
import { getData } from "utils/Gateway";

/* importing dummy data */
// import reserveHistory from "../../../data/ReserveHistoryData.json";

//import context
import { GlobalProvider } from "context/GlobalContext";

const ReserveHistoryPopup = () => {
  const { reserveIdNumber, setReserveIdNumber, screenHeight } =
    useContext(GlobalProvider);

  const [reserveHistory, setReserveHistory] = useState([]);
  const [responseReceived, setResponseReceived] = useState(false);

  const token = localStorage.getItem("token");
  const { t } = useTranslation(); //for translation

  const initialDataState = {};
  const [dataState, setDataState] = useState();
  const [result, setResult] = useState(
    process(reserveHistory, initialDataState)
  );

  const onDataStateChange = (event) => {
    console.log("event -->", event);
    setDataState(event.dataState);
    setResult(process(reserveHistory, event.dataState));
  };

  /* custom date html */
  const customDateHtml = ({ dataItem }) => {
    return (
      <td className="date">
        <p
          className={`mb-1 ${
            dataItem.status == "1" ? "text-success" : "text-danger"
          } `}
        >
          {dataItem.updatedAt && dataItem.updatedAt.split("T")[0]}<br/>
          {dataItem.updatedreserveby
            ? dataItem.updatedreserveby
            : dataItem.ambreservationid && dataItem.status !== "1"
            ? "Siresa"
            : ""}
        </p>
      </td>
    );
  };

  /* custom hours html */
  const customHoursHtml = ({ dataItem }) => {
    return (
      <td className="hours">
        <p>
          {dataItem.startdate} <br />
          {dataItem.starttime} - {dataItem.endtime}
        </p>
      </td>
    );
  };

  //function for reset history
  const closeHistoryHandler = () => {
    setReserveIdNumber(null);
    setResponseReceived(false);
  };

  //function for get event history
  const getEventHistory = async () => {
    try {
      let requestUrl =
        url.API_BASEURL +
        url.API_GET_EVENT_HISTORY +
        `?token=${token}&reserveid=${reserveIdNumber}`;

      console.log("current reserveId", reserveIdNumber);

      const response = await getData(requestUrl);

      console.log("Response in history=======>", response);

      if (response.data) {
        setReserveHistory(response.data);
        setResponseReceived(true);
        setResult(process(response.data, initialDataState));
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  useEffect(() => {
    //console.log("reserve id number=======>", reserveIdNumber);
    //console.log("responseReceived =======>", responseReceived);

    //if (reserveIdNumber && responseReceived === false) {
    if (reserveIdNumber) {
      getEventHistory();
    }
  }, [reserveIdNumber, responseReceived]);

  return (
    <div
      className="offcanvas offcanvas-end border-0"
      tabIndex="-1"
      id="historyReservePopup"
      aria-labelledby="historyReservePopupLabel"
    >
      <div className="offcanvas-header align-items-start p-4">
        <h3 className="text-secondary mb-0" id="historyReservePopupLabel">
          {t("History of Reserve")}
        </h3>
        <button
          type="button"
          className="btn-close p-2 bg-white shadow-none shadow-sm m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={closeHistoryHandler}
        ></button>
      </div>
      <div className="offcanvas-body p-4 pt-0">
        <div className="table-responsive">
          <Grid
            data={result}
            style={{ height: screenHeight }}
            filterable={false}
            sortable={true}
            // groupable={true}
            onDataStateChange={onDataStateChange}
            {...dataState}
            //pageable = {true}
            //total = {products.length}
            rowHeight={50}
            reorderable
            // resizable
          >
            <GridColumn
              field="updatedAt"
              title={t("Date of Modification")}
              cell={customDateHtml}
              width="200px"
            />
            <GridColumn
              cell={customHoursHtml}
              field="startdate"
              title={t("Visit Date / Time")}
              width="200px"
            />
            {/* <GridColumn
                // field="hours.start"
                title={t("Hours")}
                width="150px"
              /> */}
            <GridColumn field="location" title={t("Location")} width="200px" />
            <GridColumn field="educator1" title={t("Educator")} width="200px" />
            <GridColumn field="tagstring" title={t("Tags")} width="200px" />
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default ReserveHistoryPopup;
