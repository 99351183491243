import React from 'react';
//import translation function for language translation
import { useTranslation } from 'react-i18next';
/* Importing react select */
import Select from 'react-select';

const AddEventsTab = () => {
  const { t } = useTranslation(); //for translation
  const events = [
    {
      label: 'AA01 - Activity of Planting Trees , 10-04-2023, 10.00',
      value: 'AA01 - Activity of Planting Trees , 10-04-2023, 10.00',
    },
    {
      label: 'AA04 - Activity , 10-04-2023, 10.00',
      value: 'AA04 - Activity , 10-04-2023, 10.00',
    },
  ];

  const [changeValues, setChangeValues] = React.useState({
    selectEvents: null,
    eventsArray: [],
  });

  return (
    <div
      className="tab-pane fade"
      id="add-events-tab-pane"
      role="tabpanel"
      aria-labelledby="add-events-tab"
      tabIndex="0"
    >
      <form action="">
        <div className="form-group mb-4">
          <label htmlFor="addEvents">{t('Add Events')}</label>
          <Select
            className="nosearch_select"
            options={events}
            isMulti
            value={changeValues.selectEvents}
            onChange={val =>
              setChangeValues({
                eventsArray: val.map(events => events.value),
              })
            }
          />
        </div>
        <div className="action">
          <button type="submit" className="btn btn-primary mw-unset">
            {t('Save')}
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddEventsTab;
