/* eslint-disable */
import React, { useEffect, useContext, useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";

//import translation function for language translation
import { useTranslation } from "react-i18next";

/*import url and gateway methods */
import { postData, putData, uploadMultipleFile, getData } from "utils/Gateway";
import * as url from "urlhelpers/UrlHelper";

//import context
import { GlobalProvider } from "context/GlobalContext";
import downloadFileHandler from "helpers/DownloadFile";

import TaskStatusOptions from "data/Prod/TaskStatus.json";
import TaskPriority from "data/Prod/TaskPriority.json";
import { assetImages } from "constants";

const ViewTaskDetailsPopup = () => {
  const todayValue = new Date().toISOString().split("T")[0];

  const token = localStorage.getItem("token"); // token
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const { t } = useTranslation(); //for translation
  /*---- CKEditor classic editor configuration start ----- */
  const editorConfig = {
    toolbar: ["bold", "italic", "link", "bulletedList", "numberedList"],
    ckfinder: {
      // Upload the images to the server using the CKFinder QuickUpload command.
      //   uploadUrl: url.URL_TEXT_EDITOR_IMAGE_UPLOAD_2,
    },
    placeholder: "Type description here...",
  };
  /*---- CKEditor classic editor configuration end ----- */

  const {
    setreloadTaskList,
    editTaskId,
    seteditTaskId,
    setSuccess,
    setError,
    setNotificationString,
  } = useContext(GlobalProvider);

  const [userName, setUserName] = useState("");
  const [userImage, setUserImage] = useState("");
  const [projectName, setprojectName] = useState("");
  const [projectId, setprojectId] = useState(null);
  const [title, settitle] = useState("");
  const [details, setdetails] = useState("");
  const [startDate, setstartDate] = useState(todayValue);
  const [endDate, setendDate] = useState(todayValue);
  const [hours, sethours] = useState("");
  const [price, setPrice] = useState("");
  const [priority, setpriority] = useState("");
  const [taskStatus, settaskStatus] = useState("");
  const [uploadedDocFiles, setUploadedDocFiles] = useState([]);

  const [taskStatusValue, settaskStatusValue] = useState(null);
  const [taskStatusData, settaskStatusData] = useState("");
  const [logHours, setlogHours] = useState("");
  const [logStartDate, setlogStartDate] = useState(todayValue);
  const [logEndDate, setlogEndDate] = useState(todayValue);
  const [logDetails, setlogDetails] = useState("");

  const [logList, setLogList] = useState([]);

  /* hook for validation */
  const [validation, setValidation] = useState({
    hoursWarning: false,
    detailsWarning: false,
    statusWarning: false,
  });

  /* function to validate form */
  const validate = () => {
    let isValid = true;

    if (logHours === "" || logHours == 0) {
      setValidation((prevState) => ({ ...prevState, hoursWarning: true }));
      isValid = false;
    }

    if (taskStatusData === null) {
      setValidation((prevState) => ({ ...prevState, userWarning: true }));
      isValid = false;
    }

    if (details === "") {
      setValidation((prevState) => ({ ...prevState, detailsWarning: true }));
      isValid = false;
    }

    return isValid;
  };

  //function for get project details
  const getTaskDetails = async () => {
    try {
      let requestUrl =
        url.API_BASEURL +
        url.API_GET_TASK_DETAILS +
        `/${editTaskId}?token=${token}`;

      const response = await getData(requestUrl);

      // console.log(response);
      if (response.status && response.data) {
        settitle(response.data.title);
        setdetails(response.data.description);
        setUserName(response.data.assignedusername);
        setUserImage(response.data.assigneduserimage);
        setprojectName(response.data.projectname);
        setprojectId(response.data.project._id);
        sethours(response.data.hours);
        setPrice(response.data.price);
        setstartDate(response.data.startdate);
        setendDate(response.data.enddate);
        setpriority(response.data.taskpriority);
        settaskStatus(response.data.taskstatus);
        //assign uploaded files
        setUploadedDocFiles(response?.data?.uploadedfiles);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //select status
  const statusSelectionHandler = (val) => {
    settaskStatusValue(val);
    settaskStatusData(val.value);
  };

  //reset
  const resetLogFormHandler = () => {
    setlogHours("");
    setlogStartDate(todayValue);
    setlogEndDate(todayValue);
    TaskStatusOptions.map((statusData) => {
      if (statusData.value == "1") {
        statusSelectionHandler(statusData);
      }
    });
    setlogDetails("");
  };

  //save task
  const saveLogHandler = async () => {
    if (validate()) {
      try {
        let taskData = {
          project: projectId,
          projecttask: editTaskId,
          completedpercent: taskStatusData,
          description: logDetails,
          startdate: logStartDate,
          enddate: logEndDate,
          hours: logHours,
          price,
          uploads: [],
        };

        let response = {};

        let requestUrl =
          url.API_BASEURL + url.API_ADD_PROJECT_TASK_LOG + `?token=${token}`;

        response = await postData(requestUrl, taskData);

        console.log(response);

        if (response.status) {
          resetLogFormHandler();
          setreloadTaskList(true);
          getTaskLogs();
        }
      } catch (error) {
        console.log(error.message);
      }
    }
  };

  //get task logs
  const getTaskLogs = async () => {
    try {
      let requestUrl =
        url.API_BASEURL +
        url.API_GET_PROJECT_TASK_LOGS +
        `?token=${token}&taskid=${editTaskId}`;

      console.log("log list url----->", requestUrl);

      const response = await getData(requestUrl);

      console.log(response);

      if (response.status) {
        setLogList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const closePopup = () => {
    resetLogFormHandler();
    seteditTaskId(null);
  };

  useEffect(() => {
    if (editTaskId) {
      getTaskDetails();
      getTaskLogs();
      // Get the tab element you want to trigger
      let tabElement = document.querySelector(
        '[data-bs-toggle="tab"][data-bs-target="#task-details2"]'
      );

      // Check if the tab element exists
      if (tabElement) {
        // Trigger the tab toggle
        var tab = new bootstrap.Tab(tabElement);
        tab.show();
      }
      TaskStatusOptions.map((statusData) => {
        if (statusData.value == "1") {
          statusSelectionHandler(statusData);
        }
      });
    }
  }, [editTaskId]);

  return (
    <div
      className="offcanvas offcanvas-end border-0 task_view_user"
      id="add_task_view"
    >
      <div className="offcanvas-header position-relative align-items-start p-4">
        <h3 className="text-secondary mb-0" id="saveReservationPopupLabel">
          <span className="d-block">Task Details</span>
        </h3>
        <button
          type="button"
          className="btn-close p-2 bg-white shadow-none shadow-sm m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={closePopup}
        ></button>
      </div>
      <div className="offcanvas-body position-relative p-4 pt-0">
        <ul
          className="nav nav-tabs w-100 flex-nowrap fs-sm lh-1 fw-semibold text-center border-0 text-nowrap overflow-auto mt-3"
          id="myTab"
          role="tablist"
        >
          <li className="nav-item flex-fill" role="presentation">
            <a
              className="text-black d-block px-3 py-3 position-relative active"
              id="task-details-tab2"
              data-bs-toggle="tab"
              data-bs-target="#task-details2"
              type="button"
              role="tab"
              aria-controls="task-details"
              aria-selected="true"
            >
              Tasks Details
            </a>
          </li>
          <li className="nav-item flex-fill" role="presentation">
            <a
              className="text-black d-block px-3 py-3 position-relative"
              id="logs-tab2"
              data-bs-toggle="tab"
              data-bs-target="#logs2"
              type="button"
              role="tab"
              aria-controls="logs"
              aria-selected="false"
              tabIndex="-1"
            >
              Logs
            </a>
          </li>
        </ul>

        <div className="tab-content h-100" id="myTabContent">
          <div
            className="tab-pane py-4 fade show active"
            id="task-details2"
            role="tabpanel"
            aria-labelledby="task-details-tab"
            tabIndex="0"
          >
            <form action="">
              <div className="form-group mb-4">
                <div className="user_task_hdng mb-4">
                  <h2>{title}</h2>
                </div>
                <div className="task_info_innr">
                  <div className="task_info_row d-flex align-items-center gap-1 mb-2 py-2 flex-wrap">
                    <label className="mb-0 w-sm-100">Assigned to</label>
                    <div className="sign_prsn_outer d-flex align-items-center gap-2">
                      <div className="aign_usr_item bg-gray-300 rounded-8 px-2 py-1 d-flex align-items-center gap-1">
                        <span className="rounded-circle d-block usr_img">
                          <img
                            className="thumb-25 rounded-circle"
                            src={
                              userImage == ""
                                ? assetImages.defaultUser
                                : userImage
                            }
                            alt=""
                          />
                        </span>
                        <span className="d-block">{userName}</span>
                      </div>
                    </div>
                  </div>
                  <div className="task_info_row d-flex align-items-center gap-1 mb-2 py-2">
                    <label className="mb-0">Project</label>
                    <h5 className="mb-0">{projectName}</h5>
                  </div>
                  <div className="task_info_row d-flex align-items-center gap-1 mb-2 py-2">
                    <label className="mb-0">Status</label>
                    <h5 className="d-flex align-items-center gap-1 mb-0">
                      <i className="material-symbols-outlined text-green">
                        check_circle
                      </i>
                      <span>{taskStatus}</span>
                    </h5>
                  </div>
                  <div className="task_info_row d-flex align-items-center gap-1 mb-2 py-2">
                    <label className="mb-0">Time(Hrs)</label>
                    <h5 className="d-flex align-items-center gap-1 mb-0">
                      <i className="material-symbols-outlined text-red">
                        schedule
                      </i>
                      <span>{hours} Hrs</span>
                    </h5>
                  </div>
                  <div className="task_info_row d-flex align-items-center gap-1 mb-2 py-2">
                    <label className="mb-0">Deadline</label>
                    <h5 className="d-flex align-items-center gap-1 mb-0">
                      <i className="material-symbols-outlined">
                        calendar_today
                      </i>
                      <span>
                        {startDate} To {endDate}
                      </span>
                    </h5>
                  </div>
                  <div className="task_info_row d-flex align-items-center gap-1 mb-2 py-2">
                    <label className="mb-0">Priority</label>
                    <div className="piority_status d-inline-flex align-items-center py-1 px-2 rounded-10 bg-primary text-white fs-xs">
                      {priority}
                    </div>
                  </div>
                  <div className="task_info_row d-flex align-items-center gap-1 py-2">
                    <label className="mb-0">Price (Euro)</label>
                    <h5 className="d-flex align-items-center gap-1 mb-0">
                      <i className="material-symbols-outlined text-green">
                        euro
                      </i>
                      <span>{price}</span>
                    </h5>
                  </div>
                </div>
              </div>
              <div className="form-group mb-4">
                <label htmlFor="Details" className="d-block  fw-semibold mb-2">
                  Details
                </label>
                <div className="details_para">
                  <p
                    className="mb-3"
                    dangerouslySetInnerHTML={{ __html: details }}
                  ></p>
                </div>
              </div>
              <div className="form-group mb-4">
                <label
                  htmlFor="attachments"
                  className="d-block  fw-semibold mb-2"
                >
                  Attachments
                </label>
                <ul className="uploaded-files d-flex flex-wrap gap-3 p-0 m-0 mt-3">
                  {uploadedDocFiles.map((file, index) => {
                    return (
                      <li
                        className="file d-flex gap-3 align-items-center px-3 py-2 border border-gray rounded-3"
                        key={index}
                      >
                        <p className="fw-medium">{file.name}</p>
                        <Link to="#" onClick={() => downloadFileHandler(file)}>
                          <span className="d-block material-symbols-outlined">
                            download
                          </span>
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </form>
          </div>

          {/* log tab */}
          <div
            className="tab-pane h-100 fade"
            id="logs2"
            role="tabpanel"
            aria-labelledby="logs-tab"
            tabIndex="0"
          >
            <div className="d-flex flex-column h-100">
              {/* {logList.map((logData, index) => {
                return (
                  <ul className="logs flex-fill p-4 overflow-auto">
                    <li className="log-date pb-3">
                      <p className="d-flex gap-2 align-items-center justify-content-center fs-md fw-semibold">
                        <span className="d-block">
                          {logData.logmonthstring} {logData.logyear}
                        </span>
                        <span className="d-flex align-items-center gap-1 text-gray ">
                          <span className="d-block material-symbols-outlined icon-lg">
                            schedule
                          </span>
                          <span>{logData.hours}Hr</span>
                        </span>
                      </p>
                    </li>

                    <li className="log">
                      <div className="d-flex gap-2">
                        <div
                          className="avatar rounded-circle overflow-hidden"
                          style={{ width: "24px", height: "24px" }}
                        >
                          <img
                            src={
                              logData.userimage == ""
                                ? assetImages.defaultUser
                                : logData.userimage
                            }
                            alt="Richard"
                            className="w-100 h-100 object-fit-cover object-center"
                          />
                        </div>
                        <div>
                          <p className="d-flex align-items-center gap-2 mb-2">
                            <span className="d-block fs-md fw-semibold">
                              {logData.username}
                            </span>
                            <span className="d-block fs-sm text-gray">
                              {logData.startdate}
                            </span>
                            <span className="d-block fs-xs lh-1 text-primary gradient-light px-2 py-1 rounded-90">
                              {logData.hours}hr
                            </span>
                          </p>
                          <p className="message body-bg px-3 py-2 fs-sm rounded-10">
                            {logData.description}
                          </p>
                        </div>
                      </div>
                    </li>

                    <li className="log">
                      <div className="d-flex gap-2">
                        <div
                          className="avatar rounded-circle overflow-hidden"
                          style={{ width: "24px", height: "24px" }}
                        >
                          <img
                            src={
                              logData.userimage == ""
                                ? assetImages.defaultUser
                                : logData.userimage
                            }
                            alt="Richard"
                            className="w-100 h-100 object-fit-cover object-center"
                          />
                        </div>
                        <div>
                          <p className="d-flex align-items-center gap-2 mb-2">
                            <span className="d-block fs-md fw-semibold">
                              {logData.username}
                            </span>
                            <span className="d-block fs-sm text-gray">
                              {logData.startdate}
                            </span>
                            <span className="d-block fs-xs lh-1 text-primary gradient-light px-2 py-1 rounded-90">
                              {logData.hours}hr
                            </span>
                          </p>
                          <p className="message body-bg px-3 py-2 fs-sm rounded-10">
                            {logData.description}
                          </p>
                        </div>
                      </div>
                    </li>

                    <li className="log">
                      <div className="d-flex gap-2">
                        <div
                          className="avatar rounded-circle overflow-hidden"
                          style={{ width: "24px", height: "24px" }}
                        >
                          <img
                            src={
                              logData.userimage == ""
                                ? assetImages.defaultUser
                                : logData.userimage
                            }
                            alt="Richard"
                            className="w-100 h-100 object-fit-cover object-center"
                          />
                        </div>
                        <div>
                          <p className="d-flex align-items-center gap-2 mb-2">
                            <span className="d-block fs-md fw-semibold">
                              {logData.username}
                            </span>
                            <span className="d-block fs-sm text-gray">
                              {logData.startdate}
                            </span>
                            <span className="d-block fs-xs lh-1 text-primary gradient-light px-2 py-1 rounded-90">
                              {logData.hours}hr
                            </span>
                          </p>
                          <p className="message body-bg px-3 py-2 fs-sm rounded-10">
                            {logData.description}
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>
                );
              })} */}
              <div className="logs flex-fill p-4 overflow-auto">
                {logList.map((logData, index) => {
                  return (
                    <div key={index}>
                      <div className="log-date pb-3">
                        <p className="d-flex gap-2 align-items-center justify-content-center fs-md fw-semibold mt-3">
                          <span className="d-block">
                            {logData.logmonthstring} {logData.logyear}
                          </span>
                          <span className="d-flex align-items-center gap-1 text-gray ">
                            <span className="d-block material-symbols-outlined icon-lg">
                              schedule
                            </span>
                            <span>{logData.logmonthhour}Hr</span>
                          </span>
                        </p>
                      </div>
                      {logData.logs.map((log, index2) => {
                        return (
                          <div className="log mt-1" key={index2}>
                            <div className="d-flex gap-2">
                              <div
                                className="avatar rounded-circle overflow-hidden"
                                style={{ width: "24px", height: "24px" }}
                              >
                                <img
                                  src={
                                    log.userimage == ""
                                      ? assetImages.defaultUser
                                      : log.userimage
                                  }
                                  alt="Richard"
                                  className="w-100 h-100 object-fit-cover object-center"
                                />
                              </div>
                              <div>
                                <p className="d-flex align-items-center gap-2 mb-2">
                                  <span className="d-block fs-md fw-semibold">
                                    {log.username}
                                  </span>
                                  <span className="d-block fs-sm text-gray">
                                    {log.startdate}
                                  </span>
                                  <span className="d-block fs-xs lh-1 text-primary gradient-light px-2 py-1 rounded-90">
                                    {log.hours}hr
                                  </span>
                                </p>
                                <p className="message body-bg px-3 py-2 fs-sm rounded-10">
                                  {log.description}
                                </p>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  );
                })}

                {/* <div className="log-date pb-3">
                  <p className="d-flex gap-2 align-items-center justify-content-center fs-md fw-semibold mt-3">
                    <span className="d-block">November 2022</span>
                    <span className="d-flex align-items-center gap-1 text-gray ">
                      <span className="d-block material-symbols-outlined icon-lg">
                        schedule
                      </span>
                      <span>0Hr</span>
                    </span>
                  </p>
                </div>
                <div className="log">
                  <div className="d-flex gap-2">
                    <div
                      className="avatar rounded-circle overflow-hidden"
                      style={{ width: "24px", height: "24px" }}
                    >
                      <img
                        src="assets/img/user2.png"
                        alt="Jhon"
                        className="w-100 h-100 object-fit-cover object-center"
                      />
                    </div>
                    <div>
                      <p className="d-flex align-items-center gap-2 mb-2">
                        <span className="d-block fs-md fw-semibold">Jhon</span>
                        <span className="d-block fs-sm text-gray">
                          04/09/2022
                        </span>
                      </p>
                      <p className="message gradient-light px-3 py-2 fs-sm rounded-10">
                        Task has been completed by Hrithik Roshan
                      </p>
                    </div>
                  </div>
                </div>
                <div className="log">
                  <div className="d-flex gap-2">
                    <div
                      className="avatar rounded-circle overflow-hidden"
                      style={{ width: "24px", height: "24px" }}
                    >
                      <img
                        src="assets/img/user2.png"
                        alt="Jhon"
                        className="w-100 h-100 object-fit-cover object-center"
                      />
                    </div>
                    <div>
                      <p className="d-flex align-items-center gap-2 mb-2">
                        <span className="d-block fs-md fw-semibold">Jhon</span>
                        <span className="d-block fs-sm text-gray">
                          04/09/2022
                        </span>
                      </p>
                      <p className="message gradient-light px-3 py-2 fs-sm rounded-10">
                        Task has been completed by Hrithik Roshan
                      </p>
                    </div>
                  </div>
                </div> */}
              </div>
              {/* log submit form */}
              <div className="log-form body-bg px-4 py-3">
                <form onSubmit={(e) => e.preventDefault()}>
                  <div className="form-group mb-3 logs_time_row">
                    <div className="row">
                      <div className="col-lg-3 mb-3 mb-lg-0 ">
                        <label
                          htmlFor=""
                          className="d-block fs-sm fw-semibold mb-2"
                        >
                          Time (Hrs)
                        </label>
                        <input
                          type="number"
                          className="form-control bg-white border-gray-300 fs-sm shadow-none"
                          placeholder="Hrs required"
                          value={logHours}
                          onChange={(e) => setlogHours(e.target.value)}
                        />
                        {validation.hoursWarning && (
                          <div className="error-message mt-2">
                            <p className="d-flex align-items-center gap-1 text-danger">
                              <span className="material-symbols-outlined">
                                warning
                              </span>
                              <span>{t("Please enter hours")}!</span>
                            </p>
                          </div>
                        )}
                      </div>
                      <div className="col-lg-6">
                        <label
                          htmlFor=""
                          className="d-block fs-sm fw-semibold mb-2"
                        >
                          Dates
                        </label>
                        <div className="field-container d-flex flex-column flex-lg-row gap-3 align-items-lg-center bg-lg-white border-lg border-gray-300 rounded-10">
                          <input
                            type="date"
                            className="form-control bg-white border-gray-300 fs-sm shadow-none"
                            placeholder="Start date required"
                            value={logStartDate}
                            onChange={(e) => setlogStartDate(e.target.value)}
                          />
                          <input
                            type="date"
                            className="form-control bg-white border-gray-300 fs-sm shadow-none"
                            placeholder="End date required"
                            value={logEndDate}
                            onChange={(e) => setlogEndDate(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-3 mb-3 mb-lg-0 ">
                        <label
                          htmlFor=""
                          className="d-block fs-sm fw-semibold mb-2"
                        >
                          Task Status
                        </label>
                        <Select
                          placeholder={t("Select task status")}
                          options={TaskStatusOptions}
                          value={taskStatusValue}
                          onChange={(val) => {
                            statusSelectionHandler(val);
                          }}
                        />
                        {validation.statusWarning && (
                          <div className="error-message mt-2">
                            <p className="d-flex align-items-center gap-1 text-danger">
                              <span className="material-symbols-outlined">
                                warning
                              </span>
                              <span>{t("Please select status")}!</span>
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="form-group mb-3">
                    <textarea
                      name=""
                      id=""
                      cols="30"
                      rows="5"
                      className="form-control bg-white fs-sm border-gray-300 shadow-none"
                      placeholder="Eneter your message"
                      value={logDetails}
                      onChange={(e) => setlogDetails(e.target.value)}
                    ></textarea>
                    {validation.detailsWarning && (
                      <div className="error-message mt-2">
                        <p className="d-flex align-items-center gap-1 text-danger">
                          <span className="material-symbols-outlined">
                            warning
                          </span>
                          <span>{t("Please enter message")}!</span>
                        </p>
                      </div>
                    )}
                  </div>
                  <div className="action d-flex align-items-center gap-3">
                    {/* <div className="form-check p-0 d-flex align-items-center gap-2 m-0 cursor-pointer">
                      <input
                        className="form-check-input d-block m-0"
                        type="checkbox"
                        value=""
                        id="completed"
                      />
                      <label
                        className="form-check-label d-block text-gray cursor-pointer"
                        htmlFor="completed"
                      >
                        Completed Task
                      </label>
                    </div> */}
                    {/* <p className="d-flex align-items-center gap-2 fs-xs">
                      <span className="d-block material-symbols-outlined icon-md">
                        schedule
                      </span>
                      <span className="d-block">58Hrs / 100Hrs</span>
                    </p> */}
                    <button
                      onClick={saveLogHandler}
                      type="button"
                      className="btn btn-primary"
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewTaskDetailsPopup;
