/* eslint-disable */
import React, { useState, useEffect, useContext, createRef } from "react";

import { Link } from "react-router-dom";

// kendo react modules ==========================
import "@progress/kendo-theme-material/dist/all.css";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";

// impoting excel colums
import {
  ExcelExport,
  ExcelExportColumn,
} from "@progress/kendo-react-excel-export";

//api url requirement
import * as url from "urlhelpers/UrlHelper";
import { getData } from "utils/Gateway";

//import context
import { GlobalProvider } from "context/GlobalContext";

//import translation function for language translation
import { useTranslation } from "react-i18next";

const LabelList = ({
  downloadExcel,
  search,
  setRecords,
  checkedRecordCount,
}) => {
  const token = localStorage.getItem("token"); // token
  const { t } = useTranslation(); //for translation

  const {
    reloadLabelList,
    setReloadLabelList,
    setEditLabelData,
    screenHeight,
  } = useContext(GlobalProvider);

  const [labelList, setLabelList] = useState([]);
  const [responseReceived, setResponseReceived] = useState(false);

  // without pagination ------------------------------------
  const initialDataState = {};
  const [dataState, setDataState] = React.useState();
  const [result, setResult] = React.useState(
    process(labelList, initialDataState)
  );

  const onDataStateChange = (event) => {
    console.log("event -->", event);
    setDataState(event.dataState);
    setResult(process(labelList, event.dataState));
  };
  /* Handle the button's onClick event and set it to export the data by using the ExcelExport save method. */
  const _exporter = createRef();

  const exportExcel = () => {
    if (_exporter.current) {
      _exporter.current.save();
    }
  };

  /* function to search */
  const filterData = () => {
    if (!result || !result.data) {
      return []; // Return an empty array if result or result.data is undefined
    }

    const filteredData = result.data.filter((item) => {
      const title = item?.title?.toLowerCase() || "";
      const slug = item?.slug?.toLowerCase() || "";
      const labeldate = item?.labeldate;

      return (
        (title && title.includes(search.toLowerCase())) ||
        (slug && slug.includes(search.toLowerCase())) ||
        (labeldate && labeldate.includes(search))
      );
    });
    setRecords(filteredData.length);
    return filteredData;
  };

  /* function to get all label data */
  const getAllLabelList = async () => {
    try {
      let requestUrl =
        url.API_BASEURL + url.API_GET_ALL_TAG + `?token=${token}`;

      console.log("reserve list url =====>>>", requestUrl);

      const response = await getData(requestUrl);

      console.log("label list response ========>>>", response);

      if (response.data) {
        setLabelList(response.data);
        setResponseReceived(true);
        dataState
          ? setResult(process(response.data, dataState))
          : setResult(process(response.data, initialDataState));
        setRecords(response.data.length);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  // check the selected count and show the count in top and also selected download xls ------------------------
  const handelSelectedCount = () => {
    let checkboxes = document.querySelectorAll(
      'input[name="selectLabelRecord"]'
    );

    let totalSelected = 0;
    let filteredData = [];
    checkboxes.forEach((checkbox) => {
      if (checkbox.checked) {
        totalSelected++;
        const filteredUser = labelList.find(
          (user) => user._id === checkbox.value
        );
        filteredUser && filteredData.push(filteredUser);
      }
    });

    // Update the content of the block
    checkedRecordCount(totalSelected);
    return filteredData;
  };

  // mark all checkbox as selected ----------------
  const selectAllRecords = (e) => {
    document
      .querySelectorAll('.k-master-row input[name="selectLabelRecord"]')
      .forEach((checkbox) => {
        checkbox.checked = e.target.checked;
      });
    handelSelectedCount();
  };

  // blocks to replace the Grid HTML ==============================================
  const SelectRecordHeader = () => {
    return (
      <div className="d-flex align-items-center">
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            id="selectAllMain"
            onClick={selectAllRecords}
          />
        </div>
      </div>
    );
  };

  /* Select single row */
  const SelectRecordRow = (props) => {
    return (
      <td>
        <div className="form-check">
          <input
            className="form-check-input "
            type="checkbox"
            value={props.dataItem._id}
            name="selectLabelRecord"
            onClick={handelSelectedCount}
          />
        </div>
      </td>
    );
  };

  /* custom title html */
  const titleHtml = ({ dataItem }) => {
    return (
      <td className="title">
        <div className="d-flex align-items-center gap-2">
          <span>{dataItem.title}</span>
          <Link
            to="#addUpdateLabelPopup"
            data-bs-toggle="offcanvas"
            className="ml-auto edit_icon"
          >
            <span
              className="d-block material-symbols-outlined icon-md"
              onClick={() => {
                setEditLabelData(dataItem);
              }}
            >
              edit
            </span>
          </Link>
        </div>
      </td>
    );
  };

  /* hook to reload list */
  useEffect(() => {
    if (reloadLabelList || !responseReceived) {
      getAllLabelList();
      setResult(process(labelList, initialDataState));
      if (reloadLabelList) {
        setReloadLabelList(false);
      }
    }
  }, [reloadLabelList, responseReceived]);

  /* hook to hit  exportExcel() function*/
  useEffect(() => {
    downloadExcel && exportExcel();
  }, [downloadExcel]);

  /* hook to hit filterData function on search */
  useEffect(() => {
    filterData();
  }, [search]);

  /* set records based on onDataStateChange */
  useEffect(() => {
    setRecords(result.data.length);
  }, [result]);

  return (
    <div className="table-responsive">
      {/* ============ download xls start ========== */}
      <ExcelExport
        data={
          handelSelectedCount().length > 0 ? handelSelectedCount() : labelList
        }
        fileName="Label.xlsx"
        ref={_exporter}
      >
        <ExcelExportColumn field="title" title={t("Title")} />
        <ExcelExportColumn field="slug" title={t("Slug")} />
        <ExcelExportColumn field="isreservedstring" title={t("Reserved")} />
        <ExcelExportColumn field="labeldate" title={t("Date")} />
      </ExcelExport>

      {/* ============ download xls end ========== */}
      {search ? (
        <Grid
          style={{ height: screenHeight }}
          data={filterData()}
          filterable={true}
          sortable={true}
          groupable={true}
          onDataStateChange={onDataStateChange}
          {...dataState}
          //pageable = {true}
          //total = {products.length}
          reorderable
          // resizable
        >
          <GridColumn
            field="id"
            headerCell={SelectRecordHeader}
            cell={SelectRecordRow}
            filterable={false}
            sortable={false}
            width="70px"
          />
          <GridColumn
            cell={titleHtml}
            field="title"
            title={t("Title")}
            //width="200px"
          />
          <GridColumn
            field="slug"
            title={t("Slug")}
            //width="200px"
          />
          <GridColumn
            field="isreservedstring"
            title={t("Reserved")}
            //width="200px"
          />

          <GridColumn
            field="labeldate"
            title={t("Date")}
            //width="200px"
          />
        </Grid>
      ) : (
        <Grid
          style={{ height: screenHeight }}
          data={result}
          filterable={true}
          sortable={true}
          groupable={true}
          onDataStateChange={onDataStateChange}
          {...dataState}
          //pageable = {true}
          //total = {products.length}
          reorderable
          // resizable
        >
          <GridColumn
            field="id"
            headerCell={SelectRecordHeader}
            cell={SelectRecordRow}
            filterable={false}
            sortable={false}
            width="70px"
          />
          <GridColumn
            cell={titleHtml}
            field="title"
            title={t("Title")}
            //width="200px"
          />
          <GridColumn
            field="slug"
            title={t("Slug")}
            //width="200px"
          />

          <GridColumn
            field="isreservedstring"
            title={t("Reserved")}
            //width="200px"
          />

          <GridColumn
            field="labeldate"
            title={t("Date")}
            //width="200px"
          />
        </Grid>
      )}
    </div>
  );
};

export default LabelList;
