/* eslint-disable */
import React, { useEffect, useState, useRef } from "react";
import ReactDOM from "react-dom";
import LeftMenu from "components/common/LeftMenu";
import UserMenu from "components/common/UserMenu";
import CustomNotification from "components/common/CustomNotification";
import ProjectBreadCrumb from "components/ProjectComponents/BreadCrumb/ProjectBreadCrumb";
import ProjectHeader from "components/ProjectComponents/Header/ProjectHeader";
import ProjectList from "components/ProjectComponents/ProjectList/ProjectList";
import SaveProjectPopup from "components/ProjectComponents/Popup/SaveProjectPopup";
import AddLabel from "components/common/AddLabel";
import ProjectFilter from "components/ProjectComponents/Filter/ProjectFilter";
const Project = () => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  /* useState hook to download excel file */
  const [downloadExcel, setDownloadExcel] = useState(false);
  /* hook to search */
  const [search, setSearch] = useState("");
  /* hook to get number of records */
  const [records, setRecords] = useState(0);
  const blockRefSelected = useRef(null); // this will be used to acccess dom element directly
  /* function for checking record count */
  const checkedRecordCount = (selectCount) => {
    const blockElementSelect = ReactDOM.findDOMNode(blockRefSelected.current);
    if (blockElementSelect) {
      blockElementSelect.innerHTML = selectCount;
    }
  };
  useEffect(() => {
    document.title = "Project"; // Update the title when Login component mounts
  }, []);
  return (
    <>
      <UserMenu />
      <LeftMenu />
      <section className="mail-wrapper pt-2">
        <div className="container-fluid px-4">
          <ProjectBreadCrumb />
          <ProjectHeader
            setDownloadExcel={setDownloadExcel}
            setSearch={setSearch}
            records={records}
            blockRefSelected={blockRefSelected}
          />
          <ProjectFilter />
          <ProjectList
            downloadExcel={downloadExcel}
            search={search}
            setRecords={setRecords}
            checkedRecordCount={checkedRecordCount}
          />
        </div>
      </section>
      <SaveProjectPopup />
      <AddLabel module={"project"} />
      {/*----------- custom notification component start -----------*/}
      <CustomNotification />
      {/*----------- custom notification component end -----------*/}
    </>
  );
};
export default Project;
