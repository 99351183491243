/* eslint-disable */
import { useState, useContext } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";

//import context
import { GlobalProvider } from "context/GlobalContext";

//import translation function for language translation
import { useTranslation } from "react-i18next";

const ToggleColumn = () => {
  const { t } = useTranslation(); //for translation
  const {} = useContext(GlobalProvider);

  //use state for select field/columns
  const [selectedColumnValue, setSelectedColumnValue] = useState(null);
  const [columns, setColumns] = useState([]);

  //option for select dropdown
  const columnOption = [
    { label: "Name", value: "Name" },
    { label: "Role", value: "Role" },
    { label: "Email", value: "Email" },
    { label: "Phone", value: "Phone" },
    { label: "City", value: "City" },
    { label: "State", value: "State" },
    { label: "Country", value: "Country" },
  ];

  //function for select column by dropdown
  const fieldSelectionHandler = (val) => {
    setSelectedColumnValue(val);
    const columnValaues = val.map((column) => {
      return column.value;
    });
    setColumns(columnValaues);
  };

  //function for display selected columns
  // const columnSelectionHandler = (e) => {
  //   e.preventDefault();
  //   if (columns.length == 0) {
  //     return;
  //   } else {
  //     setshowUserNameColumn(false);
  //     setShowUserRoleColumn(false);
  //     setShowUserEmailColumn(false);
  //     setShowUserPhoneColumn(false);
  //     setShowUserCityColumn(false);
  //     setShowUserStateColumn(false);
  //     setShowUserCountryColumn(false);

  //     columns.map((column) => {
  //       console.log(column);
  //       if (column == 'Name') {
  //         setshowUserNameColumn(true);
  //       }

  //       if (column == 'Role') {
  //         setShowUserRoleColumn(true);
  //       }

  //       if (column == 'Email') {
  //         setShowUserEmailColumn(true);
  //       }

  //       if (column == 'Phone') {
  //         setShowUserPhoneColumn(true);
  //       }

  //       if (column == 'City') {
  //         setShowUserCityColumn(true);
  //       }

  //       if (column == 'State') {
  //         setShowUserStateColumn(true);
  //       }

  //       if (column == 'Country') {
  //         setShowUserCountryColumn(true);
  //       }
  //     });
  //   }
  // };

  //function for reset select
  const resetColumn = () => {
    setshowUserNameColumn(true);
    setShowUserRoleColumn(true);
    setShowUserEmailColumn(true);
    setShowUserPhoneColumn(true);
    setShowUserCityColumn(true);
    setShowUserStateColumn(true);
    setShowUserCountryColumn(true);
    setSelectedColumnValue(null);
    setColumns([]);
  };

  return (
    <div className="collapse my-3" id="coursecolumnCollapse">
      <div className="p-3 bg-gray-200">
        <div className="d-flex gap-3 align-items-center justify-content-between mb-3">
          <h4 className="mb-0">Select Columns</h4>

          {/* ----- return button start ------ */}
          <Link
            to="#"
            className="d-flex gap-1 align-items-center text-black"
            onClick={resetColumn}
          >
            <span className="d-block material-symbols-outlined icon-md">
              sync
            </span>
            <span className="d-block fw-medium">{t("Refresh")}</span>
          </Link>
          {/* ----- return button end ------ */}
        </div>
        <form /* onSubmit={columnSelectionHandler} */>
          <div className="form-container d-flex flex-wrap gap-2">
            <div className="form-group flex-fill">
              <Select
                className="nosearch_select"
                isMulti
                options={columnOption}
                value={selectedColumnValue}
                onChange={(val) => fieldSelectionHandler(val)}
              />
            </div>
            <div className="action">
              <button type="submit" className="btn btn-primary mw-unset">
                {t("Show")}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ToggleColumn;
