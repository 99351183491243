/* eslint-disable */
import React, { useEffect, useContext, useState } from "react";
import { Link } from "react-router-dom";
// Import CKEditor here (both required).
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
//import translation function for language translation
import { useTranslation } from "react-i18next";
/*import url and gateway methods */
import { postData, putData, uploadMultipleFile, getData } from "utils/Gateway";
import * as url from "urlhelpers/UrlHelper";
//import context
import { GlobalProvider } from "context/GlobalContext";
import downloadFileHandler from "helpers/DownloadFile";
const SaveProjectPopup = () => {
  const todayValue = new Date().toISOString().split("T")[0];
  const token = localStorage.getItem("token"); // token
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const { t } = useTranslation(); //for translation
  /*---- CKEditor classic editor configuration start ----- */
  const editorConfig = {
    toolbar: ["bold", "italic", "link", "bulletedList", "numberedList"],
    ckfinder: {
      // Upload the images to the server using the CKFinder QuickUpload command.
      //   uploadUrl: url.URL_TEXT_EDITOR_IMAGE_UPLOAD_2,
    },
    placeholder: t("Type description here..."),
  };
  /*---- CKEditor classic editor configuration end ----- */
  const {
    setreloadProjectList,
    editProjectId,
    seteditProjectId,
    setSuccess,
    setError,
    setNotificationString,
  } = useContext(GlobalProvider);
  const [title, settitle] = useState("");
  const [summary, setsummary] = useState("");
  const [details, setdetails] = useState("");
  const [startDate, setstartDate] = useState(todayValue);
  const [endDate, setendDate] = useState(todayValue);
  const [docFileUploading, setDocFileUploading] = useState(false);
  const [uploadedDocFiles, setUploadedDocFiles] = useState([]);
  const [uploadedDocFileIds, setUploadedDocFileIds] = useState([]);
  const [isSending, setIsSending] = useState(false);
  /* hook for validation */
  const [validation, setValidation] = useState({
    titleWarning: false,
    summaryWarning: false,
    detailsWarning: false,
    dateWarning: false,
  });
  /* function to validate form */
  const validate = () => {
    let isValid = true;
    if (title === "") {
      setValidation((prevState) => ({ ...prevState, titleWarning: true }));
      isValid = false;
    }
    if (summary === "") {
      setValidation((prevState) => ({ ...prevState, summaryWarning: true }));
      isValid = false;
    }
    if (details === "") {
      setValidation((prevState) => ({ ...prevState, detailsWarning: true }));
      isValid = false;
    }
    if (
      startDate === "" ||
      endDate == "" ||
      startDate === endDate ||
      endDate < startDate
    ) {
      setValidation((prevState) => ({ ...prevState, dateWarning: true }));
      isValid = false;
    }
    return isValid;
  };
  //function for upload doc files
  const docFileUploadHandler = async (e) => {
    try {
      setDocFileUploading(true);
      const docFiles = e.target.files;
      let requestUrl =
        url.API_BASEURL + url.API_FILE_UPLOAD_MULTIPLE + `?token=${token}`;
      const response = await uploadMultipleFile(requestUrl, docFiles);
      if (response.status) {
        /*----- assign uploaded files with path for display start ------*/
        const uploadedFileValues = response.data.map(
          ({ name, path, _id, ...other }) => ({
            name,
            path: url.SERVER_URL + path,
            _id,
          })
        );
        //check old file exist or not if exist then new file will concat with old file
        if (uploadedDocFiles.length == 0) {
          setUploadedDocFiles(uploadedFileValues);
        } else {
          let fileArr = uploadedDocFiles.concat(uploadedFileValues);
          setUploadedDocFiles(fileArr);
        }
        /*----- assign uploaded files with path for display end ------*/
        setDocFileUploading(false);
        /*----- assign uploaded file ids start ------*/
        const fileIds = response.data.map(({ _id, ...other }) => ({ _id }));
        //check old file ids are exist or not.If exist then new id will concat with old id
        if (uploadedDocFileIds.length == 0) {
          setUploadedDocFileIds(fileIds);
        } else {
          let fileArr = uploadedDocFileIds.concat(fileIds);
          setUploadedDocFileIds(fileArr);
        }
        /*----- assign uploaded file ids end ------*/
      }
      resetDocInput();
    } catch (error) {
      console.log(error.message);
    }
  };
  //function for delete file from upload stack
  const closeDocFileHandler = (index) => {
    const closedFile = [...uploadedDocFiles];
    const removedFileId = [...uploadedDocFileIds];
    closedFile.splice(index, 1);
    removedFileId.splice(index, 1);
    setUploadedDocFiles(closedFile);
    setUploadedDocFileIds(removedFileId);
    resetDocInput();
  };
  //function for reset doc inputs
  const resetDocInput = () => {
    const file = document.getElementById("uploadedProjectDoc");
    if (file) {
      file.value = null;
    } else {
      return;
    }
  };
  //function for get project details
  const getProjectDetails = async () => {
    try {
      let requestUrl =
        url.API_BASEURL +
        url.API_GET_PROJECT_DETAILS +
        `/${editProjectId}?token=${token}`;
      const response = await getData(requestUrl);
      console.log(response);
      if (response.status && response.data) {
        const data = response.data;
        settitle(data.title);
        setsummary(data.summary);
        setdetails(data.description);
        setstartDate(data.startdate);
        setendDate(data.enddate);
        //assign uploaded files
        setUploadedDocFiles(
          response?.data?.uploads.map(({ name, path, _id, ...other }) => ({
            name,
            path: url.SERVER_URL + path,
            _id,
          }))
        );
        setUploadedDocFileIds(response?.data?.uploadedfileids);
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  /* function to add label */
  const saveProjectHandler = async (e) => {
    e.preventDefault();
    if (validate()) {
      setIsSending(true);
      try {
        let projectData = {
          title,
          summary,
          description: details,
          startdate: startDate,
          enddate: endDate,
          uploads: uploadedDocFileIds,
        };
        let requestUrl = url.API_BASEURL;
        let response = {};
        if (editProjectId) {
          requestUrl =
            requestUrl +
            url.API_UPDATE_PROJECT +
            `/${editProjectId}?token=${token}`;
          response = await putData(requestUrl, projectData);
        } else {
          requestUrl = requestUrl + url.API_ADD_NEW_PROJECT + `?token=${token}`;
          response = await postData(requestUrl, projectData);
        }
        setIsSending(false);
        if (response.status) {
          resetHandler();
          setreloadProjectList(true);
          setSuccess(true);
          setNotificationString(t("Project added successfully"));
          bootstrap.Offcanvas.getInstance(
            document.querySelector("#addUpdateProjectPopup")
          ).hide();
        }
      } catch (error) {
        console.error(error.message);
        setError(true);
        setNotificationString(
          t("Something went wrong, please try again later")
        );
      }
    }
  };
  /* reset */
  const resetHandler = () => {
    settitle("");
    setsummary("");
    setdetails("");
    setstartDate("");
    setendDate("");
    setUploadedDocFiles([]);
    setUploadedDocFileIds([]);
    seteditProjectId(null);
  };
  useEffect(() => {
    if (editProjectId) {
      getProjectDetails();
    }
  }, [editProjectId]);
  return (
    <div
      className="offcanvas offcanvas-end border-0"
      tabIndex="-1"
      id="addUpdateProjectPopup"
      aria-labelledby="addLabelPopupLabel"
    >
      <div className="offcanvas-header p-4">
        <h3 className="text-secondary mb-0" id="addLabelPopupLabel">
          {t("Save Project")}
        </h3>
        {/* ------- pop up close button start ------- */}
        <button
          type="button"
          id="closeCreateMail"
          className="btn-close p-2 bg-white shadow-none shadow-sm m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={resetHandler}
        ></button>
        {/* ------- pop up close button end ------- */}
      </div>
      <div className="offcanvas-body p-4 pt-0">
        {/* ------- label form section start ------ */}
        <form onSubmit={(e) => e.preventDefault()}>
          {/* ------- Project name section start ------- */}
          <div className="form-group mb-4">
            <label htmlFor="name">{t("Project Name")}</label>
            <input
              type="text"
              id="name"
              placeholder={t("Enter Here")}
              className="form-control"
              value={title}
              onChange={(e) => settitle(e.target.value)}
            />
            {/* title warning */}
            {validation.titleWarning && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>{t("Please enter title")}!</span>
                </p>
              </div>
            )}
          </div>
          {/* ------- Project name section end ------- */}
          {/* ------- Project summary section start ------- */}
          <div className="form-group mb-4">
            <label htmlFor="slug">{t("Project Summary")}</label>
            <textarea
              type="text"
              id="slug"
              cols="30"
              rows="3"
              placeholder={t("Enter Summary")}
              className="form-control"
              value={summary}
              onChange={(e) => setsummary(e.target.value)}
            ></textarea>
            {/* summary warning */}
            {validation.summaryWarning && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>{t("Please enter summary")}!</span>
                </p>
              </div>
            )}
          </div>
          {/* ------- Project summary section end ------- */}
          {/* project description start */}
          <div className="form-group mb-4">
            <label htmlFor="slug">{t("Description")}</label>
            <CKEditor
              className="form-control border-0 shadow-none"
              editor={ClassicEditor}
              config={editorConfig}
              data={details ? details : ""}
              onReady={(editor) => {
                editor.editing.view.change((writer) => {
                  writer.setStyle(
                    "height",
                    "150px",
                    editor.editing.view.document.getRoot()
                  );
                });
              }}
              onChange={(event, editor) => {
                let editorContent = editor.getData();
                setdetails(editorContent);
              }}
            />
            {/* slug warning */}
            {validation.detailsWarning && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>{t("Please enter Details")}!</span>
                </p>
              </div>
            )}
          </div>
          {/* project description end */}
          {/* dates start */}
          <div className="form-group mb-4">
            <div className="row">
              <div className="col-md-6 mb-4 mb-md-0">
                <label
                  htmlFor="startDate"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  {t("Start Date")}
                </label>
                <input
                  type="date"
                  id="startDate"
                  className="form-control fs-sm shadow-none"
                  value={startDate}
                  onChange={(e) => setstartDate(e.target.value)}
                />
              </div>
              <div className="col-md-6">
                <label
                  htmlFor="endDate"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  {t("End Date")}
                </label>
                <input
                  type="date"
                  id="endDate"
                  className="form-control fs-sm shadow-none"
                  value={endDate}
                  onChange={(e) => setendDate(e.target.value)}
                />
              </div>
            </div>
            {validation.dateWarning && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>{t("Please enter valid dates")}!</span>
                </p>
              </div>
            )}
          </div>
          {/* dates end */}
          {/* ========= user file upload start ========== */}
          <div className="form-group mb-4">
            {docFileUploading ? (
              <div className="d-flex align-items-center gap-1 mb-1">
                <p className="fw-bold">{t("Please wait while uploading")}</p>{" "}
                {[1, 2, 3, 4, 5, 6, 7, 8].map((count, index) => {
                  return (
                    <div
                      key={index}
                      className="spinner-grow spinner-grow-sm"
                      role="status"
                      style={{ height: "6px", width: "6px" }}
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  );
                })}
              </div>
            ) : (
              <label>{t("Upload Documents")}</label>
            )}
            <input
              type="file"
              className="form-control"
              id="uploadedProjectDoc"
              multiple
              onChange={docFileUploadHandler}
            />
            {uploadedDocFiles.length > 0 && (
              <ul className="uploaded-files d-flex flex-wrap gap-3 p-0 m-0 mt-3">
                {uploadedDocFiles.map((file, index) => {
                  return (
                    <li
                      className="file d-flex gap-3 align-items-center px-3 py-2 border border-gray rounded-3"
                      key={index}
                    >
                      <Link to="#" onClick={() => downloadFileHandler(file)}>
                        <span className="d-block material-symbols-outlined">
                          download
                        </span>
                      </Link>
                      <p className="fw-medium">{file.name}</p>
                      <Link to="#" onClick={() => closeDocFileHandler(index)}>
                        <span className="d-block material-symbols-outlined">
                          close
                        </span>
                      </Link>
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
          {/* ========= user file upload end ===========*/}
          {/* ------- label submit button start ------- */}
          <div className="action">
            <button
              onClick={saveProjectHandler}
              type="button"
              className="btn btn-primary mw-unset"
              disabled={isSending ? true : false}
              style={{ cursor: isSending ? "not-allowed" : "pointer" }}
            >
              {t("Save")}
              {isSending && (
                <div
                  className="mx-2 spinner-border spinner-border-sm"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              )}
            </button>
          </div>
          {/* ------- label submit button end ------- */}
        </form>
        {/* ------- label form section end ------ */}
      </div>
    </div>
  );
};
export default SaveProjectPopup;
