import React from 'react';
import { Link } from 'react-router-dom';
import { assetImages } from '../../../constants';

//import translation function for language translation
import { useTranslation } from 'react-i18next';

const EmailVerificationLeftPanel = () => {
  const { t } = useTranslation(); //for translation
  return (
    <div
      className="login_leftpnl"
      style={{ backgroundImage: `url(${assetImages.authenticationBG})` }}
    >
      <div className="authentication_text">
        <h1 className="text-white text-shadow-sm">
          Coneix les activitats per a centres educatius
        </h1>
        <Link to="#" className="btn btn-white">
          {t('Learn More')}
        </Link>
      </div>
    </div>
  );
};

export default EmailVerificationLeftPanel;
