import SurveyFeedbackBreadCrumb from 'components/SurveyFeedbackComponents/BreadCrumb/SurveyFeedbackBreadCrumb';
import SurveyFeedbackForm from 'components/SurveyFeedbackComponents/SurveyFeedbackForm';
import LeftMenu from 'components/common/LeftMenu';
import UserMenu from 'components/common/UserMenu';

const SurveyFeedback = () => {
  return (
    <>
      <UserMenu />
      <LeftMenu />
      <section className="mail-wrapper py-4">
        <div className="container-fluid px-4">
          <SurveyFeedbackBreadCrumb />
          <SurveyFeedbackForm />
        </div>
      </section>
    </>
  );
};

export default SurveyFeedback;
