/* eslint-disable */
import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import UserMenu from 'components/common/UserMenu';
import LeftMenu from 'components/common/LeftMenu';
import SurveyQuestionHeader from 'components/common/SurveyQuestionHeader/SurveyQuestionHeader';
import FeedbackResponseBreadCrumb from 'components/FeedbackResponseComponents/BreadCrumb/FeedbackResponseBreadCrumb';
import FeedbackresponseHeader from 'components/FeedbackResponseComponents/Header/FeedbackresponseHeader';
import FeedbackResponseList from 'components/FeedbackResponseComponents/List/FeedbackResponseList';
import ViewSurveyFeedbackPopup from 'components/FeedbackResponseComponents/Popup/ViewSurveyFeedbackPopup';
import SurveyFeedbackThanksModal from 'components/FeedbackResponseComponents/Modal/SurveyFeedbackThanksModal';
import CustomNotification from 'components/common/CustomNotification';

const FeedbackResponses = () => {
  /* useState hook to download excel file */
  const [downloadExcel, setDownloadExcel] = useState(false);
  /* hook to search */
  const [search, setSearch] = useState('');

  /* hook to get number of records */
  const [records, setRecords] = useState(0);

  const blockRefSelected = useRef(null); // this will be used to acccess dom element directly

  /* function for checking record count */
  const checkedRecordCount = selectCount => {
    console.log('selectCount', selectCount);
    const blockElementSelect = ReactDOM.findDOMNode(blockRefSelected.current);
    if (blockElementSelect) {
      blockElementSelect.innerHTML = selectCount;
    }
  };

  useEffect(() => {
    document.title = 'Feedbackresponses'; // Update the title when Register component mounts
  }, []);

  return (
    <>
      <UserMenu />
      <LeftMenu />
      <section className="survey-question-wrapper">
        <SurveyQuestionHeader page="survey" />
        <div className="container-fluid px-4">
          <FeedbackResponseBreadCrumb />
          <FeedbackresponseHeader
            setDownloadExcel={setDownloadExcel}
            setSearch={setSearch}
            records={records}
            blockRefSelected={blockRefSelected}
          />
          <FeedbackResponseList
            downloadExcel={downloadExcel}
            search={search}
            setRecords={setRecords}
            checkedRecordCount={checkedRecordCount}
          />
        </div>
      </section>
      <ViewSurveyFeedbackPopup />
      <SurveyFeedbackThanksModal />
      {/*----------- custom notification component start -----------*/}
      <CustomNotification />
      {/*----------- custom notification component end -----------*/}
    </>
  );
};

export default FeedbackResponses;
