/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";

/* Importing react select */
import Select from "react-select";

import { Link } from "react-router-dom";

/*import url and gateway methods */
import {
  getData,
  postData,
  uploadFile,
  putData,
  uploadMultipleFile,
} from "utils/Gateway";
import * as url from "urlhelpers/UrlHelper";

//import context
import { GlobalProvider } from "context/GlobalContext";

//import options form json file
import languageOptions from "data/Prod/Language.json";
import statusOptions from "data/Prod/UserStatus.json";

//import images
// import { assetImages } from 'constants';

//import translation function for language translation
import { useTranslation } from "react-i18next";
import CustomNotification from "components/common/CustomNotification";

//import file download function
import downloadFileHandler from "helpers/DownloadFile";

import {
  getUserBrowser,
  getPublicIP,
  getTimeStamp,
} from "helpers/AuthHelper/AuthHelper.js";

const UserFormPopup = () => {
  const { t } = useTranslation(); //for translation
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const {
    setShowUserList,
    editUserData,
    copyUserData,
    setEditUserData,
    setCopyUserData,
    setSuccess,
    setError,
    setNotificationString,
    setUserFormReset,
    userFormReset,
  } = useContext(GlobalProvider);

  //for tag/labels use state
  const [tagList, setTagList] = useState([]);

  //for company list
  const [companyList, setCompanyList] = useState([]);

  //service list use state
  const [serviceList, setServiceList] = useState([]);

  //use state for manager list
  const [managerList, setManagerList] = useState([]);

  const [isCompany, setIsCompany] = useState(false);
  const [companyValue, setCompanyValue] = useState(null);
  const [companyId, setCompanyId] = useState(null);
  const [cif, setCif] = useState(""); //usestate for company
  const [companyName, setCompanyName] = useState(""); //usestate for company
  const [manager, setManager] = useState(null); //usestate for company
  const [userName, setUserName] = useState(""); //usestate for name
  const [surname, setSurname] = useState(""); //usestate for surname
  const [email, setEmail] = useState(""); //usestate for email
  const [position, setPosition] = useState(""); //usestate for position
  const [selectedLanguage, setSelectedLanguage] = useState(null); //usestate for language
  const [phone, setPhone] = useState(""); //usestate for phone
  const [contactEmails, setContactEmails] = useState(""); //usestate for contact email
  const [location, setLocation] = useState(""); //usestate for location
  const [country, setCountry] = useState(""); // useState for country
  const [state, setState] = useState(""); // useState for state
  const [city, setCity] = useState(""); // useState for city
  const [zipcode, setZipcode] = useState(""); //usestate for zipcode
  const [costPerHour, setCostPerHour] = useState(1); //use state for cose per hour
  const [mapCoordinate, setMapCoordinate] = useState(""); //usestate for co ordinate
  const [latitude, setLatitude] = useState(""); //usestate for co lattitutde
  const [longitude, setLongitude] = useState(""); //usestate for logitude
  const [selectedStatus, setSelectedStatus] = useState(null); //usestate for status
  const [website, setWebsite] = useState(""); //usestate for webiste
  const [services, setServices] = useState([]); //use state for selected services
  const [rank, setRank] = useState(0); //use state for rank
  const [uploadedPreviewImage, setUploadedPreviewImage] = useState("");
  const [uploadedImageId, setUploadedImageId] = useState(null); // use state for uploaded image id
  const [isUploading, setIsUploading] = useState(false);
  const [uploadedUserFiles, setUploadedUserFiles] = useState([]); //uploaded user files
  const [isUserFilesUploading, setIsUserFilesUploading] = useState(false); //
  const [uploadedUserFilesIds, setUploadedUserFilesIds] = useState([]); //uploaded file ids

  /* useState hook to change select data */
  const [languageSelectedValue, setLanguageSelectedValue] = useState(null);
  const [managerValue, setManagerValue] = useState(null);
  const [statusSelectedValue, setStatusSelectedValue] = useState(null);
  const [serviceValue, setServiceValue] = useState(null); //use state for service/category value

  const [isRequiredFieldFillPending, setIsRequiredFieldFillPending] =
    useState(false);
  const [isSaving, setIsSaving] = useState(false);

  /* hook for validation */
  const [validation, setValidation] = useState({
    cifWarning: false,
    cifExistWarning: false,
    nameWarning: false,
    emailWarning: false,
    validemailWarning: false,
    emailExistWarning: false,
    statuswarning: false,
    zipWarning: false,
    locationWarning: false,
  });

  // custom style for category
  const customSelectStyle = {
    // options style
    option: (provided, state) => ({
      ...provided,
      borderBottom: "1px dotted pink",
      color: "#666666",
      fontSize: "14px",
      fontFamily: "sans-serif",
    }),

    // Value style
    control: (styles) => ({
      ...styles,
      fontSize: "14px",
      padding: "5px",
      fontFamily: "sans-serif",
      border: "1px solid #D6D6D6",
      backgroundColor: "#f4f4f4",
      height: 50,
      boxShadow: "none",
    }),

    // placeholder style
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: "#8E8E8E",
        padding: "5px",
        fontSize: "14px",
        marginVertical: "4px",
        fontFamily: "sans-serif",
      };
    },

    // Indicator style
    dropdownIndicator: (base, state) => {
      let changes = { color: "#505050" };
      return Object.assign(base, changes);
    },
  };

  //function for get all companies
  const getCompanyList = async () => {
    try {
      let requestUrl =
        url.API_BASEURL +
        url.API_GET_COMPANY_LIST +
        `?token=${token}&isselect=${true}`;

      const response = await getData(requestUrl);

      if (response.status) {
        setCompanyList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get user details
  const getUserDetails = async () => {
    try {
      let requestUrl = url.API_BASEURL;

      if (editUserData) {
        requestUrl =
          requestUrl +
          url.API_GET_USER_DETAILS +
          `/${editUserData}?token=${token}`;
      }

      if (copyUserData) {
        requestUrl =
          requestUrl +
          url.API_GET_USER_DETAILS +
          `/${copyUserData}?token=${token}`;
      }

      const response = await getData(requestUrl);

      console.log("Response in view profile ------>", response);

      if (response.status) {
        setIsCompany(response.data?.iscompany);

        if (response.data?.usercompany) {
          companySelectionHandler(response.data?.usercompany);
        }

        setCif(response.data?.cif ?? "");

        setCompanyName(response.data?.companyid?.name ?? "");

        /*---- asign manager start ----*/
        if (response.data?.managerdata) {
          managerSelectionHandler(response.data?.managerdata);
        }

        /*---- asign manager end ----*/
        const photoimage = response.data?.photoimage;

        if (editUserData && photoimage) {
          setUploadedImageId(photoimage._id);
          setUploadedPreviewImage(url.SERVER_URL + photoimage.path);
        } else {
          setUploadedPreviewImage("");
        }

        /*------ image assign end ------*/

        setUserName(response.data?.name ?? ""); //assign name
        setSurname(response.data?.surname ?? ""); //assign surname

        if (editUserData) {
          setEmail(response.data?.email ?? ""); //assign email
        }

        setPosition(response.data?.position ?? ""); //assign position
        setPhone(response.data?.phone ?? ""); //assign phone
        setLocation(response.data?.address ?? ""); //assign address/location
        setZipcode(response.data?.zipcode ?? ""); //assign zipcode
        setMapCoordinate(response.data?.mapcoordinate ?? ""); //assing mapcoordinate
        setCountry(response.data?.country ?? ""); // assign country
        setState(response.data?.state ?? ""); // assign state
        setCity(response.data?.city ?? ""); // assign city
        setCostPerHour(response.data?.costperhour ?? 1); //assign cost per hour
        setLatitude(response.data?.latitude ?? ""); //assign latitude
        setLongitude(response.data?.longitude ?? ""); //assign longitude
        setWebsite(response.data?.website ?? ""); //assign website

        /*----- assign service start ------*/
        if (response.data?.services) {
          serviceSelectionHandler(response.data?.services);
        }
        /*----- assign service end ------*/

        /*------ assign language start ------*/
        for (let language of languageOptions) {
          if (language.value == response.data?.language) {
            languageSelectionHandler(language); //set language value
          }
        }
        /*------ assign language end ------*/

        /*------ assign status start ------*/
        for (let status of statusOptions) {
          if (status.value == response?.data?.status) {
            statusSelectionHandler(status);
          }
        }
        /*------ assign status end ------*/

        /* =============== assign user uploads starts ================= */
        if (response.data?.uploads) {
          if (editUserData) {
            setUploadedUserFiles(
              response.data?.uploads.map(({ name, path, _id, ...other }) => ({
                name,
                path: url.SERVER_URL + path,
                _id,
              }))
            );
            const editedFilesIds = response.data?.uploads.map(
              ({ _id, ...other }) => ({ _id })
            );
            setUploadedUserFilesIds(editedFilesIds);
          }
        }
        /* =============== assign user ends ================= */
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  //function for get all user list
  const getALlManagerList = async () => {
    try {
      let requestUrl =
        url.API_BASEURL +
        url.API_GET_ALL_USER +
        `?token=${token}&isselect=${true}&slug=manager`;

      const response = await getData(requestUrl);

      if (response.status) {
        setManagerList(response.data);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  //function for get list of all tags/labels
  const getAllTags = async () => {
    try {
      let requestUrl =
        url.API_BASEURL + url.API_ALL_TAGS + `?token=${token}&isselect=${true}`;

      const response = await getData(requestUrl);

      if (response.status) {
        setTagList(response.data);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  //function for get service/category list
  const getServiceList = async () => {
    try {
      let requestUrl =
        url.API_BASEURL +
        url.API_GET_CATEGORIES +
        `?token=${token}&parent=services`;

      const response = await getData(requestUrl);

      if (response.status) {
        setServiceList(response.data);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  //function for select company
  const companySelectionHandler = (val) => {
    // console.log(val);
    setCompanyValue(val);
    if (val) {
      setCompanyId(val.value);
    }
  };

  //function for image upload
  const imageUploadHandler = async (e) => {
    const file = e.target.files[0];
    // console.log(file);
    setIsUploading(true);
    try {
      const requestUrl =
        url.API_BASEURL + url.API_FILE_UPLOAD + `?token=${token}`;

      const response = await uploadFile(requestUrl, file);

      console.log(response);

      if (response.status) {
        setIsUploading(false);
        setUploadedPreviewImage(url.SERVER_URL + response.data.path);
        setUploadedImageId(response.data._id);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  //function for user files upload
  const filesUploadHandler = async (e) => {
    try {
      setIsUserFilesUploading(true);
      let requestUrl =
        url.API_BASEURL + url.API_FILE_UPLOAD_MULTIPLE + `?token=${token}`;

      const response = await uploadMultipleFile(requestUrl, e.target.files);

      if (response.status) {
        console.log(`response`, response.data);

        /*----- assign uploaded files with path for display start ------*/
        const uploadedFileValues = response.data.map(
          ({ name, path, _id, ...other }) => ({
            name,
            path: url.SERVER_URL + path,
            _id,
          })
        );

        //check old file exist or not if exist then new file will concat with old file
        if (uploadedUserFiles.length == 0) {
          setUploadedUserFiles(uploadedFileValues);
        } else {
          let fileArr = uploadedUserFiles.concat(uploadedFileValues);
          setUploadedUserFiles(fileArr);
        }
        /*----- assign uploaded files with path for display end ------*/

        setIsUserFilesUploading(false);

        /*----- assign uploaded file ids start ------*/
        const fileIds = response.data.map(({ _id, ...other }) => ({ _id }));

        //check old file ids are exist or not.If exist then new id will concat with old id
        if (uploadedUserFilesIds.length == 0) {
          setUploadedUserFilesIds(fileIds);
        } else {
          let fileArr = uploadedUserFilesIds.concat(fileIds);
          setUploadedUserFilesIds(fileArr);
        }
        /*----- assign uploaded file ids end ------*/
      }
      resetUserFiles();
    } catch (error) {
      console.error(error.message);
    }
  };

  //function for clear educator file value
  const resetUserFiles = () => {
    const userFile = document.getElementById("uploadedUserFiles");
    if (userFile) {
      userFile.value = null;
    } else {
      return;
    }
  };

  //function for delete file from upload stack
  const closeUserFileHandler = (index) => {
    const closedFile = [...uploadedUserFiles];
    const removedFileId = [...uploadedUserFilesIds];

    closedFile.splice(index, 1);
    removedFileId.splice(index, 1);

    setUploadedUserFiles(closedFile);
    setUploadedUserFilesIds(removedFileId);

    resetUserFiles();
  };

  /* function for validation */
  const validateForm = () => {
    let isValid = true;

    if (isCompany && cif == "") {
      setValidation((prevState) => ({ ...prevState, cifWarning: true }));
      isValid = false;
    }

    if (userName === "") {
      setValidation((prevState) => ({ ...prevState, nameWarning: true }));
      isValid = false;
    }

    if (email === "") {
      setValidation((prevState) => ({ ...prevState, emailWarning: true }));
      isValid = false;
    }

    if (email !== "") {
      const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (!email.match(mailformat)) {
        setValidation((prevState) => ({
          ...prevState,
          validemailWarning: true,
        }));
        isValid = false;
      }
    }

    if (selectedStatus === null) {
      setValidation((prevState) => ({ ...prevState, statuswarning: true }));
      isValid = false;
    }

    if (zipcode === "") {
      setValidation((prevState) => ({ ...prevState, zipWarning: true }));
      isValid = false;
    }

    if (isCompany && cif == "") {
    }

    // if (location === "") {
    //   setValidation((prevState) => ({ ...prevState, locationWarning: true }));
    //   isValid = false;
    // }

    return isValid;
  };

  //function for manager selection
  const managerSelectionHandler = (val) => {
    setManagerValue(val);
    val && setManager(val.value);
  };

  //function for language selection
  const languageSelectionHandler = (val) => {
    setLanguageSelectedValue(val);
    setSelectedLanguage(val.value);
  };

  //function for status selection
  const statusSelectionHandler = (val) => {
    setStatusSelectedValue(val);
    setSelectedStatus(val.value);
    setValidation((prevState) => ({
      ...prevState,
      statuswarning: false,
    }));
  };

  //function for select service/category
  const serviceSelectionHandler = (val) => {
    setServiceValue(val);
    const serviceArr = val.map((label) => {
      return label.value;
    });
    setServices(serviceArr);
  };

  /* function to fetch location from zip code */
  const getLocationFromZip = async (e) => {
    e.preventDefault();
    const url = `https://maps.googleapis.com/maps/api/geocode/json?components=postal_code:${zipcode}&address=ES&region=es&key=AIzaSyDt4dbtdHCHLj8grTzTVxpztvqUGDkvkaI`;

    if (zipcode !== "") {
      try {
        const response = await fetch(url);
        const data = await response.json();
        if (data.status === "OK") {
          const results = data.results;
          if (results.length > 0) {
            results[0]?.address_components.map((item) => {
              // console.log(`item`, JSON.stringify(item));

              if (item.types[0] === "country") {
                setCountry(item?.long_name);
              }
              if (item.types[0] === "locality") {
                setCity(item?.long_name);
              }
              if (item.types[0] === "administrative_area_level_1") {
                setState(item?.long_name);
              }
            });

            setLatitude(results[0]?.geometry?.location?.lat);
            setLongitude(results[0]?.geometry?.location?.lng);

            setMapCoordinate(
              `https://maps.google.com/maps?q=${results[0]?.geometry?.location?.lat},${results[0]?.geometry?.location?.lng}`
            );
          } else {
            console.log("No results found.");
          }
        } else {
          console.log("Request failed with status:", data.status);
        }
      } catch (error) {
        console.error("Error:", error.message);
      }
    } else {
      setValidation((prevState) => ({ ...prevState, zipWarning: true }));
    }
  };

  //function for save user
  const saveUpdateUserHandler = async () => {
    const detectedBrowser = getUserBrowser();
    const userPublicIp = await getPublicIP();

    const { loginDate, loginTime, loginTimeStamp } = getTimeStamp();

    const userData = {
      iscompany: isCompany ? isCompany.toString() : "",
      cif: cif,
      companyname: companyName,
      companyid: companyId ? companyId : null,
      manager: manager || userInfo._id,
      photoimage: uploadedImageId,
      name: userName,
      position,
      surname,
      email,
      language: selectedLanguage,
      phone,
      address: location,
      city: city,
      state: state,
      zipcode,
      country: country,
      status: selectedStatus,
      mapcoordinate: mapCoordinate,
      services,
      latitude,
      longitude,
      costperhour: costPerHour,
      uploads: uploadedUserFilesIds,
      website,
      browser: detectedBrowser,
      userip: userPublicIp,
    };

    if (!editUserData) {
      userData.logindate = loginDate;
      userData.logintime = loginTime;
      userData.logintimestamp = loginTimeStamp;
    }

    if (validateForm()) {
      setIsRequiredFieldFillPending(false);
      setIsSaving(true);
      try {
        let requestUrl = url.API_BASEURL;

        let response = {};

        if (editUserData) {
          requestUrl =
            requestUrl +
            url.API_UPDATE_USER +
            `/${editUserData}` +
            `?token=${token}`;

          console.log("edit user url----->", requestUrl);

          response = await putData(requestUrl, userData);
        } else {
          requestUrl = requestUrl + url.API_ADD_NEW_USER + `?token=${token}`;

          console.log("save user url----->", requestUrl);

          response = await postData(requestUrl, userData);
        }

        console.log("response in user form ---->", response);
        setIsSaving(false);

        if (response.status) {
          resetUserData();
          optionHandler();
          setNotificationString(
            editUserData
              ? t("User updated successfully")
              : t("User added successfully")
          );

          setSuccess(true);
          setShowUserList(true);
          let offCanvasPopup = document.querySelector("#saveUserPopup");
          let offCanvas = bootstrap.Offcanvas.getInstance(offCanvasPopup);
          offCanvas.hide();
        } else {
          if (response.emailwarning) {
            setValidation((prevState) => ({
              ...prevState,
              emailExistWarning: true,
            }));
          }

          if (response.cifwarning) {
            setValidation((prevState) => ({
              ...prevState,
              cifExistWarning: true,
            }));
          }
        }
      } catch (error) {
        console.error(error.message);
        setError(true);
        setNotificationString(
          t("Something went wrong, please try again later")
        );
      }
    } else {
      setIsRequiredFieldFillPending(true);
    }
  };

  //function for reset user data
  const resetUserData = () => {
    setEditUserData(null);
    setCopyUserData(null);
    resetFile();
    resetUserFiles();
    setUploadedUserFiles([]);
    setUploadedUserFilesIds([]);
    setUploadedImageId(null);
    setIsCompany(false);
    setCompanyValue(null);
    setCompanyId(null);
    setCompanyName("");
    setCif("");
    setUserName("");
    setSurname("");
    setEmail("");
    setSelectedLanguage(null);
    setPhone("");
    setContactEmails("");
    setLocation("");
    setManagerValue(null);
    setManager(null);
    setZipcode("");
    setMapCoordinate("");
    setLatitude("");
    setLongitude("");
    setLanguageSelectedValue(null);
    setCostPerHour(1);
    setCountry("");
    setState(""); // useState for state
    setCity(""); // useState for city
    setPosition("");
    setWebsite("");
    setLatitude("");
    setLongitude("");
    setServiceValue(null);
    setServices([]);

    /*------ reset status start ------*/
    for (let status of statusOptions) {
      if (status.value == "1") {
        statusSelectionHandler(status);
      }
    }
    /*------ reset status end ------*/

    setUploadedPreviewImage("");
    setValidation({
      cifWarning: false,
      cifExistWarning: false,
      nameWarning: false,
      emailWarning: false,
      emailExistWarning: false,
      statuswarning: false,
      zipWarning: false,
      locationWarning: false,
    });
  };

  // function for clear file value
  const resetFile = () => {
    const file = document.getElementById("uploadedUserImageFile");
    if (file) {
      file.value = null;
    } else {
      return;
    }
  };

  //function for get all options
  const optionHandler = () => {
    getCompanyList();
    getALlManagerList();
    getAllTags();
    getServiceList();
  };

  //useeffect for edit data
  useEffect(() => {
    if (editUserData) {
      getUserDetails();
      console.log("edit user data------>", editUserData);
    }
  }, [editUserData]);

  /* ------ useEffect for copy data ------ */
  useEffect(() => {
    if (copyUserData) {
      console.log(`copyUserData`, copyUserData);
      getUserDetails();
    }
  }, [copyUserData]);

  //use effect for reset
  useEffect(() => {
    if (userFormReset) {
      resetUserData();
      optionHandler();
      setUserFormReset(false);
    }
  }, [userFormReset]);

  useEffect(() => {
    optionHandler();
  }, []);

  return (
    <div className="offcanvas offcanvas-end border-0" id="saveUserPopup">
      <div className="offcanvas-header position-relative align-items-start p-4">
        <h3 className="text-secondary mb-0" id="saveReservationPopupLabel">
          <span className="d-block">{t("User Form")}</span>
        </h3>

        {/* ------ pop close button start ------ */}
        <button
          type="button"
          className="btn-close p-2 bg-white shadow-none shadow-sm m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={resetUserData}
        ></button>
        {/* ------ pop close button end ------ */}
      </div>
      <div className="offcanvas-body position-relative p-4 pt-0">
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="user_formOuter">
            <div className="row">
              <div className="col-lg-6">
                {/* ----- is company section start ----- */}
                <div className="form-group mb-4 form-check">
                  <label htmlFor="iscompany" className="mt-2">
                    {t("Is Company")}
                  </label>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="iscompany"
                    checked={isCompany}
                    onChange={(e) => {
                      setIsCompany(e.target.checked);
                    }}
                  />
                </div>
                {/* ----- is company section start ----- */}
              </div>
              <div className="col-lg-6"></div>
            </div>

            {/* Company details if is company */}
            {isCompany ? (
              <div className="row">
                <div className="col-lg-6">
                  {/* ----- is company section start ----- */}
                  <div className="form-group mb-4">
                    <label>{t("Company Name")}</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder={t("Enter company name")}
                      value={companyName}
                      onChange={(e) => {
                        setCompanyName(e.target.value);
                        setValidation((prevState) => ({
                          ...prevState,
                          cifWarning: false,
                        }));
                        setIsRequiredFieldFillPending(false);
                      }}
                    />
                  </div>
                  {/* ----- is company section start ----- */}
                </div>

                <div className="col-lg-6">
                  <div className="form-group mb-4">
                    <label>{t("CIF")}</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder={t("Enter cif number")}
                      value={cif}
                      onChange={(e) => {
                        setCif(e.target.value);
                        setValidation((prevState) => ({
                          ...prevState,
                          cifWarning: false,
                        }));
                        setIsRequiredFieldFillPending(false);
                      }}
                    />
                    {/* cif warning */}
                    {validation.cifWarning && (
                      <div className="error-message mt-2">
                        <p className="d-flex align-items-center gap-1 text-danger">
                          <span className="material-symbols-outlined">
                            warning
                          </span>
                          <span>{t("Please enter cif")}!</span>
                        </p>
                      </div>
                    )}

                    {/* cif exist */}
                    {validation.cifExistWarning && (
                      <div className="error-message mt-2">
                        <p className="d-flex align-items-center gap-1 text-danger">
                          <span className="material-symbols-outlined">
                            warning
                          </span>
                          <span>{t("CIF already exist")}!</span>
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}

            {/*-------- company and manager section start --------*/}
            <div className="row">
              {/*-------- company section start --------*/}
              <div className="col-lg-6">
                <div className="form-group mb-4">
                  <label>{t("Select Company")}</label>
                  <Select
                    placeholder={t("Select Company")}
                    isClearable={true}
                    styles={customSelectStyle}
                    options={companyList}
                    value={companyValue}
                    onChange={(val) => {
                      companySelectionHandler(val);
                    }}
                  />
                </div>
              </div>
              {/*-------- company section end --------*/}
              {/*-------- manager section start --------*/}
              <div className="col-lg-6">
                {userInfo.role.name == "admin" ? (
                  <div className="form-group mb-4">
                    <label>{t("Manager")}</label>
                    <Select
                      isClearable={true}
                      styles={customSelectStyle}
                      options={managerList}
                      value={managerValue}
                      onChange={(val) => {
                        managerSelectionHandler(val);
                      }}
                    />
                  </div>
                ) : null}
              </div>
              {/* ------ manager section end ------ */}
            </div>
            {/*-------- company and manager section end --------*/}

            {/*-------- Name and Surname section start --------*/}
            <div className="row">
              {/*-------- Name section start --------*/}
              <div className="col-lg-6">
                <div className="form-group mb-4">
                  <label>{t("Name")}</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={t("Enter name")}
                    value={userName}
                    onChange={(e) => {
                      setUserName(e.target.value);
                      setValidation((prevState) => ({
                        ...prevState,
                        nameWarning: false,
                      }));
                      setIsRequiredFieldFillPending(false);
                    }}
                  />
                  {/* email warning */}
                  {validation.nameWarning && (
                    <div className="error-message mt-2">
                      <p className="d-flex align-items-center gap-1 text-danger">
                        <span className="material-symbols-outlined">
                          warning
                        </span>
                        <span>{t("Please enter your name")}!</span>
                      </p>
                    </div>
                  )}
                </div>
              </div>
              {/*-------- Name section end --------*/}

              {/*-------- Surname section start --------*/}
              <div className="col-lg-6">
                <div className="form-group mb-4">
                  <label>{t("Surname")}</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={t("Enter surname")}
                    value={surname}
                    onChange={(e) => setSurname(e.target.value)}
                  />
                </div>
              </div>
              {/*-------- Surname section end --------*/}
            </div>
            {/*-------- Name and Surname section end --------*/}

            {/*-------- Email and Position section start --------*/}
            <div className="row">
              {/*-------- Email section start -------*/}
              <div className="col-lg-6">
                <div className="form-group mb-4">
                  <label>{t("Email")}</label>
                  <input
                    type="email"
                    className="form-control"
                    placeholder={t("Enter email")}
                    value={email}
                    // readOnly={editUserData ? true : false}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      setValidation((prevState) => ({
                        ...prevState,
                        emailWarning: false,
                        validemailWarning: false,
                        emailExistWarning: false,
                      }));
                      setIsRequiredFieldFillPending(false);
                    }}
                  />
                  {/* email warning */}
                  {validation.emailWarning && (
                    <div className="error-message mt-2">
                      <p className="d-flex align-items-center gap-1 text-danger">
                        <span className="material-symbols-outlined">
                          warning
                        </span>
                        <span>{t("Please enter email")}!</span>
                      </p>
                    </div>
                  )}
                  {validation.validemailWarning && (
                    <div className="error-message mt-2">
                      <p className="d-flex align-items-center gap-1 text-danger">
                        <span className="material-symbols-outlined">
                          warning
                        </span>
                        <span>{t("Please enter a valid email")}!</span>
                      </p>
                    </div>
                  )}
                  {/* email exist warning */}
                  {validation.emailExistWarning && (
                    <div className="error-message mt-2">
                      <p className="d-flex align-items-center gap-1 text-danger">
                        <span className="material-symbols-outlined">
                          warning
                        </span>
                        <span>{t("Email already exists")}!</span>
                      </p>
                    </div>
                  )}
                </div>
              </div>
              {/*-------- Email section end --------*/}

              {/*-------- Position section start --------*/}
              <div className="col-lg-6">
                <div className="form-group mb-4">
                  <label>{t("Position")}</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={t("Enter position")}
                    value={position}
                    onChange={(e) => setPosition(e.target.value)}
                  />
                </div>
              </div>
              {/*-------- Position section end --------*/}
            </div>
            {/*-------- Email and Position section end --------*/}

            {/* ------ location and zipcode section start ------ */}
            <div className="row">
              {/* ------ location section start ------ */}
              <div className="col-lg-6">
                <div className="form-group mb-4">
                  <label htmlFor="location">{t("Location")}</label>
                  <div className="field-container position-relative">
                    <input
                      type="text"
                      id="location"
                      className="form-control"
                      placeholder={t("Enter Address")}
                      value={location}
                      onChange={(e) => {
                        setLocation(e.target.value);
                        // setValidation((prevState) => ({
                        //   ...prevState,
                        //   zipWarning: false,
                        // }));
                        // setIsRequiredFieldFillPending(false);
                      }}
                    />
                    <span className="d-block material-symbols-outlined icon-md text-gray position-absolute top-50 end-0 translate-middle-y me-3">
                      my_location
                    </span>
                  </div>
                  {/* {validation.locationWarning && (
                    <div className="error-message mt-2">
                      <p className="d-flex align-items-center gap-1 text-danger">
                        <span className="material-symbols-outlined">
                          warning
                        </span>
                        <span>{t("Please enter location")} !</span>
                      </p>
                    </div>
                  )} */}
                </div>
              </div>
              {/* ------ location section end ------ */}

              {/* -------- zip code section start ------- */}
              <div className="col-lg-6">
                <div className="form-group mb-4">
                  <label>{t("Zip Code")}</label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder={t("Enter zip code")}
                    value={zipcode}
                    onChange={(e) => {
                      setZipcode(e.target.value);
                      setValidation((prevState) => ({
                        ...prevState,
                        zipWarning: false,
                      }));
                      setIsRequiredFieldFillPending(false);
                    }}
                  />
                  <button
                    className="btn btn-sm btn-primary my-1"
                    onClick={getLocationFromZip}
                    type="button"
                  >
                    {t("Fetch location by zipcode")}
                  </button>
                  {/* zipcode warning */}
                  {validation.zipWarning && (
                    <div className="error-message mt-2">
                      <p className="d-flex align-items-center gap-1 text-danger">
                        <span className="material-symbols-outlined">
                          warning
                        </span>
                        <span>{t("Please enter a valid zip code")}!</span>
                      </p>
                    </div>
                  )}
                </div>
              </div>
              {/* -------- zip code section end ------- */}
            </div>
            {/* ------ location and zipcode section end ------ */}

            {/* ----- country,city,state section start ------ */}
            <div className="row">
              {/* ========= select country starts======== */}
              <div className="col-lg-4">
                <div className="form-group mb-4">
                  <label>{t("Country")}</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={t("Enter Country")}
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                  />
                </div>
              </div>
              {/* ========= select country ends======== */}

              {/* ========= select state starts======== */}
              <div className="col-lg-4">
                <div className="form-group mb-4">
                  <label>{t("State")}</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={t("Enter State")}
                    value={state}
                    onChange={(e) => setState(e.target.value)}
                  />
                </div>
              </div>

              {/* ========= select state ends======== */}

              {/* ========= select city starts======== */}
              <div className="col-lg-4">
                <div className="form-group mb-4">
                  <label>{t("City")}</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={t("Enter City")}
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                  />
                </div>
              </div>
              {/* ========= select city ends======== */}
            </div>
            {/* ----- country,city,state section end ------ */}

            {/* ----- phone and website section start ------ */}
            <div className="row">
              {/* ------ phone section start ------ */}
              <div className="col-lg-6">
                <div className="form-group mb-4">
                  <label>{t("Phone")}</label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder={t("Enter phone number")}
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </div>
              </div>
              {/* ------ phone section end ------ */}

              {/* ------ website section start ------ */}
              <div className="col-lg-6">
                <div className="form-group mb-4">
                  <label>{t("Website")}</label>
                  <input
                    type="text"
                    value={website}
                    onChange={(e) => setWebsite(e.target.value)}
                    className="form-control"
                    placeholder={t("Enter URL")}
                  />
                </div>
              </div>
              {/* ------ website section end ------ */}
            </div>
            {/* ----- phone and website section end ------ */}

            {/* ----- select service section start ------ */}
            <div className="row">
              <div className="col-lg-12">
                <div className="form-group mb-4">
                  <label>{t("Services")}</label>
                  <Select
                    isMulti
                    className="nosearch_select"
                    options={serviceList}
                    value={serviceValue}
                    onChange={(val) => {
                      serviceSelectionHandler(val);
                    }}
                    styles={customSelectStyle}
                  />
                </div>
              </div>
            </div>
            {/* ----- select service section end ------ */}

            {/* ----- language,cost perhour,status section start ------ */}
            <div className="row">
              {/* ========= select language starts======== */}
              <div className="col-lg-3">
                <div className="form-group mb-4">
                  <label>{t("Language")}</label>
                  <Select
                    className="nosearch_select"
                    options={languageOptions}
                    value={languageSelectedValue}
                    onChange={(val) => {
                      languageSelectionHandler(val);
                    }}
                    styles={customSelectStyle}
                  />
                </div>
              </div>
              {/* ========= select language ends======== */}

              {/* -------- cost per hour section start ------- */}
              <div className="col-lg-5">
                <div className="form-group mb-4">
                  <label>{t("Cost per hour")}</label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder={t("Cost per hour")}
                    value={costPerHour}
                    onChange={(e) => setCostPerHour(e.target.value)}
                  />
                </div>
              </div>
              {/* -------- cost per hour section end ------- */}

              {/* ========= select status starts======== */}
              <div className="col-lg-4">
                <div className="form-group mb-4">
                  <label>{t("Status")}</label>
                  <Select
                    className="nosearch_select"
                    // options={statusOptions}
                    options={statusOptions.map((option) => ({
                      ...option,
                      label: t(option.label), // Translate the label using the t function
                    }))}
                    value={statusSelectedValue}
                    onChange={(val) => {
                      statusSelectionHandler(val);
                    }}
                    styles={customSelectStyle}
                  />
                  {/* status warning */}

                  {validation.statuswarning && (
                    <div className="error-message mt-2">
                      <p className="d-flex align-items-center gap-1 text-danger">
                        <span className="material-symbols-outlined">
                          warning
                        </span>
                        <span>{t("Please enter status")}!</span>
                      </p>
                    </div>
                  )}
                </div>
              </div>
              {/* ========= select status ends======== */}
            </div>
            {/* ----- language,cost perhour,status section end ------ */}

            {/* -------- latitude and longitude section start ---------  */}
            <div className="row">
              {/* -------- latitude section start ---------  */}
              <div className="col-lg-6">
                <div className="form-group mb-4">
                  <label>{t("Latitude")}</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={t("Latitude")}
                    value={latitude}
                    onChange={(e) => setLatitude(e.target.value)}
                  />
                </div>
              </div>
              {/* -------- latitude section end ---------  */}

              {/* -------- longitude section start ---------  */}
              <div className="col-lg-6">
                <div className="form-group mb-4">
                  <label>{t("Longitude")}</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={t("Longitude")}
                    value={longitude}
                    onChange={(e) => setLongitude(e.target.value)}
                  />
                </div>
              </div>
              {/* -------- longitude section end ---------  */}
            </div>
            {/* -------- latitude and longitude section end ---------  */}

            {/* -------- map co ordinate section start ------- */}
            <div className="row">
              <div className="col-lg-12">
                <div className="form-group mb-4">
                  <label>{t("Map Coordinate")}</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={t("Enter GPS Position")}
                    value={mapCoordinate}
                    onChange={(e) => setMapCoordinate(e.target.value)}
                  />
                  <button
                    className="btn btn-sm btn-primary my-1"
                    onClick={() => window.open(mapCoordinate, "_blank")}
                    type="button"
                  >
                    {t("Go to maps")}
                  </button>
                </div>
              </div>
            </div>
            {/* -------- map co ordinate section end ------- */}
          </div>

          {/* ========= user file upload start ========== */}
          <div className="form-group mb-4">
            {isUserFilesUploading ? (
              <div className="d-flex align-items-center gap-1 mb-1">
                <p className="fw-bold">{t("Please wait while uploading")}</p>{" "}
                {[1, 2, 3, 4, 5, 6, 7, 8].map((count, index) => {
                  return (
                    <div
                      key={index}
                      className="spinner-grow spinner-grow-sm"
                      role="status"
                      style={{ height: "6px", width: "6px" }}
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  );
                })}
              </div>
            ) : (
              <label>{t("Uploads")}</label>
            )}

            <input
              type="file"
              className="form-control"
              id="uploadedUserFiles"
              multiple
              onChange={filesUploadHandler}
            />

            {uploadedUserFiles.length > 0 && (
              <ul className="uploaded-files d-flex flex-wrap gap-3 p-0 m-0 mt-3">
                {uploadedUserFiles.map((file, index) => {
                  return (
                    <li
                      className="file d-flex gap-3 align-items-center px-3 py-2 border border-gray rounded-3"
                      key={index}
                    >
                      <Link to="#" onClick={() => downloadFileHandler(file)}>
                        <span className="d-block material-symbols-outlined">
                          download
                        </span>
                      </Link>
                      <p className="fw-medium">{file.name}</p>
                      <Link to="#" onClick={() => closeUserFileHandler(index)}>
                        <span className="d-block material-symbols-outlined">
                          close
                        </span>
                      </Link>
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
          {/* ========= user file upload end ===========*/}

          {/* ========= User profile image file upload starts ========== */}
          <div className="form-group mb-4">
            <label>{t("Profile picture")}</label>
            <div className="d-flex align-items-center gap-3">
              <div className="img-preview border border-2 border-gray rounded-1 overflow-hidden">
                <img
                  src={
                    uploadedPreviewImage == ""
                      ? "https://placehold.co/150x150"
                      : uploadedPreviewImage
                  }
                  alt="user"
                  className="w-100 h-100 object-cover object-center"
                />
              </div>

              <div className="w-100">
                {isUploading && (
                  <div className="d-flex align-items-center gap-1 mb-1">
                    <p className="fw-bold">
                      {t("Please wait while uploading")}
                    </p>{" "}
                    {[1, 2, 3, 4, 5, 6, 7, 8].map((count, index) => {
                      return (
                        <div
                          key={index}
                          className="spinner-grow spinner-grow-sm"
                          role="status"
                          style={{ height: "5px", width: "5px" }}
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      );
                    })}
                  </div>
                )}
                <input
                  type="file"
                  className="form-control"
                  id="uploadedUserImageFile"
                  onChange={imageUploadHandler}
                />
              </div>
            </div>
          </div>
          {/* ========= User profile image file upload ends ===========*/}

          {/* ------ save/update button section start ------ */}
          <div className="action d-flex align-items-center gap-2">
            <button
              type="submit"
              className="btn btn-primary"
              disabled={isSaving ? true : false}
              style={{ cursor: isSaving ? "not-allowed" : "pointer" }}
              onClick={saveUpdateUserHandler}
            >
              {editUserData ? t("Update") : t("Save")}
              {isSaving && (
                <div
                  className="mx-2 spinner-border spinner-border-sm"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              )}
            </button>
            {isRequiredFieldFillPending && (
              <p className="d-flex align-items-center gap-1 text-danger">
                <span className="material-symbols-outlined">warning</span>
                <span>{t("Please fill the required input fields")}!!!</span>
              </p>
            )}
          </div>
          {/* ------ save/update button section end ------ */}
        </form>
      </div>
    </div>
  );
};

export default UserFormPopup;
