/* eslint-disable */
import React, { useContext } from "react";
import { Link } from "react-router-dom";

//import constext
import { GlobalProvider } from "context/GlobalContext";

//import translation function for language translation
import { useTranslation } from "react-i18next";

const MaterialCalendarHeader = ({ selectedKit }) => {
  const { agendaWaitning } = useContext(GlobalProvider);

  const { t } = useTranslation(); //for translation

  return (
    <div className="calender_srch_top">
      {/* ------- arrow back icon section start ------- */}
      <div className="calender_addBtn mb-2 d-flex">
        <Link
          to="/materialkit"
          className="column-collapse btn btn-primary icon-btn mw-unset"
        >
          <span className="d-block material-symbols-outlined icon-fill">
            arrow_back
          </span>
        </Link>

        {agendaWaitning ? (
          <div className="d-flex align-items-center justify-content-center gap-2 ms-5 mx-auto">
            <b className="text-primary">{t("Please wait for a while")}</b>
            <p className="placeholder-glow" style={{ width: "300px" }}>
              <span className="d-block placeholder"></span>
            </p>
          </div>
        ) : (
          ""
        )}
      </div>
      {/* ------- arrow back icon section end ------- */}

      {/* -------  add icon section start ------- */}
      <div className="calender_addBtn mb-2 d-flex">
        <p className="m-2 text-primary">
          {" "}
          <b>
            {selectedKit ? selectedKit.name : ""} || {t("Unit per day")} :{" "}
            {selectedKit ? selectedKit.unitsperday : ""} ||{" "}
            {t("Units per course")} :{" "}
            {selectedKit ? selectedKit.unitspercourse : ""}
          </b>
        </p>

        {/* <Link
          to="#saveMaterialSchedulePopup"
          data-bs-toggle="offcanvas"
          className="btn btn-primary"
        >
          <i className="material-icons-outlined">add</i>
        </Link> */}
      </div>
      {/* -------  add icon section end ------- */}
    </div>
  );
};

export default MaterialCalendarHeader;
