/* eslint-disable */
import AgendaBody from "components/AgendaComponents/AgendaBody/AgendaBody";
import AgendaBreadCrumb from "components/AgendaComponents/AgendaBreadCrumb/AgendaBreadCrumb";
import AgendaFilter from "components/AgendaComponents/Filter/AgendaFilter";
import AgendaHeader from "components/AgendaComponents/Header/AgendaHeader";
import LeftMenu from "components/common/LeftMenu";
import UserMenu from "components/common/UserMenu";
import React, { useEffect } from "react";
const Agenda = () => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  useEffect(() => {
    document.title = "Agenda"; // Update the title when Register component mounts
  }, []);
  return (
    <>
      {/* Menu section starts */}
      <UserMenu />
      <LeftMenu />
      {/* Menu section ends */}
      {userInfo?.role?.name == "admin" || userInfo?.role?.name == "manager" ? (
        <section className="mail-wrapper py-4">
          <div className="container-fluid px-4">
            {/* ---- bread crumb start ---- */}
            <AgendaBreadCrumb />
            {/* ---- bread crumb end ---- */}
            <AgendaHeader />
            <AgendaFilter />
            <AgendaBody />
          </div>
        </section>
      ) : null}
    </>
  );
};
export default Agenda;
