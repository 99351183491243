/* eslint-disable */
import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";

//import translation function for language translation
import { useTranslation } from "react-i18next";

//import context
import { GlobalProvider } from "context/GlobalContext";

//import global utility functions
import { stringToDateTime } from "components/common/Utility.js";

//import file download function
import downloadFileHandler from "helpers/DownloadFile";

const ViewMailPopup = () => {
  const token = localStorage.getItem("token"); // token

  const { t } = useTranslation(); //for translation

  // loggedin user information
  const currentUser = JSON.parse(localStorage.getItem("userInfo"));

  // context data
  const { viewConversationData, viewMail } = useContext(GlobalProvider);

  const [userInputs, setUserInputs] = useState({
    receivermail: "",
    receiver: "",
    sendermail: "",
    sender: "",
    date: "",
    reserveid: "",
    reservearraystring: "",
    subject: "",
    description: "",
    tracker: "",
    uploads: [],
  });

  useEffect(() => {
    console.log("viewConversationData ===>", viewConversationData);

    if (viewConversationData) {
      setUserInputs((prevState) => ({
        ...prevState,
        receivermail: viewConversationData.receivermail,
        receivername: viewConversationData.receivername,
        sendermail: viewConversationData.sendermail,
        sendername: viewConversationData.sendername,
        date: stringToDateTime(viewConversationData.createdAt),
        reserveid: viewConversationData.reserveid,
        subject: viewConversationData.subject,
        description: viewConversationData.description,
        tracker: viewConversationData.tracker,
        uploads: viewConversationData.uploads,
        reservearraystring: viewConversationData.reserveArrayString,
      }));
    }
  }, [viewConversationData]);

  return (
    <div
      className="offcanvas offcanvas-end border-0"
      tabIndex="-1"
      id="EmailDetailsPopup"
      aria-labelledby="EmailDetailsPopupLabel"
    >
      <div className="offcanvas-header p-4">
        <h3 className="text-secondary mb-0" id="EmailDetailsPopupLabel">
          {userInputs.subject}
        </h3>
        {viewMail ? (
          <Link data-bs-toggle="offcanvas" to="#email_history_popup">
            <span className="material-icons-outlined">close</span>
          </Link>
        ) : (
          <button
            type="button"
            id="closeCreateMail"
            className="btn-close p-2 bg-white shadow-none shadow-sm m-0"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        )}
      </div>
      <div className="offcanvas-body p-4 pt-0">
        <div className="main-meta form-group mb-3">
          <ul className="list_stye_none">
            <li className="d-flex align-items-center gap-3">
              <span className="text-gray-600">{t("From")}</span>
              <Link to="#">
                {userInputs.sendermail} ({userInputs.sendername})
              </Link>
            </li>
            <li className="d-flex align-items-center gap-3">
              <span className="text-gray-600">{t("To")}</span>
              <Link to="#">
                {userInputs.receivermail} ({userInputs.receivername})
              </Link>
            </li>
            <li className="d-flex align-items-center gap-3">
              <span className="text-gray-600">{t("Date")}</span>
              <p>
                <span>{userInputs.date}</span>
              </p>
            </li>
            <li className="d-flex align-items-center gap-3">
              <span className="text-gray-600">{t("ID")}</span>
              <p>
                <span>
                  {userInputs.reserveid} {userInputs.reservearraystring}
                </span>
              </p>
            </li>
          </ul>
        </div>
        <div className="message-body bg-gray-500 p-4 mb-3">
          <p dangerouslySetInnerHTML={{ __html: userInputs.description }} />
        </div>

        <div className="tags mb-5">
          <ul className="d-flex align-items-center gap-2 flex-wrap list_stye_none">
            <li className="bg-gray-500 px-2 py-1">
              <span>
                <b>{t("Sent")}:</b>{" "}
              </span>
              <span>{userInputs.date}</span>
            </li>
            <li className="bg-gray-500 px-2 py-1">
              <span>
                <b>{t("Delivery")}:</b>{" "}
              </span>
              <span>{userInputs.date}</span>
            </li>
            <li className="bg-gray-500 px-2 py-1">
              <span>
                <b>{t("Read")}:</b>{" "}
              </span>
              <span>-</span>
            </li>
            {/* <li className="bg-gray-500 px-2 py-1">
              <span>
                <b>{t("Accepted")}:</b>{" "}
              </span>
              <span>-</span>
            </li> */}
          </ul>
        </div>
        <div>
          {userInputs.uploads
            ? userInputs.uploads.length > 0 && (
                <ul className="uploaded-files d-flex flex-wrap gap-3 p-0 m-0 mt-3">
                  {userInputs.uploads.map((file, index) => {
                    return (
                      <li
                        className="file d-flex gap-3 align-items-center px-3 py-2 border border-gray rounded-3"
                        key={index}
                      >
                        <Link to="#" onClick={() => downloadFileHandler(file)}>
                          <span className="d-block material-symbols-outlined">
                            download
                          </span>
                        </Link>
                        <p className="fw-medium">{file.name}</p>
                      </li>
                    );
                  })}
                </ul>
              )
            : ""}
        </div>
        {/* reply to the mail  */}
        {/* <form action="">
            <div className="reply mb-3">
              <label htmlFor="reply" className="mb-3">
                <h3 className="mb-0">{t('Reply')}</h3>
              </label>
              <textarea
                name="reply"
                id="reply"
                cols="30"
                rows="15"
                className="form-control"
              >
                I love coding and problem solving. I have 3+ years of experience
                in the web development sector. Love working on both server and
                client side code. I like to get to know my clients closely to
                facilitate better communication.
              </textarea>
            </div>
            <div className="action d-flex justify-content-end">
              <button type="submit" className="btn btn-primary mw-unset">
                <span className="d-block material-symbols-outlined icon-fill">
                  send
                </span>
              </button>
            </div>
          </form> */}
      </div>
    </div>
  );
};

export default ViewMailPopup;
