/* eslint-disable */
import { useState, useEffect, useContext } from "react";

//import translation function for language translation
import { useTranslation } from "react-i18next";
import Select from "react-select";

//api url requirement
import * as url from "urlhelpers/UrlHelper";
import { getData, postData, putData } from "utils/Gateway";

//import context
import { GlobalProvider } from "context/GlobalContext";

const AddUpdateCategoryPopup = ({
  updateCategoryData,
  setUpdateCategoryData,
  setreloadCategoryList,
}) => {
  const { setSuccess, setError, setNotificationString } =
    useContext(GlobalProvider);

  const token = localStorage.getItem("token");
  const { t } = useTranslation(); //for translation

  const [parentCategoryList, setParentCategoryList] = useState([]);
  const [categoryName, setCategoryName] = useState("");
  const [categorySlug, setCategorySlug] = useState("");
  const [isCategoryRoot, setIsCategoryRoot] = useState(false);
  const [parentCategoryValue, setparentCategoryValue] = useState(null);

  const [isAddUpdating, setIsAddUpdating] = useState(false);

  /* hook for validation */
  const [validation, setValidation] = useState({
    nameWarning: false,
    slugWarning: false,
    rootParentWarning: false,
  });

  /* function to validate form */
  const validate = () => {
    let isValid = true;

    if (categoryName === "") {
      setValidation((prevState) => ({ ...prevState, nameWarning: true }));
      isValid = false;
    }

    if (categorySlug === "") {
      setValidation((prevState) => ({ ...prevState, slugWarning: true }));
      isValid = false;
    }

    if (!isCategoryRoot && parentCategoryValue === null) {
      setValidation((prevState) => ({ ...prevState, rootParentWarning: true }));
      isValid = false;
    }

    return isValid;
  };

  //function for get all parent category
  const getAllParentCategory = async () => {
    try {
      let requestUrl =
        url.API_BASEURL +
        url.API_GET_CATEGORIES +
        `?token=${token}&isroot=${true}`;

      console.log("url of parent category list------>", requestUrl);

      const response = await getData(requestUrl);

      console.log("response in parent category------>", response);

      if (response.status) {
        setParentCategoryList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for save category
  const addUpdateCategoryHandler = async () => {
    if (validate()) {
      try {
        setIsAddUpdating(true);
        let categoryData = {
          name: categoryName,
          slug: categorySlug,
          status: "1",
          parent: parentCategoryValue?.value || null,
          isroot: isCategoryRoot,
        };

        let requestUrl = url.API_BASEURL;

        let response = null;

        if (updateCategoryData) {
          requestUrl =
            requestUrl +
            url.API_UPDATE_CATEGORIY +
            `/${updateCategoryData._id}` +
            `?token=${token}`;
          response = await putData(requestUrl, categoryData);
        } else {
          requestUrl =
            requestUrl + url.API_ADD_NEW_CATEGORY + `?token=${token}`;
          response = await postData(requestUrl, categoryData);
        }

        if (response.status) {
          setIsAddUpdating(false);
          resetHandler();
          setreloadCategoryList(true);
          setSuccess(true);
          setNotificationString(
            t(
              updateCategoryData
                ? "Category has been updated successfully"
                : "Category has been added successfully"
            )
          );
          let offCanvasPopup = document.querySelector("#addCategoryPopup");
          let offCanvas = bootstrap.Offcanvas.getInstance(offCanvasPopup);
          offCanvas.hide();
          getAllParentCategory();
        }
      } catch (error) {
        console.log(error.message);
        setError(true);
        setNotificationString(
          t("Something went wrong, please try again later")
        );
      }
    }
  };

  const resetHandler = () => {
    setCategoryName("");
    setCategorySlug("");
    setIsCategoryRoot(false);
    setparentCategoryValue(null);
    setUpdateCategoryData(null);
    setValidation({
      nameWarning: false,
      slugWarning: false,
      rootParentWarning: false,
    });
  };

  useEffect(() => {
    if (updateCategoryData) {
      console.log("update category data-------->", updateCategoryData);
      setValidation({
        nameWarning: false,
        slugWarning: false,
        rootParentWarning: false,
      });
      updateCategoryData?.name && setCategoryName(updateCategoryData?.name);
      updateCategoryData?.slug && setCategorySlug(updateCategoryData?.slug);
      setIsCategoryRoot(updateCategoryData.isroot);
      setparentCategoryValue(updateCategoryData?.parentdata);
    }
  }, [updateCategoryData]);

  useEffect(() => {
    getAllParentCategory();
  }, []);

  return (
    <div
      className="offcanvas offcanvas-end border-0"
      tabIndex="-1"
      id="addCategoryPopup"
      aria-labelledby="addCategoryPopupLabel"
    >
      <div className="offcanvas-header p-4">
        <h3 className="text-secondary mb-0" id="addCategoryPopupLabel">
          {t(updateCategoryData ? "Update Category" : "Add Category")}
        </h3>
        {/* ----- close button start ----- */}
        <button
          type="button"
          id="closeCreateMail"
          className="btn-close p-2 bg-white shadow-none shadow-sm m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={resetHandler}
        ></button>
        {/* ----- close button end ----- */}
      </div>
      <div className="offcanvas-body p-4 pt-0">
        <form onSubmit={(e) => e.preventDefault()}>
          {/* ----- name section start ----- */}
          <div className="form-group mb-4">
            <label htmlFor="name">{t("Label Name")}</label>
            <input
              type="text"
              id="name"
              placeholder={t("Enter Here")}
              className="form-control"
              value={categoryName}
              onChange={(e) => {
                setCategoryName(e.target.value);
                setValidation((prevState) => ({
                  ...prevState,
                  nameWarning: false,
                }));
              }}
            />
            {validation.nameWarning && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>{t("Please enter name")}!</span>
                </p>
              </div>
            )}
          </div>
          {/* ----- name section end ----- */}

          {/* ----- slug section start ----- */}
          <div className="form-group mb-4">
            <label htmlFor="slug">{t("Slug")}</label>
            <input
              type="text"
              id="slug"
              placeholder={t("Enter Here")}
              className="form-control"
              value={categorySlug}
              onChange={(e) => {
                setCategorySlug(e.target.value);
                setValidation((prevState) => ({
                  ...prevState,
                  slugWarning: false,
                }));
              }}
            />
            {validation.slugWarning && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>{t("Please enter slug")}!</span>
                </p>
              </div>
            )}
          </div>
          {/* ----- slug section end ----- */}

          {/* ----- isroot checkbox section start ----- */}
          {parentCategoryValue ? null : (
            <div className="form-check mb-4">
              <input
                className="form-check-input"
                type="checkbox"
                checked={isCategoryRoot}
                onChange={(e) => {
                  setIsCategoryRoot(e.target.checked);
                  setValidation((prevState) => ({
                    ...prevState,
                    rootParentWarning: false,
                  }));
                }}
                id="flexCheckDefault"
              />
              <label
                className="form-check-label text-primary"
                htmlFor="flexCheckDefault"
              >
                {t("Set as Root Category")}
              </label>
            </div>
          )}
          {/* ----- isroot checkbox section end ----- */}

          {/* ----- parent select section start ----- */}
          {isCategoryRoot ? null : (
            <div className="form-group select-parents mb-4">
              <label htmlFor="selectparents">{t("Select parents")}</label>
              <Select
                options={parentCategoryList}
                value={parentCategoryValue}
                onChange={(val) => {
                  setparentCategoryValue(val);
                  setValidation((prevState) => ({
                    ...prevState,
                    rootParentWarning: false,
                  }));
                }}
              />
            </div>
          )}
          {/* ----- parent select section end ----- */}

          {validation.rootParentWarning && (
            <div className="error-message mb-4">
              <p className="d-flex align-items-center gap-1 text-danger">
                <span className="material-symbols-outlined">warning</span>
                <span>{t("Please either select root or select parent")}!</span>
              </p>
            </div>
          )}

          {/* ----- save/update section start ----- */}
          <div className="action">
            <button
              type="submit"
              className="btn btn-primary mw-unset"
              disabled={isAddUpdating ? true : false}
              style={{ cursor: isAddUpdating ? "not-allowed" : "pointer" }}
              onClick={addUpdateCategoryHandler}
            >
              {t(updateCategoryData ? "Update" : "Save")}
              {isAddUpdating && (
                <div
                  className="mx-2 spinner-border spinner-border-sm"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              )}
            </button>
          </div>
          {/* ----- save/update section end ----- */}
        </form>
      </div>
    </div>
  );
};

export default AddUpdateCategoryPopup;
