import React from 'react';
import AddGroupPopupHeader from './AddGroupPopupHeader';
import GroupNameTab from './GroupPopupTabs/GroupNameTab';
import GroupMembersTab from './GroupPopupTabs/GroupMembersTab';
import AddEventsTab from './GroupPopupTabs/AddEventsTab';

const AddGroupPopup = () => {
  return (
    <div
      className="offcanvas offcanvas-end border-0"
      tabIndex="-1"
      id="addGroupPopup"
      aria-labelledby="addGroupPopupLabel"
    >
      <div className="offcanvas-header p-4">
        <button
          type="button"
          id="closeCreateMail"
          className="btn-close p-2 bg-white shadow-none shadow-sm ms-auto m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div className="offcanvas-body p-4 pt-0">
        <AddGroupPopupHeader />
        <div className="tab-content" id="myTabContent">
          <GroupNameTab />
          <GroupMembersTab />
          <AddEventsTab />
        </div>
      </div>
    </div>
  );
};

export default AddGroupPopup;
