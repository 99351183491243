/* eslint-disable */

//import translation function for language translation
import { useTranslation } from "react-i18next";

const TimeStopWarningModal = ({ onConfirm }) => {
  const { t } = useTranslation(); //for translation

  return (
    <div
      className="modal fade"
      id="timeStopWarning"
      tabIndex="-1"
      aria-labelledby="submitSurveyLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content border-0 rounded-0">
          <div className="modal-header border-0">
            <button
              type="button"
              className="btn-close p-2 bg-white shadow-none shadow-sm m-0 ms-auto"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body text-center p-5 pt-0">
            <img
              src="assets/img/submit-survey.png"
              alt="Survey Submitted Successfully!"
              className="img-fluid"
            />
            <div className="alert_content text-center">
              <h3>
                {t("Are you sure")} , <br /> {t("You want to end the day now")}?
              </h3>
              {/* <p>You can reschedule this time again if it necessary </p> */}
            </div>
            <div className="modal-btns d-flex align-items-center gap-2 justify-content-center mt-4">
              <button
                type="button"
                className="btn bg-light text-gray"
                data-bs-dismiss="modal"
              >
                {t("No")}, {t("Stay In Progress")}
              </button>
              <button
                data-bs-dismiss="modal"
                onClick={onConfirm}
                className="btn btn-primary"
              >
                {t("Yes")}, {t("Complete it Now")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TimeStopWarningModal;
