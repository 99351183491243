/* eslint-disable */
import React, { useEffect, useState, useRef } from "react";
import LeftMenu from "components/common/LeftMenu";
import UserMenu from "components/common/UserMenu";
import CustomNotification from "components/common/CustomNotification";
import RolePriviledgeBreadCrumb from "components/RolePriviledgeComponents/BreadCrumb/RolePriviledgeBreadCrumb";
import RolePriviledgeHeader from "components/RolePriviledgeComponents/Header/RolePriviledgeHeader";
import RolePriviledgeList from "components/RolePriviledgeComponents/RolePriviledgeList/RolePriviledgeList";
import AddUpdateRolePriviledgePopup from "components/RolePriviledgeComponents/Popup/AddUpdateRolePriviledgePopup";

const RolePriviledge = () => {
  useEffect(() => {
    document.title = "Role Privilege";
  }, []);

  return (
    <>
      <UserMenu />
      <LeftMenu />
      <section className="mail-wrapper pt-2">
        <div className="container-fluid px-4">
          <RolePriviledgeBreadCrumb />
          <RolePriviledgeHeader />
          <RolePriviledgeList />
        </div>
      </section>
      <AddUpdateRolePriviledgePopup />
      <CustomNotification />
    </>
  );
};

export default RolePriviledge;
