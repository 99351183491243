// Global utility functions for all components

export function stringToDateTime(dateString, onlyDate = false) {
  // Function logic using the parameters
  //const dateString = "2023-05-29T16:35:35.048Z";

  const dateObj = new Date(dateString);

  const year = dateObj.getFullYear();
  const month = dateObj.getMonth() + 1; // Months are zero-based, so we add 1
  const day = dateObj.getDate();
  const hours = dateObj.getHours();
  const minutes = dateObj.getMinutes();

  const formattedDate = `${day.toString().padStart(2, '0')}-${month
    .toString()
    .padStart(2, '0')}-${year}`;
  const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes
    .toString()
    .padStart(2, '0')}`;

  // console.log(`Date: ${formattedDate}`);
  // console.log(`Time: ${formattedTime}`);

  if (onlyDate === true) {
    return formattedDate;
  }

  return `${formattedDate} ${formattedTime}`;
}
