/* eslint-disable */
import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import UserMenu from "components/common/UserMenu";
import LeftMenu from "components/common/LeftMenu";
import SurveyQuestionHeader from "components/common/SurveyQuestionHeader/SurveyQuestionHeader";
import SurveyListArea from "components/SurveyComponents/SurveyListArea";
import SaveSurveyPopup from "components/SurveyComponents/Popup/SaveSurveyPopup";
import CustomNotification from "components/common/CustomNotification";

const Survey = () => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  useEffect(() => {
    document.title = " Survey"; // Update the title when Register component mounts
  }, []);

  return (
    <>
      <UserMenu />
      <LeftMenu />
      {userInfo?.role?.name == "admin" || userInfo?.role?.name == "manager" ? (
        <section className="survey-question-wrapper">
          <SurveyQuestionHeader page="survey" />
          <SurveyListArea />
        </section>
      ) : null}

      <SaveSurveyPopup />
      {/*----------- custom notification component start -----------*/}
      <CustomNotification />
      {/*----------- custom notification component end -----------*/}
    </>
  );
};

export default Survey;
