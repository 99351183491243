/* eslint-disable */
import { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";

/*import url and gateway methods */
import { getData } from "utils/Gateway";
import * as url from "urlhelpers/UrlHelper";

//import translation function for language translation
import { useTranslation } from "react-i18next";

//import context
import { GlobalProvider } from "context/GlobalContext";

import TaskStatusOptions from "data/Prod/TaskStatus.json";
import TaskPriority from "data/Prod/TaskPriority.json";

const EmployeeLogFilter = () => {
  const {
    setReloadEmployeeLogList,
    setfilterEmployeeLogDates,
    setfilterEmployeeLogUsers,
  } = useContext(GlobalProvider);

  const token = localStorage.getItem("token"); // token
  const { t } = useTranslation(); //for translation
  const todayValue = new Date().toISOString().split("T")[0];

  //date range
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const [userName, setUserName] = useState("");
  const [userList, setUserList] = useState([]);

  const [userValue, setUserValue] = useState(null);
  const [selectedUsers, setselectedUsers] = useState([]);

  // Hook for date compare validation
  const [dateWarning, setDateWarning] = useState(false);

  //function for get all user list
  const getAllUserList = async () => {
    try {
      let requestUrl =
        url.API_BASEURL +
        url.API_GET_ALL_USER +
        `?token=${token}&isselect=${true}&username=${userName}`;

      const response = await getData(requestUrl);

      // console.log(response);

      if (response.status) {
        setUserList(response.data);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  //function for reciever selection
  const userSelectionHandler = (val) => {
    setUserValue(val);
    setselectedUsers(val.map((item) => item.value));
  };

  //function for reset
  const resetHandler = () => {
    setFromDate("");
    setToDate("");
    setUserName("");
    setUserList([]);
    setUserValue(null);
    setselectedUsers([]);

    setfilterEmployeeLogDates("");
    setfilterEmployeeLogUsers([]);

    setReloadEmployeeLogList(true);
  };

  //function for search
  const searchHandler = () => {
    //assign data for search

    if (fromDate !== "" && toDate == "") {
      setfilterEmployeeLogDates(fromDate);
    } else if (fromDate !== "" && toDate !== "") {
      setfilterEmployeeLogDates(fromDate + "," + toDate);
    } else {
      setfilterEmployeeLogDates("");
    }

    selectedUsers.length == 0
      ? setfilterEmployeeLogUsers([])
      : setfilterEmployeeLogUsers(selectedUsers);
  };

  //use effect for search receiver
  useEffect(() => {
    if (userName.length >= 3) {
      getAllUserList();
    }
  }, [userName]);

  return (
    <div className="collapse mt-3" id="filterCollapse">
      <div className="p-3 bg-gray-200">
        <h4>{t("Filter")}</h4>
        <form onSubmit={(e) => e.preventDefault()}>
          {/* date and title */}
          <div className="form-group mb-3">
            <div className="row">
              <div className="col-lg-6">
                <label>{t("Dates")}</label>
                <div className="field-container d-sm-flex border flex-fill">
                  {/* ------- from date input start ------- */}
                  <input
                    type="date"
                    id="from_date"
                    className="form-control bg-white border-0"
                    placeholder={t("From date")}
                    value={fromDate}
                    onChange={(e) => {
                      setFromDate(e.target.value), setDateWarning(false);
                    }}
                  />
                  {/* ------- from date input end ------- */}

                  {/* ------- to date input start ------- */}
                  <input
                    type="date"
                    id="to_date"
                    className="form-control bg-white border-0"
                    placeholder={t("To date")}
                    value={toDate}
                    onChange={(e) => {
                      setToDate(e.target.value), setDateWarning(false);
                    }}
                  />
                  {/* ------- to date input end ------- */}
                </div>
              </div>
              <div className="col-lg-6">
                <label>{t("User")}</label>
                <Select
                  isMulti
                  placeholder={t("Select User")}
                  options={userList}
                  value={userValue}
                  onChange={(val) => {
                    userSelectionHandler(val);
                  }}
                  onInputChange={(val) => {
                    setUserName(val);
                  }}
                />
              </div>
            </div>
          </div>

          {/* -------- search and reset button section start -------- */}
          <div className="action d-flex gap-3 align-items-center justify-content-end">
            <Link
              onClick={resetHandler}
              to="#"
              className="d-flex gap-1 align-items-center text-black"
            >
              <span className="d-block material-symbols-outlined icon-md">
                sync
              </span>
              <span className="d-block fw-medium">{t("Refresh")}</span>
            </Link>
            <button
              type="submit"
              className="btn btn-primary"
              onClick={searchHandler}
            >
              {t("Filter")}
            </button>
          </div>
          {/* -------- search and reset button section end -------- */}
        </form>
      </div>
    </div>
  );
};

export default EmployeeLogFilter;
