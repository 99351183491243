/* eslint-disable */
import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

//import translation function for language translation
import { useTranslation } from "react-i18next";

/*import url and gateway methods */
import { postData } from "../../../utils/Gateway";
import * as url from "../../../urlhelpers/UrlHelper";

const EmailVerificationRightPanel = () => {
  const { t } = useTranslation(); //for translation
  const code1Ref = useRef(null);
  const code2Ref = useRef(null);
  const code3Ref = useRef(null);
  const code4Ref = useRef(null);

  const [codeWarning, setCodeWarning] = useState(false);
  const [isValidating, setIsValidating] = useState(false);
  const [firstCode, setFirstCode] = useState("");
  const [secondCode, setSecondCode] = useState("");
  const [thirdCode, setThirdCode] = useState("");
  const [fourthCode, setFourthCode] = useState("");
  const [wrongOtp, setWrongOtp] = useState(false);

  //function for validate user
  const validateOtpHandler = async (e) => {
    e.preventDefault();
    if (
      firstCode == "" ||
      secondCode == "" ||
      thirdCode == "" ||
      fourthCode == ""
    ) {
      setCodeWarning(true);
    } else {
      setCodeWarning(false);
      let finalCode = firstCode + secondCode + thirdCode + fourthCode;

      try {
        setIsValidating(true);
        let userData = {
          otp: finalCode,
        };

        let requestUrl = url.API_BASEURL + url.API_VALIDATE_OTP;

        let response = await postData(requestUrl, userData);

        if (response.status) {
          setWrongOtp(false);
          // assigning the loggedin user's id,token,all data to the browser's local storage.
          localStorage.setItem("token", response.token);
          localStorage.setItem("userInfo", JSON.stringify(response.data));
          localStorage.setItem("userID", JSON.stringify(response.data._id));
          setIsValidating(false);
          resetAll();
          window.location.reload();
        } else {
          setWrongOtp(true);
        }
      } catch (error) {
        throw error;
      }
    }
  };

  //function for reset all date
  const resetAll = () => {
    setFirstCode("");
    setSecondCode("");
    setThirdCode("");
    setFourthCode("");
  };

  // function for backspace reference fourth digit
  const fourthCodeKey = (e) => {
    if (e.key == "Backspace") {
      if (fourthCode == "" && fourthCode.length == 0) {
        code3Ref.current?.focus();
      }
    }
  };

  // function for backspace reference third digit
  const thirdCodeKey = (e) => {
    if (e.key == "Backspace") {
      if (thirdCode == "" && thirdCode.length == 0) {
        code2Ref.current?.focus();
      }
    }
  };

  // function for backspace reference second digit
  const secondCodeKey = (e) => {
    if (e.key == "Backspace") {
      if (secondCode == "" && secondCode.length == 0) {
        code1Ref.current?.focus();
      }
    }
  };

  // use effect for shift second input
  useEffect(() => {
    if (firstCode !== "" && firstCode.length == 1) {
      code2Ref.current?.focus();
    }
  }, [firstCode]);

  // use effect for shift third input
  useEffect(() => {
    if (secondCode !== "" && secondCode.length == 1) {
      code3Ref.current?.focus();
    }
  }, [secondCode]);

  // use effect for shift fourth input
  useEffect(() => {
    if (thirdCode !== "" && thirdCode.length == 1) {
      code4Ref.current?.focus();
    }
  }, [thirdCode]);

  return (
    <div className="login_rightpnl">
      <form id="login_form" className="login_sec" onSubmit={validateOtpHandler}>
        <div className="form-title mb-4">
          <h3>{t("Email Verification")}</h3>
          <h6 className="mb-0">{t("Please enter details to proceed")}</h6>
        </div>
        <div className="form-group mb-4">
          <label className="text-gray-dark fw-semibold">{t("Enter OTP")}</label>
          <div className="verification_fillouter">
            <div className="verify_fill">
              <input
                type="number"
                ref={code1Ref}
                value={firstCode}
                onChange={(e) => {
                  setFirstCode(e.target.value.slice(0, e.target.maxLength));
                }}
                placeholder="--"
                maxLength="1"
                className="form-control bg-white border-white rounded-3 text-center"
              />
            </div>
            <div className="verify_fill">
              <input
                type="number"
                ref={code2Ref}
                value={secondCode}
                onChange={(e) => {
                  setSecondCode(e.target.value.slice(0, e.target.maxLength));
                }}
                onKeyDown={secondCodeKey}
                placeholder="--"
                maxLength="1"
                className="form-control bg-white border-white rounded-3 text-center"
              />
            </div>
            <div className="verify_fill">
              <input
                type="number"
                ref={code3Ref}
                value={thirdCode}
                onChange={(e) => {
                  setThirdCode(e.target.value.slice(0, e.target.maxLength));
                }}
                onKeyDown={thirdCodeKey}
                placeholder="--"
                maxLength="1"
                className="form-control bg-white border-white rounded-3 text-center"
              />
            </div>
            <div className="verify_fill">
              <input
                type="number"
                ref={code4Ref}
                value={fourthCode}
                onChange={(e) =>
                  setFourthCode(e.target.value.slice(0, e.target.maxLength))
                }
                onKeyDown={fourthCodeKey}
                placeholder="--"
                maxLength="1"
                className="form-control bg-white border-white rounded-3 text-center"
              />
            </div>
          </div>
        </div>
        {wrongOtp && (
          <div className="error-message mb-4">
            <p className="d-flex align-items-center gap-1 text-danger">
              <span className="material-symbols-outlined">warning</span>
              <span>
                {t("Wrong OTP")}. <Link to="#">{t("Resend OTP")}</Link>
              </span>
            </p>
          </div>
        )}

        <div className="action d-flex flex-wrap align-items-center justify-content-between gap-3">
          <button type="submit" className="btn btn-primary">
            {t("Log in")}
          </button>
        </div>
      </form>
    </div>
  );
};

export default EmailVerificationRightPanel;
