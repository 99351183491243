/* eslint-disable */
import React, { createRef, useEffect, useState } from "react";
import { Link } from "react-router-dom";
// kendo react modules ==========================
import "@progress/kendo-theme-material/dist/all.css";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";
// ES2015 module syntax
import {
  ExcelExport,
  ExcelExportColumn,
} from "@progress/kendo-react-excel-export";
//import translation function for language translation
import { useTranslation } from "react-i18next";
import AddUpdateCategoryPopup from "../Popup/AddUpdateCategoryPopup";
//api url requirement
import * as url from "urlhelpers/UrlHelper";
import { getData } from "utils/Gateway";
//import context
import { GlobalProvider } from "context/GlobalContext";
import { useContext } from "react";
const CategoryList = ({
  downloadExcel,
  reloadCategoryList,
  setreloadCategoryList,
  checkedRecordCount,
  setRecords,
  search,
}) => {
  const token = localStorage.getItem("token");
  const { screenHeight, setSuccess, setNotificationString, setError } =
    useContext(GlobalProvider);
  const [categoryList, setCategoryList] = useState([]);
  const [responseReceived, setResponseReceived] = useState(false);
  const { t } = useTranslation(); //for translation
  // without pagination ------------------------------------
  const initialDataState = {};
  const [dataState, setDataState] = useState();
  const [result, setResult] = useState(process(categoryList, initialDataState));
  const [updateCategoryData, setUpdateCategoryData] = useState(false);
  const onDataStateChange = (event) => {
    setDataState(event.dataState);
    setResult(process(categoryList, event.dataState));
  };
  /* Handle the button's onClick event and set it to export the data by using the ExcelExport save method. */
  const _exporter = createRef();
  const exportExcel = () => {
    if (_exporter.current) {
      _exporter.current.save();
    }
  };
  const getAllCategory = async () => {
    try {
      let requestUrl =
        url.API_BASEURL + url.API_GET_CATEGORIES + `?token=${token}`;
      console.log("url of category list------>", requestUrl);
      const response = await getData(requestUrl);
      console.log("response in category------>", response);
      if (response.status) {
        setRecords(response.data.length);
        setCategoryList(response.data);
        setResponseReceived(true);
        dataState
          ? setResult(process(response.data, dataState))
          : setResult(process(response.data, initialDataState));
        setNotificationString("Category list fetched successfully");
        setSuccess(true);
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  /* function to search */
  const filterData = () => {
    if (!result || !result.data) {
      return []; // Return an empty array if result or result.data is undefined
    }
    const filteredData = result.data.filter((item) => {
      const name = item?.name?.toLowerCase() || "";
      const slug = item?.slug?.toLowerCase() || "";
      const parentname = item?.parentname?.toLowerCase() || "";
      return (
        (name && name.includes(search.toLowerCase())) ||
        (slug && slug.includes(search.toLowerCase())) ||
        (parentname && parentname.includes(search.toLowerCase()))
      );
    });
    setRecords(filteredData.length);
    return filteredData;
  };
  // check the selected count and show the count in top ------------------------
  const handelSelectedCount = () => {
    let checkboxes = document.querySelectorAll(
      'input[name="selectCategoryRecord"]'
    );
    let totalSelected = 0;
    let filteredData = [];
    checkboxes.forEach((checkbox) => {
      if (checkbox.checked) {
        totalSelected++;
        const filteredUser = categoryList.find(
          (category) => category._id === checkbox.value
        );
        filteredUser && filteredData.push(filteredUser);
      }
    });
    // Update the content of the block
    checkedRecordCount(totalSelected);
    return filteredData;
  };
  // mark all checkbox as selected ----------------
  const selectAllRecords = (e) => {
    document
      .querySelectorAll('.k-master-row input[name="selectCategoryRecord"]')
      .forEach((checkbox) => {
        checkbox.checked = e.target.checked;
      });
    handelSelectedCount();
  };
  // blocks to replace the Grid HTML ==============================================
  const SelectRecordHeader = () => {
    return (
      <div className="d-flex align-items-center">
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            id="selectAllMain"
            onClick={selectAllRecords}
          />
        </div>
      </div>
    );
  };
  /* Select single row */
  const SelectRecordRow = (props) => {
    return (
      <td>
        <div className="form-check">
          <input
            className="form-check-input "
            type="checkbox"
            value={props.dataItem._id}
            name="selectCategoryRecord"
            onClick={handelSelectedCount}
          />
        </div>
      </td>
    );
  };
  /* custom title html */
  const titleHtml = ({ dataItem }) => {
    return (
      <td className="title">
        <div className="d-flex align-items-center gap-2">
          <span>{dataItem.name}</span>
          <Link
            to="#addCategoryPopup"
            data-bs-toggle="offcanvas"
            className="ml-auto edit_icon"
            onClick={() => {
              setUpdateCategoryData(dataItem);
            }}
          >
            <span className="d-block material-symbols-outlined icon-md">
              edit
            </span>
          </Link>
        </div>
      </td>
    );
  };
  useEffect(() => {
    if (reloadCategoryList) {
      getAllCategory();
      setreloadCategoryList(false);
    }
  }, [reloadCategoryList]);
  useEffect(() => {
    getAllCategory();
  }, []);
  /* hook to hit  exportExcel() function*/
  useEffect(() => {
    downloadExcel && exportExcel();
  }, [downloadExcel]);
  /* hook to hit filterData function on search */
  useEffect(() => {
    filterData();
  }, [search]);
  /* set records based on onDataStateChange */
  useEffect(() => {
    setRecords(result.data.length);
  }, [result]);
  return (
    <div className="table-responsive">
      {/* ============ download xls start ========== */}
      <ExcelExport
        data={categoryList}
        fileName="Categories.xlsx"
        ref={_exporter}
      >
        <ExcelExportColumn field="name" title={t("Name")} />
        <ExcelExportColumn field="slug" title={t("Slug")} />
        <ExcelExportColumn field="parentname" title={t("Parent")} />
      </ExcelExport>
      {/* ============ download xls end ========== */}
      {/* ------ grid view start ------ */}
      {search ? (
        <Grid
          style={{ minWidth: "100%", height: screenHeight }}
          data={filterData()}
          filterable={true}
          sortable={true}
          groupable={true}
          onDataStateChange={onDataStateChange}
          {...dataState}
          //pageable = {true}
          //total = {products.length}
          reorderable
          // resizable
        >
          <GridColumn
            field="id"
            headerCell={SelectRecordHeader}
            cell={SelectRecordRow}
            filterable={false}
            sortable={false}
            width="70px"
          />
          <GridColumn
            cell={titleHtml}
            field="name"
            title={t("Name")}
            //width="200px"
          />
          <GridColumn
            field="slug"
            title={t("Slug")}
            //width="200px"
          />
          <GridColumn
            field="parentname"
            title={t("Parent")}
            //width="200px"
          />
        </Grid>
      ) : (
        <Grid
          style={{ minWidth: "100%", height: screenHeight }}
          data={result}
          filterable={true}
          sortable={true}
          groupable={true}
          onDataStateChange={onDataStateChange}
          {...dataState}
          //pageable = {true}
          //total = {products.length}
          reorderable
          // resizable
        >
          <GridColumn
            field="id"
            headerCell={SelectRecordHeader}
            cell={SelectRecordRow}
            filterable={false}
            sortable={false}
            width="70px"
          />
          <GridColumn
            cell={titleHtml}
            field="name"
            title={t("Name")}
            // width="200px"
          />
          <GridColumn
            field="slug"
            title={t("Slug")}
            //width="200px"
          />
          <GridColumn
            field="parentname"
            title={t("Parent")}
            //width="200px"
          />
        </Grid>
      )}
      {/* ------ grid view end ------ */}
      {/* ------ addupdate popup ----- */}
      <AddUpdateCategoryPopup
        setUpdateCategoryData={setUpdateCategoryData}
        updateCategoryData={updateCategoryData}
        setreloadCategoryList={setreloadCategoryList}
      />
    </div>
  );
};
export default CategoryList;
