import AddEventRolePopup from 'components/EventRoleComponents/AddEventRolePopup';
import EventRoleBody from 'components/EventRoleComponents/EventRoleBody';
import EventRoleNav from 'components/EventRoleComponents/EventRoleNav';
import LeftMenu from 'components/common/LeftMenu';
import UserMenu from 'components/common/UserMenu';
import React from 'react';

const EventRole = () => {
  /* useState hook to download excel file */
  const [downloadExcel, setDownloadExcel] = React.useState(false);
  React.useEffect(() => {
    document.title = ' Event Role'; // Update the title when Register component mounts
  }, []);
  return (
    <>
      <UserMenu />
      <LeftMenu />
      <section className="mail-wrapper py-4">
        <div className="container-fluid px-4">
          <EventRoleNav setDownloadExcel={setDownloadExcel} />
          <EventRoleBody downloadExcel={downloadExcel} />
        </div>
      </section>
      <AddEventRolePopup />
    </>
  );
};

export default EventRole;
