/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import UserMenu from "components/common/UserMenu";
import LeftMenu from "components/common/LeftMenu";
import CustomNotification from "components/common/CustomNotification";
import ReserveTemplateBreadCrumb from "components/ReserveTemplateComponents/BreadCrumb/ReserveTemplateBreadCrumb";
import ReserveTemplateHeader from "components/ReserveTemplateComponents/Header/ReserveTemplateHeader";
import ToggleColumns from "components/ReserveTemplateComponents/ToggleColumns/ToggleColumns";
import ReserveTemplateFilter from "components/ReserveTemplateComponents/Filter/ReserveTemplateFilter";
import ReserveTemplateListBody from "components/ReserveTemplateComponents/ReserveTemplateListBody/ReserveTemplateListBody";

const ReserveTemplateList = () => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  /* useState hook to download excel file */
  const [downloadExcel, setDownloadExcel] = useState(false);

  /* hook to search */
  const [search, setSearch] = useState("");

  /* hook to get number of records */
  const [records, setRecords] = useState(0);

  const blockRefSelected = useRef(null); // this will be used to acccess dom element directly

  /* function for checking record count */
  const checkedRecordCount = (selectCount) => {
    console.log("selectCount", selectCount);
    const blockElementSelect = ReactDOM.findDOMNode(blockRefSelected.current);
    if (blockElementSelect) {
      blockElementSelect.innerHTML = selectCount;
    }
  };

  useEffect(() => {
    document.title = " Reserve Template"; // Update the title when Register component mounts
  }, []);
  return (
    <>
      {/* -------------------- menu section start ---------------------- */}
      <UserMenu />
      <LeftMenu />
      {/* -------------------- menu section end ---------------------- */}

      {userInfo?.role?.name == "admin" || userInfo?.role?.name == "manager" ? (
        <section className="mail-wrapper py-1">
          <div className="container-fluid px-4">
            {/*--------------------- bread crumb start ----------------------- */}
            <ReserveTemplateBreadCrumb />
            {/*--------------------- bread crumb end ----------------------- */}
            {/* ------------------- header seaction start --------------------- */}
            <ReserveTemplateHeader
              setDownloadExcel={setDownloadExcel}
              setSearch={setSearch}
              records={records}
              blockRefSelected={blockRefSelected}
            />
            {/* ------------------- header seaction end --------------------- */}
            {/* -------------------- select columns start ----------------------- */}
            <ToggleColumns />
            {/* -------------------- select columns end ----------------------- */}
            {/* --------------- filter start -------------------- */}
            <ReserveTemplateFilter />
            {/* --------------- filter end -------------------- */}
            {/* ------------------- list area start -------------------- */}
            <ReserveTemplateListBody
              downloadExcel={downloadExcel}
              search={search}
              setRecords={setRecords}
              checkedRecordCount={checkedRecordCount}
            />
            {/* ------------------- list area end -------------------- */}
          </div>
        </section>
      ) : null}

      {/*----------- custom notification component start -----------*/}
      <CustomNotification />
      {/*----------- custom notification component end -----------*/}
    </>
  );
};

export default ReserveTemplateList;
