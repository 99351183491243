/* eslint-disable */
import React, { useState, useEffect, useContext, createRef } from "react";
import { Link } from "react-router-dom";
// kendo react modules ==========================
import "@progress/kendo-theme-material/dist/all.css";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";
//api url requirement
import * as url from "urlhelpers/UrlHelper";
import { getData } from "utils/Gateway";
//import context
import { GlobalProvider } from "context/GlobalContext";
//import translation function for language translation
import { useTranslation } from "react-i18next";
import { assetImages } from "constants";
const DailyTaskList = ({ search, setRecords, checkedRecordCount }) => {
  const token = localStorage.getItem("token"); // token
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const todayValue = new Date().toISOString().split("T")[0];
  const { t } = useTranslation(); //for translation
  const {
    reloadTaskList,
    setreloadTaskList,
    seteditTaskId,
    filterTaskDateRange,
    filterTaskTitle,
    filterTaskStatus,
    filterTaskPriorities,
    filterTaskLabels,
    screenHeight,
  } = useContext(GlobalProvider);
  const [TaskList, setTaskList] = useState([]);
  const [responseReceived, setResponseReceived] = useState(false);
  // without pagination ------------------------------------
  const initialDataState = {};
  const [dataState, setDataState] = useState();
  const [result, setResult] = useState(process(TaskList, initialDataState));
  const onDataStateChange = (event) => {
    setDataState(event.dataState);
    setResult(process(TaskList, event.dataState));
  };
  /* function to search */
  const filterData = () => {
    if (!result || !result.data) {
      return []; // Return an empty array if result or result.data is undefined
    }
    const filteredData = result.data.filter((item) => {
      const title = item?.title?.toLowerCase() || "";
      const projectname = item?.projectname?.toLowerCase() || "";
      const assignedusername = item?.assignedusername?.toLowerCase() || "";
      const startdate = item?.startdate;
      const enddate = item?.enddate;
      const hours = item?.hours.toString();
      const taskpriority = item?.taskpriority?.toLowerCase() || "";
      const tagstring = item?.tagstring?.toLowerCase() || "";
      const taskstatus = item?.taskstatus?.toLowerCase() || "";
      return (
        (title && title.includes(search.toLowerCase())) ||
        (projectname && projectname.includes(search.toLowerCase())) ||
        (assignedusername && assignedusername.includes(search.toLowerCase())) ||
        (startdate && startdate.includes(search)) ||
        (enddate && enddate.includes(search)) ||
        (hours && hours.includes(search)) ||
        (taskpriority && taskpriority.includes(search.toLowerCase())) ||
        (tagstring && tagstring.includes(search.toLowerCase())) ||
        (taskstatus && taskstatus.includes(search.toLowerCase()))
      );
    });
    setRecords(filteredData.length);
    return filteredData;
  };
  /* function to get all label data */
  const getAllTaskList = async () => {
    try {
      let requestUrl =
        url.API_BASEURL +
        url.API_GET_ALL_TASKS +
        `?token=${token}&isdailytask=${true}`;
      if (filterTaskDateRange != "") {
        requestUrl = requestUrl + `&filterdates=${filterTaskDateRange}`;
      } else {
        requestUrl = requestUrl + `&filterdates=${todayValue},${todayValue}`;
      }
      if (filterTaskTitle != "") {
        requestUrl = requestUrl + `&filtertitle=${filterTaskTitle}`;
      }
      if (filterTaskLabels.length > 0) {
        requestUrl = requestUrl + `&filtertags=${filterTaskLabels}`;
      }
      if (filterTaskStatus.length > 0) {
        requestUrl = requestUrl + `&filtercompletestatus=${filterTaskStatus}`;
      }
      if (filterTaskPriorities.length > 0) {
        requestUrl = requestUrl + `&filterpriorities=${filterTaskPriorities}`;
      }
      // console.log("task list url =====>>>", requestUrl);
      const response = await getData(requestUrl);
      // console.log("task list response ========>>>", response);
      if (response.data) {
        setTaskList(response.data);
        setResponseReceived(true);
        dataState
          ? setResult(process(response.data, dataState))
          : setResult(process(response.data, initialDataState));
        setRecords(response.data.length);
      }
    } catch (error) {
      console.error(error.message);
    }
  };
  // check the selected count and show the count in top and also selected download xls ------------------------
  const handelSelectedCount = () => {
    let checkboxes = document.querySelectorAll(
      'input[name="selectTaskRecord"]'
    );
    let totalSelected = 0;
    let filteredData = [];
    checkboxes.forEach((checkbox) => {
      if (checkbox.checked) {
        totalSelected++;
        const filteredUser = TaskList.find(
          (user) => user._id === checkbox.value
        );
        filteredUser && filteredData.push(filteredUser);
      }
    });
    // Update the content of the block
    checkedRecordCount(totalSelected);
    return filteredData;
  };
  // mark all checkbox as selected ----------------
  const selectAllRecords = (e) => {
    document
      .querySelectorAll('.k-master-row input[name="selectTaskRecord"]')
      .forEach((checkbox) => {
        checkbox.checked = e.target.checked;
      });
    handelSelectedCount();
  };
  // blocks to replace the Grid HTML ==============================================
  const SelectRecordHeader = () => {
    return (
      <div className="d-flex align-items-center">
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            id="selectAllMain"
            onClick={selectAllRecords}
          />
        </div>
      </div>
    );
  };
  /* Select single row */
  const SelectRecordRow = (props) => {
    return (
      <td>
        <div className="form-check">
          <input
            className="form-check-input "
            type="checkbox"
            value={props.dataItem._id}
            filtername="selectedrecord"
            name="selectTaskRecord"
            onClick={handelSelectedCount}
          />
        </div>
      </td>
    );
  };
  /* custom title html */
  const titleHtml = ({ dataItem }) => {
    return (
      <td className="title kendoCustomColumn overflow-visible">
        <div className="d-flex align-items-center gap-2">
          <span>{dataItem.title}</span>
          <Link
            to="#add_task_view"
            data-bs-toggle="offcanvas"
            onClick={() => {
              seteditTaskId(dataItem._id);
            }}
            className="ml-auto edit_icon mt-2"
          >
            <span className="material-symbols-outlined">chevron_right</span>
          </Link>
          {/* <div className="dropdown cell-dropdown ms-auto mt-2">
            <button
              className="dropdown-toggle bg-transparent p-0 border-0"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <span className="d-block material-symbols-outlined icon-md">
                more_vert
              </span>
            </button>
            <ul className="dropdown-menu border-0 shadow-lg">
              <li>
                <Link
                  className="dropdown-item"
                  to="#add_task"
                  data-bs-toggle="offcanvas"
                  onClick={() => {
                    seteditTaskId(dataItem._id);
                  }}
                >
                  Edit
                </Link>
              </li>
              <li>
                <Link
                  className="dropdown-item"
                  to="#add_task_view"
                  data-bs-toggle="offcanvas"
                  onClick={() => {
                    seteditTaskId(dataItem._id);
                  }}
                >
                  View
                </Link>
              </li>
            </ul>
          </div> */}
        </div>
      </td>
    );
  };
  //complete status html
  const statusHtml = ({ dataItem }) => {
    return (
      <td className="activity">
        <span
          className={
            dataItem.completedpercent == "0"
              ? "text-orange"
              : dataItem.completedpercent == "3"
              ? "text-green"
              : dataItem.completedpercent == "1"
              ? "text-primary"
              : "text-secondary"
          }
        >
          {dataItem.taskstatus}
        </span>
      </td>
    );
  };
  useEffect(() => {
    if (
      filterTaskDateRange != "" ||
      filterTaskTitle != "" ||
      filterTaskLabels.length > 0 ||
      filterTaskPriorities.length > 0 ||
      filterTaskStatus.length > 0
    ) {
      getAllTaskList();
    }
  }, [
    filterTaskDateRange,
    filterTaskTitle,
    filterTaskLabels,
    filterTaskStatus,
    filterTaskPriorities,
  ]);
  /* hook to reload list */
  useEffect(() => {
    if (reloadTaskList || !responseReceived) {
      getAllTaskList();
      setResult(process(TaskList, initialDataState));
      if (reloadTaskList) {
        setreloadTaskList(false);
      }
    }
  }, [reloadTaskList, responseReceived]);
  /* hook to hit filterData function on search */
  useEffect(() => {
    filterData();
  }, [search]);
  /* set records based on onDataStateChange */
  useEffect(() => {
    setRecords(result.data.length);
  }, [result]);
  return (
    <div className="table-responsive rearrangeColumn">
      {search ? (
        <Grid
          style={{ height: screenHeight }}
          data={filterData()}
          filterable={true}
          sortable={true}
          groupable={true}
          onDataStateChange={onDataStateChange}
          {...dataState}
          //pageable = {true}
          //total = {products.length}
          reorderable
          // resizable
        >
          <GridColumn
            field="id"
            headerCell={SelectRecordHeader}
            cell={SelectRecordRow}
            filterable={false}
            sortable={false}
            width="70px"
          />
          <GridColumn
            cell={titleHtml}
            field="title"
            title={t("Task Title")}
            width="250px"
          />
          <GridColumn
            field="projectname"
            title={t("Project Name")}
            width="200px"
          />
          <GridColumn
            field="taskstartdate"
            title={t("Start Date")}
            width="200px"
          />
          <GridColumn field="taskenddate" title={t("End Date")} width="200px" />
          <GridColumn field="hours" title={t("Hours")} width="200px" />
          <GridColumn
            field="loggedhours"
            title={t("Logged Hours")}
            width="200px"
          />
          <GridColumn
            field="taskpriority"
            title={t("Task Priority")}
            width="200px"
          />
          <GridColumn field="tagstring" title={t("Labels")} width="200px" />
          <GridColumn
            field="taskstatus"
            title={t("Task Status")}
            width="200px"
            cell={statusHtml}
          />
        </Grid>
      ) : (
        <Grid
          style={{ height: screenHeight }}
          data={result}
          filterable={true}
          sortable={true}
          groupable={true}
          onDataStateChange={onDataStateChange}
          {...dataState}
          //pageable = {true}
          //total = {products.length}
          reorderable
          // resizable
        >
          <GridColumn
            field="id"
            headerCell={SelectRecordHeader}
            cell={SelectRecordRow}
            filterable={false}
            sortable={false}
            width="70px"
          />
          <GridColumn
            cell={titleHtml}
            field="title"
            title={t("Task Title")}
            width="250px"
          />
          <GridColumn
            field="projectname"
            title={t("Project Name")}
            width="200px"
          />
          <GridColumn
            field="taskstartdate"
            title={t("Start Date")}
            width="200px"
          />
          <GridColumn field="taskenddate" title={t("End Date")} width="200px" />
          <GridColumn field="hours" title={t("Hours")} width="200px" />
          <GridColumn
            field="loggedhours"
            title={t("Logged Hours")}
            width="200px"
          />
          <GridColumn
            field="taskpriority"
            title={t("Task Priority")}
            width="200px"
          />
          <GridColumn field="tagstring" title={t("Labels")} width="200px" />
          <GridColumn
            field="taskstatus"
            title={t("Task Status")}
            width="200px"
            cell={statusHtml}
          />
        </Grid>
      )}
    </div>
  );
};
export default DailyTaskList;
