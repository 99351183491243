/* eslint-disable */
import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { protectedRoutes, publicRoutes } from "./Route";

const Authmiddleware = () => {
  const token = localStorage.getItem("token");

  return (
    <Switch>
      {/* ====== non authenticated routes ====== */}

      {!token &&
        publicRoutes.map((routename, index) => (
          <Route
            path={routename.path}
            component={routename.component}
            exact
            key={index}
          />
        ))}

      {/* ======end of  non authenticated routes ====== */}

      {/* ====== authenticated routes ====== */}

      {token &&
        protectedRoutes.map((routename, index) => (
          <Route
            path={routename.path}
            component={routename.component}
            exact
            key={index}
          />
        ))}

      {/* ====== end of authenticated routes ====== */}

      {token ? <Redirect to="/dashboard" /> : <Redirect to="/" />}
    </Switch>
  );
};

export default Authmiddleware;
