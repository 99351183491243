/* eslint-disable */
import { useState, useEffect, useContext, createRef } from "react";

// kendo react modules ==========================
import "@progress/kendo-theme-material/dist/all.css";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";
import { Link } from "react-router-dom";
// ES2015 module syntax
import {
  ExcelExport,
  ExcelExportColumn,
} from "@progress/kendo-react-excel-export";

import * as url from "urlhelpers/UrlHelper";
import { getData } from "utils/Gateway";

//import context
import { GlobalProvider } from "context/GlobalContext";

//import translation function for language translation
import { useTranslation } from "react-i18next";
import EditReserveTemplatePopup from "../Popup/EditReserveTemplatePopup";

const ReserveTemplateListBody = ({
  downloadExcel,
  search,
  setRecords,
  checkedRecordCount,
}) => {
  const {
    screenHeight,
    reloadReserveTemplateList,
    setReloadReserveTemplateList,
  } = useContext(GlobalProvider);

  const token = localStorage.getItem("token"); // token
  const { t } = useTranslation(); //for translation

  const [templateList, setTemplateList] = useState([]);
  const [responseReceived, setResponseReceived] = useState(false);
  const [reserveTemplateId, setReserveTemplateId] = useState(null);

  // without pagination ------------------------------------
  const initialDataState = {};
  const [dataState, setDataState] = useState();
  const [result, setResult] = useState(process(templateList, initialDataState));

  const onDataStateChange = (event) => {
    setDataState(event.dataState);
    setResult(process(templateList, event.dataState));
  };

  //function for get all reserve as select
  const getAllReserveTemplate = async () => {
    try {
      let requestUrl =
        url.API_BASEURL +
        url.API_GET_ALL_EVENT_TEMPLATE_LIST +
        `?token=${token}`;

      console.log("Get all reserve template url========>", requestUrl);

      const response = await getData(requestUrl);

      console.log("Get all reserve template response========>", response);

      if (response.status) {
        setTemplateList(response.data);
        setResponseReceived(true);
        dataState
          ? setResult(process(response.data, dataState))
          : setResult(process(response.data, initialDataState));
        setRecords(response.data.length);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  /* custom name cell html */
  const customNameHtml = (props) => {
    return (
      <td className="">
        <div className="d-flex align-items-center gap-2">
          <span>{props.dataItem.templatename} </span>
          <Link
            to="#editReservationTemplatePopup"
            data-bs-toggle="offcanvas"
            className="ml-auto edit_icon"
            onClick={() => {
              setReserveTemplateId(props.dataItem._id);
            }}
          >
            <span className="d-block material-symbols-outlined icon-md">
              edit
            </span>
          </Link>
        </div>
      </td>
    );
  };

  // check the selected count and show the count in top ------------------------
  const handelSelectedCount = () => {
    let checkboxes = document.querySelectorAll(
      'input[name="selectTemplateRecord"]'
    );

    let totalSelected = 0;
    let filteredData = [];
    checkboxes.forEach((checkbox) => {
      if (checkbox.checked) {
        totalSelected++;
        const filteredReserve = templateList.find(
          (rserve) => rserve._id === checkbox.value
        );
        filteredReserve && filteredData.push(filteredReserve);
      }
    });

    // Update the content of the block
    checkedRecordCount(totalSelected);
    return filteredData;
  };

  // mark all checkbox as selected ----------------
  const selectAllRecords = (e) => {
    document
      .querySelectorAll('.k-master-row input[name="selectTemplateRecord"]')
      .forEach((checkbox) => {
        checkbox.checked = e.target.checked;
      });
    handelSelectedCount();
  };

  // blocks to replace the Grid HTML ==============================================
  const SelectRecordHeader = () => {
    return (
      <div className="d-flex align-items-center">
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            id="selectAllMain"
            onClick={selectAllRecords}
          />
        </div>
      </div>
    );
  };

  /* Select single row */
  const SelectRecordRow = (props) => {
    return (
      <td>
        <div className="form-check">
          <input
            className="form-check-input "
            type="checkbox"
            value={props.dataItem._id}
            name="selectTemplateRecord"
            filtername="selectedrecord"
            onClick={handelSelectedCount}
          />
        </div>
      </td>
    );
  };

  useEffect(() => {
    if (reloadReserveTemplateList) {
      getAllReserveTemplate();
      setReloadReserveTemplateList(false);
    }
  }, [reloadReserveTemplateList]);

  const afterCloseEditPopup = () => {
    getAllReserveTemplate();
  };

  useEffect(() => {
    getAllReserveTemplate();
  }, []);

  /* set records based on onDataStateChange */
  useEffect(() => {
    setRecords(result.data.length);
  }, [result]);

  return (
    <div className="activity_table">
      <div className="table-responsive">
        <Grid
          style={{ height: screenHeight }}
          data={result}
          filterable={true}
          sortable={true}
          groupable={true}
          onDataStateChange={onDataStateChange}
          {...dataState}
          //pageable = {true}
          //total = {products.length}
          reorderable
          // resizable
        >
          <GridColumn
            field="id"
            headerCell={SelectRecordHeader}
            cell={SelectRecordRow}
            filterable={false}
            sortable={false}
            width="70px"
          />
          <GridColumn
            cell={customNameHtml}
            field="templatename"
            title={t("Template Name")}
            width="300px"
          />

          <GridColumn
            field="activitycode"
            title={t("Activity Code")}
            //width="200px"
          />
          <GridColumn
            field="activityname"
            title={t("Activity Name")}
            //width="200px"
          />

          <GridColumn
            field="activitytype"
            title={t("Activity type")}
            //width="200px"
          />
          <GridColumn
            field="reservedate"
            title={t("Reserve Date")}
            //width="200px"
          />
        </Grid>
      </div>

      {/* ----- edit template popup ---- */}
      <EditReserveTemplatePopup
        reserveTemplateId={reserveTemplateId}
        setReserveTemplateId={setReserveTemplateId}
        afterCloseEditPopup={afterCloseEditPopup}
      />
    </div>
  );
};

export default ReserveTemplateListBody;
