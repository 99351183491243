/* eslint-disable */

const ToggleTask = () => {
  return (
    <div className="collapse mt-3 " id="columnCollapse">
      <div className="task_info_row">
        <div className="dashboard-cards mb-4">
          <div className="row">
            <div className="col-sm-6 col-lg-3 mb-2 mb-sm-4 mb-lg-0">
              <div className="bg-light p-4 rounded-8">
                <h6>Started Working</h6>
                <p className="fs-lg fw-semibold">
                  11:36 <span className="text-gray">AM</span>
                </p>
              </div>
            </div>
            <div className="col-sm-6 col-lg-3 mb-2 mb-sm-4 mb-lg-0">
              <div className="bg-light p-4 rounded-8">
                <h6>Today's Task Count</h6>
                <p className="fs-lg fw-semibold">8</p>
              </div>
            </div>
            <div className="col-sm-6 col-lg-3 mb-2 mb-sm-0">
              <div className="bg-light p-4 rounded-8">
                <h6>Overdue Task Count</h6>
                <p className="fs-lg fw-semibold">4</p>
              </div>
            </div>
            <div className="col-sm-6 col-lg-3">
              <div className="bg-light p-4 rounded-8">
                <h6>Work Hours this Month</h6>
                <p className="fs-lg fw-semibold">
                  132 <span className="text-gray">Hours</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ToggleTask;
