/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";

//import translation function for language translation
import { useTranslation } from "react-i18next";

/*import url and gateway methods */
import { putData } from "utils/Gateway";
import * as url from "urlhelpers/UrlHelper";

//import context
import { GlobalProvider } from "context/GlobalContext";

const RolePriviledgeHeader = () => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const { setReloadRolePriviledgeList, setSuccess, setNotificationString } =
    useContext(GlobalProvider);

  const { t } = useTranslation(); //for translation

  return (
    <div className="filter-container d-flex flex-wrap align-items-center gap-2 border-bottom border-gray justify-content-end">
      {/* ----- refresh icon start ------ */}
      <Link
        to="#"
        className="filter-btn btn btn-primary icon-btn mw-unset "
        onClick={() => {
          setReloadRolePriviledgeList(true);
          setSuccess(true);
          setNotificationString(t("The list has been refreshed successfully"));
        }}
      >
        <span className="d-block material-symbols-outlined">refresh</span>
      </Link>
      {/* ----- refresh icon end ------ */}

      {/* -------- add new label popip button start -------- */}
      {userInfo.role.name == "admin" ? (
        <Link
          to="#addUpdateRolePrevPopup"
          data-bs-toggle="offcanvas"
          role="button"
          aria-controls="addLabelPopup"
          className="save-reservation btn btn-primary icon-btn mw-unset"
        >
          <span className="d-block material-symbols-outlined">add</span>
        </Link>
      ) : null}
      {/* -------- add new label popip button end -------- */}
    </div>
  );
};

export default RolePriviledgeHeader;
