/* eslint-disable */
import React, { useState, createRef, useEffect, useContext } from "react";
// kendo react modules ==========================
import "@progress/kendo-theme-material/dist/all.css";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";
// impoting excel colums
import {
  ExcelExport,
  ExcelExportColumn,
} from "@progress/kendo-react-excel-export";
//import translation function for language translation
import { useTranslation } from "react-i18next";
//import api requiremnts
import * as url from "urlhelpers/UrlHelper";
import { getData, postData } from "utils/Gateway";
import { Link } from "react-router-dom";
//import context
import { GlobalProvider } from "context/GlobalContext";
import { currencySymbol } from "Config/Config";
const SalesOrderList = ({
  downloadExcel,
  checkedRecordCount,
  search,
  setRecords,
}) => {
  const {
    setSalesOrderData,
    reloadSalesOrderList,
    setReloadSalesOrderList,
    showSalesOrderInvoiceIdColumn,
    showSalesOrderClientColumn,
    showSalesOrderInvoiceDateColumn,
    showSalesOrderBudgetStatusColumn,
    showSalesOrderDiscountColumn,
    showSalesOrderTotalAmountColumn,
    showSalesOrderCreatedDateColumn,
    showSalesOrderPayblePriceColumn,
    // ----- search requriement -----
    searchSalesOrderDate,
    setSearchSalesOrderDate,
    searchSalesOrderDateType,
    setSearchSalesOrderDateType,
    searchSalesOrderClients,
    setSearchSalesOrderClients,
    searchSalesOrderBudgetStatus,
    setSearchSalesOrderBudgetStatus,
    searchSalesOrderDiscount,
    setSearchSalesOrderDiscount,
    //notification
    setSuccess,
    setError,
    setNotificationString,
    screenHeight,
  } = useContext(GlobalProvider);
  const token = localStorage.getItem("token");
  const { t } = useTranslation(); //for translation
  const [salesOrderList, setSalesOrderList] = useState([]);
  const [salesOrderItemsData, setSalesOrderItemsData] = useState([]);
  const [responseReceived, setResponseReceived] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // without pagination ------------------------------------
  const initialDataState = {};
  const [dataState, setDataState] = useState();
  const [result, setResult] = useState(
    process(salesOrderList, initialDataState)
  );
  const onDataStateChange = (event) => {
    setDataState(event.dataState);
    setResult(process(salesOrderList, event.dataState));
  };
  /* Handle the button's onClick event and set it to export the data by using the ExcelExport save method. */
  const _exporter = createRef();
  const exportExcel = () => {
    if (_exporter.current) {
      _exporter.current.save();
    }
  };
  // mark all checkbox as selected ----------------
  const selectAllRecords = (e) => {
    document
      .querySelectorAll('.k-master-row input[name="selectSalesOrderRecord"]')
      .forEach((checkbox) => {
        checkbox.checked = e.target.checked;
      });
    handelSelectedCount();
  };
  // blocks to replace the Grid HTML ==============================================
  const SelectRecordHeader = () => {
    return (
      <div className="d-flex align-items-center">
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            id="selectAllMain"
            onClick={selectAllRecords}
          />
        </div>
      </div>
    );
  };
  /* Select single row */
  const SelectRecordRow = (props) => {
    return (
      <td>
        <div className="form-check">
          <input
            className="form-check-input "
            type="checkbox"
            value={props.dataItem._id}
            data-emailstring={
              props.dataItem.client ? props.dataItem.client.email : ""
            }
            name="selectSalesOrderRecord"
            filtername="selectedrecord"
            onClick={handelSelectedCount}
          />
        </div>
      </td>
    );
  };
  // check the selected count and show the count in top and also selected download xls ------------------------
  const handelSelectedCount = () => {
    let checkboxes = document.querySelectorAll(
      'input[name="selectSalesOrderRecord"]'
    );
    let totalSelected = 0;
    let filteredData = [];
    checkboxes.forEach((checkbox) => {
      if (checkbox.checked) {
        totalSelected++;
        const filteredUser = salesOrderList.find(
          (user) => user._id === checkbox.value
        );
        filteredUser && filteredData.push(filteredUser);
      }
    });
    // Update the content of the block
    checkedRecordCount(totalSelected);
    return filteredData;
  };
  // sync to billage platform
  const syncToBillage = async (salesOrderId) => {
    // console.log("salesOrderId", salesOrderId);
    let requestUrl =
      url.API_BASEURL +
      url.API_SYNC_BILLAGE +
      `/${salesOrderId}` +
      `?token=${token}`;
    const response = await getData(requestUrl);
    console.log(response);
    if (response.status) {
      setSuccess(true);
      setNotificationString(t("Invoice Sync Successfully"));
      setReloadSalesOrderList(true);
    } else {
      setError(true);
      setNotificationString(t("Something went wrong, please try again later"));
    }
  };
  //sales order id html
  const salesOrderBlockHtml = ({ dataItem }) => {
    return (
      <td>
        <div className="d-flex align-items-center">
          <p className="name mb-1">{dataItem.invoiceid}</p>
          <Link
            to="#saveSalesOrderPopup"
            data-bs-toggle="offcanvas"
            onClick={() => {
              setSalesOrderData(dataItem._id);
            }}
            className="float-end ms-2"
          >
            <span
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title={t("Edit Sales Order")}
              className="d-block material-symbols-outlined icon-md"
            >
              edit
            </span>
          </Link>
          <Link
            to={`/salesorder/invoice/${dataItem._id}`}
            className="float-end ms-2"
            target="_blank"
          >
            <span
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title={t("View in pdf")}
              className="d-block material-symbols-outlined icon-md"
            >
              picture_as_pdf
            </span>
          </Link>
          {dataItem.syncbillage ? (
            <Link to="#" className="float-end ms-2">
              <span
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title={t("Billage synced")}
                className="d-block material-symbols-outlined icon-md text-green"
              >
                credit_score
              </span>
            </Link>
          ) : (
            <>
              <button
                className=" bg-transparent p-0 border-0 ms-2"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title={t("Sync to Billage")}
                  className="d-block material-symbols-outlined text-red"
                >
                  payment
                </span>
              </button>
              <ul className="dropdown-menu border-0 shadow-lg ">
                <li>
                  <Link
                    to="#"
                    className="dropdown-item"
                    onClick={() => {
                      syncToBillage(dataItem._id);
                    }}
                  >
                    {t("Sync to Billage")}
                  </Link>
                </li>
              </ul>
            </>
          )}
        </div>
      </td>
    );
  };

  //totalAmountCell
  const totalAmountCell = ({ dataItem }) => {
    return (
      <td>
        <p>
          {dataItem.totalactivitycost} {currencySymbol}
        </p>
      </td>
    );
  };

  //payble cell
  const paybleAmountCell = ({ dataItem }) => {
    return (
      <td>
        <p>
          {dataItem.totalpaybleprice} {currencySymbol}
        </p>
      </td>
    );
  };

  //function for get all sales record
  const getAllSalesRecords = async () => {
    try {
      setIsLoading(true);
      let requestUrl =
        url.API_BASEURL +
        url.API_GET_ALL_SALES_ORDER +
        `?token=${token}&ordertypequery=2`;
      //search by date
      if (searchSalesOrderDate != "" && searchSalesOrderDateType != null) {
        requestUrl =
          requestUrl +
          `&salesdate=${searchSalesOrderDate}&salesdatetype=${searchSalesOrderDateType}`;
      }
      //search by client
      if (searchSalesOrderClients.length > 0) {
        requestUrl = requestUrl + `&salesclients=${searchSalesOrderClients}`;
      }
      //search by grouped
      if (searchSalesOrderBudgetStatus.length > 0) {
        requestUrl =
          requestUrl +
          `&salesorserbudgetstatus=${searchSalesOrderBudgetStatus}`;
      }
      //search by discount
      if (searchSalesOrderDiscount != 0) {
        requestUrl = requestUrl + `&salesdiscount=${searchSalesOrderDiscount}`;
      }
      console.log("url for sales order list------->", requestUrl);
      const response = await getData(requestUrl);
      console.log("response in sales order------->", response);
      if (response.status) {
        setIsLoading(false);
        setSalesOrderList(response.data);
        setResponseReceived(true);
        dataState
          ? setResult(process(response.data, dataState))
          : setResult(process(response.data, initialDataState));
        setRecords(response.data.length);
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  const getSalesOrderItemsData = () => {
    console.log("salesOrderList", salesOrderList.length);
    let salesOrderItemArray = [];
    if (salesOrderList.length > 0) {
      const setDownloadData = salesOrderList.map((salesOrder) => {
        if (salesOrder.salesorderitems.length > 0) {
          ///console.log('salesOrder.salesorderitems', salesOrder.salesorderitems);
          salesOrder.salesorderitems.map((orderItem) => {
            salesOrderItemArray.push(orderItem);
          });
        }
      });
      setSalesOrderItemsData(salesOrderItemArray);
      console.log("salesOrderItemsData", salesOrderItemsData);
    }
  };
  /* function to search */
  const filterData = () => {
    if (!result || !result.data) {
      return []; // Return an empty array if result or result.data is undefined
    }
    const filteredData = result.data.filter((item) => {
      const invoiceid = item?.invoiceid?.toString() || "";
      const clientfullname = item?.clientfullname?.toLowerCase() || "";
      const invoicedate = item?.invoicedate || "";
      const budgetstatus = item?.budgetstatus?.toLowerCase() || "";
      const totalactivitycost = item?.totalactivitycost?.toString() || "";
      const discountpercent = item?.discountpercent?.toString() || "";
      const totalpaybleprice = item?.totalpaybleprice?.toString() || "";
      const createddate = item?.createddate || "";
      return (
        (invoiceid && invoiceid.includes(search)) ||
        (clientfullname && clientfullname.includes(search.toLowerCase())) ||
        (invoicedate && invoicedate.includes(search)) ||
        (budgetstatus && budgetstatus.includes(search.toLowerCase())) ||
        (totalactivitycost && totalactivitycost.includes(search)) ||
        (discountpercent && discountpercent.includes(search)) ||
        (totalpaybleprice && totalpaybleprice.includes(search)) ||
        (createddate && createddate.includes(search))
      );
    });
    setRecords(filteredData.length);
    return filteredData;
  };
  //use effect for search
  useEffect(() => {
    if (
      (searchSalesOrderDate != "" && searchSalesOrderDateType != null) ||
      searchSalesOrderClients.length > 0 ||
      searchSalesOrderBudgetStatus.length > 0 ||
      searchSalesOrderDiscount != 0
    ) {
      getAllSalesRecords();
    }
  }, [
    searchSalesOrderDate,
    searchSalesOrderDateType,
    searchSalesOrderClients,
    searchSalesOrderBudgetStatus,
    searchSalesOrderDiscount,
  ]);
  useEffect(() => {
    if (responseReceived === false) {
      getAllSalesRecords();
    }
  }, [responseReceived]);
  useEffect(() => {
    if (reloadSalesOrderList) {
      console.log(
        "inside loading sales order list ----------------------------------"
      );
      getAllSalesRecords();
      // checkedRecordCount(0);
      setResult(process(salesOrderList, initialDataState));
      setReloadSalesOrderList(false);
    }
  }, [reloadSalesOrderList]);
  /* hook to hit  exportExcel() function*/
  useEffect(() => {
    downloadExcel && exportExcel();
  }, [downloadExcel]);
  /* hook to hit filterData function on search */
  useEffect(() => {
    filterData();
  }, [search]);
  /* set records based on onDataStateChange */
  useEffect(() => {
    setRecords(result.data.length);
  }, [result]);
  useEffect(() => {
    getSalesOrderItemsData();
  }, [salesOrderList]);
  return (
    <div className="table-responsive">
      {/* ============ download xls start ========== */}
      <ExcelExport
        data={
          salesOrderItemsData
          // handelSelectedCount().length > 0
          //   ? handelSelectedCount()
          //   : salesOrderList
        }
        fileName="Funbrain_Sales_Order.xlsx"
        ref={_exporter}
      >
        <ExcelExportColumn
          field="salesorderinvoicedate"
          title={t("Order_date")}
        />
        <ExcelExportColumn field="customer" title={t("Client_name")} />
        <ExcelExportColumn field="orderid" title={t("Order_Id")} />
        <ExcelExportColumn field="SERIE_ALIAS" title={t("SERIE_ALIAS")} />
        <ExcelExportColumn field="orderstatus" title={t("Order_Status")} />
        <ExcelExportColumn field="category" title={t("Category ")} />
        <ExcelExportColumn
          field="clientobservation"
          title={t("Client_Observation")}
        />
        <ExcelExportColumn
          field="internalobservation"
          title={t("Internal_Observation")}
        />
        <ExcelExportColumn field="paymentform" title={t("Payment_Form")} />
        <ExcelExportColumn field="paymentterms" title={t("Payment_Terms")} />
        <ExcelExportColumn field="activityname" title={t("Product")} />
        <ExcelExportColumn
          field="conceptdescription"
          title={t("Concept_Description")}
        />
        <ExcelExportColumn
          field="conceptobservation"
          title={t("Concept_Observation")}
        />
        <ExcelExportColumn field="unit" title={t("Units")} />
        <ExcelExportColumn field="activitycost" title={t("Unit_Price")} />
        <ExcelExportColumn field="retention" title={t("Percent_Retention")} />
        <ExcelExportColumn field="taxpercent" title={t("Percent_Tax")} />
        <ExcelExportColumn field="discountpercent" title={t("Discount ")} />
        <ExcelExportColumn field="salesorderduedate" title={t("Due_Date")} />
        <ExcelExportColumn field="totalprice" title={t("Total_Price")} />
      </ExcelExport>
      {/* ============ download xls end ========== */}
      {/* ================= grid view starts ====================== */}
      {search ? (
        <Grid
          style={{ height: screenHeight }}
          data={filterData()}
          filterable={true}
          sortable={true}
          groupable={true}
          onDataStateChange={onDataStateChange}
          {...dataState}
          //pageable = {true}
          //total = {products.length}
          reorderable
          // resizable
        >
          <GridColumn
            field="id"
            headerCell={SelectRecordHeader}
            cell={SelectRecordRow}
            filterable={false}
            sortable={false}
            width="70px"
          />
          {showSalesOrderInvoiceIdColumn && (
            <GridColumn
              field="invoiceid"
              title={t("Order Id")}
              cell={salesOrderBlockHtml}
              width="200px"
            />
          )}
          {showSalesOrderClientColumn && (
            <GridColumn
              field="clientfullname"
              title={t("Client")}
              width="400px"
            />
          )}
          {showSalesOrderInvoiceDateColumn && (
            <GridColumn
              field="salesorderinvoicedate"
              title={t("Order Date")}
              width="200px"
            />
          )}
          <GridColumn
            field="budgetinvoiceid"
            title={t("Budget")}
            width="200px"
          />
          {showSalesOrderBudgetStatusColumn && (
            <GridColumn
              field="budgetstatus"
              title={t("Budget Status")}
              width="200px"
            />
          )}
          {showSalesOrderTotalAmountColumn && (
            <GridColumn
              field="totalactivitycost"
              title={t("Total Amount")}
              width="200px"
              cell={totalAmountCell}
            />
          )}
          {showSalesOrderDiscountColumn && (
            <GridColumn
              field="discountpercent"
              title={t("Discount") + ` %`}
              width="200px"
            />
          )}
          {showSalesOrderPayblePriceColumn && (
            <GridColumn
              field="totalpaybleprice"
              title={t("Payable Amount")}
              width="200px"
              cell={paybleAmountCell}
            />
          )}
          {showSalesOrderCreatedDateColumn && (
            <GridColumn
              field="salesordercreateddate"
              title={t("Created Date")}
              width="200px"
            />
          )}
        </Grid>
      ) : (
        <Grid
          data={isLoading ? null : result}
          style={{ height: screenHeight }}
          filterable={true}
          sortable={true}
          groupable={true}
          onDataStateChange={onDataStateChange}
          {...dataState}
          //pageable = {true}
          //total = {products.length}
          reorderable
          // resizable
        >
          <GridColumn
            field="id"
            headerCell={SelectRecordHeader}
            cell={SelectRecordRow}
            filterable={false}
            sortable={false}
            width="70px"
          />
          {showSalesOrderInvoiceIdColumn && (
            <GridColumn
              field="invoiceid"
              title={t("Order Id")}
              cell={salesOrderBlockHtml}
              width="200px"
            />
          )}
          {showSalesOrderClientColumn && (
            <GridColumn
              field="clientfullname"
              title={t("Client")}
              width="400px"
            />
          )}
          {showSalesOrderInvoiceDateColumn && (
            <GridColumn
              field="salesorderinvoicedate"
              title={t("Order Date")}
              width="200px"
            />
          )}
          <GridColumn
            field="budgetinvoiceid"
            title={t("Budget")}
            width="200px"
          />
          {showSalesOrderBudgetStatusColumn && (
            <GridColumn
              field="budgetstatus"
              title={t("Budget Status")}
              width="200px"
            />
          )}
          {showSalesOrderTotalAmountColumn && (
            <GridColumn
              field="totalactivitycost"
              title={t("Total Amount")}
              width="200px"
              cell={totalAmountCell}
            />
          )}
          {showSalesOrderDiscountColumn && (
            <GridColumn
              field="discountpercent"
              title={t("Discount") + ` %`}
              width="200px"
            />
          )}
          {showSalesOrderPayblePriceColumn && (
            <GridColumn
              field="totalpaybleprice"
              title={t("Payable Amount")}
              width="200px"
              cell={paybleAmountCell}
            />
          )}
          {showSalesOrderCreatedDateColumn && (
            <GridColumn
              field="salesordercreateddate"
              title={t("Created Date")}
              width="200px"
            />
          )}
        </Grid>
      )}
      {/* ================= grid view ends ====================== */}
    </div>
  );
};
export default SalesOrderList;
