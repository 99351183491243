import React from 'react';
import Authmiddleware from 'routes/AuthMiddleWare';
import GlobalContext from 'context/GlobalContext';

const App = () => {
  return (
    <GlobalContext>
      <Authmiddleware />
    </GlobalContext>
  );
};

export default App;
