import React from 'react';

//import translation function for language translation
import { useTranslation } from 'react-i18next';

const AddSpecialNeedsPopup = () => {
  const { t } = useTranslation(); //for translation
  return (
    <div
      className="offcanvas offcanvas-end border-0"
      tabIndex="-1"
      id="addSpecialNeedsPopup"
      aria-labelledby="addSpecialNeedsPopupLabel"
    >
      <div className="offcanvas-header p-4">
        <h3 className="text-secondary mb-0" id="addSpecialNeedsPopupLabel">
          {t('Add Special Needs')}
        </h3>
        <button
          type="button"
          id="closeCreateMail"
          className="btn-close p-2 bg-white shadow-none shadow-sm m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div className="offcanvas-body p-4 pt-0">
        <form action="">
          <div className="form-group mb-4">
            <label htmlFor="name">{t('Name')}</label>
            <input
              type="text"
              id="name"
              placeholder={t('Enter Here')}
              className="form-control"
            />
          </div>
          <div className="form-group mb-4">
            <label htmlFor="comments">{t('Comments')}</label>
            <textarea
              name="comments"
              id="comments"
              cols="30"
              rows="5"
              className="form-control"
              placeholder={t('Enter Here')}
            ></textarea>
          </div>
          <div className="action">
            <button type="submit" className="btn btn-primary mw-unset">
              {t('Save')}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddSpecialNeedsPopup;
