import { assetImages } from "constants";
import React from "react";

//import translation function for language translation
import { useTranslation } from "react-i18next";

const SurveyFeedbackThanksModal = ({ closeModalHandler }) => {

  const { t } = useTranslation(); //for translation

  return (
    <div
      className="modal fade"
      id="submitSurveyFeedback"
      tabIndex="-1"
      aria-labelledby="submitSurveyLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content border-0 rounded-0">
          <div className="modal-header border-0">
            <button
              type="button"
              className="btn-close p-2 bg-white shadow-none shadow-sm m-0 ms-auto"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={closeModalHandler}
            ></button>
          </div>
          <div className="modal-body text-center p-5 pt-0">
            <img
              src={assetImages.submitSurvey}
              alt="Survey Submitted Successfully!"
              className="img-fluid"
            />
            <h3> {t("Thanks")}</h3>
            <p>{t("Your response submitted successfully")}</p>
            <button
              data-bs-dismiss="modal"
              aria-label="Close"
              className="mt-3 btn btn-primary"
              onClick={closeModalHandler}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SurveyFeedbackThanksModal;
