/* eslint-disable */
import React, { useEffect, useContext, useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
// Import CKEditor here (both required).
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
//import translation function for language translation
import { useTranslation } from "react-i18next";
/*import url and gateway methods */
import { postData, putData, uploadMultipleFile, getData } from "utils/Gateway";
import * as url from "urlhelpers/UrlHelper";
//import context
import { GlobalProvider } from "context/GlobalContext";
import downloadFileHandler from "helpers/DownloadFile";
import TaskStatusOptions from "data/Prod/TaskStatus.json";
import TaskPriority from "data/Prod/TaskPriority.json";
const SaveTaskPopup = () => {
  const todayValue = new Date().toISOString().split("T")[0];
  const token = localStorage.getItem("token"); // token
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const { t } = useTranslation(); //for translation
  /*---- CKEditor classic editor configuration start ----- */
  const editorConfig = {
    toolbar: ["bold", "italic", "link", "bulletedList", "numberedList"],
    ckfinder: {
      // Upload the images to the server using the CKFinder QuickUpload command.
      //   uploadUrl: url.URL_TEXT_EDITOR_IMAGE_UPLOAD_2,
    },
    placeholder: "Type description here...",
  };
  /*---- CKEditor classic editor configuration end ----- */
  const {
    setreloadTaskList,
    editTaskId,
    seteditTaskId,
    setSuccess,
    setError,
    setNotificationString,
  } = useContext(GlobalProvider);
  const [projectList, setProjectList] = useState([]);
  const [userName, setUserName] = useState("");
  const [userList, setUserList] = useState([]);
  const [projectValue, setprojectValue] = useState(null);
  const [projectId, setprojectId] = useState(null);
  const [userValue, setUserValue] = useState(null);
  const [title, settitle] = useState("");
  const [details, setdetails] = useState("");
  const [startDate, setstartDate] = useState(todayValue);
  const [endDate, setendDate] = useState(todayValue);
  const [hours, sethours] = useState("");
  const [price, setPrice] = useState("");
  const [priorityValue, setpriorityValue] = useState(null);
  const [priority, setpriority] = useState("");
  const [taskStatusValue, settaskStatusValue] = useState(null);
  const [taskStatus, settaskStatus] = useState("");
  const [docFileUploading, setDocFileUploading] = useState(false);
  const [uploadedDocFiles, setUploadedDocFiles] = useState([]);
  const [uploadedDocFileIds, setUploadedDocFileIds] = useState([]);
  const [isSending, setIsSending] = useState(false);
  const [isRequiredFieldFillPending, setIsRequiredFieldFillPending] =
    useState(false);
  /* hook for validation */
  const [validation, setValidation] = useState({
    titleWarning: false,
    userWarning: false,
    detailsWarning: false,
    dateWarning: false,
    projectWarning: false,
    timeWarning: false,
    priceWarning: false,
  });
  /* function to validate form */
  const validate = () => {
    let isValid = true;
    if (title === "") {
      setValidation((prevState) => ({ ...prevState, titleWarning: true }));
      isValid = false;
    }
    if (projectId === null) {
      setValidation((prevState) => ({ ...prevState, projectWarning: true }));
      isValid = false;
    }
    if (userValue === null) {
      setValidation((prevState) => ({ ...prevState, userWarning: true }));
      isValid = false;
    }
    if (details === "") {
      setValidation((prevState) => ({ ...prevState, detailsWarning: true }));
      isValid = false;
    }
    if (
      startDate === "" ||
      endDate == "" ||
      startDate === endDate ||
      endDate < startDate
    ) {
      setValidation((prevState) => ({ ...prevState, dateWarning: true }));
      isValid = false;
    }
    // if (hours == "") {
    //   setValidation((prevState) => ({ ...prevState, timeWarning: true }));
    //   isValid = false;
    // }
    // if (price == "") {
    //   setValidation((prevState) => ({ ...prevState, priceWarning: true }));
    //   isValid = false;
    // }
    return isValid;
  };
  /* function to get all label data */
  const getAllProjectList = async () => {
    try {
      let requestUrl =
        url.API_BASEURL + url.API_GET_ALL_PROJECTS + `?token=${token}`;
      // console.log("project list url =====>>>", requestUrl);
      const response = await getData(requestUrl);
      // console.log("project list response ========>>>", response);
      if (response.data) {
        setProjectList(
          response.data.map((val) => ({
            label: val.title,
            value: val._id,
            startdate: val.startdate,
            enddate: val.enddate,
          }))
        );
      }
    } catch (error) {
      console.error(error.message);
    }
  };
  //function for get all user list
  const getAllUserList = async () => {
    try {
      let requestUrl =
        url.API_BASEURL +
        url.API_GET_ALL_USER +
        `?token=${token}&isselect=${true}&username=${userName}`;
      const response = await getData(requestUrl);
      console.log(response);
      if (response.status) {
        setUserList(response.data);
      }
    } catch (error) {
      console.error(error.message);
    }
  };
  //function for upload doc files
  const docFileUploadHandler = async (e) => {
    try {
      setDocFileUploading(true);
      const docFiles = e.target.files;
      let requestUrl =
        url.API_BASEURL + url.API_FILE_UPLOAD_MULTIPLE + `?token=${token}`;
      const response = await uploadMultipleFile(requestUrl, docFiles);
      if (response.status) {
        /*----- assign uploaded files with path for display start ------*/
        const uploadedFileValues = response.data.map(
          ({ name, path, _id, ...other }) => ({
            name,
            path: url.SERVER_URL + path,
            _id,
          })
        );
        //check old file exist or not if exist then new file will concat with old file
        if (uploadedDocFiles.length == 0) {
          setUploadedDocFiles(uploadedFileValues);
        } else {
          let fileArr = uploadedDocFiles.concat(uploadedFileValues);
          setUploadedDocFiles(fileArr);
        }
        /*----- assign uploaded files with path for display end ------*/
        setDocFileUploading(false);
        /*----- assign uploaded file ids start ------*/
        const fileIds = response.data.map(({ _id, ...other }) => ({ _id }));
        //check old file ids are exist or not.If exist then new id will concat with old id
        if (uploadedDocFileIds.length == 0) {
          setUploadedDocFileIds(fileIds);
        } else {
          let fileArr = uploadedDocFileIds.concat(fileIds);
          setUploadedDocFileIds(fileArr);
        }
        /*----- assign uploaded file ids end ------*/
      }
      resetDocInput();
    } catch (error) {
      console.log(error.message);
    }
  };
  //function for delete file from upload stack
  const closeDocFileHandler = (index) => {
    const closedFile = [...uploadedDocFiles];
    const removedFileId = [...uploadedDocFileIds];
    closedFile.splice(index, 1);
    removedFileId.splice(index, 1);
    setUploadedDocFiles(closedFile);
    setUploadedDocFileIds(removedFileId);
    resetDocInput();
  };
  //function for reset doc inputs
  const resetDocInput = () => {
    const file = document.getElementById("uploadedTaskDoc");
    if (file) {
      file.value = null;
    } else {
      return;
    }
  };
  //select project
  const projectSelectionHandler = (val) => {
    if (val) {
      setprojectValue(val);
      setprojectId(val.value);
      setstartDate(val.startdate);
      setendDate(val.enddate);
      setValidation((prevState) => ({
        ...prevState,
        projectWarning: false,
      }));
    } else {
      setprojectValue(null);
      setprojectId(null);
    }
  };
  //function for reciever selection
  const userSelectionHandler = (val) => {
    if (val) {
      setUserValue(val);
      setValidation((prevState) => ({
        ...prevState,
        userWarning: false,
      }));
    } else {
      setUserValue(null);
    }
  };
  //select status
  const statusSelectionHandler = (val) => {
    settaskStatusValue(val);
    settaskStatus(val.value);
  };
  //select priority
  const prioritySelectionHandler = (val) => {
    setpriorityValue(val);
    setpriority(val.value);
  };
  //function for get project details
  const getTaskDetails = async () => {
    try {
      let requestUrl =
        url.API_BASEURL +
        url.API_GET_TASK_DETAILS +
        `/${editTaskId}?token=${token}`;
      const response = await getData(requestUrl);
      // console.log(response);
      if (response.status && response.data) {
        userSelectionHandler(response.data.assigneduserdata);
        projectSelectionHandler(response.data.projectdata);
        settitle(response.data.title);
        setdetails(response.data.description);
        TaskStatusOptions.map((statusData) => {
          if (statusData.value == response.data.completedpercent) {
            statusSelectionHandler(statusData);
          }
        });
        sethours(response.data.hours);
        setPrice(response.data.price);
        setstartDate(response.data.startdate);
        setendDate(response.data.enddate);
        TaskPriority.map((priorityData) => {
          if (priorityData.value === response.data.priority) {
            prioritySelectionHandler(priorityData);
          }
        });
        //assign uploaded files
        setUploadedDocFiles(
          response?.data?.uploads.map(({ name, path, _id, ...other }) => ({
            name,
            path: url.SERVER_URL + path,
            _id,
          }))
        );
        setUploadedDocFileIds(response?.data?.uploadedfileids);
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  /* function to add label */
  const saveTaskHandler = async (e) => {
    e.preventDefault();
    if (validate()) {
      setIsRequiredFieldFillPending(false);
      setIsSending(true);
      try {
        let taskData = {
          assigneduser: userValue.value,
          project: projectId,
          hours,
          price,
          priority,
          completedpercent: taskStatus,
          title,
          description: details,
          startdate: startDate,
          enddate: endDate,
          uploads: uploadedDocFileIds,
        };
        let requestUrl = url.API_BASEURL;
        let response = {};
        if (editTaskId) {
          requestUrl =
            requestUrl +
            url.API_UPDATE_PROJECT_TASK +
            `/${editTaskId}?token=${token}`;
          response = await putData(requestUrl, taskData);
        } else {
          requestUrl =
            requestUrl + url.API_ADD_NEW_PROJECT_TASK + `?token=${token}`;
          response = await postData(requestUrl, taskData);
        }
        //   console.log(response);
        setIsSending(false);
        if (response.status) {
          resetHandler();
          setreloadTaskList(true);
          setSuccess(true);
          setNotificationString(t("Task added successfully"));
          bootstrap.Offcanvas.getInstance(
            document.querySelector("#add_task")
          ).hide();
        }
      } catch (error) {
        console.error(error.message);
        setError(true);
        setNotificationString(
          t("Something went wrong, please try again later")
        );
      }
    } else {
      setIsRequiredFieldFillPending(true);
    }
  };
  /* reset */
  const resetHandler = () => {
    settitle("");
    setdetails("");
    setUserName("");
    setUserList([]);
    setprojectValue(null);
    setprojectId(null);
    setUserValue(null);
    setstartDate(todayValue);
    setendDate(todayValue);
    sethours("");
    setPrice("");
    setUploadedDocFiles([]);
    setUploadedDocFileIds([]);
    seteditTaskId(null);
  };
  useEffect(() => {
    if (editTaskId) {
      getTaskDetails();
    }
  }, [editTaskId]);
  //use effect for search receiver
  useEffect(() => {
    if (userName.length >= 3) {
      getAllUserList();
    }
  }, [userName]);
  useEffect(() => {
    getAllProjectList();
    TaskStatusOptions.map((statusData) => {
      if (statusData.value == "0") {
        statusSelectionHandler(statusData);
      }
    });
    TaskPriority.map((priorityData) => {
      if (priorityData.value === "2") {
        prioritySelectionHandler(priorityData);
      }
    });
  }, []);
  return (
    <div className="offcanvas offcanvas-end border-0" id="add_task">
      <div className="offcanvas-header position-relative align-items-start p-4">
        <h3 className="text-secondary mb-0" id="saveReservationPopupLabel">
          <span className="d-block">{t("Save Task")}</span>
        </h3>
        <button
          type="button"
          className="btn-close p-2 bg-white shadow-none shadow-sm m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={resetHandler}
        ></button>
      </div>
      <div className="offcanvas-body position-relative p-4 pt-0">
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="field-container flex-fill">
            {/* project select */}
            <div className="form-group mb-4">
              <label htmlFor="project" className="d-block fw-medium mb-2">
                {t("Project")}
              </label>
              <Select
                isClearable
                placeholder={t("Select project")}
                options={projectList}
                value={projectValue}
                onChange={(val) => projectSelectionHandler(val)}
              />
              {validation.projectWarning && (
                <div className="error-message mt-2">
                  <p className="d-flex align-items-center gap-1 text-danger">
                    <span className="material-symbols-outlined">warning</span>
                    <span>{t("Please select project")}!</span>
                  </p>
                </div>
              )}
            </div>
            {/* assigned user */}
            <div className="form-group mb-4">
              <label htmlFor="assignTo" className="d-block fw-medium mb-2">
                {t("Assign To")}
              </label>
              <Select
                isClearable
                placeholder={t("Search by Name")}
                options={userList}
                value={userValue}
                onChange={(val) => {
                  userSelectionHandler(val);
                }}
                onInputChange={(val) => {
                  setUserName(val);
                }}
              />
              {validation.userWarning && (
                <div className="error-message mt-2">
                  <p className="d-flex align-items-center gap-1 text-danger">
                    <span className="material-symbols-outlined">warning</span>
                    <span>{t("Please select user")}!</span>
                  </p>
                </div>
              )}
            </div>
            {/* title */}
            <div className="form-group mb-4">
              <label htmlFor="taskDetails" className="d-block fw-medium mb-2">
                {t("Task Title")}
              </label>
              <input
                className="form-control"
                placeholder={t("Enter task title")}
                value={title}
                onChange={(e) => {
                  setValidation((prevState) => ({
                    ...prevState,
                    titleWarning: false,
                  }));
                  settitle(e.target.value);
                }}
              />
              {validation.titleWarning && (
                <div className="error-message mt-2">
                  <p className="d-flex align-items-center gap-1 text-danger">
                    <span className="material-symbols-outlined">warning</span>
                    <span>{t("Please enter name")}!</span>
                  </p>
                </div>
              )}
            </div>
            {/* details */}
            <div className="form-group mb-4">
              <label htmlFor="taskDetails" className="d-block fw-medium mb-2">
                {t("Task Details")}
              </label>
              <CKEditor
                className="form-control"
                editor={ClassicEditor}
                config={editorConfig}
                data={details}
                onReady={(editor) => {
                  editor.editing.view.change((writer) => {
                    writer.setStyle(
                      "height",
                      "150px",
                      editor.editing.view.document.getRoot()
                    );
                  });
                }}
                onChange={(event, editor) => {
                  let editorContent = editor.getData();
                  setdetails(editorContent);
                  setValidation((prevState) => ({
                    ...prevState,
                    detailsWarning: false,
                  }));
                }}
              />
              {validation.detailsWarning && (
                <div className="error-message mt-2">
                  <p className="d-flex align-items-center gap-1 text-danger">
                    <span className="material-symbols-outlined">warning</span>
                    <span>{t("Please enter details")}!</span>
                  </p>
                </div>
              )}
            </div>
            {/* date and time */}
            <div className="form-group mb-4">
              <div className="row">
                <div className="col-6">
                  <label htmlFor="date" className="d-block fw-medium mb-2">
                    {t("Start Date")}
                  </label>
                  <input
                    type="date"
                    id="date"
                    className="form-control"
                    value={startDate}
                    onChange={(e) => {
                      setstartDate(e.target.value);
                      setValidation((prevState) => ({
                        ...prevState,
                        dateWarning: false,
                      }));
                    }}
                  />
                </div>
                <div className="col-6">
                  <label
                    htmlFor="timeConsumed"
                    className="d-block fw-medium mb-2"
                  >
                    {t("End Date")}
                  </label>
                  <input
                    type="date"
                    id="date"
                    className="form-control"
                    value={endDate}
                    onChange={(e) => {
                      setendDate(e.target.value);
                      setValidation((prevState) => ({
                        ...prevState,
                        dateWarning: false,
                      }));
                    }}
                  />
                </div>
              </div>
              {validation.dateWarning && (
                <div className="error-message mt-2">
                  <p className="d-flex align-items-center gap-1 text-danger">
                    <span className="material-symbols-outlined">warning</span>
                    <span>
                      {t("Please enter valid start date and end date")}!
                    </span>
                  </p>
                </div>
              )}
            </div>
            {/* hours and price */}
            <div className="form-group mb-4">
              <div className="row">
                <div className="col-6">
                  <label htmlFor="date" className="d-block fw-medium mb-2">
                    {`${t("Time")} (${t("Hours")})`}
                  </label>
                  <input
                    type="number"
                    id="time"
                    placeholder={t("Hours")}
                    className="form-control"
                    value={hours}
                    onChange={(e) => {
                      sethours(e.target.value);
                      setValidation((prevState) => ({
                        ...prevState,
                        timeWarning: false,
                      }));
                    }}
                  />
                  {validation.timeWarning && (
                    <div className="error-message mt-2">
                      <p className="d-flex align-items-center gap-1 text-danger">
                        <span className="material-symbols-outlined">
                          warning
                        </span>
                        <span>{t("Please enter hours")}!</span>
                      </p>
                    </div>
                  )}
                </div>
                <div className="col-6">
                  <label
                    htmlFor="timeConsumed"
                    className="d-block fw-medium mb-2"
                  >
                    {t("Price")}
                  </label>
                  <input
                    type="number"
                    id="price"
                    className="form-control"
                    placeholder={t("Enter price")}
                    value={price}
                    onChange={(e) => {
                      setPrice(e.target.value);
                      setValidation((prevState) => ({
                        ...prevState,
                        priceWarning: false,
                      }));
                    }}
                  />
                  {validation.priceWarning && (
                    <div className="error-message mt-2">
                      <p className="d-flex align-items-center gap-1 text-danger">
                        <span className="material-symbols-outlined">
                          warning
                        </span>
                        <span>{t("Please enter price")}!</span>
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {/* task status */}
            <div className="form-group mb-4">
              <div className="row">
                <div className="col-6">
                  <label
                    htmlFor="statustask"
                    className="d-block fw-medium mb-2"
                  >
                    {t("Complete Status")}
                  </label>
                  <Select
                    placeholder={t("Select task status")}
                    options={TaskStatusOptions}
                    value={taskStatusValue}
                    onChange={(val) => {
                      statusSelectionHandler(val);
                    }}
                  />
                </div>
                <div className="col-6">
                  <label
                    htmlFor="timeConsumed"
                    className="d-block fw-medium mb-2"
                  >
                    {t("Priority")}
                  </label>
                  <Select
                    placeholder="Select Priority"
                    options={TaskPriority}
                    value={priorityValue}
                    onChange={(val) => {
                      prioritySelectionHandler(val);
                    }}
                  />
                </div>
              </div>
            </div>
            {/* uploads */}
            <div className="form-group mb-4">
              {docFileUploading ? (
                <div className="d-flex align-items-center gap-1 mb-1">
                  <p className="fw-bold">{t("Please wait while uploading")}</p>{" "}
                  {[1, 2, 3, 4, 5, 6, 7, 8].map((count, index) => {
                    return (
                      <div
                        key={index}
                        className="spinner-grow spinner-grow-sm"
                        role="status"
                        style={{ height: "6px", width: "6px" }}
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <label>{t("Upload Documents")}</label>
              )}
              <input
                type="file"
                className="form-control"
                id="uploadedTaskDoc"
                multiple
                onChange={docFileUploadHandler}
              />
              {uploadedDocFiles.length > 0 && (
                <ul className="uploaded-files d-flex flex-wrap gap-3 p-0 m-0 mt-3">
                  {uploadedDocFiles.map((file, index) => {
                    return (
                      <li
                        className="file d-flex gap-3 align-items-center px-3 py-2 border border-gray rounded-3"
                        key={index}
                      >
                        <Link to="#" onClick={() => downloadFileHandler(file)}>
                          <span className="d-block material-symbols-outlined">
                            download
                          </span>
                        </Link>
                        <p className="fw-medium">{file.name}</p>
                        <Link to="#" onClick={() => closeDocFileHandler(index)}>
                          <span className="d-block material-symbols-outlined">
                            close
                          </span>
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              )}
            </div>
          </div>
          {/* button */}
          <div className="action d-flex gap-3 align-items-center">
            <button
              type="button"
              className="btn btn-primary"
              onClick={saveTaskHandler}
              disabled={isSending ? true : false}
              style={{ cursor: isSending ? "not-allowed" : "pointer" }}
            >
              {t("Save")}
              {isSending && (
                <div
                  className="mx-2 spinner-border spinner-border-sm"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              )}
            </button>
            {/* <div className="form-check p-0 d-flex align-items-center gap-2 m-0 cursor-pointer">
              <label
                className="form-check-label d-block text-gray cursor-pointer mb-0"
                htmlFor="task"
              >
                {t("Task Completed")}
              </label>
              <input
                className="form-check-input"
                type="checkbox"
                //     value=""
                name="reservation"
                id="task"
              />
            </div> */}
            {isRequiredFieldFillPending && (
              <p className="d-flex align-items-center gap-1 text-danger">
                <span className="material-symbols-outlined">warning</span>
                <span>{t("Please fill the required input fields")}!!!</span>
              </p>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};
export default SaveTaskPopup;
