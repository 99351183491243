/* eslint-disable */
import React, { useState, useEffect, useContext, useRef } from "react";
import ReactDOM from "react-dom";
import MailTemplateHeader from "components/MailTemplateComponents/Header/MailTemplateHeader";
import MailTemplateFilter from "components/MailTemplateComponents/Filter/MailTemplateFilter";
import ToggleColumns from "components/MailTemplateComponents/ToggleColumns/ToggleColumns";
import LeftMenu from "components/common/LeftMenu";
import UserMenu from "components/common/UserMenu";
import CreateMailTemplatePopup from "components/MailTemplateComponents/Popup/CreateMailTemplatePopup";
import MailTemplateList from "components/MailTemplateComponents/MailTemplateList";
import MailTemplateBreadCrumb from "components/MailTemplateComponents/BreadCrumb/MailTemplateBreadCrumb";
import AddLabel from "components/common/AddLabel";
import CustomNotification from "components/common/CustomNotification";

const MailTemplate = () => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  /* useState hook to download excel file */
  const [downloadExcel, setDownloadExcel] = useState(false);

  /* hook to search */
  const [search, setSearch] = useState("");

  /* hook to get number of records */
  const [records, setRecords] = useState(0);

  const blockRefSelected = useRef(null); // this will be used to acccess dom element directly

  /* function for checking record count */
  const checkedRecordCount = (selectCount) => {
    console.log("selectCount", selectCount);
    const blockElementSelect = ReactDOM.findDOMNode(blockRefSelected.current);
    if (blockElementSelect) {
      blockElementSelect.innerHTML = selectCount;
    }
  };

  useEffect(() => {
    document.title = " Mail Template"; // Update the title when Register component mounts
  }, []);

  return (
    <>
      {/* ------------------- menu section start ------------------ */}
      <UserMenu />
      <LeftMenu />
      {/* ------------------- menu section end ------------------ */}

      {userInfo?.role?.name == "admin" || userInfo?.role?.name == "manager" ? (
        <section className="mail-wrapper py-1">
          <div className="container-fluid px-4">
            {/*--------------------- bread crumb start ----------------------- */}
            <MailTemplateBreadCrumb />
            {/*--------------------- bread crumb end ----------------------- */}
            {/* ------------------- header seaction start --------------------- */}
            <MailTemplateHeader
              setDownloadExcel={setDownloadExcel}
              setSearch={setSearch}
              records={records}
              blockRefSelected={blockRefSelected}
            />
            {/* ------------------- header seaction end --------------------- */}
            {/* -------------------- select columns start ----------------------- */}
            <ToggleColumns />
            {/* -------------------- select columns end ----------------------- */}
            {/* --------------- filter start -------------------- */}
            <MailTemplateFilter />
            {/* --------------- filter end -------------------- */}
            {/* ------------------- list area start -------------------- */}
            <MailTemplateList
              downloadExcel={downloadExcel}
              search={search}
              setRecords={setRecords}
              checkedRecordCount={checkedRecordCount}
            />
            {/* ------------------- list area end -------------------- */}
          </div>
        </section>
      ) : null}
      {/* ---------------- popup section start --------------------- */}
      <CreateMailTemplatePopup />
      {/* ---------------- popup section end --------------------- */}
      {/* ------ add label ------ */}
      <AddLabel module={"mailtemplate"} />
      {/*----------- custom notification component start -----------*/}
      <CustomNotification />
      {/*----------- custom notification component end -----------*/}
    </>
  );
};

export default MailTemplate;
