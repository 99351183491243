import React from 'react';
import { Link } from 'react-router-dom';
//import translation function for language translation
import { useTranslation } from 'react-i18next';
const AddGroupPopupHeader = () => {
  const { t } = useTranslation(); //for translation
  return (
    <ul className="nav nav-tabs gap-2 border-0 mb-4" id="myTab" role="tablist">
      <li className="nav-item" role="presentation">
        <Link
          to="#"
          className="btn btn-outline-primary active"
          id="group-name-tab"
          data-bs-toggle="tab"
          data-bs-target="#group-name-tab-pane"
          type="button"
          role="tab"
          aria-controls="group-name-tab-pane"
          aria-selected="true"
        >
          {t('Group Name')}
        </Link>
      </li>
      <li className="nav-item" role="presentation">
        <Link
          to="#"
          className="btn btn-outline-primary"
          id="group-member-tab"
          data-bs-toggle="tab"
          data-bs-target="#group-member-tab-pane"
          type="button"
          role="tab"
          aria-controls="group-member-tab-pane"
          aria-selected="false"
        >
          {t('Group Members')}
        </Link>
      </li>
      <li className="nav-item" role="presentation">
        <Link
          to="#"
          className="btn btn-outline-primary"
          id="add-events-tab"
          data-bs-toggle="tab"
          data-bs-target="#add-events-tab-pane"
          type="button"
          role="tab"
          aria-controls="add-events-tab-pane"
          aria-selected="false"
        >
          {t('Add Events')}
        </Link>
      </li>
    </ul>
  );
};

export default AddGroupPopupHeader;
