/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";

/* Importing react select */
import Select from "react-select";

//import translation function for language translation
import { useTranslation } from "react-i18next";

/*import url and gateway methods */
import { getData, postData, putData } from "utils/Gateway";
import * as url from "urlhelpers/UrlHelper";

//import context
import { GlobalProvider } from "context/GlobalContext";

import StatusOptions from "data/Prod/ReserveStatus.json";

// Manage Tags or Label for all modules =============================
// ==================================================================

const ReserveStatusChange = (params) => {
  const { t } = useTranslation(); //for translation
  const token = localStorage.getItem("token");

  const {
    setReloadReservationList,
    setSuccess,
    setError,
    setNotificationString,
  } = useContext(GlobalProvider);

  //use state for status selection
  const [statusValue, setStatusValue] = useState(null);
  const [currentStatus, setCurrentStatus] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);

  //function for status selection
  const statusSelectionHandler = (val) => {
    setStatusValue(val);
    setCurrentStatus(val.value);
  };

  //function for update status
  const updateStatus = async () => {
    setIsUpdating(true);

    const checkboxes = document.querySelectorAll(
      'input[name="selectReserveRecord"]'
    );

    // // run a loop for all selected components or checkboxId
    for (let checkbox of checkboxes) {
      // if checked
      if (checkbox.checked) {
        // get the checked components
        let selectedReserveId = checkbox.value;

        let reserveData = {
          reservestatus: currentStatus,
        };

        let requestUrl =
          url.API_BASEURL +
          url.API_UPDATE_RESERVE_EVENT +
          `/${selectedReserveId}?token=${token}`;

        console.log("submit reserve status", requestUrl);

        console.log("Status data----->", reserveData);

        try {
          const response = await putData(requestUrl, reserveData);
          console.log("response in status update----->", response);
          setSuccess(true);

          response.data.reservestatus === "1"
            ? setNotificationString(t("Reservation has been Confirmed"))
            : response.data.reservestatus === "0"
            ? setNotificationString(t("Reservation has been deleted"))
            : setNotificationString(
                t("Reservation has been cancelled at the moment")
              );
        } catch (error) {
          console.log(error.message);
          setError(true);
          setNotificationString("Something went wrong, please try again later");
        }
      }
    }

    setIsUpdating(false);
    //close the offcanvas of addLabel
    let offCanvasPopup = document.querySelector("#status_pnl");
    let offCanvas = bootstrap.Offcanvas.getInstance(offCanvasPopup);
    offCanvas.hide();

    resetHandler();
    setReloadReservationList(true);
  };

  //function for reset all values
  const resetHandler = () => {
    setStatusValue(null);
    setCurrentStatus(null);
  };

  return (
    <div
      className="offcanvas offcanvas-end border-0 status_offcanvas"
      id="status_pnl"
      aria-labelledby="status_pnl"
    >
      <div className="offcanvas-header position-relative align-items-start p-4">
        <h3 className="text-secondary mb-0" id="saveReservationPopupLabel">
          <span className="d-block">{t("Update Status")}</span>
        </h3>
        <button
          type="button"
          className="btn-close p-2 bg-white shadow-none shadow-sm m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={resetHandler}
        ></button>
      </div>
      <div className="offcanvas-body position-relative p-4 pt-0">
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="form-group mb-4">
            <div className="row">
              {/* ======== select status starts =========*/}
              <div className="col-lg-12">
                <label htmlFor="status">{t("Status")}</label>
                <Select
                  className="nosearch_select"
                  options={StatusOptions}
                  value={statusValue}
                  onChange={(val) => {
                    statusSelectionHandler(val);
                  }}
                />
              </div>
            </div>
          </div>

          <div className="tags_btns d-flex align-items-center gap-2">
            <button
              className="btn btn-primary d-flex align-items-center justify-content-center gap-1 w-100"
              disabled={isUpdating ? true : false}
              style={{ cursor: isUpdating ? "not-allowed" : "pointer" }}
              onClick={updateStatus}
            >
              {isUpdating ? (
                <div
                  className="mx-2 spinner-border spinner-border-sm"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              ) : (
                <span className="material-icons-round">check_circle</span>
              )}
              <span>{t("Update")}</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ReserveStatusChange;
