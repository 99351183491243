/* eslint-disable */
import * as React from "react";
import "@progress/kendo-theme-material/dist/all.css";

import {
  Scheduler,
  WeekView,
  DayView,
  MonthView,
} from "@progress/kendo-react-scheduler";

import baseData from "data/Scheduler.json";

function Schedule() {
  // get json data about date ------------------------------
  const currentYear = new Date().getFullYear();
  const parseAdjust = (eventDate) => {
    const date = new Date(eventDate);
    date.setFullYear(currentYear);
    return date;
  };

  const sampleData = baseData.map((dataItem) => ({
    id: dataItem.TaskID,
    start: parseAdjust(dataItem.Start),
    startTimezone: dataItem.startTimezone,
    end: parseAdjust(dataItem.End),
    endTimezone: dataItem.endTimezone,
    isAllDay: dataItem.isAllDay,
    title: dataItem.Title,
    description: dataItem.Description,
    recurrenceRule: dataItem.RecurrenceRule,
    recurrenceId: dataItem.RecurrenceID,
    recurrenceExceptions: dataItem.RecurrenceException,
    roomId: dataItem.RoomID,
    ownerID: dataItem.OwnerID,
    personId: dataItem.OwnerID,
  }));

  const displayDate = new Date(Date.UTC(currentYear, 5, 24));

  const [data, setData] = React.useState(sampleData);

  return (
    <Scheduler
      data={data}
      //onDataChange={handleDataChange}
      editable={{
        add: true,
        remove: true,
        drag: true,
        resize: true,
        select: true,
        edit: true,
      }}
      //defaultDate={displayDate}
      //onSave={handleSave}
    >
      <DayView
      //numberOfDays={2}
      />
      <WeekView />
      <MonthView />
    </Scheduler>
  );
}

export default Schedule;
