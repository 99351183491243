/* eslint-disable */
import React, { useState, useContext, useEffect } from "react";

import { useParams } from "react-router-dom";
import Select from "react-select";

//import translation function for language translation
import { useTranslation } from "react-i18next";

/*import url and gateway methods */
import { putData, deleteData, getData } from "utils/Gateway";
import * as url from "urlhelpers/UrlHelper";

//import context
import { GlobalProvider } from "context/GlobalContext";

//import option for availibility
import availibilityOptions from "data/Prod/CalendarAvailability.json";
import Shifts from "data/Prod/Shifts.json";

import { parseAdjust } from "helpers/ParseAdjust";
import ReserveStatusOptions from "data/Prod/ReserveStatus.json";

const EditSchedulePopup = () => {
  const token = localStorage.getItem("token"); // token
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  /* ========== get user id from url params ========= */
  const params = useParams();

  const { t } = useTranslation(); //for translation

  const { setReloadCalendarEvents, editCalendarData, setEditCalendarData } =
    useContext(GlobalProvider);

  const [clientList, setClientList] = useState([]);
  const [conceptList, setConceptList] = useState([]);
  const [reserveList, setReserveList] = useState([]);

  const [clientValue, setClientValue] = useState(null);
  const [conceptValue, setConceptValue] = useState(null);
  const [reserveValue, setReserveValue] = useState(null);

  /* useState hook for selected Availability */
  const [availabilityValue, setAvailabilityValue] = useState(null);
  const [availabilityData, setAvailabilityData] = useState(null);

  //use state for shift
  const [shiftValue, setShiftValue] = useState(null);
  const [shiftData, setShiftData] = useState(null);

  //for loading animation
  const [isUpdating, setIsUpdating] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  /* Hook for validation start */
  const [validation, setValidation] = useState({
    timeWarning: false,
  });

  const [errorMessage, setErrorMessage] = useState("");

  /* function for validation starts */
  const formValidation = () => {
    let isValid = true;

    if (scheduleInputs.starttime > scheduleInputs.endtime) {
      setValidation((prevState) => ({ ...prevState, timeWarning: true }));
      isValid = false;
    }

    return isValid;
  };

  /* useState hook for input fields */
  const [scheduleInputs, setScheduleInputs] = useState({
    user: params.id,
    name: "",
    date: "",
    starttime: "",
    endtime: "",
    description: "",
  });

  const [isEditing, setIsEditing] = useState({
    startTimeEdit: false,
    endTimeEdit: false,
  });

  const [activityName, setactivityName] = useState("");
  const [session, setsession] = useState("");
  const [reserveDate, setreserveDate] = useState("");
  const [reserveAddress, setreserveAddress] = useState("");
  const [reserveStatus, setreserveStatus] = useState("");

  //function for get all clients
  const getAllClient = async () => {
    try {
      let clientRequestUrl =
        url.API_BASEURL +
        url.API_GET_ALL_USER +
        `?token=${token}&isselect=${true}&slug=client`;

      let benificiaryRequestUrl =
        url.API_BASEURL +
        url.API_GET_ALL_USER +
        `?token=${token}&isselect=${true}&slug=beneficiary`;

      const clientResponse = await getData(clientRequestUrl);

      const beneficiaryResponse = await getData(benificiaryRequestUrl);

      if (clientResponse.status && beneficiaryResponse.status) {
        let clientArr = [];
        clientArr = [...clientResponse.data, ...beneficiaryResponse.data];
        setClientList(clientArr);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  //function for get all concept
  const getAllConceptList = async () => {
    try {
      let requestUrl =
        url.API_BASEURL + url.API_GET_ALL_CONCEPT + `?token=${token}`;

      const response = await getData(requestUrl);

      if (response.status) {
        setConceptList(response.data);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  //function for get all reserve
  const getAllReserveList = async () => {
    try {
      let requestUrl =
        url.API_BASEURL +
        url.API_GET_ALL_RESERVE_EVENTS +
        `?token=${token}&isselect=${true}&scheduleuser=${params.id}`;

      console.log(
        "Reserve list url for profile schedule tab ========>",
        requestUrl
      );

      const response = await getData(requestUrl);

      console.log(
        "Reserve list response for profile schedule tab ========>",
        response
      );

      if (response.status) {
        setReserveList(response.data);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  //function for get schedule details
  const getScheduleDetails = async (schedulId) => {
    try {
      let requestUrl = `${url.API_BASEURL}${url.API_GET_SCHEDULE_DETAILS}/${schedulId}?token=${token}`;

      console.log(requestUrl);

      const response = await getData(requestUrl);

      console.log(response);

      if (response.status) {
        setScheduleInputs((prevState) => ({
          ...prevState,
          name: response.data.name || "",
          date: response.data.date || "",
          starttime: response.data.starttime || "",
          endtime: response.data.endtime || "",
          description: response.data.description || "",
        }));

        /*--------- assign availability start -----------*/
        if (response.data.availability) {
          for (let availibility of availibilityOptions) {
            if (availibility.value == response.data.availability) {
              availabilitySelectionHandler(availibility);
            }
          }
        }
        /*--------- assign availability end -----------*/

        /*-------- assign shift start --------*/
        if (response.data.shift) {
          for (let shift of Shifts) {
            console.log(shift);
            if (shift.id === response.data.shift) {
              shiftSelectionHandler(shift);
            }
          }
        }
        /*-------- assign shift end --------*/

        /*------- assign client start -------*/
        if (response.data.clientid) {
          setClientValue(response.data.clientid);
        }
        /*------- assign client end -------*/

        /*------- assign concept start -------*/
        if (response.data.conceptid) {
          setConceptValue(response.data.conceptid);
        }
        /*------- assign conceptid end -------*/

        /*------- assign reserve start -------*/
        if (response.data.reserveid) {
          reserveSelectionHandler(response.data.reserveid);
        }
        /*------- assign reserve end -------*/
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for Availability selection
  const availabilitySelectionHandler = (val) => {
    //console.log(val);

    setScheduleInputs((prevState) => ({
      ...prevState,
      name: val.label,
    }));

    setAvailabilityValue(val);
    setAvailabilityData(val.value);
  };

  //function for shift selection
  const shiftSelectionHandler = (val) => {
    setShiftValue(val);
    setShiftData(val.value);

    //if(scheduleInputs.starttime == ""){
    // setScheduleInputs((prevState) => ({
    //   ...prevState,
    //   starttime: val.start,
    //   endtime: val.end,
    // }));
    //}
  };

  //reserve selection
  const reserveSelectionHandler = (val) => {
    if (val) {
      setReserveValue(val);
      setactivityName(val.activityname);
      setsession(val.session);
      setreserveDate(val.reservedate);
      setreserveAddress(val.address);
      for (let reserveStatus of ReserveStatusOptions) {
        if (reserveStatus.value == val.reservestatus) {
          setreserveStatus(reserveStatus.label);
        }
      }
    } else {
      setReserveValue(null);
      setactivityName("");
      setsession("");
      setreserveDate("");
      setreserveAddress("");
      setreserveStatus("");
    }
  };

  /* function to edit calender event */
  const editScheduleHandler = async (e) => {
    e.preventDefault();

    if (formValidation()) {
      setErrorMessage("");
      try {
        let response = {};
        setIsUpdating(true);

        let nonAvailableDates = "";

        if (editCalendarData.length > 1) {
          let scheduleData = {
            user: params.id,
            name: scheduleInputs.name,
            starttime: scheduleInputs.starttime,
            endtime: scheduleInputs.endtime,
            description: scheduleInputs.description,
            availability: availabilityData,
            shift: shiftData,
            conceptid: conceptValue?.value,
            clientid: clientValue?.value,
            reserveid: reserveValue?.value,
          };

          console.log(`Schedule Data===========`, scheduleData);

          let responseCount = 0;

          for (let calendarId of editCalendarData) {
            let requestUrl =
              url.API_BASEURL +
              url.API_UPDATE_SCHEDULE +
              `/${calendarId}` +
              `?token=${token}`;

            console.log("Request url of edit schedule =========>", requestUrl);

            response = await putData(requestUrl, scheduleData);

            console.log(`response updating schedule data ========>>`, response);

            if (response.status) {
              responseCount++;
            } else {
              nonAvailableDates += `${response.nonavailabledate}, `;
            }
          }

          if (nonAvailableDates === "") {
            resetHandler();
            bootstrap.Offcanvas.getInstance(
              document.querySelector("#editSchedulePopup")
            ).hide();
            setReloadCalendarEvents(true);
          } else {
            setErrorMessage(
              `${nonAvailableDates} ${t(
                "dates are not avalible to save new record"
              )}`
            );
          }
        } else {
          let scheduleData = {
            user: params.id,
            name: scheduleInputs.name,
            date: scheduleInputs.date,
            starttime: scheduleInputs.starttime,
            endtime: scheduleInputs.endtime,
            description: scheduleInputs.description,
            availability: availabilityData,
            shift: shiftData,
            conceptid: conceptValue?.value,
            clientid: clientValue?.value,
            reserveid: reserveValue?.value,
          };

          console.log(`Schedule Data===========`, scheduleData);

          let requestUrl =
            url.API_BASEURL +
            url.API_UPDATE_SCHEDULE +
            `/${editCalendarData[0]}` +
            `?token=${token}`;

          console.log("Request url of edit schedule =========>", requestUrl);

          response = await putData(requestUrl, scheduleData);

          console.log(`response updating schedule data ========>>`, response);

          if (response.status) {
            resetHandler();
            bootstrap.Offcanvas.getInstance(
              document.querySelector("#editSchedulePopup")
            ).hide();
            setReloadCalendarEvents(true);
          } else {
            nonAvailableDates += `${response.nonavailabledate}, `;
            setErrorMessage(
              `${nonAvailableDates} ${t(
                "dates are not avalible to save new record"
              )}`
            );
          }
        }

        setIsUpdating(false);
      } catch (error) {
        console.error(error.message);
      }
    } else {
      setErrorMessage(t("Fill up all fields. Please Try again!"));
    }
  };

  //function for delete schedule
  const deleteScheduleHandler = async (e) => {
    e.preventDefault();

    try {
      setIsDeleting(true);
      if (editCalendarData.length > 1) {
        for (let calendarId of editCalendarData) {
          let requestUrl =
            url.API_BASEURL +
            url.API_DELETE_SCHEDULE +
            `/${calendarId}` +
            `?token=${token}`;

          console.log("Request url of delete schedule =========>", requestUrl);

          const response = await deleteData(requestUrl);

          console.log(`response deleting schedule data ========>>`, response);
        }
      } else {
        let requestUrl =
          url.API_BASEURL +
          url.API_DELETE_SCHEDULE +
          `/${editCalendarData[0]}` +
          `?token=${token}`;

        console.log("Request url of delete schedule =========>", requestUrl);

        const response = await deleteData(requestUrl);

        console.log(`response deleting schedule data ========>>`, response);
      }

      resetHandler();
      bootstrap.Offcanvas.getInstance(
        document.querySelector("#editSchedulePopup")
      ).hide();
      setReloadCalendarEvents(true);

      setIsDeleting(false);
    } catch (error) {
      console.log("Error in delete schedule=========>", error.message);
    }
  };

  /* reset handler */
  const resetHandler = () => {
    setScheduleInputs({
      name: "",
      date: "",
      starttime: "",
      endtime: "",
      description: "",
    });
    setAvailabilityData(null);
    setAvailabilityValue(null);
    setClientValue(null);
    setConceptValue(null);
    setReserveValue(null);
    setEditCalendarData([]);
    setValidation({
      timeWarning: false,
    });
  };

  /* Hook to assign edit data */
  useEffect(() => {
    // console.log(
    //   "Edit 2 Schedule data for user =============>",
    //   editCalendarData[0]
    // );
    getAllConceptList();
    getAllReserveList();
    getAllClient();
    if (editCalendarData.length > 0) {
      getScheduleDetails(editCalendarData[0]);
    }
  }, [editCalendarData]);

  return (
    <div className="offcanvas offcanvas-end border-0" id="editSchedulePopup">
      <div className="offcanvas-header align-items-start p-4">
        <h3 className="text-secondary mb-0" id="saveReservationPopupLabel">
          <span className="d-block">
            {userInfo?.role?.name == "admin" ||
            userInfo?.role?.name == "manager"
              ? t("Update Calendar Record")
              : t("Your Calendar Record")}
          </span>
        </h3>
        <button
          type="button"
          className="btn-close p-2 bg-white shadow-none shadow-sm m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={resetHandler}
        ></button>
      </div>
      <div className="offcanvas-body p-4 pt-0">
        <div className="calendar_data_item">
          {/* ------- availibility and shift section start ------ */}
          <div className="form-group mb-4">
            <div className="row">
              {/* ------ Availability section start ----- */}
              <div className="col-lg-6">
                <label htmlFor="record">{t("Set record as")} *</label>

                <Select
                  options={availibilityOptions}
                  value={availabilityValue}
                  onChange={(val) => {
                    if (
                      userInfo?.role?.name == "admin" ||
                      userInfo?.role?.name == "manager"
                    ) {
                      availabilitySelectionHandler(val);
                    }
                  }}
                />
              </div>
              {/* ------ Availability section end ----- */}

              {/* ------ Shift section start ----- */}
              {availabilityData && availabilityData != "2" ? (
                <div className="col-lg-6">
                  <label htmlFor="shift">{t("Select shift/turn")} *</label>
                  <Select
                    options={Shifts}
                    value={shiftValue}
                    onChange={(val) => {
                      if (
                        userInfo?.role?.name == "admin" ||
                        userInfo?.role?.name == "manager"
                      ) {
                        shiftSelectionHandler(val);
                      }
                    }}
                  />

                  {/* date compare warning */}
                  {validation.shiftWarning && (
                    <div className="error-message mt-2">
                      <p className="d-flex align-items-center gap-1 text-danger">
                        <span className="material-symbols-outlined">
                          warning
                        </span>
                        <span>{t("Please select shift")}!</span>
                      </p>
                    </div>
                  )}
                </div>
              ) : (
                ""
              )}
              {/* ------ Shift section end ----- */}
            </div>
          </div>
          {/* ------- availibility and shift section start ------ */}

          {availabilityData && availabilityData != "2" ? (
            <div className="col-lg-12 mb-3">
              <p className="mb-0 fw-bold">
                {t("Shift")} : {shiftData == "1" ? t("Morning") : ""}{" "}
                {shiftData == "2" ? t("Evening") : ""}
              </p>
            </div>
          ) : (
            ""
          )}

          {/* ========== date/time section start ============ */}
          {editCalendarData.length > 1 ? null : (
            <div className="form-group mb-4">
              <div className="row">
                <div className="col-lg-4">
                  <label htmlFor="date">{t("Date")} *</label>
                  <input
                    type="date"
                    id="date"
                    className="form-control"
                    placeholder="DD/MM/YYYY"
                    value={scheduleInputs.date}
                    onChange={(val) => {
                      if (
                        userInfo?.role?.name == "admin" ||
                        userInfo?.role?.name == "manager"
                      ) {
                        setScheduleInputs((prevState) => ({
                          ...prevState,
                          date: val.target.value,
                        }));
                      }
                    }}
                  />
                  {/* start date warning */}
                  {/* {validation.startDateWarning && (
                    <div className="error-message mt-2">
                      <p className="d-flex align-items-center gap-1 text-danger">
                        <span className="material-symbols-outlined">
                          warning
                        </span>
                        <span>{t('Please enter date')}!</span>
                      </p>
                    </div>
                  )} */}
                  {/* start date warning */}
                  {/* {validation.startDateWarning && (
                    <div className="error-message mt-2">
                      <p className="d-flex align-items-center gap-1 text-danger">
                        <span className="material-symbols-outlined">
                          warning
                        </span>
                        <span>{t('Please enter start date')}!</span>
                      </p>
                    </div>
                  )} */}
                </div>
              </div>
            </div>
          )}

          {/* ========== date/time section start ============ */}
          <div className="form-group mb-4">
            <div className="row">
              {/* ---- start time section start ----- */}
              <div className="col-lg-6">
                <label htmlFor="startTime">{t("Start Time")} *</label>
                <input
                  type={isEditing.startTimeEdit ? "time" : "text"}
                  id="startTime"
                  className="form-control"
                  placeholder="HH : MM"
                  onClick={() =>
                    setIsEditing((prevState) => ({
                      ...prevState,
                      startTimeEdit: true,
                    }))
                  }
                  onBlur={() =>
                    setIsEditing((prevState) => ({
                      ...prevState,
                      startTimeEdit: false,
                    }))
                  }
                  value={scheduleInputs.starttime}
                  onChange={(val) => {
                    if (
                      userInfo?.role?.name == "admin" ||
                      userInfo?.role?.name == "manager"
                    ) {
                      setScheduleInputs((prevState) => ({
                        ...prevState,
                        starttime: val.target.value,
                      }));

                      setValidation((prevState) => ({
                        ...prevState,
                        timeWarning: false,
                      }));
                    }
                  }}
                />

                {/* time compare warning */}
                {validation.timeWarning && (
                  <div className="error-message mt-2">
                    <p className="d-flex align-items-center gap-1 text-danger">
                      <span className="material-symbols-outlined">warning</span>
                      <span>
                        {t("End time should be greater than start time")}!
                      </span>
                    </p>
                  </div>
                )}
              </div>
              {/* ---- start time section end ----- */}

              {/* ---- end time section start ----- */}
              <div className="col-lg-6">
                <label htmlFor="endTime">{t("End Time")} *</label>
                <input
                  type={isEditing.endTimeEdit ? "time" : "text"}
                  id="endTime"
                  className="form-control"
                  placeholder="HH : MM"
                  onClick={() =>
                    setIsEditing((prevState) => ({
                      ...prevState,
                      endTimeEdit: true,
                    }))
                  }
                  onBlur={() =>
                    setIsEditing((prevState) => ({
                      ...prevState,
                      endTimeEdit: false,
                    }))
                  }
                  value={scheduleInputs.endtime}
                  onChange={(val) => {
                    if (
                      userInfo?.role?.name == "admin" ||
                      userInfo?.role?.name == "manager"
                    ) {
                      setScheduleInputs((prevState) => ({
                        ...prevState,
                        endtime: val.target.value,
                      }));
                      setValidation((prevState) => ({
                        ...prevState,
                        timeWarning: false,
                      }));
                    }
                  }}
                />
                {/* start time warning */}
                {/* {validation.endTimeWarning && (
                    <div className="error-message mt-2">
                      <p className="d-flex align-items-center gap-1 text-danger">
                        <span className="material-symbols-outlined">
                          warning
                        </span>
                        <span>{t('Please enter end time')}!</span>
                      </p>
                    </div>
                  )} */}
              </div>
              {/* ---- end time section end ----- */}
            </div>
          </div>
          {/* ========== date/time section end ============ */}

          {/* ========== title section start ============ */}
          <div className="form-group mb-4">
            <label>{t("Display Title")} *</label>
            <input
              type="text"
              className="form-control"
              placeholder={t("Display Title")}
              value={scheduleInputs.name}
              onChange={(val) => {
                if (
                  userInfo?.role?.name == "admin" ||
                  userInfo?.role?.name == "manager"
                ) {
                  setScheduleInputs((prevState) => ({
                    ...prevState,
                    name: val.target.value,
                  }));
                }
              }}
            />
          </div>
          {/* ========== title section end ============ */}

          {/* ------- select concept section start ------ */}
          <div className="form-group mb-4">
            <label htmlFor="conceptselect">{t("Select Concept")}</label>
            <Select
              options={conceptList}
              placeholder={t("Select concept")}
              value={conceptValue}
              onChange={(val) => {
                setConceptValue(val);
              }}
            />
          </div>
          {/* ------- select concept section end ------ */}

          {/* ------- select client section start ------ */}
          <div className="form-group mb-4">
            <label htmlFor="clientselect">{t("Select Client")}</label>
            <Select
              options={clientList}
              placeholder={t("Select client")}
              value={clientValue}
              onChange={(val) => {
                if (
                  userInfo?.role?.name == "admin" ||
                  userInfo?.role?.name == "manager"
                ) {
                  setClientValue(val);
                }
              }}
            />
          </div>
          {/* ------- select client section end ------ */}

          {/* ------- select reserve section start ------ */}
          <div className="form-group mb-4">
            <label htmlFor="reserveselect">{t("Select Reserve")}</label>
            <Select
              options={reserveList}
              placeholder={t("Select reserve")}
              value={reserveValue}
              onChange={(val) => {
                reserveSelectionHandler(val);
              }}
            />
          </div>
          {/* ------- select reserve section end ------ */}

          {reserveValue ? (
            <div className="form-group mb-4">
              <p>
                <span className="fw-semibold">Activity</span> : {activityName}{" "}
              </p>
              <p>
                <span className="fw-semibold">Session</span> : {session}{" "}
              </p>
              <p>
                <span className="fw-semibold">Reserve Date</span> :{" "}
                {reserveDate}
              </p>
              <p>
                <span className="fw-semibold">Reserve Address</span> :{" "}
                {reserveAddress}
              </p>
              <p>
                <span className="fw-semibold">Reserve Status</span> :{" "}
                {reserveStatus}
              </p>
            </div>
          ) : null}

          {/* =============== description section starts ============= */}
          <div className="form-group mb-4">
            <label htmlFor="educatorComments">{t("Description")}</label>
            <textarea
              name="educatorComments"
              id="educatorComments"
              cols="30"
              rows="4"
              className="form-control"
              placeholder={t("Enter Description")}
              value={scheduleInputs.description}
              onChange={(val) => {
                if (
                  userInfo?.role?.name == "admin" ||
                  userInfo?.role?.name == "manager"
                ) {
                  setScheduleInputs((prevState) => ({
                    ...prevState,
                    description: val.target.value,
                  }));
                }
              }}
            ></textarea>
            {/* comments warning */}
            {/* {validation.commentsWarning && (
                <div className="error-message mt-2">
                  <p className="d-flex align-items-center gap-1 text-danger">
                    <span className="material-symbols-outlined">warning</span>
                    <span>{t('Please enter comments')}!</span>
                  </p>
                </div>
              )} */}
          </div>
          {/* =============== description section ends ============= */}
        </div>

        {/* ---- update and delete button start ----- */}
        {userInfo?.role?.name == "admin" ||
        userInfo?.role?.name == "manager" ? (
          <div className="action d-flex align-items-center gap-2">
            <button
              className="btn btn-primary"
              onClick={editScheduleHandler}
              disabled={isUpdating ? true : false}
              style={{ cursor: isUpdating ? "not-allowed" : "pointer" }}
            >
              {t("Save")}
              {isUpdating && (
                <div
                  className="mx-2 spinner-border spinner-border-sm"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              )}
            </button>

            <button
              className="btn btn-danger"
              onClick={deleteScheduleHandler}
              disabled={isDeleting ? true : false}
              style={{ cursor: isDeleting ? "not-allowed" : "pointer" }}
            >
              {t("Delete")}{" "}
              {isDeleting && (
                <div
                  className="mx-2 spinner-border spinner-border-sm"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              )}
            </button>

            {errorMessage === "" ? null : (
              <p className="warring">
                <i className="material-icons-outlined">warning</i>
                <span>{errorMessage}</span>
              </p>
            )}
          </div>
        ) : null}
        {/* ---- update and delete button end ----- */}
      </div>
    </div>
  );
};

export default EditSchedulePopup;
