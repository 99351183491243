/* eslint-disable */
import { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";

/*import url and gateway methods */
import { postData, getData } from "utils/Gateway";
import * as url from "urlhelpers/UrlHelper";

//import translation function for language translation
import { useTranslation } from "react-i18next";

//import context
import { GlobalProvider } from "context/GlobalContext";

//import options from json file
import batchLotOption from "data/Prod/Batch.json";

const ActivityFilter = () => {
  const token = localStorage.getItem("token");

  const {
    setReloadActivityList,
    setSearchActivityName,
    setsearchActivityCode,
    setSearchActivityCourse,
    setSearchActivityLot,
  } = useContext(GlobalProvider);
  const { t } = useTranslation(); //for translation

  const [courseList, setCourseList] = useState([]); //course option

  const [activityName, setActivityName] = useState("");
  const [activityCode, setactivityCode] = useState("");

  const [courseValue, setCourseValue] = useState(null);
  const [selectedCourseIds, setSelectedCourseIds] = useState([]);

  const [lotValue, setLotValue] = useState(null);
  const [selectedLots, setSelectedLots] = useState([]);

  //function for get course list
  const getAllCourseList = async () => {
    try {
      let requestUrl =
        url.API_BASEURL +
        url.API_GET_ALL_COURSE +
        `?token=${token}&isselect=${true}`;

      const response = await getData(requestUrl);

      if (response.status) {
        setCourseList(response.data);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  //function for course selection
  const courseSelectionHandler = (val) => {
    setCourseValue(val);
    const courseArr = val.map((item) => {
      return item.value;
    });
    setSelectedCourseIds(courseArr);
  };

  //function for lot selcetion
  const lotSelectionHandler = (val) => {
    setLotValue(val);
    let lotArr = val.map((item) => item.value);
    setSelectedLots(lotArr);
  };

  //function for search
  const searchHandler = () => {
    setSearchActivityName(activityName);
    setsearchActivityCode(activityCode);
    setSearchActivityCourse(selectedCourseIds);
    setSearchActivityLot(selectedLots);
  };

  //function for reset
  const resetHandler = () => {
    setActivityName("");
    setactivityCode("");
    setCourseValue(null);
    setSelectedCourseIds([]);
    setLotValue(null);
    setSelectedLots([]);
    setSearchActivityName("");
    setsearchActivityCode("");
    setSearchActivityCourse([]);
    setSearchActivityLot([]);
    setReloadActivityList(true);
  };

  useEffect(() => {
    getAllCourseList();
  }, []);

  return (
    <div className="collapse mt-3" id="filterCollapse">
      <div className="p-3 bg-gray-200">
        <h4>{t("Filter")}</h4>
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="form-group mb-3">
            {/* ------- name and code row start ------- */}
            <div className="row mb-3">
              {/* ----- name section start ----- */}
              <div className="col-6">
                <input
                  type="text"
                  name="activityname"
                  id="id"
                  placeholder={t("Name")}
                  className="form-control bg-white"
                  value={activityName}
                  onChange={(e) => setActivityName(e.target.value)}
                />
              </div>
              {/* ----- name section end ----- */}

              {/* ----- code section start ----- */}
              <div className="col-6">
                <div className="field-container position-relative">
                  <input
                    type="text"
                    name="activitycode"
                    id="activitycode"
                    placeholder={t("Code")}
                    className="form-control bg-white"
                    value={activityCode}
                    onChange={(e) => setactivityCode(e.target.value)}
                  />
                  <span className="d-block material-symbols-outlined position-absolute start-0 top-50 translate-middle-y">
                    search
                  </span>
                </div>
              </div>
              {/* ----- code section end ----- */}
            </div>
            {/* ------- name and code row end ------- */}

            {/* ------- course and lot selection row start ------- */}
            <div className="row">
              {/* ----- course selection section start ----- */}
              <div className="col-6">
                <div className="field-container position-relative">
                  <Select
                    isMulti
                    options={courseList}
                    placeholder={t("Select course")}
                    value={courseValue}
                    onChange={(val) => {
                      courseSelectionHandler(val);
                    }}
                  />
                </div>
              </div>
              {/* ----- course selection section end ----- */}

              {/* ----- lot selection section start ----- */}
              <div className="col-6">
                <div className="field-container position-relative">
                  <Select
                    isMulti
                    options={batchLotOption}
                    placeholder={t("Select lot")}
                    value={lotValue}
                    onChange={(val) => {
                      lotSelectionHandler(val);
                    }}
                  />
                </div>
              </div>
              {/* ----- lot selection section end ----- */}
            </div>
            {/* ------- course and lot selection row end ------- */}
          </div>

          {/* -------- search and reset button section start -------- */}
          <div className="action d-flex gap-3 align-items-center justify-content-end">
            <Link
              onClick={resetHandler}
              to="#"
              className="d-flex gap-1 align-items-center text-black"
            >
              <span className="d-block material-symbols-outlined icon-md">
                sync
              </span>
              <span className="d-block fw-medium">{t("Refresh")}</span>
            </Link>
            <button
              type="submit"
              className="btn btn-primary"
              onClick={searchHandler}
            >
              {t("Filter")}
            </button>
          </div>
          {/* -------- search and reset button section end -------- */}
        </form>
      </div>
    </div>
  );
};

export default ActivityFilter;
