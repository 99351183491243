import React, { useContext } from "react";
import { Link } from "react-router-dom";

//import translation function for language translation
import { useTranslation } from "react-i18next";

//import constext
import { GlobalProvider } from "context/GlobalContext";

const ReserveBreadCrumb = () => {
  const { isReserveListLoading } = useContext(GlobalProvider);

  const { t } = useTranslation(); //for translation

  // const glowCount = 5;

  return (
    <nav aria-label="breadcrumb" className="d-flex align-items-center gap-3 mb-3">
      <ul className="breadcrumb mb-0">
        <li className="breadcrumb-item">
          <Link to="/" className="text-secondary">
            {t("Dashboard")}
          </Link>
        </li>
        <li className="breadcrumb-item text-gray-600" aria-current="page">
          {t("Reservations")}
        </li>


      </ul>

      {isReserveListLoading && (
          <div className="d-flex align-items-center justify-content-center mx-auto">
              <span className="me-5 fw-bold"> {t("Please wait while we process the data")} {" "}</span>
              <p className="placeholder-glow" style={{width: '300px'}} >
                <span className="d-block placeholder"></span>
              </p>
          </div>
        )}
    </nav>
  );
};

export default ReserveBreadCrumb;
