/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";

// //import translation function for language translation
import { useTranslation } from "react-i18next";

// /*import url and gateway methods */
import { getData } from "utils/Gateway";
import * as url from "urlhelpers/UrlHelper";

/* importing schedular from kendo react schedular */
import {
  Scheduler,
  DayView,
  WeekView,
  MonthView,
  AgendaView,
  SchedulerItem,
} from "@progress/kendo-react-scheduler";

import {
  IntlProvider,
  load,
  LocalizationProvider,
  loadMessages,
} from "@progress/kendo-react-intl";

import likelySubtags from "cldr-core/supplemental/likelySubtags.json";
import currencyData from "cldr-core/supplemental/currencyData.json";
import weekData from "cldr-core/supplemental/weekData.json";
import numbers from "cldr-numbers-full/main/es/numbers.json";
import currencies from "cldr-numbers-full/main/es/currencies.json";
import caGregorian from "cldr-dates-full/main/es/ca-gregorian.json";
import dateFields from "cldr-dates-full/main/es/dateFields.json";
import timeZoneNames from "cldr-dates-full/main/es/timeZoneNames.json";
load(
  likelySubtags,
  currencyData,
  weekData,
  numbers,
  currencies,
  caGregorian,
  dateFields,
  timeZoneNames
);

// import other translations for spainish
import esMessages from "data/Prod/esCalendarMessages.json";
loadMessages(esMessages, "es-ES");

//import constext
import { GlobalProvider } from "context/GlobalContext";

const MaterialCalendarBody = ({ selectedKit }) => {
  const { t, i18n } = useTranslation();

  /* ========== get user id from url params ========= */
  const params = useParams();
  const token = localStorage.getItem("token"); // for token

  //import conext
  const {
    reloadScheduleMaterial,
    setReloadScheduleMaterial,
    setEditScheduleMaterialData,
    agendaWaitning,
    setAgendaWaitning,
  } = useContext(GlobalProvider);

  // default user data ------------------------------------
  const [calendarData, setCalendarData] = useState([]);
  const [responseReceived, setResponseReceived] = useState(false);

  // get json data about date ------------------------------
  const currentYear = new Date().getFullYear();

  const parseAdjust = (eventDate) => {
    const date = new Date(eventDate);
    date.setFullYear(currentYear);
    return date;
  };

  const handleDateChange = (e) => {
    const selectedDate = e.value;

    const month = selectedDate.getMonth() + 1; // Get the month name
    const year = selectedDate.getFullYear(); // Get the full year

    getAllAgenda(month, year);
  };

  // function for get all calendar data
  const getAllAgenda = async (month, year) => {
    setAgendaWaitning(true);
    try {
      let requestUrl =
        url.API_BASEURL +
        url.API_GET_ALL_SCHEDULE_MATERIALKIT +
        `?token=${token}&month=${month}&year=${year}&materialkit=${params.id}`;

      console.log("Url of calender list========>", requestUrl);

      const response = await getData(requestUrl);
      console.log(`response in calender list ==============>`, response.data);

      if (response.status) {
        setAgendaWaitning(false);
        setCalendarData(response.data);
        setResponseReceived(true);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  // set response to match kendo scheduler event schema --------------------------------
  const kendoCalendarData = calendarData.map((dataItem, index) => ({
    id: index + 1,
    start: parseAdjust(dataItem.start, dataItem.starttime),
    end: parseAdjust(dataItem.end, dataItem.endtime),
    isAllDay: false,
    title: dataItem.title,
    description: dataItem.description,
    date: dataItem.date,
    availability: dataItem.surplus,
    starttime: dataItem.starttime,
    endtime: dataItem.endtime,
    color: dataItem.color,
  }));

  console.log("kendo calendar data -->", kendoCalendarData);

  /*---------- Handel Edit Calendar Schedule data ------------*/
  let scheduleItemHandler = (event) => {
    //assign schedule date in context
    setEditScheduleMaterialData(event.target.props.dataItem);

    const offcanvas = new window.bootstrap.Offcanvas(
      document.getElementById("editMaterialSchedulePopup")
    );
    offcanvas.show();
  };

  /*--------- function for custom schedule ---------*/
  const CustomSchedule = (props) => {
    return (
      <SchedulerItem
        {...props}
        style={{
          ...props.style,
          background: props.dataItem.color,
        }}
      />
    );
  };

  useEffect(() => {
    const currentDate = new Date();
    const month = currentDate.getMonth() + 1; // Get the current month name
    const year = currentDate.getFullYear(); // Get the current full year

    getAllAgenda(month, year);
  }, []);

  return (
    <LocalizationProvider language={i18n.language == "en" ? "en-EN" : "es-ES"}>
      <IntlProvider locale={i18n.language == "en" ? "en" : "es"}>
        <Scheduler
          data={kendoCalendarData}
          defaultDate={new Date()}
          item={CustomSchedule}
          onDateChange={handleDateChange}
        >
          <MonthView />
          <WeekView />
          <DayView />
        </Scheduler>
      </IntlProvider>
    </LocalizationProvider>
  );
};

export default MaterialCalendarBody;
