import AddGroupPopup from 'components/GroupsComponents/AddGroupPopup/AddGroupPopup';
import GroupsBody from 'components/GroupsComponents/GroupsBody';
import GroupsNav from 'components/GroupsComponents/GroupsNav';
import LeftMenu from 'components/common/LeftMenu';
import UserMenu from 'components/common/UserMenu';
import React, { useState, useEffect } from 'react';

const Groups = () => {
  /* useState hook to download excel file */
  const [downloadExcel, setDownloadExcel] = useState(false);
  /* hook to search */
  const [search, setSearch] = useState('');

  useEffect(() => {
    document.title = 'FunBrain | Groups'; // Update the title when Login component mounts
  }, []);
  return (
    <>
      <UserMenu />
      <LeftMenu />
      <section className="groups-wrapper py-4">
        <div className="container-fluid px-4">
          <GroupsNav
            setDownloadExcel={setDownloadExcel}
            setSearch={setSearch}
          />
          <GroupsBody downloadExcel={downloadExcel} search={search} />
        </div>
      </section>
      <AddGroupPopup />
    </>
  );
};

export default Groups;
