/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import SalesOrderBreadCrumb from "components/SalesOrderComponents/BreadCrumb/SalesOrderBreadCrumb";
import SalesOrderHeader from "components/SalesOrderComponents/Header/SalesOrderHeader";
import SalesOrderList from "components/SalesOrderComponents/SalesOrderList/SalesOrderList";
import LeftMenu from "components/common/LeftMenu";
import UserMenu from "components/common/UserMenu";
import SalesOrderFilter from "components/SalesOrderComponents/Filter/SalesOrderFilter";
import ToggleColumn from "components/SalesOrderComponents/ToggleColumn/ToggleColumn";
import CustomNotification from "components/common/CustomNotification";
import BudgetSendMailPopup from "components/common/Popup/BudgetSendMailPopup";
import SaveSalesOrderPopup from "components/SalesOrderComponents/Popup/SaveSalesOrderPopup";

const SalesOrder = () => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  /* useState hook to download excel file */
  const [downloadExcel, setDownloadExcel] = useState(false);
  const blockRefSelected = useRef(null); // this will be used to acccess dom element directly
  /* hook to get number of records */
  const [records, setRecords] = useState(0);
  /* function for checking record count */
  const checkedRecordCount = (selectCount) => {
    // console.log("Count in sales order----->", selectCount);
    const blockElementSelect = ReactDOM.findDOMNode(blockRefSelected.current);
    if (blockElementSelect) {
      blockElementSelect.innerHTML = selectCount;
    }
  };
  /* hook to search */
  const [search, setSearch] = useState("");
  //const [selectedEmails, setSelectedEmails] = useState([]);
  useEffect(() => {
    document.title = "Sales Order";
  }, []);
  return (
    <>
      <UserMenu />
      <LeftMenu />
      {userInfo?.role?.name == "admin" || userInfo?.role?.name == "manager" ? (
        <section className="sales-order-wrapper pt-2">
          <div className="container-fluid px-4">
            <SalesOrderBreadCrumb />
            <SalesOrderHeader
              setDownloadExcel={setDownloadExcel}
              blockRefSelected={blockRefSelected}
              setSearch={setSearch}
              records={records}
            />
            <ToggleColumn />
            <SalesOrderFilter />
            <SalesOrderList
              search={search}
              downloadExcel={downloadExcel}
              checkedRecordCount={checkedRecordCount}
              setRecords={setRecords}
            />
            {/* ------ all popup render here ------ */}
            <SaveSalesOrderPopup />

            <BudgetSendMailPopup moduleName={"salesorder"} />
          </div>

          {/*----------- custom notification component start -----------*/}
          <CustomNotification />
          {/*----------- custom notification component end -----------*/}
        </section>
      ) : null}
    </>
  );
};
export default SalesOrder;
