/* eslint-disable */
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { assetImages } from "../../constants";
import { useTranslation, Trans } from "react-i18next";

//import options
import languageOptions from "data/Prod/Language.json";

// Header for guest users without login =============================
// ==================================================================

const Header = () => {
  /* ------------- Language translation imports starts here ------------- */
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage("en");
  }, []);

  return (
    <header id="header" className="position-fixed top-0 start-0 end-0">
      <nav className="navbar navbar-expand-lg bg-dark shadow-sm">
        {/* ------- logo section start ------- */}
        <div className="container-fluid px-4">
          <Link className="navbar-brand p-0" to="/">
            <img
              src={assetImages.logo}
              alt="FunBrain Logo"
              className="img-fluid"
            />
          </Link>
        </div>
        {/* ------- logo section end ------- */}

        {/* ------- language section start ------ */}
        <div className="me-4">
          <ul className="list_stye_none d-flex gap-3 align-items-center">
            <li className="language-selector">
              <div className="dropdown">
                <button
                  className="dropdown-toggle bg-transparent text-white border-0"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {/* ------ get the value from i18 and display start----- */}
                  {i18n.language}
                  {/* ------ get the value from i18 and display end----- */}
                </button>
                {/* ------ language list section start ----- */}
                <ul className="dropdown-menu mw-unset rounded-1 border-0 shadow-lg">
                  {languageOptions.map((item, index) => {
                    return (
                      <li key={index}>
                        <Link
                          onClick={() => {
                            i18n.changeLanguage(item.value);
                          }}
                          className="dropdown-item"
                          to="#"
                        >
                          {item.value}
                        </Link>
                      </li>
                    );
                  })}

                  {/* <li>
                      <Link className="dropdown-item" to="#">
                        Es
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="#">
                        Ca
                      </Link>
                    </li> */}
                </ul>
                {/* ------ language list section end ----- */}
              </div>
            </li>
          </ul>
        </div>
        {/* ------- language section start ------ */}
      </nav>
    </header>
  );
};

export default Header;
