/* eslint-disable */
import React, { useEffect, useState, useRef } from "react";
import ReactDOM from "react-dom";
import LeftMenu from "components/common/LeftMenu";
import UserMenu from "components/common/UserMenu";
import CustomNotification from "components/common/CustomNotification";
import AddLabel from "components/common/AddLabel";
import TaskList from "components/TaskComponents/TaskList/TaskList";
import TaskBreadCrumb from "components/TaskComponents/BreadCrumb/TaskBreadCrumb";
import TaskHeader from "components/TaskComponents/Header/TaskHeader";
// import ToggleTask from "components/TaskComponents/ToogleTask/ToggleTask";
import SaveTaskPopup from "components/TaskComponents/Popup/SaveTaskPopup";
import TaskFilter from "components/TaskComponents/Filter/TaskFilter";
const Task = () => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  /* useState hook to download excel file */
  const [downloadExcel, setDownloadExcel] = useState(false);
  /* hook to search */
  const [search, setSearch] = useState("");
  /* hook to get number of records */
  const [records, setRecords] = useState(0);
  const blockRefSelected = useRef(null); // this will be used to acccess dom element directly
  /* function for checking record count */
  const checkedRecordCount = (selectCount) => {
    const blockElementSelect = ReactDOM.findDOMNode(blockRefSelected.current);
    if (blockElementSelect) {
      blockElementSelect.innerHTML = selectCount;
    }
  };
  useEffect(() => {
    document.title = "Task"; // Update the title when Login component mounts
  }, []);
  return (
    <>
      <UserMenu />
      <LeftMenu />
      <section className="mail-wrapper py-4">
        <div className="container-fluid px-4">
          <TaskBreadCrumb />
          <TaskHeader
            setDownloadExcel={setDownloadExcel}
            setSearch={setSearch}
            records={records}
            blockRefSelected={blockRefSelected}
          />
          {/* <ToggleTask /> */}
          <TaskFilter />
          <TaskList
            downloadExcel={downloadExcel}
            search={search}
            setRecords={setRecords}
            checkedRecordCount={checkedRecordCount}
          />
        </div>
      </section>
      <SaveTaskPopup />
      <AddLabel module={"task"} />
      {/*----------- custom notification component start -----------*/}
      <CustomNotification />
      {/*----------- custom notification component end -----------*/}
    </>
  );
};
export default Task;
