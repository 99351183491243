/* eslint-disable */
import { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";

//import translation function for language translation
import { useTranslation } from "react-i18next";
import { GlobalProvider } from "context/GlobalContext";

/*import url and gateway methods */
import { postData, putData } from "utils/Gateway";
import * as url from "urlhelpers/UrlHelper";

const SalesOrderHeader = ({
  setDownloadExcel,
  blockRefSelected,
  setSearch,
  records,
}) => {
  const { t } = useTranslation(); //for translation

  const token = localStorage.getItem("token"); // token

  const {
    setReloadSalesOrderList,
    setSuccess,
    setError,
    setNotificationString,
  } = useContext(GlobalProvider);

  /* function to click download xls*/
  const handleDownloadClick = () => {
    setDownloadExcel(true);
    setTimeout(() => setDownloadExcel(false), 500);
  };

  /* hook to get the search value */
  const [searchValue, setSearchValue] = useState("");

  //function for deactivate/update user
  const deleteSalesOrder = () => {
    const checkboxes = document.querySelectorAll(
      'input[name="selectSalesOrderRecord"]'
    );

    checkboxes.forEach(async (checkbox) => {
      if (checkbox.checked) {
        let salesData = {
          status: "0",
        };
        try {
          let requestUrl =
            url.API_BASEURL +
            url.API_UPDATE_SALES_ORDER +
            `/${checkbox.value}` +
            `?token=${token}`;
          const response = await putData(requestUrl, salesData);
          if (response.status) {
            setReloadSalesOrderList(true);
            setSuccess(true);
            setNotificationString("Sales order has been deleted successfully");
          }
        } catch (error) {
          console.error(error.message);
          setError(true);
          setNotificationString("Something went wrong, please try again later");
        }
      }
    });
  };

  //function for deactivate/update user
  const sendInvoiceByMail = () => {
    const checkboxes = document.querySelectorAll(
      'input[name="selectSalesOrderRecord"]'
    );

    checkboxes.forEach(async (checkbox) => {
      if (checkbox.checked) {
        const email = checkbox.getAttribute("data-emailstring");
        const recieversMailsArray = [];
        if (email) {
          recieversMailsArray.push(email);
        }

        const mailData = {
          salesorderid: checkbox.value,
          template: "budget-customer-invoice",
          receivermailarray: recieversMailsArray,
        };

        console.log("mail data", mailData);

        try {
          let requestUrl =
            url.API_BASEURL +
            url.API_SEND_MESSAGE_BY_TEMPLATE +
            `?token=${token}`;

          const response = await postData(requestUrl, mailData);

          if (response.status) {
            setSuccess(true);
            setNotificationString("Mail sent successfully");
          }
        } catch (error) {
          console.error(error.message);
          setError(true);
          setNotificationString("Something went wrong, please try again later");
        }
      }
    });
  };

  /* hook to empty search when searchValue gets empty */
  useEffect(() => {
    !searchValue && setSearch("");
  }, [searchValue]);

  return (
    <div className="filter-container d-flex flex-wrap align-items-center gap-2 border-bottom border-gray">
      {/* -------- show column button start -------- */}
      <Link
        to="#salesorder_show_columnCollapse"
        data-bs-toggle="collapse"
        className="column-collapse btn btn-primary icon-btn mw-unset"
      >
        <span className="d-block material-symbols-outlined icon-fill">
          visibility
        </span>
      </Link>
      {/* -------- show column button end -------- */}

      {/* -------- excel download button start -------- */}
      <Link
        onClick={handleDownloadClick}
        to="#"
        className="download btn btn-primary icon-btn mw-unset"
      >
        <span className="d-block material-symbols-outlined">download</span>
      </Link>
      {/* -------- excel download button end -------- */}

      {/* -------- search form section start -------- */}
      <div className="search-container position-relative d-none d-md-block">
        <form onSubmit={(e) => e.preventDefault()}>
          <input
            type="search"
            placeholder={t("Search here")}
            className="form-control bg-transparent py-2 border-0"
            onChange={(e) => setSearchValue(e.target.value)}
            onKeyDown={(e) => {
              e.key === "Enter" && (e.preventDefault(), setSearch(searchValue));
            }}
          />
          <button
            onClick={() => setSearch(searchValue)}
            type="submit"
            className="bg-transparent text-dark p-0 border-0 position-absolute top-50 translate-middle-y"
          >
            <span className="d-block material-symbols-outlined">search</span>
          </button>
        </form>
      </div>
      {/* -------- search form section end -------- */}

      {/* ------------- showing records start ----------------- */}
      <p className="text-gray fw-medium ms-auto me-1">
        {t("Showing")}
        <span className="text-black ms-1">{records}</span> {t("Records")} -
        <span className="text-black ms-1" ref={blockRefSelected}>
          {" "}
          0{" "}
        </span>{" "}
        {t("Selected")}
      </p>
      {/* ------------- showing records end ----------------- */}

      {/* ---------- refresh button start -------- */}
      <Link
        to="#"
        onClick={() => {
          setReloadSalesOrderList(true);
          setSuccess(true);
          setNotificationString("The list has been refreshed successfully");
        }}
        className="filter-btn btn btn-primary icon-btn mw-unset"
      >
        <span
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="Refresh"
          className="d-block material-symbols-outlined"
        >
          refresh
        </span>
      </Link>
      {/* ---------- refresh button end -------- */}

      {/* ---------- filter button start -------- */}
      <Link
        to="#filterSalesOrderCollapse"
        data-bs-toggle="collapse"
        className="filter-btn btn btn-primary icon-btn mw-unset "
      >
        <span className="d-block material-symbols-outlined">filter_alt</span>
      </Link>
      {/* ---------- filter button end -------- */}

      {/* ---------- create new sales order -------- */}
      <Link
        // to="#addnewsalesorder"
        to="#saveSalesOrderPopup"
        data-bs-toggle="offcanvas"
        role="button"
        className="btn btn-primary icon-btn mw-unset"
      >
        <span
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title={t("New Sales Order")}
          className="d-block material-symbols-outlined"
        >
          add
        </span>
      </Link>
      {/* ---------- sales order ends here -------- */}

      {/* -------- action button dropdown section start ------- */}
      <div className="dropdown">
        <button
          className="btn btn-primary dropdown-toggle mw-unset"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          {t("Actions")}
        </button>
        <ul className="dropdown-menu border-0 shadow-lg">
          {/* ------ add/remove section start ------ */}
          <li>
            <Link className="dropdown-item" to="#">
              {t("Add/Remove Label")}
            </Link>
          </li>
          {/* ------ add/remove section end ------ */}

          {/* ------ send mail button start ------- */}

          <li>
            <Link to="#" className="dropdown-item" onClick={sendInvoiceByMail}>
              {t("Send Invoice By Mail")}
            </Link>
          </li>

          {/* ------ send mail button end ------- */}

          {/* ------ delete section start ------ */}
          <li>
            <Link className="dropdown-item" to="#" onClick={deleteSalesOrder}>
              {t("Delete selected")}
            </Link>
          </li>
          {/* ------ delete section end ------ */}
        </ul>
      </div>
      {/* -------- action button dropdown section end ------- */}
    </div>
  );
};

export default SalesOrderHeader;
