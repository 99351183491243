/* eslint-disable */
import { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-select';

//import translation function for language translation
import { useTranslation } from 'react-i18next';

//import context
import { GlobalProvider } from 'context/GlobalContext';

const ToggleColumns = () => {
  const { t } = useTranslation(); //for translation

  const {
    setShowMailTemplateNameColumn,
    setShowMailTemplateSlugColumn,
    setShowMailTemplateSubjectColumn,
    setShowMailTemplateLabelColumn,
    setShowMailTemplateDateColumn,
    /* ---------------------------------------------- */
    showMailTemplateNameColumn,
    showMailTemplateSlugColumn,
    showMailTemplateSubjectColumn,
    showMailTemplateLabelColumn,
    showMailTemplateDateColumn,
  } = useContext(GlobalProvider);

  const columnOption = [
    { label: t('Name'), value: 'Name' },
    { label: t('Slug'), value: 'Slug' },
    { label: t('Subject'), value: 'Subject' },
    { label: t('Label'), value: 'Label' },
    { label: t('Date'), value: 'Date' },
  ];

  //use state for select field/columns
  const [selectedColumnValue, setSelectedColumnValue] = useState(null);
  const [columns, setColumns] = useState([]);

  //function for select column by dropdown
  const fieldSelectionHandler = val => {
    setSelectedColumnValue(val);
    const columnValaues = val.map(column => {
      return column.value;
    });
    setColumns(columnValaues);
  };

  //function for display selected columns
  const columnSelectionHandler = e => {
    e.preventDefault();
    if (columns.length == 0) {
      return;
    } else {
      setShowMailTemplateNameColumn(false);
      setShowMailTemplateSlugColumn(false);
      setShowMailTemplateSubjectColumn(false);
      setShowMailTemplateLabelColumn(false);
      setShowMailTemplateDateColumn(false);

      columns.map(column => {
        if (column == 'Name') {
          setShowMailTemplateNameColumn(true);
        }

        if (column == 'Slug') {
          setShowMailTemplateSlugColumn(true);
        }

        if (column == 'Subject') {
          setShowMailTemplateSubjectColumn(true);
        }

        if (column == 'Label') {
          setShowMailTemplateLabelColumn(true);
        }

        if (column == 'Date') {
          setShowMailTemplateDateColumn(true);
        }
      });
    }
  };

  const resetColumn = () => {
    setShowMailTemplateNameColumn(true);
    setShowMailTemplateSlugColumn(true);
    setShowMailTemplateSubjectColumn(true);
    setShowMailTemplateLabelColumn(true);
    setShowMailTemplateDateColumn(true);
    setSelectedColumnValue(null);
    setColumns([]);
  };

  return (
    <div className="collapse mt-3" id="columntemplateCollapse">
      <div className="p-3 bg-gray-200">
        <div className="d-flex gap-3 align-items-center justify-content-between mb-3">
          <h4 className="mb-0">{t('Show Columns')}</h4>
          <Link
            to="#"
            onClick={resetColumn}
            className="d-flex gap-1 align-items-center text-black"
          >
            <span className="d-block material-symbols-outlined icon-md">
              sync
            </span>
            <span className="d-block fw-medium">{t('Refresh')}</span>
          </Link>
        </div>
        <form onSubmit={e => columnSelectionHandler(e)}>
          <div className="form-container d-flex flex-wrap gap-2">
            <div className="form-group flex-fill">
              <Select
                className="nosearch_select"
                isMulti
                options={columnOption}
                value={selectedColumnValue}
                onChange={val => fieldSelectionHandler(val)}
              />
            </div>
            <div className="action">
              <button type="submit" className="btn btn-primary mw-unset">
                {t('Show')}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ToggleColumns;
