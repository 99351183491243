/* eslint-disable */
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";

//import translation function for language translation
import { useTranslation } from "react-i18next";

//import context
import { GlobalProvider } from "context/GlobalContext";

/*import url and gateway methods */
import { getData, putData } from "utils/Gateway";
import * as url from "urlhelpers/UrlHelper";

const ReservationHeader = ({
  setDownloadExcel,
  setSearch,
  records,
  blockRefSelected,
  setFilterTitle,
  setfilterJsonData,
  setFilterId,
  setFilterDefault,
  reloadFilterList,
  setReloadFilterList,
  setResetFilterData,
  setIsSearchExecute,
}) => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const token = localStorage.getItem("token"); // token
  const {
    setReloadReservationList,
    setReloadRefresh,
    setResetReserveSaveForm,
    setSuccess,
    setError,
    setNotificationString,
  } = useContext(GlobalProvider);

  const { t } = useTranslation(); //for translation

  const [filterList, setFilterList] = useState([]); //filter option
  const [filterValue, setFilterValue] = useState(null);

  //function for get all saved filters
  const getAllFilters = async () => {
    try {
      let requestUrl =
        url.API_BASEURL +
        url.API_GET_ALL_FILTER +
        `?token=${token}&isselect=${true}`;

      // console.log("filter request url >>", requestUrl);

      const response = await getData(requestUrl);

      // console.log("all filter list reseponse >>>", response);

      if (response.status) {
        setFilterList(response.data);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  // filter select handler
  const filterSelectionHandler = (val) => {
    setFilterValue(val);
    setFilterTitle(val.label);
    setFilterId(val.value);

    const jsonFilterData = JSON.parse(val.parameters);
    setfilterJsonData(jsonFilterData);
    setFilterDefault(val.isdefault);
  };

  //function for reset filter select
  const resetFilterSelect = () => {
    setFilterValue(null);
    setFilterTitle("");
    setFilterId(null);
    setfilterJsonData(null);
    setFilterDefault(false);
    setResetFilterData(true);
  };

  //function for search filter
  const searchFilterHandler = () => {
    setIsSearchExecute(true);
  };

  //function for change is template
  // const removeTemplateHandler = () => {
  //   const checkboxes = document.querySelectorAll(
  //     'input[name="selectReserveRecord"]'
  //   );

  //   checkboxes.forEach(async (checkbox) => {
  //     if (checkbox.checked) {
  //       let reserveData = {
  //         istemplate: "false",
  //       };

  //       try {
  //         let requestUrl =
  //           url.API_BaseUrl +
  //           url.API_UPDATE_RESERVE_EVENT +
  //           `/${checkbox.value}` +
  //           `?token=${token}`;

  //         const response = await putData(requestUrl, reserveData);
  //         if (response.status) {
  //           setReloadReservationList(true);
  //           setSuccess(true);
  //           setNotificationString(t("Remove template successfully"));
  //         }
  //       } catch (error) {
  //         console.error(error.message);
  //         setError(true);
  //         setNotificationString(
  //           t("Something went wrong, please try again later")
  //         );
  //       }
  //     }
  //   });
  // };

  //function for delete/de-activate event
  const confirmDelete = () => {
    const result = window.confirm(t("Are you sure you want to delete?"));
    if (result) {
      deActivateReserves();
    }
  };

  const deActivateReserves = () => {
    const checkboxes = document.querySelectorAll(
      'input[name="selectReserveRecord"]'
    );

    checkboxes.forEach(async (checkbox) => {
      if (checkbox.checked) {
        let reserveData = {
          status: "0",
        };

        try {
          let requestUrl =
            url.API_BASEURL +
            url.API_UPDATE_RESERVE_EVENT +
            `/${checkbox.value}` +
            `?token=${token}`;

          const response = await putData(requestUrl, reserveData);
          if (response.status) {
            setReloadReservationList(true);
            setSuccess(true);
            setNotificationString(t(response.message));
          }
        } catch (error) {
          console.error(error.message);
          setError(true);
          setNotificationString(
            t("Something went wrong, please try again later")
          );
        }
      }
    });
  };

  //function for calculate cost
  const addCostHandeler = async (e) => {
    const checkboxes = document.querySelectorAll(
      'input[name="selectReserveRecord"]'
    );

    for (const checkbox of checkboxes) {
      if (checkbox.checked) {
        try {
          let requestUrl =
            url.API_BASEURL +
            url.API_CREATE_COST +
            `/${checkbox.value}` +
            `?token=${token}`;

          // console.log(requestUrl);

          const response = await getData(requestUrl);

          // console.log("cost calculation response", response);

          if (response.status) {
            // show success notification
            setSuccess(true);
            setNotificationString(t("Cost calculated for reserve"));
            continue;
          }
        } catch (error) {
          console.error(error.message);
        }
      }
    }
  };

  /* hook to get the search value */
  const [searchValue, setSearchValue] = useState("");

  /* function to click download xls*/
  const handleDownloadClick = () => {
    setDownloadExcel(true);
    setTimeout(() => setDownloadExcel(false), 500);
  };

  //refresh function
  const refreshHandler = () => {
    setReloadRefresh(true);
    // setReloadReservationList(true);
  };

  //function for default filter
  const defaultFilterHandler = async () => {
    try {
      let requestUrl =
        url.API_BASEURL + url.API_GET_DEFAULT_FILTER + `?token=${token}`;

      // console.log("filter request url >>", requestUrl);

      const response = await getData(requestUrl);

      console.log("default filter response >>>", response);

      if (response.status) {
        if (response.data) {
          let filterData = response.data;

          if (filterData.isdefault) {
            filterSelectionHandler(filterData);
          }
        }
      } else {
        resetFilterSelect();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    !searchValue && setSearch("");
  }, [searchValue]);

  useEffect(() => {
    if (reloadFilterList) {
      // setFilterValue(null);
      getAllFilters();
      setReloadFilterList(false);
    }
  }, [reloadFilterList]);

  useEffect(() => {
    getAllFilters();
    defaultFilterHandler();
  }, []);

  return (
    <div className="filter-container d-flex flex-wrap align-items-center gap-2">
      {/* --------- toogle column section start --------- */}
      <Link
        to="#reservation_show_columnCollapse"
        className="column-collapse btn btn-primary icon-btn mw-unset"
        data-bs-toggle="collapse"
        role="button"
        aria-expanded="false"
        aria-controls="columnCollapse"
      >
        <span
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title={t("Show columns")}
          className="d-block material-symbols-outlined icon-fill"
        >
          visibility
        </span>
      </Link>
      {/* --------- toogle column section end --------- */}

      {/* --------- download excel button start --------- */}
      <Link
        to="#"
        onClick={handleDownloadClick}
        className="download btn btn-primary icon-btn mw-unset"
        data-bs-toggle="tooltip"
        data-bs-placement="top"
        title={t("Download in XLS")}
      >
        <span className="d-block material-symbols-outlined">download</span>
      </Link>
      {/* --------- download excel button end --------- */}

      {/* --------- redirect mail conversation start -------- */}
      <Link
        to="/conversation"
        className="email btn btn-primary icon-btn mw-unset position-relative d-none d-md-block"
        data-bs-toggle="tooltip"
        data-bs-placement="top"
        title={t("Mail conversation")}
      >
        <span className="d-block material-symbols-outlined icon-fill">
          mail
        </span>
        {/* <span className="badge d-inline-block bg-black position-absolute top-0 start-100 translate-middle">
          <span className="unread">99</span>/150
        </span> */}
      </Link>
      {/* --------- redirect mail conversation end -------- */}

      {/* ------- input search section start --------- */}
      <div className="search-container position-relative d-none d-md-block">
        <form onSubmit={(e) => e.preventDefault()}>
          <input
            type="search"
            placeholder={t("Search here")}
            className="form-control bg-transparent py-2 border-0"
            onChange={(e) => setSearchValue(e.target.value)}
            onKeyDown={(e) => {
              e.key === "Enter" && (e.preventDefault(), setSearch(searchValue));
            }}
          />
          <button
            onClick={() => setSearch(searchValue)}
            type="button"
            className="bg-transparent text-dark p-0 border-0 position-absolute top-50 translate-middle-y"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Search"
          >
            <span className="d-block material-symbols-outlined">search</span>
          </button>
        </form>
      </div>
      {/* ------- input search section end --------- */}

      {/* ------ select filter section start ------ */}
      <div className="d-flex align-items-center">
        <label className="mt-2 me-2"> {t("Quick filter")} </label>
        <Select
          placeholder={t("Select filter")}
          className="nosearch_select quickfilter"
          options={filterList}
          value={filterValue}
          onChange={(val) => {
            filterSelectionHandler(val);
          }}
        />

        <button
          className="ms-2 btn btn-sm btn-primary mw-unset"
          onClick={searchFilterHandler}
        >
          {t("Filter")}
        </button>

        <Link
          to="#"
          className="btn btn-sm mw-unset"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="Reset Filter name"
          onClick={resetFilterSelect}
        >
          {t("Clear")}
        </Link>
      </div>
      {/* ------ select filter section end ------ */}

      {/* ------------- showing records start ----------------- */}
      <p className="text-gray fw-medium ms-auto me-3">
        <span className="text-black ms-1">{records}</span> {t("Records")} -
        <span className="text-black ms-1" ref={blockRefSelected}>
          {" "}
          0{" "}
        </span>{" "}
        {t("Selected")}
      </p>
      {/* ------------- showing records end ----------------- */}

      {/* ---------- refresh button start -------- */}
      <Link
        to="#"
        className="filter-btn btn btn-primary icon-btn mw-unset"
        onClick={refreshHandler}
      >
        <span
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title={t("Refresh")}
          className="d-block material-symbols-outlined"
        >
          refresh
        </span>
      </Link>
      {/* ---------- refresh button end -------- */}

      {/* ---------- search filter button start -------- */}
      <Link
        to="#filterCollapse"
        className="filter-btn btn btn-primary icon-btn mw-unset"
        data-bs-toggle="collapse"
        role="button"
        aria-expanded="false"
        aria-controls="filterCollapse"
      >
        <span
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title={t("Filter")}
          className="d-block material-symbols-outlined"
        >
          filter_alt
        </span>
      </Link>
      {/* ---------- search filter button end -------- */}

      {/* ---------- add new reserve form button start -------- */}
      {userInfo?.role?.name == "admin" || userInfo?.role?.name == "manager" ? (
        <Link
          to="#saveReservationPopup"
          data-bs-toggle="offcanvas"
          role="button"
          aria-controls="saveReservationPopup"
          className="save-reservation btn btn-primary icon-btn mw-unset"
          onClick={() => {
            setResetReserveSaveForm(true);
          }}
        >
          <span
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title={t("Save Reserve")}
            className="d-block material-symbols-outlined"
          >
            add
          </span>
        </Link>
      ) : null}
      {/* ---------- add new reserve form button start -------- */}

      {/* -------- action dropdown section start -------- */}
      <div className="dropdown">
        <button
          className="btn btn-primary dropdown-toggle mw-unset"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          {t("Actions")}
        </button>
        <ul className="dropdown-menu border-0 shadow-lg">
          {/* ------ add tag button start ------- */}
          <li>
            <Link
              to="#tags_pnl"
              data-bs-toggle="offcanvas"
              role="button"
              className="dropdown-item"
            >
              {t("Add/Remove Label")}
            </Link>
          </li>
          {/* ------ add tag button end ------- */}

          {/* ------ download excel button start ------- */}
          <li>
            <Link
              onClick={handleDownloadClick}
              className="dropdown-item"
              to="#"
            >
              {t("Download in Xls")}
            </Link>
          </li>
          {/* ------ download excel button end ------- */}

          {/* ------ send mail button start ------- */}
          {userInfo?.role?.name == "admin" ||
          userInfo?.role?.name == "manager" ? (
            <li>
              <Link
                to="#createSendNotificationEmailPopup"
                data-bs-toggle="offcanvas"
                role="button"
                aria-controls="createEmailPopup"
                className="dropdown-item"
              >
                {t("Send Mail")}
              </Link>
            </li>
          ) : null}
          {/* ------ send mail button end ------- */}

          {/* ------ send group mail ------- */}
          {userInfo?.role?.name == "admin" ||
          userInfo?.role?.name == "manager" ? (
            <li>
              <Link
                to="#sendGroupMailPopup"
                data-bs-toggle="offcanvas"
                role="button"
                aria-controls="sendGroupMailPopup"
                className="dropdown-item"
              >
                {t("Send Group mail")}
              </Link>
            </li>
          ) : null}
          {/* ------ send group mail ------- */}

          {/* ------ change status ------- */}
          {userInfo?.role?.name == "admin" ||
          userInfo?.role?.name == "manager" ? (
            <li>
              <Link
                to="#status_pnl"
                data-bs-toggle="offcanvas"
                role="button"
                className="dropdown-item"
              >
                {t("Change Status")}
              </Link>
            </li>
          ) : null}
          {/* ------ change status ends------- */}

          {/* ----- Calculate Cost start ------ */}
          {userInfo?.role?.name == "admin" ||
          userInfo?.role?.name == "manager" ? (
            <li>
              <Link
                className="dropdown-item"
                to="#"
                role="button"
                aria-controls="costcalculation"
                onClick={addCostHandeler}
              >
                {t("Calculate cost")}
              </Link>
            </li>
          ) : null}
          {/* ----- Calculate Cost end ------ */}

          {/* ------ Create sales order start ------- */}
          {userInfo?.role?.name == "admin" ||
          userInfo?.role?.name == "manager" ? (
            <li>
              <Link
                to="#createsalesorder"
                data-bs-toggle="offcanvas"
                role="button"
                className="dropdown-item"
              >
                {t("Create Salesorder")}
              </Link>
            </li>
          ) : null}
          {/* ------ Create sales order end------- */}

          {/* ------ delete button start ------- */}
          {userInfo?.role?.name == "admin" ||
          userInfo?.role?.name == "manager" ? (
            <li>
              <Link className="dropdown-item" to="#" onClick={confirmDelete}>
                {t("Delete selected")}
              </Link>
            </li>
          ) : null}
          {/* ------ delete button end ------- */}
        </ul>
      </div>
      {/* -------- action dropdown section end -------- */}
    </div>
  );
};

export default ReservationHeader;
