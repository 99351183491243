/* eslint-disable */
import MaterialCalendarBody from "components/MaterialCalendarComponents/MaterialCalendarBody/MaterialCalendarBody";
import MaterialCalendarBreadCrump from "components/MaterialCalendarComponents/MaterialCalendarBreadCrump/MaterialCalendarBreadCrump";
import MaterialCalendarHeader from "components/MaterialCalendarComponents/MaterialCalendarHeader/MaterialCalendarHeader";
import AddNewMaterialSchedulePopup from "components/MaterialCalendarComponents/Popup/AddNewMaterialSchedulePopup";
import EditMaterialSchedulePopup from "components/MaterialCalendarComponents/Popup/EditMaterialSchedulePopup";
import CustomNotification from "components/common/CustomNotification";
import LeftMenu from "components/common/LeftMenu";
import UserMenu from "components/common/UserMenu";
import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";

/*import url and gateway methods */
import { getData } from "utils/Gateway";
import * as url from "urlhelpers/UrlHelper";

const MaterialCalendar = () => {
  const [selectedKit, setSelectedKit] = useState("");

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const params = useParams();
  const token = localStorage.getItem("token");

  const getSelectedKit = async () => {
    try {
      if (params.id) {
        const requestUrl =
          url.API_BASEURL +
          url.API_MATERIALKIT_DETAILS +
          `/${params.id}?token=${token}`;

        const response = await getData(requestUrl);

        console.log("Response Material Kit details ------>", response);

        if (response.status) {
          setSelectedKit(response.data);
        } else {
          setSelectedKit(null);
        }
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  useEffect(() => {
    document.title = " Material Calendar";
    if (params.id) {
      getSelectedKit();
    }
  }, []);

  return (
    <>
      <UserMenu />
      <LeftMenu />
      {userInfo?.role?.name == "admin" || userInfo?.role?.name == "manager" ? (
        <section className="mail-wrapper py-4">
          <div className="container-fluid px-4">
            <MaterialCalendarBreadCrump selectedKit={selectedKit} />
            <MaterialCalendarHeader selectedKit={selectedKit} />
            <MaterialCalendarBody selectedKit={selectedKit} />
          </div>
        </section>
      ) : null}
      <AddNewMaterialSchedulePopup selectedKit={selectedKit} />
      <EditMaterialSchedulePopup selectedKit={selectedKit} />
      {/*----------- custom notification component start -----------*/}
      <CustomNotification />
      {/*----------- custom notification component end -----------*/}
    </>
  );
};

export default MaterialCalendar;
