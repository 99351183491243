import React, { useEffect } from 'react';
import Header from 'components/common/Header';
import RsgisterLeftPanel from 'components/RegisterComponents/LeftPanel/Leftpanel';
import RegisterRightPanel from 'components/RegisterComponents/RightPanel/RightPanel';

const Register = () => {
  useEffect(() => {
    document.title = 'Register'; // Update the title when Register component mounts
  }, []);

  return (
    <div id="site_wrapper">
      <Header />
      <section className="login_wrapper signup_wrapper">
        <RsgisterLeftPanel />
        <RegisterRightPanel />
      </section>
    </div>
  );
};

export default Register;
