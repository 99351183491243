import React from 'react';
import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

const SurveyFeedbackBreadCrumb = () => {
  const { t } = useTranslation(); //for translation

  return (
    <nav aria-label="breadcrumb">
      <ul className="breadcrumb">
        <li className="breadcrumb-item">
          <Link to="/" className="text-secondary">
            {t('Dashboard')}
          </Link>
        </li>
        <li className="breadcrumb-item text-gray-600" aria-current="page">
          {t('Survey')}
        </li>
      </ul>
    </nav>
  );
};

export default SurveyFeedbackBreadCrumb;
