/* eslint-disable */
import React, { useState, useEffect, useContext, createRef } from "react";

import { Link } from "react-router-dom";

// kendo react modules ==========================
import "@progress/kendo-theme-material/dist/all.css";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";

// impoting excel colums
import {
  ExcelExport,
  ExcelExportColumn,
} from "@progress/kendo-react-excel-export";

//api url requirement
import * as url from "urlhelpers/UrlHelper";
import { getData } from "utils/Gateway";

//import context
import { GlobalProvider } from "context/GlobalContext";

//import translation function for language translation
import { useTranslation } from "react-i18next";

const RolePriviledgeList = () => {
  const token = localStorage.getItem("token"); // token
  const { t } = useTranslation(); //for translation

  const {
    reloadRolePriviledgeList,
    setReloadRolePriviledgeList,
    setRolePriviledgeData,
    screenHeight,
  } = useContext(GlobalProvider);

  const [previledgeList, setPreviledgeList] = useState([]);
  const [responseReceived, setResponseReceived] = useState(false);

  // without pagination ------------------------------------
  const initialDataState = {};
  const [dataState, setDataState] = useState();
  const [result, setResult] = useState(
    process(previledgeList, initialDataState)
  );

  const onDataStateChange = (event) => {
    setDataState(event.dataState);
    setResult(process(previledgeList, event.dataState));
  };

  /* function to get all label data */
  const getAllPreviledgeList = async () => {
    try {
      let requestUrl =
        url.API_BASEURL + url.API_GET_ALL_ROLE_PRIVILEDGE + `?token=${token}`;

      // console.log("role previliege list url =====>>>", requestUrl);

      const response = await getData(requestUrl);

      // console.log("role previliege list response ========>>>", response);

      if (response.data) {
        setPreviledgeList(response.data);
        setResponseReceived(true);
        setResult(process(response.data, initialDataState));
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  // mark all checkbox as selected ----------------
  const selectAllRecords = (e) => {
    document
      .querySelectorAll('.k-master-row input[name="selectLabelRecord"]')
      .forEach((checkbox) => {
        checkbox.checked = e.target.checked;
      });
  };

  // blocks to replace the Grid HTML ==============================================
  const SelectRecordHeader = () => {
    return (
      <div className="d-flex align-items-center">
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            id="selectAllMain"
            onClick={selectAllRecords}
          />
        </div>
      </div>
    );
  };

  /* Select single row */
  const SelectRecordRow = (props) => {
    return (
      <td>
        <div className="form-check">
          <input
            className="form-check-input "
            type="checkbox"
            value={props.dataItem._id}
            name="selectLabelRecord"
          />
        </div>
      </td>
    );
  };

  /* custom title html */
  const titleHtml = ({ dataItem }) => {
    return (
      <td className="title">
        <div className="d-flex align-items-center gap-2">
          <span>{dataItem.rolename}</span>
          <Link
            to="#addUpdateRolePrevPopup"
            data-bs-toggle="offcanvas"
            className="ml-auto edit_icon"
          >
            <span
              className="d-block material-symbols-outlined icon-md"
              onClick={() => {
                setRolePriviledgeData(dataItem._id);
              }}
            >
              edit
            </span>
          </Link>
        </div>
      </td>
    );
  };

  useEffect(() => {
    if (reloadRolePriviledgeList) {
      getAllPreviledgeList();
      setReloadRolePriviledgeList(false);
    }
  }, [reloadRolePriviledgeList]);

  /* hook to reload list */
  useEffect(() => {
    getAllPreviledgeList();
  }, []);

  return (
    <div className="table-responsive">
      <Grid
        style={{ height: screenHeight }}
        data={result}
        filterable={true}
        sortable={true}
        groupable={true}
        onDataStateChange={onDataStateChange}
        {...dataState}
        //pageable = {true}
        //total = {products.length}
        reorderable
        // resizable
      >
        <GridColumn
          field="id"
          headerCell={SelectRecordHeader}
          cell={SelectRecordRow}
          filterable={false}
          sortable={false}
          width="70px"
        />
        <GridColumn
          cell={titleHtml}
          field="rolename"
          title={t("Role")}
          //width="200px"
        />
        <GridColumn field="modulenames" title={t("Previledge")} 
        //width="200px" 
        />
      </Grid>
    </div>
  );
};

export default RolePriviledgeList;
