/* eslint-disable */
import React, { useState, useContext, useEffect } from "react";

import { useParams } from "react-router-dom";

//import translation function for language translation
import { useTranslation } from "react-i18next";

/*import url and gateway methods */
import { getData } from "utils/Gateway";
import * as url from "urlhelpers/UrlHelper";
import { assetImages } from "constants";

import { formatStringDate } from "helpers/DateCalculation";

//import option for availibility
import availibilityOptions from "data/Prod/CalendarAvailability.json";
import Shifts from "data/Prod/Shifts.json";

//import context
import { GlobalProvider } from "context/GlobalContext";



const ShowAgendaDetails = ({selectedDate, selectedShift, selectedStarttime, onCloseModal}) => {

  const token = localStorage.getItem("token"); // token

  /* ========== get user id from url params ========= */
  const params = useParams();

  const { t } = useTranslation(); //for translation


  const [availableEducators, setAvailableEducators] = useState([]);
  const [assignedEducators, setAssignedEducators] = useState([]);
  const [reserveEvents, setReserveEvents] = useState([]);
  const [responseDate, setResponseDate] = useState('');
  const [responseShift, setResponseShift] = useState('');
  const [requiredEducator, setRequiredEducator] = useState('');


  const today = new Date().toISOString().split("T")[0];

  //function for get current time and set by defualt
  const getCurrentTime = () => {
    const now = new Date();
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");

    const timenow = `${hours}:${minutes}`;

    return timenow;
  };

  // get all the educators available in the date and shift
  const getAgendaEducators = async() => {
    try {

        let requestUrl =
        url.API_BASEURL +
        url.API_GET_EDUCATORS_BY_EVENT_AGENDA +
        `?token=${token}`+
        `&date=${selectedDate}`+
        `&shift=${selectedShift}`+
        `&starttime=${selectedStarttime}`;

        console.log('API_GET_EDUCATORS_BY_EVENT_AGENDA', requestUrl);

        const response = await getData(requestUrl);

        console.log("Response in agenda body------->", response);

        if(response.status){
            setAvailableEducators(response.availableEducators);

            setAssignedEducators(response.reservedEducators);

            setResponseDate(response.date);

            setResponseShift(response.shift);

            setReserveEvents(response.reserveEvents);

            setRequiredEducator(response.requiredEducator)
        }

    }catch(error){
        console.log(error.message);
    }
    
  }


  const closeModalHandler = () =>{

    resetHandler();
    onCloseModal();

  }

  const resetHandler = () =>{

    setAvailableEducators([]);
    setAssignedEducators([]);
    setReserveEvents([]);
    setResponseDate('');
    setResponseShift('');
    setRequiredEducator('');

  }

  //use effect for getAgendaEducators
  useEffect(() => {
    
    resetHandler();

    if(selectedDate && selectedStarttime){
        getAgendaEducators();
    }

  }, [selectedDate, selectedStarttime]);

  return (
    <div
      className="modal fade"
      id="agendaDetailInfo"
      tabIndex="-1"
      aria-labelledby="submitSurveyLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div className="modal-dialog modal-xl modal-dialog-centered">
        <div className="modal-content border-0 rounded-0">
          <div className="modal-header border-0">
            <button
              type="button"
              className="btn-close p-2 bg-white shadow-none shadow-sm m-0 ms-auto"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={closeModalHandler}
            ></button>
          </div>
          <div className="modal-body p-5 pt-0">
             {/* {t("Order Id")} */}
            <h3 className="text-center ">{t("Agenda Information")}</h3>
            <hr/>
            <p>
                <b>{t("Date")} : <span className="text-primary"> {responseDate ? formatStringDate(responseDate) : ''} </span> </b>
                <b className="ms-5">{t("Shift")} : <span className="text-primary"> {responseShift} </span> </b>
            </p>
            
            
            <hr/>
            <p>                
                 
                <b>{t("Educators Required")} : <span className="text-danger">{requiredEducator}</span> </b>

                <b className="ms-4">{t("Educators Assigned")} : <span className="text-danger">{assignedEducators.length}</span> </b> 

                <b className="ms-4">{t("Reserves Events")} : <span className="text-danger">{reserveEvents.length}</span> </b>
            </p>
            {reserveEvents.length > 0 && reserveEvents.map((reserve, index)=>{
                return(
                        <div key={index}>
                            <hr/>
                            <p className="text-primary">
                              {reserve.isconsecutivereserve ? (
                                <span className=" badge bg-danger me-2 fw-bold" > C </span>
                              ) : ''}
                              
                              <span className="fw-bold me-2"> {reserve.starttime} - {reserve.endtime} </span> ({reserve.reserveid})
                              
                            </p>
                            <p className="d-flex align-items-center text-nowrap">
                                <span> <b className="me-2">{t("School")} / {t("Beneficiary")} :</b> 
                                {reserve.beneficiaries[0]? reserve.beneficiaries[0].name + '' + (reserve.beneficiaries[0].surname ? reserve.beneficiaries[0].surname : '' ): ''}
                                </span>
                            </p>
                            <p className="d-flex align-items-center text-nowrap ms-2">
                                <span className="material-icons-outlined">subdirectory_arrow_right</span>
                                <b className="me-2">  {t("Educators")} :</b>
                                {reserve.educators.length > 0 && reserve.educators.map((educator, indes)=>{
                                      return  (<>
                                            <span className="d-block icon-user me-2">
                                                <img
                                                src={
                                                    educator.photoimage
                                                    ? educator.photoimage.path
                                                    : assetImages.defaultUser
                                                }
                                                alt=""
                                                />
                                            </span>

                                            <span className="d-block text-primary">
                                            {educator.name} {educator.surname}
                                            </span>
                                        </>)
                                })}

                                </p>
                                <p className="d-flex  align-items-center text-nowrap ms-4">
                                    <span className="material-icons-outlined">subdirectory_arrow_right</span>
                                    <b className="me-2"> {t("Activity")} :</b>
                                    <span>
                                        {reserve.activitycode ? (reserve.activitycode.code != 'NULL' ? reserve.activitycode.code : '___-_' ) + ' : ' + reserve.activitycode.name : ''}
                                    </span>
                                </p>
                            
                        </div>
                    )
                
            })}
            <hr/>
            <p>
                <b>{t("Educators Available")} : <span className="text-success">{availableEducators.length}</span> </b>
                
            </p>
          
            {availableEducators.length > 0 && availableEducators.map((educator, index)=>{
                return(
                            <p className="d-flex gap-2 align-items-center text-nowrap" key={index}>
                                <span className="d-block icon-user">
                                    <img
                                    src={
                                        educator.photoimage
                                        ? educator.photoimage.path
                                        : assetImages.defaultUser
                                    }
                                    alt=""
                                    />
                                </span>
                                <span className="d-block text-success">
                                {educator.name} {educator.surname}
                                </span>
                            </p>
                    )
                
            })}
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShowAgendaDetails;
