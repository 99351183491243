/* eslint-disable */
import React, { useState, useEffect, useContext, createRef } from "react";

import { Link } from "react-router-dom";

// kendo react modules ==========================
import "@progress/kendo-theme-material/dist/all.css";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";

// impoting excel colums
import {
  ExcelExport,
  ExcelExportColumn,
} from "@progress/kendo-react-excel-export";

//api url requirement
import * as url from "urlhelpers/UrlHelper";
import { getData } from "utils/Gateway";

//import context
import { GlobalProvider } from "context/GlobalContext";

//import translation function for language translation
import { useTranslation } from "react-i18next";

import { assetImages } from "constants";

const EmployeeLogList = ({
  downloadExcel,
  search,
  setRecords,
  checkedRecordCount,
}) => {
  const token = localStorage.getItem("token"); // token
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const todayValue = new Date().toISOString().split("T")[0];

  const { t } = useTranslation(); //for translation

  const {
    screenHeight,
    reloadEmployeeLogList,
    setReloadEmployeeLogList,
    filterEmployeeLogDates,
    filterEmployeeLogUsers,
  } = useContext(GlobalProvider);

  const [LogList, setLogList] = useState([]);
  const [responseReceived, setResponseReceived] = useState(false);

  // without pagination ------------------------------------
  const initialDataState = {};
  const [dataState, setDataState] = useState();
  const [result, setResult] = useState(process(LogList, initialDataState));

  /* Handle the button's onClick event and set it to export the data by using the ExcelExport save method. */
  const _exporter = createRef();

  const exportExcel = () => {
    if (_exporter.current) {
      _exporter.current.save();
    }
  };

  const onDataStateChange = (event) => {
    setDataState(event.dataState);
    setResult(process(LogList, event.dataState));
  };

  /* function to search */
  const filterData = () => {
    if (!result || !result.data) {
      return []; // Return an empty array if result or result.data is undefined
    }

    const filteredData = result.data.filter((item) => {
      const owner = item?.owner?.toLowerCase() || "";
      const date = item?.date;
      const startTime = item?.starttime;
      const endTime = item?.endtime;
      const workHours = item?.workhour.toString();
      const taskCount = item?.taskcount;
      const taskCompletedCount = item?.taskcompletedcount;

      return (
        (owner && owner.includes(search.toLowerCase())) ||
        (date && date.includes(search.toLowerCase())) ||
        (startTime && startTime.includes(search.toLowerCase())) ||
        (endTime && endTime.includes(search)) ||
        (workHours && workHours.includes(search)) ||
        (taskCount && taskCount.includes(search.toLowerCase())) ||
        (taskCompletedCount &&
          taskCompletedCount.includes(search.toLowerCase()))
      );
    });
    setRecords(filteredData.length);
    return filteredData;
  };

  /* function to get all label data */
  const getAllLogList = async () => {
    try {
      let requestUrl =
        url.API_BASEURL + url.API_GET_ALL_EMPLOYEE_LOGS + `?token=${token}`;

      if (filterEmployeeLogDates != "") {
        requestUrl = requestUrl + `&filterdates=${filterEmployeeLogDates}`;
      } else {
        requestUrl = requestUrl + `&filterdates=${todayValue},${todayValue}`;
      }

      if (filterEmployeeLogUsers.length > 0) {
        requestUrl = requestUrl + `&filterusers=${filterEmployeeLogUsers}`;
      }

      // console.log("task list url =====>>>", requestUrl);

      const response = await getData(requestUrl);

      console.log("log list response ========>>>", response);

      if (response.data) {
        setLogList(response.data);
        setResponseReceived(true);
        dataState
          ? setResult(process(response.data, dataState))
          : setResult(process(response.data, initialDataState));
        setRecords(response.data.length);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  // check the selected count and show the count in top and also selected download xls ------------------------
  const handelSelectedCount = () => {
    let checkboxes = document.querySelectorAll(
      'input[name="selectTaskRecord"]'
    );

    let totalSelected = 0;
    let filteredData = [];
    checkboxes.forEach((checkbox) => {
      if (checkbox.checked) {
        totalSelected++;
        const filteredUser = LogList.find(
          (user) => user._id === checkbox.value
        );
        filteredUser && filteredData.push(filteredUser);
      }
    });

    // Update the content of the block
    checkedRecordCount(totalSelected);
    return filteredData;
  };

  // mark all checkbox as selected ----------------
  const selectAllRecords = (e) => {
    document
      .querySelectorAll('.k-master-row input[name="selectTaskRecord"]')
      .forEach((checkbox) => {
        checkbox.checked = e.target.checked;
      });
    handelSelectedCount();
  };

  // blocks to replace the Grid HTML ==============================================
  const SelectRecordHeader = () => {
    return (
      <div className="d-flex align-items-center">
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            id="selectAllMain"
            onClick={selectAllRecords}
          />
        </div>
      </div>
    );
  };

  /* Select single row */
  const SelectRecordRow = (props) => {
    return (
      <td>
        <div className="form-check">
          <input
            className="form-check-input "
            type="checkbox"
            value={props.dataItem._id}
            filtername="selectedrecord"
            name="selectTaskRecord"
            onClick={handelSelectedCount}
          />
        </div>
      </td>
    );
  };

  /* custom title html */
  const userHtml = ({ dataItem }) => {
    return (
      <td className="title kendoCustomColumn overflow-visible">
        <div className="d-flex align-items-center gap-2">
          <span>{dataItem.owner}</span>
          {/* <Link
            to="#add_task_view"
            data-bs-toggle="offcanvas"
            onClick={() => {
              seteditTaskId(dataItem._id);
            }}
            className="ml-auto edit_icon mt-2"
          >
            <span className="material-symbols-outlined">chevron_right</span>
          </Link> */}
          {/* <div className="dropdown cell-dropdown ms-auto mt-2">
            <button
              className="dropdown-toggle bg-transparent p-0 border-0"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <span className="d-block material-symbols-outlined icon-md">
                more_vert
              </span>
            </button>
            <ul className="dropdown-menu border-0 shadow-lg">
              <li>
                <Link
                  className="dropdown-item"
                  to="#add_task"
                  data-bs-toggle="offcanvas"
                  onClick={() => {
                    seteditTaskId(dataItem._id);
                  }}
                >
                  Edit
                </Link>
              </li>
              <li>
                <Link
                  className="dropdown-item"
                  to="#add_task_view"
                  data-bs-toggle="offcanvas"
                  onClick={() => {
                    seteditTaskId(dataItem._id);
                  }}
                >
                  View
                </Link>
              </li>
            </ul>
          </div> */}
        </div>
      </td>
    );
  };

  //complete status html
  const statusHtml = ({ dataItem }) => {
    return (
      <td className="activity">
        <span
          className={
            dataItem.completedpercent == "0"
              ? "text-orange"
              : dataItem.completedpercent == "3"
              ? "text-green"
              : dataItem.completedpercent == "1"
              ? "text-primary"
              : "text-secondary"
          }
        >
          {dataItem.taskstatus}
        </span>
      </td>
    );
  };

  useEffect(() => {
    if (filterEmployeeLogDates != "" || filterEmployeeLogUsers.length > 0) {
      getAllLogList();
    }
  }, [filterEmployeeLogDates, filterEmployeeLogUsers]);

  /* hook to reload list */
  useEffect(() => {
    if (reloadEmployeeLogList || !responseReceived) {
      getAllLogList();
      setResult(process(LogList, initialDataState));
      if (reloadEmployeeLogList) {
        setReloadEmployeeLogList(false);
      }
    }
  }, [reloadEmployeeLogList, responseReceived]);

  /* hook to hit filterData function on search */
  useEffect(() => {
    filterData();
  }, [search]);

  /* set records based on onDataStateChange */
  useEffect(() => {
    setRecords(result.data.length);
  }, [result]);

  /* hook to hit  exportExcel() function*/
  useEffect(() => {
    downloadExcel && exportExcel();
  }, [downloadExcel]);

  return (
    <div className="table-responsive rearrangeColumn">
      {/* ============ download xls start ========== */}
      <ExcelExport
        data={
          handelSelectedCount().length > 0 ? handelSelectedCount() : LogList
        }
        fileName="Employeelogs.xlsx"
        ref={_exporter}
      >
        <ExcelExportColumn field="owner" title={t("Employee")} />
        <ExcelExportColumn field="employelogdate" title={t("Date")} />
        <ExcelExportColumn field="starttime" title={t("Start Time")} />
        <ExcelExportColumn field="endtime" title={t("End Time")} />
        <ExcelExportColumn field="workhour" title={t("Log Hour")} />
        <ExcelExportColumn field="taskcount" title={t("Task Completed")} />
        <ExcelExportColumn
          field="taskcompletedcount"
          title={t("Task Pending")}
        />
      </ExcelExport>

      {search ? (
        <Grid
          style={{ height: screenHeight }}
          data={filterData()}
          filterable={true}
          sortable={true}
          groupable={true}
          onDataStateChange={onDataStateChange}
          {...dataState}
          //pageable = {true}
          //total = {products.length}
          reorderable
          // resizable
        >
          <GridColumn
            field="id"
            headerCell={SelectRecordHeader}
            cell={SelectRecordRow}
            filterable={false}
            sortable={false}
            width="70px"
          />
          <GridColumn
            // cell={userHtml}
            field="owner"
            title={t("Employee")}
            width="250px"
          />
          <GridColumn field="employelogdate" title={t("Date")} width="200px" />
          <GridColumn field="starttime" title={t("Start Time")} width="200px" />
          <GridColumn field="endtime" title={t("End Time")} width="200px" />
          <GridColumn field="workhour" title={t("Log Hour")} width="200px" />
          <GridColumn
            field="taskcount"
            title={t("Task Completed")}
            width="200px"
          />
          <GridColumn
            field="taskcompletedcount"
            title={t("Task Pending")}
            width="200px"
          />
        </Grid>
      ) : (
        <Grid
          style={{ height: screenHeight }}
          data={result}
          filterable={true}
          sortable={true}
          groupable={true}
          onDataStateChange={onDataStateChange}
          {...dataState}
          //pageable = {true}
          //total = {products.length}
          reorderable
          // resizable
        >
          <GridColumn
            field="id"
            headerCell={SelectRecordHeader}
            cell={SelectRecordRow}
            filterable={false}
            sortable={false}
            width="70px"
          />
          <GridColumn
            // cell={userHtml}
            field="owner"
            title={t("Employee")}
            width="250px"
          />
          <GridColumn field="employelogdate" title={t("Date")} width="200px" />
          <GridColumn field="starttime" title={t("Start Time")} width="200px" />
          <GridColumn field="endtime" title={t("End Time")} width="200px" />
          <GridColumn field="workhour" title={t("Log Hour")} width="200px" />
          <GridColumn
            field="taskcount"
            title={t("Task Completed")}
            width="200px"
          />
          <GridColumn
            field="taskcompletedcount"
            title={t("Task Pending")}
            width="200px"
          />
        </Grid>
      )}
    </div>
  );
};

export default EmployeeLogList;
