/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";

// kendo react modules ==========================
import "@progress/kendo-theme-material/dist/all.css";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";

// import demodata =============================
// import activitydata from "../../../../data/ActivityData.json";

//import api requiremnts
import * as url from "urlhelpers/UrlHelper";
import { getData } from "utils/Gateway";

//import context
import { GlobalProvider } from "context/GlobalContext";

// import Link
import { Link, useParams } from "react-router-dom";
//import translation function for language translation
import { useTranslation } from "react-i18next";

import UserProfileTabBreadCrumb from "../BreadCrumb/UserProfileTabBreadCrumb";

const ActivityTab = (props) => {
  const token = localStorage.getItem("token");
  const { screenHeight } = useContext(GlobalProvider);
  const params = useParams();

  /* hook to search */
  const [search, setSearch] = useState("");

  /* hook to get the search value */
  const [searchValue, setSearchValue] = useState("");

  const { t } = useTranslation(); //for translation
  const [activityData, setActivityData] = useState([]);
  const [responseReceived, setResponseReceived] = useState(false);

  //function for get all reservation list
  const getAllReservationList = async () => {
    try {
      let requestUrl =
        url.API_BASEURL +
        url.API_GET_ALL_ACTIVITY_EVENTS +
        `?token=${token}&personid=${params.id}`;

      console.log("reserve list url =====>>>", requestUrl);

      const response = await getData(requestUrl);

      console.log(
        "reserve list response profile activity tab ========>>>",
        response
      );

      if (response.data) {
        setActivityData(response.data);
        setResponseReceived(true);
        setResult(process(response.data, initialDataState));
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  // without pagination ------------------------------------
  const initialDataState = {};
  const [dataState, setDataState] = useState();
  const [result, setResult] = useState(process(activityData, initialDataState));

  const onDataStateChange = (event) => {
    console.log("event -->", event);
    setDataState(event.dataState);
    setResult(process(activityData, event.dataState));
  };

  // mark all checkbox as selected ----------------
  const selectAllRecords = (e) => {
    document
      .querySelectorAll('input[name="selectRecord"]')
      .forEach((checkbox) => {
        checkbox.checked = e.target.checked;
      });
  };

  // blocks to replace the Grid HTML ==============================================
  const SelectRecordHeader = () => {
    return (
      <div className="d-flex align-items-center">
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            id="selectAllMain"
            onClick={selectAllRecords}
          />
        </div>
      </div>
    );
  };

  /* Select single row */
  const SelectRecordRow = (props) => {
    return (
      <td>
        <div className="form-check">
          <input
            className="form-check-input "
            type="checkbox"
            value={props.dataItem._id}
            name="selectRecord"
          />
        </div>
      </td>
    );
  };

  /* function to search */
  const filterData = () => {
    if (!result || !result.data) {
      return []; // Return an empty array if result or result.data is undefined
    }

    const filteredData = result.data.filter((item) => {
      const name = item?.name?.toLowerCase() || "";
      const code = item?.code?.toLowerCase() || "";
      const reservecounts = item?.reservecounts?.toString() || "";

      return (
        (name && name.includes(search.toLowerCase())) ||
        (code && code.includes(search.toLowerCase())) ||
        (reservecounts && reservecounts.includes(search))
      );
    });

    return filteredData;
  };

  useEffect(() => {
    getAllReservationList();
  }, []);

  /* hook to empty search when searchValue gets empty */
  useEffect(() => {
    !searchValue && setSearch("");
  }, [searchValue]);

  /* hook to hit filterData function on search */
  useEffect(() => {
    filterData();
  }, [search]);

  return (
    <div className="profile_tabInnr">
      <UserProfileTabBreadCrumb
        moduleName="Activity"
        userData={props?.userData}
      />

      {/* ------ search form section start ------ */}
      <div className="search-container">
        <form onSubmit={(e) => e.preventDefault()}>
          <input
            type="search"
            placeholder={t("Search here")}
            className="form-control bg-transparent py-2 border-0"
            onChange={(e) => setSearchValue(e.target.value)}
            onKeyDown={(e) => {
              e.key === "Enter" && (e.preventDefault(), setSearch(searchValue));
            }}
          />
          <button
            onClick={() => setSearch(searchValue)}
            type="button"
            className="bg-transparent text-dark p-0 border-0 position-absolute top-50 translate-middle-y"
          >
            <span className="d-block material-icons-outlined">search</span>
          </button>
        </form>
      </div>
      {/* ------ search form section end ------ */}

      {/* ======================== Grid view start =========================== */}

      {search ? (
        <Grid
          style={{ height: screenHeight }}
          data={filterData()}
          filterable={true}
          sortable={true}
          groupable={true}
          onDataStateChange={onDataStateChange}
          {...dataState}
          //pageable = {true}
          //total = {products.length}
          reorderable
          // resizable
        >
          <GridColumn
            field="id"
            headerCell={SelectRecordHeader}
            cell={SelectRecordRow}
            filterable={false}
            sortable={false}
            width="70px"
          />

          <GridColumn field="name" title={t("Name")} 
          width="400px" 
          />
          <GridColumn field="code" title={t("Code")} 
          //width="200px" 
          />
          <GridColumn
            field="reservecounts"
            title={t("Participation Count")}
            //width="200px"
          />
        </Grid>
      ) : (
        <Grid
          style={{ height: screenHeight }}
          data={result}
          filterable={true}
          sortable={true}
          groupable={true}
          onDataStateChange={onDataStateChange}
          {...dataState}
          //pageable = {true}
          //total = {products.length}
          reorderable
          // resizable
        >
          <GridColumn
            field="id"
            headerCell={SelectRecordHeader}
            cell={SelectRecordRow}
            filterable={false}
            sortable={false}
            width="70px"
          />
          <GridColumn field="name" title={t("Name")} width="400px" />
          <GridColumn field="code" title={t("Code")} 
          //width="200px" 
          />
          <GridColumn
            field="reservecounts"
            title={t("Participation Count")}
            //width="200px"
          />
        </Grid>
      )}

      {/* ======================== Grid view end =========================== */}
    </div>
  );
};

export default ActivityTab;
