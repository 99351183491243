import { Link } from "react-router-dom";

//import image
import { assetImages } from "../../constants";

//import translation function for language translation
import { useTranslation } from "react-i18next";

import * as url from "urlhelpers/UrlHelper";

const UserDropdown = () => {
  const { t } = useTranslation(); //for translation
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  return (
    <div className="menurightPnl dropdown-menu">
      <div className="account_topUsr">
        <div className="account_user_outer">
          <div className="accont_usrPic">
            {userInfo ? (
              <img
                src={
                  userInfo.photoimage
                    ? url.SERVER_URL + userInfo.photoimage.path
                    : assetImages.defaultUser
                }
                alt=""
              />
            ) : null}
          </div>
          <div className="accont_owner_name">
            {userInfo ? (
              <h3>
                {userInfo.name} {userInfo.surname}
              </h3>
            ) : null}
          </div>
        </div>
        <div className="logout_btn">
          <Link to="/logout">
            <span className="material-icons-outlined">logout</span>
          </Link>
        </div>
      </div>

      <div className="afterlogin_menu">
        <ul className="list_stye_none">
          <li>
            <Link to="/">
              <i className="material-icons-outlined">space_dashboard</i>
              <span>{t("Dashboard")}</span>
            </Link>
          </li>

          {userInfo ? (
            <li>
              <Link to={`/profile/${userInfo._id}`}>
                <i className="material-icons-outlined">account_circle</i>
                <span>{t("My profile")}</span>
              </Link>
            </li>
          ) : null}
          <li>
            <Link to="/conversation">
              <i className="material-icons-outlined">notifications</i>
              <span>{t("Notification")}</span>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default UserDropdown;
