import React, { useEffect } from "react";
import LoginLeftPanel from "components/LoginComponents/LeftPanel/LeftPanel";
import LoginRightPanel from "components/LoginComponents/RightPanel/RightPanel";
import Header from "components/common/Header";

const Login = () => {
  useEffect(() => {
    document.title = " Login"; // Update the title when Login component mounts
  }, []);

  return (
    <div id="site_wrapper">
      <Header />
      <section className="login_wrapper">
        <LoginLeftPanel />
        <LoginRightPanel />
      </section>
    </div>
  );
};

export default Login;
