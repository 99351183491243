import React from "react";
import { Link } from "react-router-dom";

//import translation function for language translation
import { useTranslation } from "react-i18next";

const UserProfileTabBreadCrumb = ({ moduleName, userData }) => {
  const { t } = useTranslation(); //for translation

  return (
    <div className="breadcum_area">
      <nav aria-label="breadcrumb">
        <ul className="breadcrumb list_stye_none">
          <li className="breadcrumb-item">
            <Link to="/" className="text-secondary">
              {t("Dashboard")}
            </Link>
          </li>
          <li className="breadcrumb-item text-gray-600" aria-current="page">
            {t(moduleName)}
          </li>
          <li className="breadcrumb-item text-gray-primary" aria-current="page">
            {userData?.name} {userData?.surname}
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default UserProfileTabBreadCrumb;
