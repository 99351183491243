/* eslint-disable */
import React, { useState, createRef, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
// kendo react modules ==========================
import "@progress/kendo-theme-material/dist/all.css";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";
//import translation function for language translation
import { useTranslation } from "react-i18next";
//import api requiremnts
import * as url from "urlhelpers/UrlHelper";
import { deleteData, getData, postData, putData } from "utils/Gateway";
import { assetImages } from "constants";
// impoting excel colums
import {
  ExcelExport,
  ExcelExportColumn,
} from "@progress/kendo-react-excel-export";
import { GlobalProvider } from "context/GlobalContext";
import calculateTotalPrice from "helpers/CalculateTotalPrice";
import SalesOrderItemTableRow from "components/common/SalesOrderItemTableRow/SalesOrderItemTableRow";
import invoiceTaxOptions from "data/Prod/InvoiceTax.json";
import { formatDate } from "helpers/DateCalculation";
import { currencySymbol } from "Config/Config";

const SaveSalesOrderPopup = ({ moduleName }) => {
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  //today value
  const todayValue = new Date().toISOString().split("T")[0];
  const {
    salesOrderData,
    setSalesOrderData,
    setReloadSalesOrderList,
    setSuccess,
    setError,
    setNotificationString,
    setclientEmail,
  } = useContext(GlobalProvider);
  const [budgetStatusList, setBudgetStatusList] = useState([]);
  const [clientList, setClientList] = useState([]); //client option
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedClientId, setSelectedClientId] = useState(null);
  const [clientEmail, setClientEmail] = useState("");
  const [clientName, setClientName] = useState("");
  const [clientAddress, setClientAddress] = useState("");
  const [clientCity, setClientCity] = useState("");
  const [clientState, setClientState] = useState("");
  const [clientCountry, setClientCountry] = useState("");
  const [clientZipcode, setClientZipcode] = useState("");
  const [invoiceId, setInvoiceId] = useState(0);
  const [invoiceDate, setInvoiceDate] = useState("");
  const [dueDate, setDueDate] = useState("");
  const [salesOrderCreating, setSalesOrderCreating] = useState(false);
  const [salesOrderItems, setSalesOrderItems] = useState([]);

  const salesOrderBlockTemplate = {
    slno: "",
    reserveoptions: [],
    reserveid: null,
    reservevalue: null,
    beneficiaryname: "",
    activitycode: "",
    reservedate: "",
    description: "",
    unit: 1,
    activitycost: 1,
    discountpercent: 0,
    taxpercentvalue: null,
    taxpercent: 0,
    discountamount: 0,
    taxamount: 0,
    totalprice: "",
  };

  const [newSalesOrderItemBlock, setNewSalesOrderItemBlock] = useState([]);

  const [notificationMessage, setNotificationMessage] = useState("");
  const [totalActivityPrice, setTotalActivityPrice] = useState(0);
  const [taxPercent, setTaxPercent] = useState(0);
  const [salesTax, setSalesTax] = useState(0);
  const [totalTaxAmount, setTotalTaxAmount] = useState(0);
  const [totalPayble, setTotalPayble] = useState(0);
  const [budgetStatusValue, setBudgetStatusValue] = useState(null);
  const [selectedBudgetStatus, setSelectedBudgetStatus] = useState(null);
  const [isClosedBudgetStatus, setIsClosedBudgetStatus] = useState(false);
  const [isBudgetUpdating, setIsBudgetUpdating] = useState(false);
  /* hook for validation */
  const [validation, setValidation] = useState({
    customerWarning: false,
    invoiceDateWarning: false,
  });
  const initialDataState = {};
  const [result, setResult] = useState(
    process(salesOrderItems, initialDataState)
  );
  const [isLoading, setIsLoading] = useState(false);
  /* hook to download xls */
  const [downloadXls, setDownloadXls] = useState(false);
  const [noteList, setNoteList] = useState([]);
  const [noteData, setNoteData] = useState("");
  const [isNoteSending, setIsNoteSending] = useState(false);
  const [isAddMoreSaving, setIsAddMoreSaving] = useState(false);
  const { t } = useTranslation(); //for translation
  /* Handle the button's onClick event and set it to export the data by using the ExcelExport save method. */
  const _exporter = createRef();
  const exportExcel = () => {
    if (_exporter.current) {
      _exporter.current.save();
    }
  };
  //const get budgte status options
  const getBudgetStatusOptions = async () => {
    try {
      let requestUrl =
        url.API_BASEURL + url.API_GET_BUDGET_STATUS_LIST + `?token=${token}`;
      const response = await getData(requestUrl);
      if (response.status) {
        setBudgetStatusList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  //function for get all user list
  const getAllClientList = async () => {
    try {
      let clientRequestUrl =
        url.API_BASEURL +
        url.API_GET_ALL_USER +
        `?token=${token}&isselect=${true}&slug=client`;
      let benificiaryRequestUrl =
        url.API_BASEURL +
        url.API_GET_ALL_USER +
        `?token=${token}&isselect=${true}&slug=beneficiary`;
      const clientResponse = await getData(clientRequestUrl);
      const beneficiaryResponse = await getData(benificiaryRequestUrl);
      if (clientResponse.status && beneficiaryResponse.status) {
        let clientArr = [];
        clientArr = [...clientResponse.data, ...beneficiaryResponse.data];
        setClientList(clientArr);
      }
    } catch (error) {
      console.error(error.message);
    }
  };
  //get sales order details
  const getSalesOrderDetails = async () => {
    try {
      setIsLoading(true);
      let requestUrl =
        url.API_BASEURL +
        url.API_GET_SALES_ORDER_DETAILS +
        `/${salesOrderData}` +
        `?token=${token}&modulename=MOD_SALESORDER&userid=${userInfo._id}&userip=${userInfo.userip}`;
      const response = await getData(requestUrl);
      console.log("resp in sales order details ----->", response);
      if (response.status) {
        setIsLoading(false);
        setClientEmail(response.data?.client?.email);
        setClientName(response?.data?.clientfullname);
        setClientAddress(response?.data?.client?.address);
        setClientCity(response?.data?.client?.city);
        setClientState(response?.data?.client?.state);
        setClientCountry(response?.data?.client?.country);
        setClientZipcode(response?.data?.client?.zipcode);
        setInvoiceId(response?.data?.invoiceid);
        setInvoiceDate(response?.data?.invoicedate);
        setDueDate(response.data?.duedate);

        const updatedSalesOrderItem =
          response?.data?.salesorderitemsextended.map((salesOrderItem) => {
            salesOrderItem.reservationId = salesOrderItem.reservation
              ? salesOrderItem.reservation._id
              : null;
            salesOrderItem.activityId = salesOrderItem?.activitycode
              ? salesOrderItem?.activitycode._id
              : null;

            salesOrderItem.beneficiaryname = salesOrderItem?.beneficiaryname;

            for (let invoiceTax of invoiceTaxOptions) {
              if (invoiceTax.value === salesOrderItem.taxpercent.toString()) {
                salesOrderItem.taxpercentvalue = invoiceTax;
              }
            }
            return salesOrderItem;
          });

        setSalesOrderItems(updatedSalesOrderItem);
        setResult(process(updatedSalesOrderItem, initialDataState));
        setTotalActivityPrice(response?.data?.totalactivitycost);
        setTaxPercent(
          response?.data?.taxpercent ? response?.data?.taxpercent : 0
        );
        const totalTax = response?.data?.taxpercent
          ? response?.data?.totalactivitycost *
            (response?.data?.taxpercent / 100)
          : response?.data?.totalactivitycost * (0 / 100);
        setSalesTax(totalTax);
        setTotalTaxAmount(response.data.totaltaxamount);
        setTotalPayble(response?.data?.totalpaybleprice + totalTax);
        budgetStatusSelectionHandler(response.data?.budgetstatus);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all notes
  const getAllNotes = async () => {
    try {
      let requestUrl =
        url.API_BASEURL +
        url.API_GET_ALL_NOTE +
        `?token=${token}&salesorderid=${salesOrderData}`;
      // console.log("url for all notes========>", requestUrl);
      const response = await getData(requestUrl);
      // console.log("response for all notes========>", response);
      if (response.status) {
        setNoteList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  /* function for validation */
  const validateForm = () => {
    let isValid = true;
    if (selectedClient === null) {
      setValidation((prevState) => ({ ...prevState, customerWarning: true }));
      isValid = false;
    }
    if (invoiceDate == "") {
      setValidation((prevState) => ({
        ...prevState,
        invoiceDateWarning: true,
      }));
      isValid = false;
    }
    return isValid;
  };

  //save sales order
  const saveSalesOrderHandler = async () => {
    let salesOrderObjectData = {
      invoicedate: invoiceDate || "",
      duedate: dueDate || "",
    };
    let requestUrl = url.API_BASEURL;
    let response = null;
    if (salesOrderData) {
      setSalesOrderCreating(true);
      salesOrderObjectData.budgetstatusid = selectedBudgetStatus;
      console.log("salesOrderData------>", salesOrderObjectData);
      requestUrl =
        requestUrl +
        url.API_UPDATE_SALES_ORDER +
        `/${salesOrderData}` +
        `?token=${token}&modulename=MOD_SALESORDER`;
      console.log("url of sales order update======>", requestUrl);
      try {
        response = await putData(requestUrl, salesOrderObjectData);
        setSalesOrderCreating(false);
        if (response.status) {
          setReloadSalesOrderList(true);
          setSuccess(true);
          setNotificationString(t("Sales order updated successfully"));
        }
      } catch (error) {
        console.log(error.message);
        setError(true);
        setNotificationString(
          t("Something went wrong, please try again later")
        );
      }
    } else {
      if (validateForm()) {
        setSalesOrderCreating(true);
        salesOrderObjectData.client = selectedClientId;
        salesOrderObjectData.ordertype = "2";
        salesOrderObjectData.createdsalesdate = todayValue;
        console.log("salesOrderData------>", salesOrderObjectData);
        requestUrl =
          requestUrl +
          url.API_CREATE_SALES_ORDER +
          `?token=${token}&modulename=MOD_SALESORDER`;
        console.log("url of sales order create======>", requestUrl);
        try {
          response = await postData(requestUrl, salesOrderObjectData);
          setSalesOrderCreating(false);
          console.log("response in sales order pop up========>", response);
          if (response.status) {
            setReloadSalesOrderList(true);
            setSuccess(true);
            setNotificationString(t("Sales order created successfully"));
            setSalesOrderData(response.data._id);
            setInvoiceId(response?.data?.invoiceid);
          }
        } catch (error) {
          console.log(error.message);
          setError(true);
          setNotificationString(
            t("Something went wrong, please try again later")
          );
        }
      }
    }
  };
  //function for save note
  const saveNoteHandler = async () => {
    // if no notedata is added
    if (noteData == "") {
      return true;
    }
    try {
      setIsNoteSending(true);
      let saveNoteData = {
        notedate: todayValue.split("-").reverse().join("/"),
        notedata: noteData,
        salesorderid: salesOrderData,
      };
      setNoteData("");
      console.log("Notedata------->", saveNoteData);
      let requestUrl =
        url.API_BASEURL +
        url.API_CREATE_NOTE +
        `?token=${token}&modulename=MOD_SALESORDER`;
      console.log("url for save note=========>", requestUrl);
      const response = await postData(requestUrl, saveNoteData);
      console.log("response in save note========>", response);
      if (response.status) {
        setIsNoteSending(false);
        getAllNotes();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for select client
  const clientSelectionHandler = (val) => {
    setSelectedClient(val);
    setSelectedClientId(val.value);
    setClientName(val.label);
    setClientCity(val.city);
    setClientState(val.state);
    setClientCountry(val.country);
    setClientEmail(val.email);
  };

  //function for get reserve list for existing sales order item
  const updateReserveInputhandler = async (index, field, val) => {
    if (val) {
      let requestUrl =
        url.API_BASEURL +
        url.API_GET_SALES_ORDER_RESERVE_LIST +
        `?token=${token}&queryreserveid=${val}`;
      const response = await getData(requestUrl);
      // console.log(response);
      if (response.status) {
        const updatedSalesItemValues = salesOrderItems.map(
          (salesOrderItem, i) => {
            if (index === i) {
              const reserveOptions = response.data.map((item) => ({
                label: item.reserveid,
                value: item.reserveid,
              }));
              const updatedItem = {
                ...salesOrderItem,
                [field]: reserveOptions,
              };
              return updatedItem;
            } else {
              return salesOrderItem;
            }
          }
        );
        setSalesOrderItems(updatedSalesItemValues);
      }
    }
  };

  //select reserve and get details
  const updateReserveSelectionHandler = async (index, field, field2, val) => {
    if (val) {
      const reserveResult = await getSalesOrderReserveDetails(val.value, index);
      const activityName = reserveResult?.activitycode?.name || "";
      const reserveDate = reserveResult?.reservedate || "";
      const salesDescription = `${activityName} ${reserveDate}`;
      const updatedSalesItemValues = salesOrderItems.map(
        (salesOrderItem, i) => {
          if (index === i) {
            const updatedItem = {
              ...salesOrderItem,
              [field]: val,
              [field2]: val.value,
              description: salesDescription,
              reservationId: reserveResult._id,
              beneficiaryname: reserveResult.beneficiaryname,
              activityId: reserveResult?.activitycode?._id,
              activitycost: reserveResult?.activitycode?.cost,
              discountpercent: reserveResult?.discount
                ? reserveResult?.discount
                : 0,
            };
            // get totalprice and discount amount based on updated activitycost and discountpercent
            const { totalamount, discountamount, totaltax } =
              calculateTotalPrice(updatedItem);
            updatedItem.totalprice = totalamount;
            updatedItem.discountamount = discountamount;
            updatedItem.taxamount = totaltax;
            return updatedItem;
          } else {
            return salesOrderItem;
          }
        }
      );
      setSalesOrderItems(updatedSalesItemValues);
    } else {
      const updatedSalesItemValues = salesOrderItems.map(
        (salesOrderItem, i) => {
          if (index === i) {
            const updatedItem = {
              ...salesOrderItem,
              [field]: null,
              [field2]: null,
              reserveoptions: [],
              reservationId: null,
              beneficiaryname: "",
              activityId: null,
            };
            // get totalprice and discount amount based on updated activitycost and discountpercent
            const { totalamount, discountamount, totaltax } =
              calculateTotalPrice(updatedItem);
            updatedItem.totalprice = totalamount;
            updatedItem.discountamount = discountamount;
            updatedItem.taxamount = totaltax;
            return updatedItem;
          } else {
            return salesOrderItem;
          }
        }
      );
      setSalesOrderItems(updatedSalesItemValues);
    }
  };
  //function for change total activity cost
  const salesOrderItemInputChangeHandler = (index, field, value) => {
    const updatedSalesItemValues = salesOrderItems.map((salesOrderItem, i) => {
      if (index === i) {
        const updatedItem = { ...salesOrderItem, [field]: value };
        // get totalprice and discount amount based on updated activitycost and discountpercent
        const { totalamount, discountamount, totaltax } =
          calculateTotalPrice(updatedItem);
        updatedItem.totalprice = totalamount;
        updatedItem.discountamount = discountamount;
        updatedItem.taxamount = totaltax;
        return updatedItem;
      } else {
        return salesOrderItem;
      }
    });
    setSalesOrderItems(updatedSalesItemValues);
  };
  //edit tax percent
  const editTaxPercentSelectionHandler = (index, field, field2, value) => {
    const updatedSalesItemValues = salesOrderItems.map((salesOrderItem, i) => {
      if (index === i) {
        const updatedItem = {
          ...salesOrderItem,
          [field]: value ? value : null,
          [field2]: value ? value.value : "0",
        };
        // get totalprice and discount amount based on updated activitycost and discountpercent
        const { totalamount, discountamount, totaltax } =
          calculateTotalPrice(updatedItem);
        updatedItem.totalprice = totalamount;
        updatedItem.discountamount = discountamount;
        updatedItem.taxamount = totaltax;
        return updatedItem;
      } else {
        return salesOrderItem;
      }
    });
    setSalesOrderItems(updatedSalesItemValues);
  };
  //update sales order item
  const editSalesOrderItemHandler = async (salesOrderItem) => {
    // console.log("edit salesOrderItem ------>", salesOrderItem);
    try {
      let salesOrderItemData = {
        reservation: salesOrderItem.reservationId,
        activitycode: salesOrderItem.activityId,
        unit: parseInt(salesOrderItem.unit),
        activitycost: parseInt(salesOrderItem.activitycost),
        description: salesOrderItem.description || "",
        discountpercent: parseInt(salesOrderItem.discountpercent),
        discountamount: parseInt(salesOrderItem.discountamount),
        taxpercent: parseInt(salesOrderItem.taxpercent),
        taxamount: parseInt(salesOrderItem.taxamount),
        totalprice: Math.ceil(salesOrderItem.totalprice),
      };
      let requestUrl =
        url.API_BASEURL +
        url.API_UPDATE_SALES_ORDER_ITEM +
        `/${salesOrderItem._id}?token=${token}`;
      console.log("Sales order item for edit ------>", salesOrderItemData);
      console.log("Sales order item edit url -------->", requestUrl);
      const response = await putData(requestUrl, salesOrderItemData);
      // console.log(response);
      if (response.status) {
        if (salesOrderItem.reservationId) {
          setNotificationMessage(
            `Reserve id ${salesOrderItem.reservation?.reserveid} is saved successfully`
          );
        } else {
          setNotificationMessage(`Order is saved successfully`);
        }
        getSalesOrderDetails();
        getAllNotes();
        setReloadSalesOrderList(true);
        setTimeout(() => setNotificationMessage(""), 2000);
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  //delete sales order item
  const deleteSalesOrderItemHandler = async (salesOrderItem) => {
    try {
      let requestUrl =
        url.API_BASEURL +
        url.API_DELETE_SALES_ORDER_ITEM +
        `/${salesOrderItem._id}?token=${token}&salesorderid=${salesOrderData}`;
      console.log("Sales order item delete url -------->", requestUrl);
      const response = await deleteData(requestUrl);
      // console.log(response);
      if (response.status) {
        getSalesOrderDetails();
        getAllNotes();
        setReloadSalesOrderList(true);
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  //add new sales Order item bloc
  const addNewSalesOrderItemBlock = () => {
    setNotificationMessage("");
    for (let invoiceTax of invoiceTaxOptions) {
      if (invoiceTax.value === "21") {
        salesOrderBlockTemplate.taxpercentvalue = invoiceTax;
        salesOrderBlockTemplate.taxpercent = parseInt(invoiceTax.value);
      }
    }
    setNewSalesOrderItemBlock([
      ...newSalesOrderItemBlock,
      salesOrderBlockTemplate,
    ]);
  };
  //delete sales order item block
  const deleteSalesOrderItemBlock = (index) => {
    setNotificationMessage("");
    const filteredSalesOrderItemBlock = [...newSalesOrderItemBlock];
    filteredSalesOrderItemBlock.splice(index, 1);
    setNewSalesOrderItemBlock(filteredSalesOrderItemBlock);
  };
  //new sales order item reserve input
  const newReserveInputhandler = async (index, field, val) => {
    if (val) {
      let requestUrl =
        url.API_BASEURL +
        url.API_GET_SALES_ORDER_RESERVE_LIST +
        `?token=${token}&queryreserveid=${val}`;
      const response = await getData(requestUrl);
      console.log(response);
      if (response.status) {
        const updatedSalesItemValues = newSalesOrderItemBlock.map(
          (salesOrderItem, i) => {
            if (index === i) {
              const reserveOptions = response.data.map((item) => ({
                label: item.reserveid,
                value: item.reserveid,
              }));
              const updatedItem = {
                ...salesOrderItem,
                [field]: reserveOptions,
              };
              return updatedItem;
            } else {
              return salesOrderItem;
            }
          }
        );
        setNewSalesOrderItemBlock(updatedSalesItemValues);
      }
    }
  };
  //select reseve for new sales order
  const newReserveSelectionHandler = async (index, field, field2, val) => {
    if (val) {
      const reserveResult = await getSalesOrderReserveDetails(val.value, index);
      const activityName = reserveResult?.activitycode?.name || "";
      const reserveDate = reserveResult?.reservedate || "";
      const salesDescription = `${activityName} ${reserveDate}`;
      const updatedSalesItemValues = newSalesOrderItemBlock.map(
        (salesOrderItem, i) => {
          if (index === i) {
            const updatedItem = {
              ...salesOrderItem,
              [field]: val,
              [field2]: val.value,
              description: salesDescription,
              reservationId: reserveResult._id,
              beneficiaryname: reserveResult.beneficiaryname,
              activityId: reserveResult?.activitycode?._id,
              activitycost: reserveResult?.activitycode?.cost,
              discountpercent: reserveResult?.discount
                ? reserveResult?.discount
                : 0,
            };
            // get totalprice and discount amount based on updated activitycost and discountpercent
            const { totalamount, discountamount, totaltax } =
              calculateTotalPrice(updatedItem);
            updatedItem.totalprice = totalamount;
            updatedItem.discountamount = discountamount;
            updatedItem.taxamount = totaltax;
            return updatedItem;
          } else {
            return salesOrderItem;
          }
        }
      );
      setNewSalesOrderItemBlock(updatedSalesItemValues);
    } else {
      const updatedSalesItemValues = newSalesOrderItemBlock.map(
        (salesOrderItem, i) => {
          if (index === i) {
            const updatedItem = {
              ...salesOrderItem,
              [field]: null,
              [field2]: null,
              reserveoptions: [],
              reservationId: null,
              beneficiaryname: "",
              activityId: null,
            };
            // get totalprice and discount amount based on updated activitycost and discountpercent
            const { totalamount, discountamount, totaltax } =
              calculateTotalPrice(updatedItem);
            updatedItem.totalprice = totalamount;
            updatedItem.discountamount = discountamount;
            updatedItem.taxamount = totaltax;
            return updatedItem;
          } else {
            return salesOrderItem;
          }
        }
      );
      setNewSalesOrderItemBlock(updatedSalesItemValues);
    }
  };
  //change the input of new sales order item block
  const newSalesOrderItemBlockInputHandler = (index, field, value) => {
    const updatedSalesItemValues = newSalesOrderItemBlock.map(
      (salesOrderItem, i) => {
        if (index === i) {
          const updatedItem = { ...salesOrderItem, [field]: value };
          // get totalprice and discount amount based on updated activitycost and discountpercent
          const { totalamount, discountamount, totaltax } =
            calculateTotalPrice(updatedItem);
          updatedItem.totalprice = totalamount;
          updatedItem.discountamount = discountamount;
          updatedItem.taxamount = totaltax;
          return updatedItem;
        } else {
          return salesOrderItem;
        }
      }
    );
    setNewSalesOrderItemBlock(updatedSalesItemValues);
  };
  //change the tax selection
  const newTaxPercentSelectionHandler = (index, field, field2, value) => {
    const updatedSalesItemValues = newSalesOrderItemBlock.map(
      (salesOrderItem, i) => {
        if (index === i) {
          const updatedItem = {
            ...salesOrderItem,
            [field]: value ? value : null,
            [field2]: value ? value.value : "0",
          };
          // get totalprice and discount amount based on updated activitycost and discountpercent
          const { totalamount, discountamount, totaltax } =
            calculateTotalPrice(updatedItem);
          updatedItem.totalprice = totalamount;
          updatedItem.discountamount = discountamount;
          updatedItem.taxamount = totaltax;
          return updatedItem;
        } else {
          return salesOrderItem;
        }
      }
    );
    setNewSalesOrderItemBlock(updatedSalesItemValues);
  };
  //get sales order details with reserve id
  const getSalesOrderReserveDetails = async (reserveId, index) => {
    try {
      let requestUrl =
        url.API_BASEURL +
        url.API_GET_SALES_ORDER_RESERVE_DETAILS +
        `?token=${token}&reserveid=${reserveId}&salesorderid=${salesOrderData}`;
      // console.log(requestUrl);
      const response = await getData(requestUrl);
      console.log("response in sales order reserve details ----->", response);
      if (response.status) {
        if (response.data.isassociated) {
          setNotificationMessage(response.message);
        } else {
          setNotificationMessage("");
        }
        return response.data;
      } else {
        setNotificationMessage(response.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  //create more sales order item handler
  const saveSalesOrderItemHandler = async (salesOrderItemBlock, index) => {
    // console.log(salesOrderItemBlock);
    setIsAddMoreSaving(true);
    try {
      let salesOrderItemData = {
        reservation: salesOrderItemBlock.reservationId,
        activitycode: salesOrderItemBlock.activityId,
        description: salesOrderItemBlock.description || "",
        unit: parseInt(salesOrderItemBlock.unit),
        activitycost: parseInt(salesOrderItemBlock.activitycost),
        discountamount: parseInt(salesOrderItemBlock.discountamount),
        discountpercent: parseInt(salesOrderItemBlock.discountpercent),
        taxpercent: parseInt(salesOrderItemBlock?.taxpercent),
        taxamount: parseInt(salesOrderItemBlock.taxamount),
        totalprice: Math.ceil(salesOrderItemBlock.totalprice),
      };
      // console.log(salesOrderItemData);
      let requestUrl =
        url.API_BASEURL +
        url.API_ADD_MORE_SALES_ORDER_ITEM +
        `/${salesOrderData}` +
        `?token=${token}`;
      const response = await putData(requestUrl, salesOrderItemData);
      setIsAddMoreSaving(false);
      if (response.status) {
        deleteSalesOrderItemBlock(index);
        getSalesOrderDetails();
        getAllNotes();
        setReloadSalesOrderList(true);
        if (salesOrderItemBlock.reservationId) {
          setNotificationMessage(
            `${t("Reserve id")} ${salesOrderItemBlock.reserveid} ${t(
              "is saved successfully"
            )}`
          );
        }
        setTimeout(() => setNotificationMessage(""), 2000);
      } else {
        setNotificationMessage(response.message);
      }
    } catch (error) {
      setError(true);
      setNotificationMessage(t("Something went wrong, please try again later"));
      setNotificationString(t("Something went wrong, please try again later"));
    }
  };
  //function for select budget status
  const budgetStatusSelectionHandler = (val) => {
    if (val) {
      setBudgetStatusValue(val);
      setSelectedBudgetStatus(val.value);
      if (val.slug != "open") {
        setIsClosedBudgetStatus(true);
      } else {
        setIsClosedBudgetStatus(false);
      }
    } else {
      setBudgetStatusValue(null);
      setSelectedBudgetStatus(null);
      setIsClosedBudgetStatus(false);
    }
  };
  //reset function
  const resetSalesOrderPopup = () => {
    setSelectedClient(null);
    setSelectedClientId(null);
    setClientName("");
    setClientAddress("");
    setClientCity("");
    setClientState("");
    setClientCountry("");
    setClientZipcode("");
    setInvoiceId(0);
    setInvoiceDate("");
    setDueDate("");
    setSalesOrderItems([]);
    setNewSalesOrderItemBlock([]);
    setResult(process(salesOrderItems, initialDataState));
    setSalesOrderData(null);
    setNoteList([]);
    setNotificationMessage("");
    setBudgetStatusValue(null);
    setSelectedBudgetStatus(null);
    setIsClosedBudgetStatus(false);
    setValidation({
      customerWarning: false,
      invoiceDateWarning: false,
    });
  };
  useEffect(() => {
    if (salesOrderData) {
      getSalesOrderDetails();
      getAllNotes();
    }
  }, [salesOrderData]);
  /* hook to hit  exportExcel() function*/
  useEffect(() => {
    downloadXls && exportExcel();
  }, [downloadXls]);
  useEffect(() => {
    getBudgetStatusOptions();
    getAllClientList();
  }, []);
  return (
    <div
      className="offcanvas offcanvas-end border-0"
      tabIndex="-1"
      id="saveSalesOrderPopup"
      aria-labelledby="salesOrderPopupLabel"
    >
      <div className="offcanvas-header justify-content-end p-4">
        <button
          type="button"
          id="closeCreateMail"
          className="btn-close p-2 bg-white shadow-none shadow-sm m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={resetSalesOrderPopup}
        ></button>
      </div>
      <div className="offcanvas-body p-4 pt-0">
        <div className="d-flex align-items-start gap-5 mb-4">
          <div className="company-info">
            <h3 className="text-secondary mb-3" id="salesOrderPopupLabel">
              {t("Salesorder")} {invoiceId == 0 ? null : `#${invoiceId}`}
            </h3>
            <h5 className="mb-2">Funbrain, S.L</h5>
            <p className="text-gray mb-1 t-15">
              Av Parc Tecnològic, 3, 08290, Cerdanyola del Vallès, Cataluña
            </p>
            <p className="mb-1 t-15">
              <strong>{t("CIF")}: </strong>{" "}
              <Link to="#" className="text-gray">
                B64430515
              </Link>
            </p>
            <p className="t-15">
              <strong>{t("Email")}: </strong>{" "}
              <Link to="#" className="text-gray">
                info@funbrain.com
              </Link>
            </p>
          </div>
          <img
            src={assetImages.logo2}
            alt="FunBrain"
            className="img-fluid ms-auto w-200"
          />
        </div>
        <div className="order-info d-flex flex-column flex-lg-row bg-gray-500 border border-gray mb-4">
          {salesOrderData ? (
            <div className="flex-fill fs-sm text-gray fw-medium p-3 border-end border-gray">
              <h6 className="fw-bold mb-3">{t("CUSTOMER INFORMATION")}</h6>
              <p className="mb-1 t-15">{clientName}</p>
              <p className="t-15">
                {clientAddress}, {clientCity}
                {clientState},{clientCountry},{clientZipcode}
              </p>
            </div>
          ) : (
            <div className="flex-fill text-gray fw-medium p-3 border-end border-gray">
              <h6 className="fw-bold mb-3">{t("CUSTOMER INFORMATION")}</h6>
              <div className="mb-2">
                <Select
                  options={clientList}
                  value={selectedClient}
                  onChange={(val) => {
                    setValidation((prevState) => ({
                      ...prevState,
                      customerWarning: false,
                    }));
                    clientSelectionHandler(val);
                  }}
                />
                {validation.customerWarning && (
                  <div className="error-message mt-2">
                    <p className="d-flex align-items-center gap-1 text-danger">
                      <span className="material-symbols-outlined">warning</span>
                      <span>{t("Please select client")}!</span>
                    </p>
                  </div>
                )}
              </div>
              {selectedClient && (
                <p className="mb-1 t-15">
                  {clientName}, {clientCity} {clientState}, {clientCountry}{" "}
                  {clientZipcode}
                </p>
              )}
              {selectedClient && (
                <p className="t-15">
                  <strong>{t("Email")}: </strong>{" "}
                  <Link to="#" className="text-gray">
                    {clientEmail}
                  </Link>
                </p>
              )}
            </div>
          )}
          <div className="flex-fill fs-sm text-gray fw-medium p-3 border-end border-gray">
            {/* -------- invoice section start ------- */}
            <h6 className="fw-bold mb-3">{t("ORDER INFORMATION")}</h6>
            {invoiceId == 0 ? null : (
              <p className="d-flex mb-3">
                <strong className="d-inline-block w-50 text-secondary">
                  {t("Order Id")}:
                </strong>
                <span className="d-inline-block text-end w-50">
                  {invoiceId}
                </span>
              </p>
            )}
            <p className="d-flex mb-2">
              <strong className="d-inline-block w-50 text-secondary">
                {t("Order Date")}:
              </strong>
              <input
                type="date"
                className="form-control bg-white px-3 py-2"
                value={invoiceDate}
                onChange={(e) => {
                  setInvoiceDate(e.target.value);
                  setValidation((prevState) => ({
                    ...prevState,
                    invoiceDateWarning: false,
                  }));
                }}
              />
            </p>
            {validation.invoiceDateWarning && (
              <div className="error-message my-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>{t("Please enter order date")}!</span>
                </p>
              </div>
            )}
            {/* -------- invoice section end ------- */}
            {/* -------- order due sate section start ------- */}
            <p className="d-flex align-items-center mb-2">
              <strong className="d-inline-block w-50 text-secondary">
                {t("Due Date")}:
              </strong>
              <input
                type="date"
                className="form-control bg-white px-3 py-2"
                value={dueDate}
                onChange={(e) => setDueDate(e.target.value)}
              />
            </p>
            {/* -------- order due date section end ------- */}
            {/* ----- select budget status section start ----- */}
            {salesOrderData ? (
              <div className="d-flex align-items-center">
                <strong className="d-inline-block w-50 text-secondary">
                  {t("Salesorder status")}:
                </strong>
                <Select
                  className="w-50"
                  placeholder={t("Select budget status")}
                  options={budgetStatusList}
                  isClearable={true}
                  value={budgetStatusValue}
                  onChange={(val) => budgetStatusSelectionHandler(val)}
                />
                <button
                  disabled={salesOrderCreating ? true : false}
                  style={{
                    cursor: salesOrderCreating ? "not-allowed" : "pointer",
                  }}
                  onClick={saveSalesOrderHandler}
                  className="btn btn-primary ms-2"
                >
                  {salesOrderData ? t("Update") : t("Add item")}
                  {salesOrderCreating && (
                    <div
                      className="mx-2 spinner-border spinner-border-sm"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  )}
                </button>
              </div>
            ) : (
              <div className="d-flex align-items-center justify-content-end">
                <button
                  disabled={salesOrderCreating ? true : false}
                  style={{
                    cursor: salesOrderCreating ? "not-allowed" : "pointer",
                  }}
                  onClick={saveSalesOrderHandler}
                  className="btn btn-primary ms-2"
                >
                  {salesOrderData ? t("Update") : t("Add item")}
                  {salesOrderCreating && (
                    <div
                      className="mx-2 spinner-border spinner-border-sm"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  )}
                </button>
              </div>
            )}
            {/* ----- select budget status section end ----- */}
          </div>
          {/* icon section */}
          <div className="action flex-fill p-3">
            {salesOrderData ? (
              <div className="d-flex justify-content-center gap-2">
                {/* <Link
                to="#"
                className="d-flex align-items-center justify-content-center bg-secondary text-white rounded-circle"
              >
                <span className="d-block material-symbols-outlined icon-md">
                  print
                </span>
              </Link> */}
                <Link
                  to={`/salesorder/invoice/${salesOrderData}`}
                  className="d-flex align-items-center justify-content-center bg-primary text-white"
                >
                  <span
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title={t("View as pdf")}
                    className="d-block material-symbols-outlined"
                  >
                    picture_as_pdf
                  </span>
                </Link>
                <Link
                  onClick={() => {
                    setclientEmail(clientEmail);
                  }}
                  to="#sendBudgetMailPopup"
                  data-bs-toggle="offcanvas"
                  className="d-flex align-items-center justify-content-center bg-primary text-white"
                >
                  <span
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title={t("Send invoice mail to customer")}
                    className="d-block material-symbols-outlined"
                  >
                    mail
                  </span>
                </Link>
              </div>
            ) : null}
          </div>
        </div>
        <div className="mb-4">
          <div
            id="salesOrders"
            className="sales-orders table align-middle table-without-separator border-top border-gray mb-0"
          >
            <div className="">
              <table className="table table-striped align-middle border-top border-gray mb-0">
                <thead>
                  <SalesOrderItemTableRow />
                </thead>
                <tbody>
                  {/* ----- api data tr section start ----- */}
                  {salesOrderItems.map((salesOrderItem, index) => {
                    return (
                      <tr key={index}>
                        <td className="slno">
                          <p>{salesOrderItem.slno}</p>
                        </td>
                        <td className="reserveid">
                          {salesOrderItem?.reservation ? (
                            <p>{salesOrderItem?.reservation?.reserveid}</p>
                          ) : (
                            <Select
                              options={salesOrderItem.reserveoptions}
                              placeholder={t("Reserve id")}
                              className="fs-sm text-gray py-2 border-gray-300 shadow-none"
                              isClearable
                              value={salesOrderItem.reservevalue}
                              onChange={(val) =>
                                updateReserveSelectionHandler(
                                  index,
                                  "reservevalue",
                                  "reserveid",
                                  val
                                )
                              }
                              onInputChange={(val) => {
                                updateReserveInputhandler(
                                  index,
                                  "reserveoptions",
                                  val
                                );
                              }}
                            />
                          )}

                          <p>{salesOrderItem?.beneficiaryname}</p>
                        </td>

                        <td className="description">
                          <textarea
                            name="description"
                            id="description"
                            value={salesOrderItem?.description}
                            onChange={(e) =>
                              salesOrderItemInputChangeHandler(
                                index,
                                "description",
                                e.target.value
                              )
                            }
                            className="form-control fs-sm text-gray bg-white py-2 border-gray-300 shadow-none"
                            rows="2"
                          ></textarea>
                        </td>
                        <td className="unit">
                          <input
                            type="number"
                            name="unit"
                            id="unit"
                            value={
                              salesOrderItem?.unit ? salesOrderItem.unit : 1
                            }
                            onChange={(e) =>
                              salesOrderItemInputChangeHandler(
                                index,
                                "unit",
                                e.target.value
                              )
                            }
                            className="form-control fs-sm text-gray bg-white py-2 border-gray-300 shadow-none"
                          />
                        </td>
                        <td className="activitycost">
                          <input
                            type="number"
                            name="activitycost"
                            id="activitycost"
                            value={salesOrderItem?.activitycost}
                            onChange={(e) =>
                              salesOrderItemInputChangeHandler(
                                index,
                                "activitycost",
                                e.target.value
                              )
                            }
                            className="form-control fs-sm text-gray bg-white py-2 border-gray-300 shadow-none"
                          />
                        </td>
                        <td className="discountpercent">
                          <input
                            type="number"
                            name="discountpercent"
                            id="discountpercent"
                            value={salesOrderItem?.discountpercent}
                            onChange={(e) =>
                              salesOrderItemInputChangeHandler(
                                index,
                                "discountpercent",
                                e.target.value
                              )
                            }
                            className="form-control fs-sm text-gray bg-white py-2 border-gray-300 shadow-none"
                          />
                        </td>
                        <td className="taxpercent">
                          <Select
                            menuPlacement="auto"
                            isClearable
                            placeholder={t("Percent")}
                            options={invoiceTaxOptions}
                            className="fs-sm text-gray py-2 border-gray-300 shadow-none"
                            value={salesOrderItem.taxpercentvalue}
                            onChange={(val) =>
                              editTaxPercentSelectionHandler(
                                index,
                                "taxpercentvalue",
                                "taxpercent",
                                val
                              )
                            }
                          />
                        </td>
                        <td className="totalprice">
                          <p>{salesOrderItem.totalprice}</p>
                        </td>
                        <td className="action">
                          {isClosedBudgetStatus ? null : (
                            <div className="d-flex align-items-cemter gap-2">
                              {salesOrderItem.activitycost < 0 ||
                              salesOrderItem.activitycost == "" ||
                              salesOrderItem.description == "" ||
                              !salesOrderItem.reservationId ? null : (
                                <button
                                  to="#"
                                  className="text-primary border-0 bg-transparent"
                                  onClick={() => {
                                    editSalesOrderItemHandler(salesOrderItem);
                                  }}
                                >
                                  <span
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    title={t("Save salesorder item")}
                                    className="d-block material-symbols-outlined icon-fill icon-md"
                                  >
                                    save
                                  </span>
                                </button>
                              )}
                              <button
                                to="#"
                                className="text-dark border-0 bg-transparent"
                                onClick={() => {
                                  deleteSalesOrderItemHandler(salesOrderItem);
                                }}
                              >
                                <span
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="top"
                                  title={t("Delete salesorder item")}
                                  className="d-block material-symbols-outlined icon-fill icon-md"
                                >
                                  delete
                                </span>
                              </button>
                            </div>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                  {/* ----- api data tr section end ----- */}
                  {/* ----- new added data tr section start ----- */}
                  {newSalesOrderItemBlock.map((salesOrderItemBlock, index) => {
                    return (
                      <tr key={index}>
                        <td className="slno">
                          <p>{salesOrderItems.length + index + 1}</p>
                        </td>
                        <td className="reserveid">
                          <Select
                            options={salesOrderItemBlock.reserveoptions}
                            placeholder={t("Reserve id")}
                            className="fs-sm text-gray py-2 border-gray-300 shadow-none"
                            isClearable
                            value={salesOrderItemBlock.reservevalue}
                            onChange={(val) =>
                              newReserveSelectionHandler(
                                index,
                                "reservevalue",
                                "reserveid",
                                val
                              )
                            }
                            onInputChange={(val) => {
                              newReserveInputhandler(
                                index,
                                "reserveoptions",
                                val
                              );
                            }}
                          />
                          <p>{salesOrderItemBlock?.beneficiaryname}</p>
                        </td>

                        <td className="description">
                          <textarea
                            name="description"
                            id="description"
                            value={salesOrderItemBlock?.description}
                            onChange={(e) =>
                              newSalesOrderItemBlockInputHandler(
                                index,
                                "description",
                                e.target.value
                              )
                            }
                            className="form-control fs-sm text-gray bg-white py-2 border-gray-300 shadow-none"
                            rows="2"
                          ></textarea>
                        </td>
                        <td className="unit">
                          <input
                            type="number"
                            name="unit"
                            id="unit"
                            value={salesOrderItemBlock.unit}
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              newSalesOrderItemBlockInputHandler(
                                index,
                                "unit",
                                inputValue
                              );
                            }}
                            className="form-control fs-sm text-gray bg-white py-2 border-gray-300 shadow-none"
                          />
                        </td>
                        <td className="activitycost">
                          <input
                            type="number"
                            name="activitycost"
                            id="activitycost"
                            value={salesOrderItemBlock.activitycost}
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              newSalesOrderItemBlockInputHandler(
                                index,
                                "activitycost",
                                inputValue
                              );
                            }}
                            className="form-control fs-sm text-gray bg-white py-2 border-gray-300 shadow-none"
                          />
                        </td>
                        <td className="discountpercent">
                          <input
                            type="number"
                            name="discountpercent"
                            id="discountpercent"
                            value={salesOrderItemBlock.discountpercent}
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              newSalesOrderItemBlockInputHandler(
                                index,
                                "discountpercent",
                                inputValue
                              );
                            }}
                            className="form-control fs-sm text-gray bg-white py-2 border-gray-300 shadow-none"
                          />
                        </td>
                        <td className="taxpercent">
                          <Select
                            className="fs-sm text-gray py-2 border-gray-300 shadow-none"
                            isClearable
                            placeholder={t("Percent")}
                            options={invoiceTaxOptions}
                            menuPlacement="auto"
                            value={salesOrderItemBlock.taxpercentvalue}
                            onChange={(val) =>
                              newTaxPercentSelectionHandler(
                                index,
                                "taxpercentvalue",
                                "taxpercent",
                                val
                              )
                            }
                          />
                        </td>
                        <td className="paybleamount">
                          <p>{salesOrderItemBlock.totalprice}</p>
                        </td>
                        <td className="action">
                          <div className="d-flex align-items-cemter gap-2">
                            {salesOrderItemBlock.description == "" ||
                            salesOrderItemBlock.activitycost == "" ||
                            salesOrderItemBlock.activitycost < 0 ||
                            !salesOrderItemBlock.reservationId ? null : (
                              <button
                                to="#"
                                className="text-primary border-0 bg-transparent"
                                disabled={isAddMoreSaving ? true : false}
                                style={{
                                  cursor: isAddMoreSaving
                                    ? "not-allowed"
                                    : "pointer",
                                }}
                                onClick={() => {
                                  saveSalesOrderItemHandler(
                                    salesOrderItemBlock,
                                    index
                                  );
                                }}
                              >
                                <span
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="top"
                                  title={t("Save salesorder item")}
                                  className="d-block material-symbols-outlined icon-fill icon-md"
                                >
                                  save
                                </span>
                              </button>
                            )}
                            <button
                              to="#"
                              className="text-dark border-0 bg-transparent"
                              onClick={() => {
                                deleteSalesOrderItemBlock(index);
                              }}
                            >
                              <span
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title={t("Delete salesorder item")}
                                className="d-block material-symbols-outlined icon-fill icon-md"
                              >
                                delete
                              </span>
                            </button>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                  {/* ----- new added data tr section end ----- */}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {salesOrderData ? (
          <div className="mb-3">
            {isClosedBudgetStatus ? null : (
              <button
                className="btn btn-primary btn-sm mw-unset"
                onClick={addNewSalesOrderItemBlock}
              >
                {t("Add More")}
              </button>
            )}
          </div>
        ) : null}
        {/* ----- error message section start ----- */}
        <div className="my-3">
          {notificationMessage == "" ? null : (
            <p className="mb-0 text-danger fw-bold">* {notificationMessage}</p>
          )}
        </div>
        {/* ----- error message section end ----- */}
        {/* note and total amount */}
        {salesOrderData ? (
          <div className="total d-flex bg-gray-500 border border-gray">
            <div className="flex-fill text-gray fw-medium p-3 border-end border-gray">
              <label htmlFor="notes" className="text-secondary fw-bold">
                {t("Notes")}:
              </label>
              {noteList.length > 0 ? (
                <div className="mb-2">
                  {noteList.map((note, index) => {
                    return (
                      <p className="mb-1" key={index}>
                        {note.notedate} : {note.note}
                      </p>
                    );
                  })}
                </div>
              ) : null}
              <form onSubmit={(e) => e.preventDefault()}>
                {/* ----- note input section start ------ */}
                <textarea
                  name="notes"
                  id="notes"
                  cols="30"
                  rows="3"
                  className="form-control bg-white mb-3"
                  placeholder={t("Enter Notes")}
                  value={noteData}
                  onChange={(e) => setNoteData(e.target.value)}
                ></textarea>
                {/* ----- note input section end ------ */}
                <button
                  type="submit"
                  className="btn btn-primary btn-sm mw-unset"
                  onClick={saveNoteHandler}
                  disabled={isNoteSending ? true : false}
                  style={{ cursor: isNoteSending ? "not-allowed" : "pointer" }}
                >
                  {t("Add Note")}
                  {isNoteSending && (
                    <div
                      className="mx-2 spinner-border spinner-border-sm"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  )}
                </button>
              </form>
            </div>
            {/* tax and other amount */}
            <div className="d-flex align-items-center justify-content-center flex-fill text-gray fw-medium p-3">
              <ul className="list_stye_none d-flex w-100 text-center">
                <li className="col">
                  <h3 className="mb-0">
                    <span className="d-block fw-normal text-secondary mb-3">
                      Amount:
                    </span>
                    <span className="d-block">
                      {totalActivityPrice} {currencySymbol}
                    </span>
                  </h3>
                </li>
                <li className="col">
                  <h3 className="mb-0">
                    <span className="d-block fw-normal text-secondary mb-3">
                      Taxes:
                    </span>
                    <span className="d-block">
                      {totalTaxAmount} {currencySymbol}
                    </span>
                  </h3>
                </li>
                <li className="total col">
                  <h3 className="mb-0">
                    <span className="d-block fw-normal text-secondary mb-3">
                      {" "}
                      {t("Total Payble")} :
                    </span>
                    <span className="d-block">
                      {totalPayble} {currencySymbol}
                    </span>
                  </h3>
                </li>
              </ul>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};
export default SaveSalesOrderPopup;
