/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";

// multi date picker
import DatePicker from "react-multi-date-picker";

//import date format from helper folder
import { formatDate } from "helpers/DateCalculation";

//import translation function for language translation
import { useTranslation } from "react-i18next";

//import context
import { GlobalProvider } from "context/GlobalContext";

const ActionLogFilter = () => {
  const { t } = useTranslation(); //for translation

  const todayValue = new Date().toISOString().split("T")[0];

  const { setfilterActionLogDates, setreloadActionLogList } =
    useContext(GlobalProvider);

  const [selectedDatesPick, setSelectedDatesPick] = useState([]);

  // Hook for date compare validation
  const [dateWarning, setDateWarning] = useState(false);

  const [selectedFilterData, setSelectedFilterData] = useState({
    selectedRadioName: "",
    fromDate: "",
    toDate: "",
    selectedDate: "",
  });

  //function for select radio
  const radioCheckHandler = (e) => {
    setDateWarning(false);
    setSelectedFilterData((prevData) => ({
      ...prevData,
      selectedRadioName: e.target.value,
    }));
  };

  const datePickerChangeHandler = (clickdate) => {
    // Uncomment the following line if you want to log the clickdate
    // console.log("clickdate", clickdate);

    let currentDateSelected = ""; // Will hold the formatted dates as a string
    let currentDateArr = []; // Will hold the formatted dates as an array

    if (clickdate) {
      // Convert clicked dates to an array of formatted date strings
      currentDateArr = clickdate.map((date) => formatDate(new Date(date)));

      // Creating a comma-separated string of formatted dates
      clickdate.map((date, index) => {
        if (currentDateSelected) {
          currentDateSelected =
            currentDateSelected + "," + formatDate(new Date(date));
        } else {
          currentDateSelected = formatDate(new Date(date));
        }
      });
    }

    setSelectedDatesPick(currentDateArr); // Updating selected dates as an array

    // Updating selectedFilterData state
    setSelectedFilterData((prevData) => ({
      ...prevData,
      selectedRadioName: "actionlogdate",
      selectedDate: currentDateSelected,
      fromDate: "",
      toDate: "",
    }));

    // Uncomment the following line if you want to log the selectedDate
    // console.log("selectedDate", currentDateSelected);
  };

  //function for search
  const searchHandler = () => {
    /*===== switch statement for date input radio start ======*/
    switch (selectedFilterData.selectedRadioName) {
      case "daterange":
        if (selectedFilterData.fromDate < selectedFilterData.toDate) {
          setfilterActionLogDates(
            selectedFilterData.fromDate + "|" + selectedFilterData.toDate
          ); // Set searchReserveDate for date range
        } else {
          setDateWarning(true); // Show date warning
        }
        break;

      case "todayradio":
        setfilterActionLogDates(todayValue); // Set searchReserveDate for today
        break;

      case "actionlogdate":
        setfilterActionLogDates(selectedFilterData.selectedDate); // Set searchReserveDate for event date
        break;

      default:
        setfilterActionLogDates(""); // Reset searchReserveDate
        break;
    }
    /*===== switch statement for date input radio end ======*/
  };

  //function for reset filter
  const resetFilterHandler = () => {
    setSelectedDatesPick([]);
    setSelectedFilterData({
      selectedRadioName: "",
      fromDate: "",
      toDate: "",
      selectedDate: "",
    });
    setfilterActionLogDates("");
    setreloadActionLogList(true);
  };

  return (
    <div className="collapse mt-3" id="filterCollapse">
      <div className="p-3 bg-gray-200">
        <h4 className="mb-3">{t("Filter")}</h4>
        {/* ------- search form section start ------- */}
        <form onSubmit={(e) => e.preventDefault()}>
          {/* date filter start  */}
          <div className="form-group mb-3">
            <div className="row align-items-center">
              {/* -------- date range section start -------- */}
              <div className="col-lg mb-3 mb-lg-0">
                <div className="d-flex align-items-center">
                  {/* -------- date input radio section start -------- */}
                  <div className="form-check mb-0 me-3">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="daterange"
                      id="dateRange"
                      value="daterange"
                      checked={
                        selectedFilterData.selectedRadioName === "daterange"
                      }
                      onChange={radioCheckHandler}
                    />
                    <label
                      className="form-check-label mb-0 me-3"
                      htmlFor="today"
                    >
                      {t("Date Range")}
                    </label>
                  </div>
                  {/* -------- date input radio section end -------- */}

                  {/* -------- date selection section start -------- */}
                  <div className="field-container d-sm-flex border flex-fill">
                    {/* ------- from date input start ------- */}
                    <input
                      type="date"
                      id="from_date"
                      className="form-control bg-white border-0 mb-3 mb-sm-0"
                      placeholder={t("From date")}
                      value={selectedFilterData.fromDate}
                      onChange={(e) => {
                        // setFromDate(e.target.value);
                        // setSelectedChecked("daterange");
                        setSelectedDatesPick([]);
                        setSelectedFilterData((prevData) => ({
                          ...prevData,
                          selectedRadioName: "daterange",
                          fromDate: e.target.value,
                          selectedDate: "",
                        }));
                        setDateWarning(false);
                      }}
                    />
                    {/* ------- from date input end ------- */}

                    {/* ------- to date input start ------- */}
                    <input
                      type="date"
                      id="to_date"
                      className="form-control bg-white border-0"
                      placeholder={t("To date")}
                      value={selectedFilterData.toDate}
                      onChange={(e) => {
                        // setToDate(e.target.value);
                        // setSelectedChecked("daterange");
                        setSelectedDatesPick([]);
                        setSelectedFilterData((prevData) => ({
                          ...prevData,
                          toDate: e.target.value,
                          selectedRadioName: "daterange",
                          selectedDate: "",
                        }));
                        setDateWarning(false);
                      }}
                    />
                    {/* ------- to date input end ------- */}
                  </div>

                  {/* -------- date selection section end -------- */}
                </div>
                {/* date compare warning */}
                {dateWarning && (
                  <div className="error-message mt-2">
                    <p className="d-flex align-items-center gap-1 text-danger">
                      <span className="material-symbols-outlined">warning</span>
                      <span>
                        {t("End date should be greater than start date")}!
                      </span>
                    </p>
                  </div>
                )}
              </div>
              {/* -------- date range section end -------- */}

              {/* --------- today section start --------- */}
              <div className="col-lg-auto mb-3 mb-lg-0">
                <div className="form-check mb-0">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="todayradio"
                    id="today"
                    value="todayradio"
                    checked={
                      selectedFilterData.selectedRadioName === "todayradio"
                    }
                    onChange={radioCheckHandler}
                  />
                  <label className="form-check-label mb-0" htmlFor="today">
                    {t("Today")}
                  </label>
                </div>
              </div>
              {/* --------- today section end --------- */}

              {/* --------- multiple date section start --------- */}
              <div className="col-lg">
                <div className="d-flex align-items-center">
                  <div className="form-check mb-0 me-3">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="eventdate"
                      id="multipleDates"
                      value="eventdate"
                      checked={
                        selectedFilterData.selectedRadioName === "eventdate"
                      }
                      onChange={radioCheckHandler}
                    />
                  </div>
                  <label className="form-check-label mb-0 me-3" htmlFor="today">
                    {t("Multiple Dates")}
                  </label>
                  <DatePicker
                    className="form-control"
                    multiple
                    value={selectedDatesPick}
                    onChange={datePickerChangeHandler}
                    format="DD-MM-YYYY"
                  />
                </div>
              </div>
              {/* --------- multiple date section end --------- */}
            </div>
          </div>
          {/* date filter end  */}

          {/* button and reset start */}
          <div className="form-group">
            <div className="row mt-4">
              <div className="col-lg-12">
                {/* ------ button section start -------- */}
                <div className="action d-flex gap-3 align-items-center justify-content-end">
                  {/* ------- reset button start ------- */}
                  <Link
                    to="#"
                    className="d-flex gap-1 align-items-center btn btn-warning text-white"
                    onClick={resetFilterHandler}
                  >
                    <span className="d-block material-symbols-outlined icon-md">
                      sync
                    </span>
                    <span className="d-block fw-medium">{t("Clear")}</span>
                  </Link>
                  {/* ------- reset button end ------- */}

                  {/* ------- search button start ------- */}
                  <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={searchHandler}
                  >
                    {t("Filter")}
                  </button>
                  {/* ------- search button start ------- */}
                </div>
                {/* ------ button section end -------- */}
              </div>
            </div>
          </div>
          {/* button and reset end */}
        </form>
        {/* ------- search form section end ------- */}
      </div>
    </div>
  );
};

export default ActionLogFilter;
