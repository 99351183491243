/* eslint-disable */
import { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
/*import url and gateway methods */
import { getData } from "utils/Gateway";
import * as url from "urlhelpers/UrlHelper";
//import translation function for language translation
import { useTranslation } from "react-i18next";
//import context
import { GlobalProvider } from "context/GlobalContext";
import TaskStatusOptions from "data/Prod/TaskStatus.json";
import TaskPriority from "data/Prod/TaskPriority.json";
const DailyTaskFilter = () => {
  const {
    setreloadTaskList,
    setfilterTaskDateRange,
    setfilterTaskTitle,
    setfilterTaskProjects,
    setfilterTaskUsers,
    setfilterTaskStatus,
    setfilterTaskPriorities,
    setfilterTaskLabels,
  } = useContext(GlobalProvider);
  const token = localStorage.getItem("token"); // token
  const { t } = useTranslation(); //for translation
  const todayValue = new Date().toISOString().split("T")[0];
  const [selectedChecked, setSelectedChecked] = useState("");
  const [labelList, setLabelList] = useState([]); // for label list
  const [projectList, setProjectList] = useState([]);
  //date range
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  //single date
  const [selectedDate, setSelectedDate] = useState(todayValue);
  const [title, settitle] = useState("");
  const [projectValue, setprojectValue] = useState(null);
  const [projectIds, setprojectIds] = useState([]);
  const [labelValue, setLabelValue] = useState(null);
  const [searchedLabel, setSearchedLabel] = useState([]);
  const [priorityValue, setpriorityValue] = useState(null);
  const [priorities, setpriorities] = useState([]);
  const [taskStatusValue, settaskStatusValue] = useState(null);
  const [taskStatusValues, settaskStatusValues] = useState([]);
  const [userName, setUserName] = useState("");
  const [userList, setUserList] = useState([]);
  const [userValue, setUserValue] = useState(null);
  const [selectedUsers, setselectedUsers] = useState([]);
  // Hook for date compare validation
  const [dateWarning, setDateWarning] = useState(false);
  //function for get all user list
  const getAllUserList = async () => {
    try {
      let requestUrl =
        url.API_BASEURL +
        url.API_GET_ALL_USER +
        `?token=${token}&isselect=${true}&username=${userName}`;
      const response = await getData(requestUrl);
      // console.log(response);
      if (response.status) {
        setUserList(response.data);
      }
    } catch (error) {
      console.error(error.message);
    }
  };
  /* function to get all label data */
  const getAllProjectList = async () => {
    try {
      let requestUrl =
        url.API_BASEURL + url.API_GET_ALL_PROJECTS + `?token=${token}`;
      // console.log("project list url =====>>>", requestUrl);
      const response = await getData(requestUrl);
      // console.log("project list response ========>>>", response);
      if (response.data) {
        setProjectList(
          response.data.map((val) => ({
            label: val.title,
            value: val._id,
            startdate: val.startdate,
            enddate: val.enddate,
          }))
        );
      }
    } catch (error) {
      console.error(error.message);
    }
  };
  //function for get all label list
  const getAllLabels = async () => {
    try {
      let requestUrl =
        url.API_BASEURL +
        url.API_GET_ALL_TAG +
        `?token=${token}&isselect=${true}`;
      const response = await getData(requestUrl);
      if (response.status) {
        setLabelList(response.data);
      }
    } catch (error) {
      console.error(error.message);
    }
  };
  //function for select radio
  const radioCheckHandler = (event) => {
    setSelectedChecked(event.target.value);
    setDateWarning(false);
  };
  //select project
  const projectSelectionHandler = (val) => {
    setprojectValue(val);
    setprojectIds(val.map((item) => item.value));
  };
  //function for reciever selection
  const userSelectionHandler = (val) => {
    setUserValue(val);
    setselectedUsers(val.map((item) => item.value));
  };
  //select status
  const statusSelectionHandler = (val) => {
    settaskStatusValue(val);
    settaskStatusValues(val.map((item) => item.value));
  };
  //select priority
  const prioritySelectionHandler = (val) => {
    setpriorityValue(val);
    setpriorities(val.map((item) => item.value));
  };
  //function for label selection
  const labelSectionHandler = (val) => {
    setLabelValue(val);
    const labelData = val.map((data) => data.value);
    setSearchedLabel(labelData);
  };
  //function for reset
  const resetHandler = () => {
    setSelectedChecked("");
    settitle("");
    setFromDate("");
    setToDate("");
    setSelectedDate(todayValue);
    setLabelValue(null);
    setSearchedLabel([]);
    setprojectValue(null);
    setprojectIds([]);
    setUserName("");
    setUserList([]);
    setUserValue(null);
    setselectedUsers([]);
    settaskStatusValue(null);
    settaskStatusValues([]);
    setpriorities([]);
    setpriorityValue(null);
    setfilterTaskTitle("");
    setfilterTaskDateRange("");
    setfilterTaskLabels([]);
    setfilterTaskProjects([]);
    setfilterTaskUsers([]);
    setfilterTaskStatus([]);
    setfilterTaskPriorities([]);
    setreloadTaskList(true);
  };
  //function for search
  const searchHandler = () => {
    //assign data for search
    /*===== switch statement for date input radio start ======*/
    // switch (selectedChecked) {
    //   case "daterange":
    //     if (fromDate < toDate) {
    //       setfilterTaskDateRange(fromDate + "," + toDate);
    //     } else {
    //       setDateWarning(true);
    //     }
    //     break;
    //   case "todayradio":
    //     setfilterTaskDateRange(todayValue);
    //     break;
    //   case "eventdate":
    //     setfilterTaskDateRange(selectedDate);
    //     break;
    //   default:
    //     setfilterTaskDateRange("");
    //     break;
    // }
    /*===== switch statement for date input radio end ======*/
    if (fromDate !== "" && toDate == "") {
      setfilterTaskDateRange(fromDate);
    } else if (fromDate !== "" && toDate !== "") {
      setfilterTaskDateRange(fromDate + "," + toDate);
    } else {
      setfilterTaskDateRange("");
    }
    title == "" ? setfilterTaskTitle("") : setfilterTaskTitle(title);
    labelList.length == 0
      ? setfilterTaskLabels([])
      : setfilterTaskLabels(searchedLabel);
    projectIds.length == 0
      ? setfilterTaskProjects([])
      : setfilterTaskProjects(projectIds);
    selectedUsers.length == 0
      ? setfilterTaskUsers([])
      : setfilterTaskUsers(selectedUsers);
    taskStatusValues.length == 0
      ? setfilterTaskStatus([])
      : setfilterTaskStatus(taskStatusValues);
    priorities.length == 0
      ? setfilterTaskPriorities([])
      : setfilterTaskPriorities(priorities);
  };
  //use effect for search receiver
  useEffect(() => {
    if (userName.length >= 3) {
      getAllUserList();
    }
  }, [userName]);
  useEffect(() => {
    // getAllProjectList();
    getAllLabels();
  }, []);
  return (
    <div className="collapse mt-3" id="filterCollapse">
      <div className="p-3 bg-gray-200">
        <h4>{t("Filter")}</h4>
        <form onSubmit={(e) => e.preventDefault()}>
          {/* date and title */}
          <div className="form-group mb-3">
            <div className="row">
              <div className="col-lg-6">
                <label>{t("Dates")}</label>
                <div className="field-container d-sm-flex border flex-fill">
                  {/* ------- from date input start ------- */}
                  <input
                    type="date"
                    id="from_date"
                    className="form-control bg-white border-0"
                    placeholder={t("From date")}
                    value={fromDate}
                    onChange={(e) => {
                      setFromDate(e.target.value), setDateWarning(false);
                    }}
                  />
                  {/* ------- from date input end ------- */}
                  {/* ------- to date input start ------- */}
                  <input
                    type="date"
                    id="to_date"
                    className="form-control bg-white border-0"
                    placeholder={t("To date")}
                    value={toDate}
                    onChange={(e) => {
                      setToDate(e.target.value), setDateWarning(false);
                    }}
                  />
                  {/* ------- to date input end ------- */}
                </div>
              </div>
              <div className="col-lg-6">
                <label>{t("Title")}</label>
                <input
                  type="text"
                  className="form-control bg-white"
                  placeholder={t("Title")}
                  value={title}
                  onChange={(e) => settitle(e.target.value)}
                />
              </div>
            </div>
          </div>
          {/* status,priority,label */}
          <div className="form-group mb-3">
            <div className="row">
              <div className="col-lg-4">
                <label>{t("Task Status")}</label>
                <Select
                  isMulti
                  placeholder={t("Select Status")}
                  options={TaskStatusOptions}
                  value={taskStatusValue}
                  onChange={(val) => {
                    statusSelectionHandler(val);
                  }}
                />
              </div>
              <div className="col-lg-4">
                <label>{t("Priority")}</label>
                <Select
                  isMulti
                  placeholder={t("Select Priority")}
                  options={TaskPriority}
                  value={priorityValue}
                  onChange={(val) => {
                    prioritySelectionHandler(val);
                  }}
                />
              </div>
              <div className="col-lg-4">
                <label>{t("Label")}</label>
                <Select
                  isMulti
                  placeholder={t("Select label")}
                  options={labelList}
                  value={labelValue}
                  onChange={(val) => {
                    labelSectionHandler(val);
                  }}
                />
              </div>
            </div>
          </div>
          {/* -------- search and reset button section start -------- */}
          <div className="action d-flex gap-3 align-items-center justify-content-end">
            <Link
              onClick={resetHandler}
              to="#"
              className="d-flex gap-1 align-items-center text-black"
            >
              <span className="d-block material-symbols-outlined icon-md">
                sync
              </span>
              <span className="d-block fw-medium">{t("Refresh")}</span>
            </Link>
            <button
              type="submit"
              className="btn btn-primary"
              onClick={searchHandler}
            >
              {t("Filter")}
            </button>
          </div>
          {/* -------- search and reset button section end -------- */}
        </form>
      </div>
    </div>
  );
};
export default DailyTaskFilter;
