/* eslint-disable */
import React, { useEffect, useContext, useState } from "react";
import Select from "react-select";

//import translation function for language translation
import { useTranslation } from "react-i18next";

/*import url and gateway methods */
import { postData, putData } from "utils/Gateway";
import * as url from "urlhelpers/UrlHelper";

//import option
import reserveOptions from "data/Prod/LabelReserveOptions.json";

//import context
import { GlobalProvider } from "context/GlobalContext";

const AddUpdateLabelPopup = () => {
  const token = localStorage.getItem("token"); // token
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const { t } = useTranslation(); //for translation

  const {
    setReloadLabelList,
    editLabelData,
    setEditLabelData,
    setSuccess,
    setError,
    setNotificationString,
    resetAddUpdateLabelPopup,
    setResetAddUpdateLabelPopup,
  } = useContext(GlobalProvider);

  const [labelName, setLabelName] = useState("");
  const [labelSlug, setLabelSlug] = useState("");
  const [isReservedValue, setIsReservedValue] = useState(null);
  const [isReservedData, setIsReservedData] = useState(null);

  const [isSending, setIsSending] = useState(false);

  //function for reserve selection
  const reserveSelectionHandler = (val) => {
    setIsReservedValue(val);
    setIsReservedData(val.value);
  };

  /* hook for validation */
  const [validation, setValidation] = useState({
    labelWarning: false,
    slugWarning: false,
    reserveWarning: false,
  });

  /* function to validate form */
  const validate = () => {
    let isValid = true;

    if (labelName === "") {
      setValidation((prevState) => ({ ...prevState, labelWarning: true }));
      isValid = false;
    }

    if (labelSlug === "") {
      setValidation((prevState) => ({ ...prevState, slugWarning: true }));
      isValid = false;
    }

    if (isReservedData === null) {
      setValidation((prevState) => ({ ...prevState, reservewarning: true }));
      isValid = false;
    }

    return isValid;
  };

  /* function to add label */
  const saveLabelHandler = async (e) => {
    e.preventDefault();

    let labelData = {
      title: labelName,
      slug: labelSlug,
      isreserved: isReservedData,
    };

    if (validate()) {
      setIsSending(true);
      try {
        let requestUrl = url.API_BASEURL + url.API_ADD_TAG + `?token=${token}`;

        const response = await postData(requestUrl, labelData);

        if (response.status) {
          setIsSending(false);
          resetHandler();
          setReloadLabelList(true);
          setSuccess(true);
          setNotificationString(t("Label added successfully"));
        }
      } catch (error) {
        console.error(error.message);
        setError(true);
        setNotificationString(
          t("Something went wrong, please try again later")
        );
      }

      bootstrap.Offcanvas.getInstance(
        document.querySelector("#addUpdateLabelPopup")
      ).hide();
    }
  };

  //function for update material kit
  const updateLabelHandler = async (e) => {
    e.preventDefault();

    const labelData = {
      title: labelName,
      slug: labelSlug,
      isreserved: isReservedData,
    };
    if (validate()) {
      setIsSending(true);
      try {
        let requestUrl =
          url.API_BASEURL +
          url.API_UPDATE_TAG +
          `/${editLabelData._id}` +
          `?token=${token}`;

        const response = await putData(requestUrl, labelData);

        console.log(response);
        if (response.status) {
          setIsSending(false);
          resetHandler();
          setReloadLabelList(true);
          setSuccess(true);
          setNotificationString(t("Label edited successfully"));
        }
      } catch (error) {
        console.error(error.message);
        setError(true);
        setNotificationString(
          t("Something went wrong, please try again later")
        );
      }
      bootstrap.Offcanvas.getInstance(
        document.querySelector("#addUpdateLabelPopup")
      ).hide();
    }
  };

  /* reset */
  const resetHandler = () => {
    setEditLabelData(null);
    setLabelName("");
    setLabelSlug("");
    let reserveValue = {
      label: "No",
      value: "0",
    };
    reserveSelectionHandler(reserveValue);
    setEditLabelData(null);
  };

  useEffect(() => {
    if (resetAddUpdateLabelPopup) {
      resetHandler();
      setResetAddUpdateLabelPopup(false);
    }
  }, [resetAddUpdateLabelPopup]);

  /* use effect hook to assign edit data */
  useEffect(() => {
    console.log(editLabelData);
    if (editLabelData) {
      //assign label name
      editLabelData?.title && setLabelName(editLabelData.title);

      //assign label slug
      editLabelData?.slug && setLabelSlug(editLabelData.slug);

      //assign reserve value
      let reserveValue = null;
      if (editLabelData.isreserved == "1") {
        reserveValue = {
          label: "Yes",
          value: "1",
        };
      } else {
        reserveValue = {
          label: "No",
          value: "0",
        };
      }
      reserveSelectionHandler(reserveValue);
    }
  }, [editLabelData]);

  useEffect(() => {
    let reserveValue = {
      label: "No",
      value: "0",
    };
    reserveSelectionHandler(reserveValue);
  }, []);

  return (
    <div
      className="offcanvas offcanvas-end border-0"
      tabIndex="-1"
      id="addUpdateLabelPopup"
      aria-labelledby="addLabelPopupLabel"
    >
      <div className="offcanvas-header p-4">
        <h3 className="text-secondary mb-0" id="addLabelPopupLabel">
          {editLabelData ? t("Edit Label") : t("Add Label")}
        </h3>

        {/* ------- pop up close button start ------- */}
        <button
          type="button"
          id="closeCreateMail"
          className="btn-close p-2 bg-white shadow-none shadow-sm m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={resetHandler}
        ></button>
        {/* ------- pop up close button end ------- */}
      </div>

      <div className="offcanvas-body p-4 pt-0">
        {/* ------- label form section start ------ */}
        <form onSubmit={editLabelData ? updateLabelHandler : saveLabelHandler}>
          {/* ------- label name section start ------- */}
          <div className="form-group mb-4">
            <label htmlFor="name">{t("Label Name")}</label>
            <input
              type="text"
              id="name"
              placeholder={t("Enter Here")}
              className="form-control"
              value={labelName}
              onChange={(e) => {
                setLabelName(e.target.value);
                setValidation((prevState) => ({
                  ...prevState,
                  labelWarning: false,
                }));
              }}
            />
            {/* level warning */}
            {validation.labelWarning && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>{t("Please enter label")}!</span>
                </p>
              </div>
            )}
          </div>
          {/* ------- label name section end ------- */}

          {/* ------- label slug section start ------- */}
          <div className="form-group mb-4">
            <label htmlFor="slug">Slug</label>
            <input
              type="text"
              id="slug"
              placeholder={t("Enter Here")}
              className="form-control"
              value={labelSlug}
              onChange={(e) => {
                setLabelSlug(e.target.value);
                setValidation((prevState) => ({
                  ...prevState,
                  slugWarning: false,
                }));
              }}
            />
            {/* slug warning */}
            {validation.slugWarning && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>{t("Please enter slug")}!</span>
                </p>
              </div>
            )}
          </div>
          {/* ------- label slug section end ------- */}

          {/* ------- label reserve select section start ------- */}
          {userInfo.role.name == "admin" ? (
            <div className="form-group mb-4">
              <label htmlFor="slug">{t("Is Reserved")}</label>
              <Select
                options={reserveOptions}
                value={isReservedValue}
                onChange={(val) => {
                  reserveSelectionHandler(val);
                }}
              />
            </div>
          ) : null}
          {/* ------- label reserve select section end ------- */}

          {/* ------- label submit button start ------- */}
          <div className="action">
            <button
              type="submit"
              className="btn btn-primary mw-unset"
              disabled={isSending ? true : false}
              style={{ cursor: isSending ? "not-allowed" : "pointer" }}
            >
              {editLabelData ? t("Update") : t("Save")}
              {isSending && (
                <div
                  className="mx-2 spinner-border spinner-border-sm"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              )}
            </button>
          </div>
          {/* ------- label submit button end ------- */}
        </form>
        {/* ------- label form section end ------ */}
      </div>
    </div>
  );
};

export default AddUpdateLabelPopup;
