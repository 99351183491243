/* eslint-disable */
import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
//import translation function for language translation
import { useTranslation } from 'react-i18next';

//import context
import { GlobalProvider } from 'context/GlobalContext';

const CostTemplateHeader = ({
  setDownloadExcel,
  records,
  setSearch,
  blockRefSelected,
}) => {
  const { t } = useTranslation(); //for translation

  const { setReloadCostList, setSuccess, setNotificationString } =
    useContext(GlobalProvider);

  /* hook to get the search value */
  const [searchValue, setSearchValue] = useState('');
  /* function to click download xls*/
  const handleDownloadClick = () => {
    setDownloadExcel(true);

    setTimeout(() => setDownloadExcel(false), 500);
  };
  /* hook to empty search when searchValue gets empty */
  useEffect(() => {
    !searchValue && setSearch('');
  }, [searchValue]);

  return (
    <>
      <div className="filter-container d-flex flex-wrap align-items-center gap-2 pb-1 border-bottom border-gray">
        <Link
          to="#columnCollapse"
          className="column-collapse btn btn-primary icon-btn mw-unset"
          data-bs-toggle="collapse"
          role="button"
          aria-expanded="false"
          aria-controls="columnCollapse"
        >
          <span className="d-block material-symbols-outlined icon-fill">
            visibility
          </span>
        </Link>
        <Link
          to="#"
          onClick={handleDownloadClick}
          className="download btn btn-primary icon-btn mw-unset"
        >
          <span className="d-block material-symbols-outlined">download</span>
        </Link>
        <div className="search-container position-relative d-none d-md-block">
          <form action="">
            <input
              type="search"
              placeholder={t('Search here')}
              className="form-control bg-transparent py-2 border-0"
              onChange={e => setSearchValue(e.target.value)}
              onKeyDown={e => {
                e.key === 'Enter' &&
                  (e.preventDefault(), setSearch(searchValue));
              }}
            />
            <button
              onClick={() => setSearch(searchValue)}
              type="button"
              className="bg-transparent text-dark p-0 border-0 position-absolute top-50 translate-middle-y"
            >
              <span className="d-block material-symbols-outlined">search</span>
            </button>
          </form>
        </div>
        {/* ------------- showing records start ----------------- */}
        <p className="text-gray fw-medium ms-auto me-3">
          {t('Showing')}
          <span className="text-black ms-1">{records}</span> {t('Records')} -
          <span className="text-black ms-1" ref={blockRefSelected}>
            {' '}
            0{' '}
          </span>{' '}
          {t('Selected')}
        </p>
        {/* ------------- showing records end ----------------- */}
        {/* ----- refresh icon start ------ */}
        <Link
          to="#"
          className="filter-btn btn btn-primary icon-btn mw-unset"
          onClick={() => {
            setReloadCostList(true);
            setSuccess(true);
            setNotificationString(
              t('The list has been refreshed successfully')
            );
          }}
        >
          <span className="d-block material-symbols-outlined">refresh</span>
        </Link>
        {/* ----- refresh icon end ------ */}
        <Link
          to="#filterCollapse"
          className="filter-btn btn btn-primary icon-btn mw-unset "
          data-bs-toggle="collapse"
          role="button"
          aria-expanded="false"
          aria-controls="filterCollapse"
        >
          <span className="d-block material-symbols-outlined">filter_alt</span>
        </Link>
      </div>
    </>
  );
};

export default CostTemplateHeader;
