import React from 'react';
//import translation function for language translation
import { useTranslation } from 'react-i18next';

/* Importing react select */
import Select from 'react-select';

const SaveInstallationsPopup = () => {
  const { t } = useTranslation(); //for translation
  /* select city values */
  const city = [
    { label: 'City 1', value: 'City 1' },
    { label: 'City 2', value: 'City 2' },
    { label: 'City 3', value: 'City 3' },
    { label: 'City 4', value: 'City 4' },
    { label: 'City 5', value: 'City 5' },
  ];

  /* hook to change the city values */
  const [changeValues, setChangeValues] = React.useState(null);
  return (
    <div
      className="offcanvas offcanvas-end border-0"
      tabIndex="-1"
      id="saveReservationPopup"
      aria-labelledby="saveReservationPopupLabel"
    >
      <div className="offcanvas-header position-relative align-items-start p-4">
        <h3 className="text-secondary mb-0" id="saveReservationPopupLabel">
          <span className="d-block">{t('Save Installation')}</span>
        </h3>
        <button
          type="button"
          className="btn-close p-2 bg-white shadow-none shadow-sm m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div className="offcanvas-body position-relative p-4 pt-0">
        <form action="">
          <div className="form-group mb-4">
            <label>{t('Name')}</label>
            <input
              type="text"
              value=""
              className="form-control"
              placeholder={t('Enter Here')}
            />
          </div>
          <div className="form-group mb-4">
            <label>{t('Responsible User')}</label>
            <input
              type="text"
              value=""
              className="form-control"
              placeholder={t('Enter Here')}
            />
          </div>
          <div className="form-group mb-4">
            <label htmlFor="observations">{t('Address')}</label>
            <textarea
              name="observations"
              id="observations"
              cols="30"
              rows="6"
              className="form-control"
              placeholder={t('Enter Comments')}
            ></textarea>
          </div>
          <div className="form-group mb-4">
            <label>{t('City')}</label>
            <Select
              className="nosearch_select"
              options={city}
              value={changeValues}
              onChange={val => setChangeValues(val)}
            />
          </div>
          <div className="form-group mb-4">
            <label>{t('Map Latitude')}</label>
            <input
              type="text"
              value=""
              className="form-control"
              placeholder={t('Enter Here')}
            />
          </div>
          <div className="form-group mb-4">
            <label>{t('Map Longitude')}</label>
            <input
              type="text"
              value=""
              className="form-control"
              placeholder={t('Enter Here')}
            />
          </div>

          <div className="action d-flex align-items-center gap-2">
            <button type="submit" className="btn btn-primary">
              {t('Save')}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SaveInstallationsPopup;
