/* eslint-disable */
import React, { useState, useEffect, createRef, useContext } from "react";

/* import images */
import { assetImages } from "constants";

// kendo react modules ==========================
import "@progress/kendo-theme-material/dist/all.css";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";

import { Link } from "react-router-dom";

// ES2015 module syntax
import {
  ExcelExport,
  ExcelExportColumn,
} from "@progress/kendo-react-excel-export";

import * as url from "urlhelpers/UrlHelper";
import { getData } from "utils/Gateway";

//import global utility functions
import { stringToDateTime } from "components/common/Utility.js";

// imports components ==========================
// import CreateEmailPopup from '../ComposeMailPopup/CreateEmailPopup';
// import ViewMailPopup from '../ViewMailPopup/ViewMailPopup';

// import demodata =============================
// import conversation from '../../../data/Conversation.json';

//import context
import { GlobalProvider } from "context/GlobalContext";

//import translation function for language translation
import { useTranslation } from "react-i18next";

const Inbox = ({ downloadExcel, search, setRecords, checkedRecordCount }) => {
  const token = localStorage.getItem("token"); // token
  const { t } = useTranslation(); //for translation

  const {
    reloadConversationList,
    setReloadConversationList,
    setViewConversationData,
    screenHeight,
    searchMailReciever,
    setSearchMailReciever,
    searchMailSender,
    setSearchMailSender,
    searchMailSubject,
    setSearchMailSubject,
    searchMailDate,
    setSearchMailDate,
    searchMailTemplateSelect,
    setSearchMailTemplateSelect,
    searchMailLabelSelect,
    setSearchMailLabelSelect,
  } = useContext(GlobalProvider);

  // if we want pagination --------------------------------
  // const [dataState, setDataState] = React.useState({ skip : 0, take : 10 });
  // const [result, setResult] = React.useState(process(conversation, dataState));

  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = (e) => {
    setScrollPosition(e.target.scrollLeft);
  };

  const [conversationList, setConversationList] = useState([]);
  const [responseReceived, setResponseReceived] = useState(false);

  // without pagination ------------------------------------
  const initialDataState = {};
  const [dataState, setDataState] = useState();
  const [result, setResult] = useState(
    process(conversationList, initialDataState)
  );

  const onDataStateChange = (event) => {
    console.log("event -->", event);
    setDataState(event.dataState);
    setResult(process(conversationList, event.dataState));
  };
  /* Handle the button's onClick event and set it to export the data by using the ExcelExport save method. */
  const _exporter = createRef();
  const exportExcel = () => {
    if (_exporter.current) {
      _exporter.current.save();
    }
  };

  // check the selected count and show the count in top ------------------------
  const handelSelectedCount = () => {
    let checkboxes = document.querySelectorAll(
      'input[name="selectMailRecord"]'
    );

    let totalSelected = 0;
    let filteredData = [];

    checkboxes.forEach((checkbox) => {
      if (checkbox.checked) {
        totalSelected++;
        const filteredUser = conversationList.find(
          (user) => user._id === checkbox.value
        );
        filteredUser && filteredData.push(filteredUser);
      }
    });

    // Update the content of the block
    checkedRecordCount(totalSelected);
    return filteredData;
  };

  // mark all checkbox as selected ----------------
  const selectAllRecords = (e) => {
    document
      .querySelectorAll('.k-master-row input[name="selectMailRecord"]')
      .forEach((checkbox) => {
        checkbox.checked = e.target.checked;
      });
    handelSelectedCount();
  };

  /* function to get all conversation data */
  const getConversationList = async () => {
    try {
      let requestUrl =
        url.API_BASEURL + url.API_GET_ALL_MESSAGE + `?token=${token}`;

      //query condition for reciver
      if (searchMailReciever) {
        requestUrl = requestUrl + `&mailreciever=${searchMailReciever}`;
      }

      //query condition for sender
      if (searchMailSender) {
        requestUrl = requestUrl + `&mailsender=${searchMailSender}`;
      }

      //query condition for subject
      if (searchMailSubject != "") {
        requestUrl = requestUrl + `&mailsubject=${searchMailSubject}`;
      }

      //query condition for mail date
      if (searchMailDate != "") {
        requestUrl = requestUrl + `&maildate=${searchMailDate}`;
      }

      //query condition for mail templates
      if (searchMailTemplateSelect.length > 0) {
        requestUrl = requestUrl + `&mailtemplates=${searchMailTemplateSelect}`;
      }

      //query condition for mail labels
      if (searchMailLabelSelect.length > 0) {
        requestUrl = requestUrl + `&maillabels=${searchMailLabelSelect}`;
      }

      console.log(requestUrl);

      const response = await getData(requestUrl);

      console.log("get messages response ========>>>", response);

      if (response.data) {
        setConversationList(response.data);
        setResponseReceived(true);

        dataState
          ? setResult(process(response.data, dataState))
          : setResult(process(response.data, initialDataState));
        setRecords(response.data.length);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  // blocks to replace the Grid HTML ==============================================
  const SelectRecordHeader = () => {
    return (
      <div className="d-flex align-items-center">
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            id="selectAllMain"
            onClick={selectAllRecords}
          />
        </div>
      </div>
    );
  };

  /* Select single row */
  const SelectRecordRow = (props) => {
    return (
      <td>
        <div className="form-check">
          <input
            className="form-check-input "
            type="checkbox"
            value={props.dataItem._id}
            name="selectMailRecord"
            filtername="selectedrecord"
            onClick={handelSelectedCount}
          />
        </div>
      </td>
    );
  };

  //html block for sender column
  const senderBlockHtml = (props) => {
    return (
      <td>
        <div className="d-flex align-items-center gap-3">
          <div className="sent-to">
            <div className="profile d-flex gap-2 align-items-center mb-1">
              <div className="img rounded-circle overflow-hidden">
                <img
                  src={
                    props?.dataItem?.sender
                      ? props?.dataItem?.sender?.photoimage
                        ? url.SERVER_URL +
                          props?.dataItem?.sender?.photoimage?.path
                        : assetImages.defaultUser
                      : assetImages.defaultUser
                  }
                  alt="Sender"
                  className="thumb-25 object-cover object-center"
                />
              </div>
              <a className="fw-medium text-dark">{props.dataItem.sendername}</a>
            </div>
            <p className="email d-flex gap-2 align-items-center">
              <span className="d-block material-symbols-outlined icon-sm">
                mail
              </span>
              <span className="text-dark">{props.dataItem.sendermail}</span>
            </p>
          </div>
          {/* <span className="d-block material-symbols-outlined icon-md ms-auto">
            done_all
          </span> */}
        </div>
      </td>
    );
  };

  //html block for reciever column
  const receiverBlockHtml = (props) => {
    return (
      <td>
        <div
          className="d-flex align-items-center gap-3"
          onClick={() => {
            setViewConversationData(props.dataItem);
          }}
        >
          <div className="sent-to">
            <div className="profile d-flex gap-2 align-items-center mb-1">
              <div className="img rounded-circle overflow-hidden">
                <img
                  src={
                    props?.dataItem?.receiver
                      ? props?.dataItem?.receiver?.photoimage
                        ? url.SERVER_URL +
                          props?.dataItem?.receiver?.photoimage?.path
                        : assetImages.defaultUser
                      : assetImages.defaultUser
                  }
                  alt="Receiver"
                  className="thumb-25 object-cover object-center"
                />
              </div>
              <a
                className="fw-medium text-dark"
                href="#EmailDetailsPopup"
                data-bs-toggle="offcanvas"
                role="button"
                aria-controls="EmailDetailsPopup"
              >
                {props.dataItem.receivername
                  ? props.dataItem.receivername
                  : props.dataItem.receivermail}
              </a>
            </div>

            <p className="email d-flex gap-2 align-items-center">
              <span className="d-block material-symbols-outlined icon-sm">
                mail
              </span>
              <a
                className="text-dark"
                href="#EmailDetailsPopup"
                data-bs-toggle="offcanvas"
                role="button"
                aria-controls="EmailDetailsPopup"
              >
                {props.dataItem.receivermail}
              </a>
            </p>
          </div>
          <span className="d-block material-symbols-outlined icon-md ms-auto">
            done_all
          </span>

          {props.dataItem.uploads
            ? props.dataItem.uploads.length > 0 && (
                <span className="d-block material-symbols-outlined icon-sm">
                  attach_file
                </span>
              )
            : ""}
        </div>
      </td>
    );
  };

  /* function to search */
  const filterData = () => {
    if (!result || !result.data) {
      return []; // Return an empty array if result or result.data is undefined
    }

    const filteredData = result.data.filter((item) => {
      const receivername = item?.receivername?.toLowerCase() || "";
      const senderName = item?.sender?.name?.toLowerCase() || "";
      const receivermail = item?.receivermail?.toLowerCase() || "";
      const subject = item?.subject?.toLowerCase() || "";
      const maildate = item?.maildate;
      const tagstring = item?.tagstring?.toLowerCase() || "";
      return (
        (receivername && receivername.includes(search.toLowerCase())) ||
        (receivermail && receivermail.includes(search.toLowerCase())) ||
        (senderName && senderName.includes(search.toLowerCase())) ||
        (subject && subject.includes(search.toLowerCase())) ||
        (maildate && maildate.includes(search)) ||
        (tagstring && tagstring.includes(search.toLowerCase()))
      );
    });
    setRecords(filteredData.length);
    return filteredData;
  };

  /* hook to reload list */
  useEffect(() => {
    if (responseReceived === false) {
      console.log("Inbox useEffect inside ------>");
      getConversationList();
    }
  }, [responseReceived]);

  // reload request
  useEffect(() => {
    if (reloadConversationList) {
      getConversationList();
      setReloadConversationList(false);
      setResult(process(conversationList, initialDataState));
    }
  }, [reloadConversationList]);

  useEffect(() => {
    if (
      searchMailReciever ||
      searchMailSender ||
      searchMailSubject != "" ||
      searchMailDate != "" ||
      searchMailTemplateSelect.length > 0 ||
      searchMailLabelSelect.length > 0
    ) {
      getConversationList();
    }
  }, [
    searchMailReciever,
    searchMailSender,
    searchMailSubject,
    searchMailDate,
    searchMailTemplateSelect,
    searchMailLabelSelect,
  ]);

  /* hook to hit  exportExcel() function*/
  useEffect(() => {
    downloadExcel && exportExcel();
  }, [downloadExcel]);

  useEffect(() => {
    filterData();
  }, [search]);

  /* set records based on onDataStateChange */
  useEffect(() => {
    setRecords(result.data.length);
  }, [result]);

  return (
    <div className="table-responsive">
      {/* ============ download xls start ========== */}
      <ExcelExport
        data={
          handelSelectedCount().length > 0
            ? handelSelectedCount()
            : conversationList
        }
        fileName="Converstion.xlsx"
        ref={_exporter}
      >
        <ExcelExportColumn
          field="receivername"
          title={t("Receiver / Delivery Status")}
        />
        <ExcelExportColumn field="sender.name" title={t("Sender")} />
        <ExcelExportColumn field="maildate" title={t("Date")} />
        <ExcelExportColumn field="Labels.LabelName" title={t("Label")} />
      </ExcelExport>

      {/* ============ download xls end ========== */}
      {search ? (
        <Grid
          style={{ height: screenHeight }}
          data={filterData()}
          filterable={true}
          sortable={true}
          groupable={true}
          onDataStateChange={onDataStateChange}
          {...dataState}
          //pageable = {true}
          //total = {products.length}
          rowHeight={100}
          reorderable
          // resizable
        >
          <GridColumn
            field="id"
            headerCell={SelectRecordHeader}
            cell={SelectRecordRow}
            filterable={false}
            sortable={false}
            width="70px"
          />
          <GridColumn
            field="receivername"
            title={t("Receiver / Delivery Status")}
            cell={receiverBlockHtml}
            width="300px"
          />

          <GridColumn
            field="sender.name"
            title={t("Sender")}
            cell={senderBlockHtml}
            width="300px"
          />

          <GridColumn field="subject" title={t("Subject")} 
          width="300px" 
          />
          <GridColumn field="reserveid" title={t("ID")} 
          //width="200px" 
          />
          <GridColumn field="maildate" title={t("Date")} 
          //width="200px" 
          />
          <GridColumn
            field="tagstring"
            title={t("Tags")}
            //width="200px"
            //cell={reserveTagBlockHtml}
          />
        </Grid>
      ) : (
        <Grid
          style={{ height: screenHeight }}
          data={result}
          filterable={true}
          sortable={true}
          groupable={true}
          onDataStateChange={onDataStateChange}
          {...dataState}
          //pageable = {true}
          //total = {products.length}
          rowHeight={100}
          reorderable
          // resizable
        >
          <GridColumn
            field="id"
            headerCell={SelectRecordHeader}
            cell={SelectRecordRow}
            filterable={false}
            sortable={false}
            width="70px"
          />
          <GridColumn
            field="receivername"
            title={t("Receiver / Delivery Status")}
            cell={receiverBlockHtml}
            width="300px"
          />

          <GridColumn
            field="sender.name"
            title={t("Sender")}
            cell={senderBlockHtml}
            width="300px"
          />

          <GridColumn field="subject" title={t("Subject")} 
          width="300px" 
          />
          <GridColumn field="reserveid" title={t("ID")} 
          width="200px" 
          />
          <GridColumn field="maildate" title={t("Date")} 
          //width="200px" 
          />
          <GridColumn
            field="tagstring"
            title={t("Tags")}
            //width="200px"
            //cell={reserveTagBlockHtml}
          />
        </Grid>
      )}
    </div>
  );
};

export default Inbox;
