import React from 'react';
//import translation function for language translation
import { useTranslation } from 'react-i18next';
const GroupNameTab = () => {
  const { t } = useTranslation(); //for translation
  return (
    <div
      className="tab-pane fade show active"
      id="group-name-tab-pane"
      role="tabpanel"
      aria-labelledby="group-name-tab"
      tabIndex="0"
    >
      <form action="">
        <div className="form-group mb-4">
          <label htmlFor="name">{t('Name')}</label>
          <input
            type="text"
            id="name"
            placeholder={t('Enter Here')}
            className="form-control"
          />
        </div>
        <div className="form-group mb-4">
          <label htmlFor="label">{t('Label')}</label>
          <input
            type="text"
            id="label"
            placeholder={t('Enter Here')}
            className="form-control"
          />
        </div>
        <div className="form-group mb-4">
          <label htmlFor="description">{t('Description')}</label>
          <textarea
            name="description"
            id="description"
            cols="30"
            rows="5"
            className="form-control"
            placeholder={t('Enter Here')}
          ></textarea>
        </div>
        <div className="action">
          <button type="submit" className="btn btn-primary mw-unset">
            {t('Save')}
          </button>
        </div>
      </form>
    </div>
  );
};

export default GroupNameTab;
