/* eslint-disable */
import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
//import translation function for language translation
import { useTranslation } from 'react-i18next';

//import context
import { GlobalProvider } from 'context/GlobalContext';

const FeedbackresponseHeader = ({
  setDownloadExcel,
  setSearch,
  records,
  blockRefSelected,
}) => {
  const { t } = useTranslation(); //for translation

  const {
    setReloadFeedbackResponse,
    setSuccess,
    setError,
    setNotificationString,
  } = useContext(GlobalProvider);

  /* hook to get the search value */
  const [searchValue, setSearchValue] = useState('');

  /* function to click download xls*/
  const handleDownloadClick = () => {
    setDownloadExcel(true);

    setTimeout(() => setDownloadExcel(false), 500);
  };

  /* hook to empty search when searchValue gets empty */
  useEffect(() => {
    !searchValue && setSearch('');
  }, [searchValue]);

  return (
    <div className="filter-container d-flex flex-wrap align-items-center gap-2 pb-1 border-bottom border-gray">
      {/* ---------- back button icon start -------- */}
      <Link
        to="/survey"
        className="column-collapse btn btn-primary icon-btn mw-unset"
      >
        <span className="d-block material-symbols-outlined icon-fill">
          arrow_back
        </span>
      </Link>
      {/* ---------- back button icon end -------- */}

      {/* ---------- toggle column icon start -------- */}
      <Link
        to="#"
        className="column-collapse btn btn-primary icon-btn mw-unset"
      >
        <span className="d-block material-symbols-outlined icon-fill">
          visibility
        </span>
      </Link>
      {/* ---------- toggle column icon end -------- */}

      {/* ---------- download excel icon start -------- */}
      <Link
        to="#"
        onClick={handleDownloadClick}
        className="download btn btn-primary icon-btn mw-unset"
      >
        <span className="d-block material-symbols-outlined">download</span>
      </Link>
      {/* ---------- download excel icon end -------- */}

      {/* ---------- search form section start -------- */}
      <div className="search-container position-relative d-none d-md-block">
        <form onSubmit={e => e.preventDefault()}>
          <input
            type="search"
            placeholder={t('Search here')}
            className="form-control bg-transparent py-2 border-0"
            onChange={e => setSearchValue(e.target.value)}
            onKeyDown={e => {
              e.key === 'Enter' && (e.preventDefault(), setSearch(searchValue));
            }}
          />
          <button
            onClick={() => setSearch(searchValue)}
            type="button"
            className="bg-transparent text-dark p-0 border-0 position-absolute top-50 translate-middle-y"
          >
            <span className="d-block material-symbols-outlined">search</span>
          </button>
        </form>
      </div>
      {/* ---------- search form section end -------- */}
      {/* ------------- showing records start ----------------- */}
      <p className="text-gray fw-medium ms-auto me-3">
        Showing
        <span className="text-black ms-1">{records}</span> Records -
        <span className="text-black ms-1" ref={blockRefSelected}>
          {' '}
          0{' '}
        </span>{' '}
        Selected
      </p>
      {/* ------------- showing records end ----------------- */}

      {/* ---------- refresh button start -------- */}
      <Link
        onClick={() => {
          setReloadFeedbackResponse(true), setSuccess(true);
          setNotificationString(t('The list has been refreshed successfully'));
        }}
        to="#"
        className="filter-btn btn btn-primary icon-btn mw-unset"
      >
        <span
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="Refresh"
          className="d-block material-symbols-outlined"
        >
          refresh
        </span>
      </Link>
      {/* ---------- refresh button end -------- */}

      {/* ---------- filter search icon start -------- */}
      <Link to="#" className="filter-btn btn btn-primary icon-btn mw-unset">
        <span className="d-block material-symbols-outlined">filter_alt</span>
      </Link>
      {/* ---------- filter search icon end -------- */}

      {/* ---------- action drowpdown section start -------- */}
      <div className="dropdown">
        <button
          className="btn btn-primary dropdown-toggle mw-unset"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          {t('Actions')}
        </button>
        <ul className="dropdown-menu border-0 shadow-lg">
          {/* ---------- delete section start -------- */}
          <li>
            <Link className="dropdown-item" to="#">
              {t('Delete selected')}
            </Link>
          </li>
          {/* ---------- delete section end -------- */}
        </ul>
      </div>
      {/* ----------action drowpdown section end -------- */}
    </div>
  );
};

export default FeedbackresponseHeader;
