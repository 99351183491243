/* eslint-disable */
import { useState } from "react";
import { Link } from "react-router-dom";
import { assetImages } from "../../constants";
import UserDropdown from "./UserDropdown";
import { useTranslation, Trans } from "react-i18next";

//import options
import languageOptions from "data/Prod/Language.json";

// Header of backend after successfull loggedin ==============================
// ===========================================================================

const UserMenu = () => {
  /* ------------- Language translation imports starts here ------------- */
  const { t, i18n } = useTranslation();

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  return (
    <header className="after_login_header">
      <div className="container-fluid">
        <div className="hdr_innr">
          <div className="left_logo">
            {userInfo ? (
              <Link
                to="#"
                className="hamberger_menu"
                onClick={(e) => {
                  e.preventDefault();
                  document.body.classList.add("add_menupnl");
                }}
              >
                <span className="material-icons-outlined">menu</span>
              </Link>
            ) : null}
            <Link
              to={userInfo ? "/dashboard" : "#"}
              className="hdr_logo text-white"
            >
              <img src={assetImages.logosvg} alt="logo" />
            </Link>
          </div>
          <div className="right_user">
            <ul className="list_stye_none d-flex gap-3 align-items-center">
              <li className="language-selector">
                <div className="dropdown">
                  <button
                    className="dropdown-toggle bg-transparent text-white border-0"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {/* ------ get the value from i18 and display start----- */}
                    {i18n.language}
                    {/* ------ get the value from i18 and display end----- */}
                  </button>

                  {/* ------ language section start ----- */}
                  <ul className="dropdown-menu mw-unset rounded-1 border-0 shadow-lg">
                    {languageOptions.map((item, index) => {
                      return (
                        <li key={index}>
                          <Link
                            onClick={() => {
                              i18n.changeLanguage(item.value);
                            }}
                            className="dropdown-item"
                            to="#"
                          >
                            {item.value}
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                  {/* ------ language section end ----- */}
                </div>
              </li>
              <li className="dropdown">
                {userInfo ? (
                  <Link
                    to="#"
                    className="hdrusr_profile"
                    data-bs-toggle="dropdown"
                  >
                    {userInfo ? (
                      <span>
                        {userInfo.name} {userInfo.surname}
                      </span>
                    ) : null}
                    <i className="material-icons-outlined">account_circle</i>
                  </Link>
                ) : null}
                {userInfo ? <UserDropdown /> : null}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </header>
  );
};

export default UserMenu;
