/* eslint-disable */
import { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import UserMenu from "components/common/UserMenu";
import LeftMenu from "components/common/LeftMenu";
import UserManagementBreadCrumb from "components/UserManagementComponents/BreadCrumb/UserManagementBreadCrumb";
import UserManagementHeader from "components/UserManagementComponents/UserManagementHeader/UserManagementHeader";
import UserListArea from "components/UserManagementComponents/UserListArea/UserListArea";
import UserFormPopup from "components/UserManagementComponents/PopUp/UserFormPopup";
import ToggleColumn from "components/UserManagementComponents/ToggleColumns/ToggleColumn";
import AddLabel from "components/common/AddLabel";
//import translation function for language translation
import { useTranslation } from "react-i18next";
import CustomNotification from "components/common/CustomNotification";
import UserManagementFilter from "components/UserManagementComponents/Filter/UserManagementFilter";
import SendMailPopup from "components/UserManagementComponents/PopUp/SendMailPopup";
const UserManagement = () => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  /* useState hook to download excel file */
  const [downloadExcel, setDownloadExcel] = useState(false);
  /* hook to get number of records */
  const [records, setRecords] = useState(0);
  const { t } = useTranslation(); //for translation
  /* hook to search */
  const [search, setSearch] = useState("");
  const [selectedEmails, setSelectedEmails] = useState([]);
  useEffect(() => {
    document.title = " Usermanagement"; // Update the title when Register component mounts
  }, []);
  const blockRefSelected = useRef(null);
  const checkedRecordCount = (selectCount) => {
    const blockElementSelect = ReactDOM.findDOMNode(blockRefSelected.current);
    if (blockElementSelect) {
      blockElementSelect.innerHTML = selectCount;
    }
  };
  return (
    <>
      <UserMenu />
      <LeftMenu />
      {userInfo?.role?.name == "admin" || userInfo?.role?.name == "manager" ? (
        <section className="mail-wrapper pt-2">
          <div className="container-fluid px-4">
            <UserManagementBreadCrumb />
            {/* -------------- user management header start --------------- */}
            <UserManagementHeader
              setDownloadExcel={setDownloadExcel}
              setSearch={setSearch}
              records={records}
              blockRefSelected={blockRefSelected}
              setSelectedEmails={setSelectedEmails}
            />
            {/* -------------- user management header end --------------- */}
            {/* ----------- toggle columns start -------------- */}
            <ToggleColumn />
            {/* ----------- toggle columns end -------------- */}
            {/* ---------------- filter start --------------- */}
            <UserManagementFilter />
            {/* ---------------- filter end --------------- */}
            {/* --------------- user list area start ---------------- */}
            <UserListArea
              downloadExcel={downloadExcel}
              search={search}
              setRecords={setRecords}
              checkedRecordCount={checkedRecordCount}
            />
            {/* --------------- user list area end ---------------- */}
          </div>
        </section>
      ) : null}
      {/* -------------- user form popup start ----------------- */}
      <UserFormPopup />
      {/* -------------- user form popup end ----------------- */}
      {/* ----- send bulk mail pop up ---- */}
      <SendMailPopup
        selectedEmails={selectedEmails}
        setSelectedEmails={setSelectedEmails}
      />
      {/* -------------- addlabel start -------------------- */}
      <AddLabel module={"user"} />
      {/* -------------- addlabel end -------------------- */}
      {/*----------- custom notification component start -----------*/}
      <CustomNotification />
      {/*----------- custom notification component end -----------*/}
    </>
  );
};
export default UserManagement;
