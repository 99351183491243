/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";

// Import CKEditor here (both required).
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

//import translation function for language translation
import { useTranslation } from "react-i18next";

/*import url and gateway methods */
import { getData, postData, putData, uploadMultipleFile } from "utils/Gateway";
import * as url from "urlhelpers/UrlHelper";

//import context
import { GlobalProvider } from "context/GlobalContext";

// clipboardCopy for mail variables
import clipboardCopy from "clipboard-copy";

// import mail variables from json file
import mailVariables from "data/Prod/MailVariables.json";
import colorCodes from "data/Prod/Colours.json";

//import file download function
import downloadFileHandler from "helpers/DownloadFile";

const CreateMailTemplatePopup = () => {
  const token = localStorage.getItem("token"); // token

  const { t } = useTranslation(); //for translation

  const {
    setReloadMailTemplateList,
    editMailTemplateData,
    setEditMailTemplateData,
    copyMailTemplateId,
    setCopyMailTemplateId,
    setNotificationString,
    setError,
    setSuccess,
  } = useContext(GlobalProvider);

  /*---- CKEditor classic editor configuration start ----- */
  const editorConfig = {
    toolbar: ["bold", "italic", "link", "bulletedList", "numberedList"],
    ckfinder: {
      // Upload the images to the server using the CKFinder QuickUpload command.
      //   uploadUrl: url.URL_TEXT_EDITOR_IMAGE_UPLOAD_2,
    },
    placeholder: t("Type description here..."),
  };
  /*---- CKEditor classic editor configuration end ----- */

  const colorOptionStyle = {
    display: "flex",
    alignItems: "center",
  };

  const colorBoxStyle = {
    width: "20px",
    height: "20px",
    marginRight: "10px",
    border: "1px solid #000",
  };

  const getOptionsFromColorCodeJson = () => {
    return colorCodes.map((color) => ({
      label: (
        <div style={colorOptionStyle}>
          <div style={{ ...colorBoxStyle, backgroundColor: color.code }} />
        </div>
      ),
      value: color.code,
    }));
  };

  //option for all survey for seclect
  const [allSurveyList, setAllSurveyList] = useState([]);

  const [colorOptions, setColorOptions] = useState([]);

  /*======== form requirement start ==========*/
  const [userInputs, setUserInputs] = useState({
    name: "",
    slug: "",
    subject: "",
    ispublic: true,
  });

  const [isUploading, setIsUploading] = useState(false);
  const [fileUploadIds, setFileUploadIds] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const [editorData, setEditorData] = useState(""); //for ck editor
  /*======== form requirement end ==========*/

  const [errorMessage, setErrorMessage] = useState("");
  const [isRequiredFieldFillPending, setIsRequiredFieldFillPending] =
    useState(false);

  //function for get template details
  const getTemplateDetails = async (templateId) => {
    try {
      let requestUrl =
        url.API_BASEURL +
        url.API_GET_TEMPLATE_DETAILS +
        `/${templateId}?token=${token}`;

      const response = await getData(requestUrl);

      console.log(response);

      if (response.status) {
        const mailTemplateData = response.data;
        setUserInputs((prevState) => ({
          ...prevState,
          name: mailTemplateData?.name || "",
          slug: mailTemplateData?.slug || "",
          subject: mailTemplateData?.subject || "",
          ispublic: true,
        }));

        setEditorData(mailTemplateData?.description);

        //assign actionlinks form repeater
        if (mailTemplateData.actionlinks) {
          if (mailTemplateData.actionlinks.length > 0) {
            updatedActionLinkBlock(mailTemplateData?.actionlinks);
          }
        }

        //assign uploaded files
        if (mailTemplateData?.uploads) {
          setUploadedFiles(
            mailTemplateData?.uploads.map(({ name, path, _id, ...other }) => ({
              name,
              path: url.SERVER_URL + path,
              _id,
            }))
          );
          const editedFilesIds = mailTemplateData?.uploads.map(
            ({ _id, ...other }) => ({ _id })
          );
          setFileUploadIds(editedFilesIds);
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  /*=========== form repeater section start ===========*/
  //template for action link block
  const actionLinkTemplate = {
    name: "",
    color: null,
    colordata: null,
    externallink: "",
    surveyvalue: null,
    surveydata: null,
  };

  //requirement for action link block
  const [actionLinkBlock, setActionLinkBlock] = useState([]);

  //function for addign action link section
  const addActionLinkBlock = () => {
    setActionLinkBlock([...actionLinkBlock, actionLinkTemplate]);
  };

  //function for delete action link block
  const onDeleteActionLinkBlock = (index) => {
    const filteredActionLinkBlock = [...actionLinkBlock];
    filteredActionLinkBlock.splice(index, 1);
    setActionLinkBlock(filteredActionLinkBlock);
  };

  //function for change survey select
  const changeActionLinkColorSelect = (index, field, field2, value) => {
    const updatedFormValues = actionLinkBlock.map((block, i) =>
      index == i
        ? Object.assign(block, { [field]: value, [field2]: value.value })
        : block
    );
    setActionLinkBlock(updatedFormValues);
  };

  //function for change survey select
  const changeActionLinkSurveySelect = (index, field, field2, value) => {
    const updatedFormValues = actionLinkBlock.map((block, i) =>
      index == i
        ? Object.assign(block, { [field]: value, [field2]: value.value })
        : block
    );
    setActionLinkBlock(updatedFormValues);
  };

  //function for other action link input field
  const changeActionLinkInputs = (index, field, value) => {
    const updatedFormValues = actionLinkBlock.map((block, i) =>
      index == i ? Object.assign(block, { [field]: value }) : block
    );

    // console.log(`updatedFormValues`, updatedFormValues);
    setActionLinkBlock(updatedFormValues);
  };

  //function for mapping form repeater data
  const updatedActionLinkBlock = (actionLink) => {
    let actionLinkArr = [];

    actionLink.map((actionLinkData) => {
      let actionLinkSavedData = {
        _id: actionLinkData?._id,
        name: actionLinkData?.name,
        color: actionLinkData?.color,
        colordata: getColorcodeLabel(actionLinkData.color),
        externallink: actionLinkData?.externallink,
        surveyvalue: actionLinkData?.survey,
        surveydata: actionLinkData?.survey?.value,
      };
      actionLinkArr.push(actionLinkSavedData);
    });

    console.log("actionLinkArr >>>>>> ", actionLinkArr);
    setActionLinkBlock(actionLinkArr);
  };

  const getColorcodeLabel = (colorCode) => {
    return {
      label: (
        <div style={colorOptionStyle}>
          <div style={{ ...colorBoxStyle, backgroundColor: colorCode }} />
        </div>
      ),
      value: colorCode,
    };
  };

  /*=========== form repeater section end ===========*/

  //function for file upload
  const uploadHandler = async (e) => {
    try {
      setIsUploading(true);
      let requestUrl =
        url.API_BASEURL + url.API_FILE_UPLOAD_MULTIPLE + `?token=${token}`;

      const response = await uploadMultipleFile(requestUrl, e.target.files);

      if (response.status) {
        console.log(
          `response in file upload mail template------>`,
          response.data
        );

        /*----- assign uploaded files with path for display start ------*/
        const uploadedFileValues = response.data.map(
          ({ name, path, _id, ...other }) => ({
            name,
            path: url.SERVER_URL + path,
            _id,
          })
        );

        //check old file exist or not if exist then new file will concat with old file
        if (uploadedFiles.length == 0) {
          setUploadedFiles(uploadedFileValues);
        } else {
          let fileArr = uploadedFiles.concat(uploadedFileValues);
          setUploadedFiles(fileArr);
        }
        /*----- assign uploaded files with path for display end ------*/

        setIsUploading(false);

        /*----- assign uploaded file ids start ------*/
        const fileIds = response.data.map(({ _id, ...other }) => ({ _id }));

        //check old file ids are exist or not.If exist then new id will concat with old id
        if (fileUploadIds.length == 0) {
          setFileUploadIds(fileIds);
        } else {
          let fileArr = fileUploadIds.concat(fileIds);
          setFileUploadIds(fileArr);
        }
        /*----- assign uploaded file ids end ------*/
        resetFile();
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  //function for get survey list
  const getAllSurvey = async () => {
    try {
      let requestUrl =
        url.API_BASEURL +
        url.API_GET_ALL_SURVEY +
        `?token=${token}&isselect=${true}`;

      console.log("survey list url =====>>>", requestUrl);

      const response = await getData(requestUrl);

      console.log("survey list response ========>>>", response);

      if (response.data) {
        setAllSurveyList(response.data);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  //function for delete file from upload stack
  const closeFileHandler = (index) => {
    const closedFile = [...uploadedFiles];
    const removedFileId = [...fileUploadIds];

    closedFile.splice(index, 1);
    removedFileId.splice(index, 1);

    setUploadedFiles(closedFile);
    setFileUploadIds(removedFileId);

    resetFile();
  };

  /* hooks for validation */
  const [validation, setValidation] = useState({
    nameWarning: false,
    slugWarning: false,
    subjectWarning: false,
    linkNameWarning: false,
    linkColorWarning: false,
    linkSurveyWarning: false,
  });

  // validate =========================
  const validate = () => {
    let isValid = true;

    if (userInputs.name === "") {
      setValidation((prevState) => ({ ...prevState, nameWarning: true }));
      isValid = false;
    }
    if (userInputs.slug === "") {
      setValidation((prevState) => ({ ...prevState, slugWarning: true }));
      isValid = false;
    }
    if (userInputs.subject === "") {
      setValidation((prevState) => ({ ...prevState, subjectWarning: true }));
      isValid = false;
    }

    /* add survey link validation ------------- */
    if (actionLinkBlock.length > 0) {
      actionLinkBlock.forEach((link) => {
        if (link.name === "") {
          setValidation((prevState) => ({
            ...prevState,
            linkNameWarning: true,
          }));
          isValid = false;
        }
        if (link.color === "") {
          setValidation((prevState) => ({
            ...prevState,
            linkColorWarning: true,
          }));
          isValid = false;
        }
        // if (link.surveyvalue === null) {
        //   setValidation((prevState) => ({
        //     ...prevState,
        //     linkSurveyWarning: true,
        //   }));
        //   isValid = false;
        // }
      });
    }

    return isValid;
  };

  /* function for save mail template */
  const saveMailTemplateHandler = async (e) => {
    e.preventDefault();

    let templateData = {
      status: "1",
      name: userInputs.name,
      slug: userInputs.slug,
      subject: userInputs.subject,
      ispublic: userInputs.ispublic.toString(),
      description: editorData,
      actionlinks: actionLinkBlock,
      uploads: fileUploadIds,
    };

    console.log("Mail template data========>", templateData);

    if (validate()) {
      setIsRequiredFieldFillPending(false);
      try {
        let requestUrl = url.API_BASEURL;
        let response = {};

        if (editMailTemplateData) {
          requestUrl +=
            url.API_UPDATE_TEMPLATE +
            `/${editMailTemplateData}` +
            `?token=${token}`;

          console.log("Request url for save mail template=======>", requestUrl);
          response = await putData(requestUrl, templateData);
        } else {
          requestUrl += url.API_ADD_TEMPLATE + `?token=${token}`;

          console.log("Request url for save mail template=======>", requestUrl);
          response = await postData(requestUrl, templateData);
        }

        console.log("Response of save mail template=======>", response);

        if (response.status) {
          resetHandler();
          setNotificationString(t("Mail template created successfully"));
          setSuccess(true);
          setReloadMailTemplateList(true);
          bootstrap.Offcanvas.getInstance(
            document.querySelector("#createEmailTemplatePopup")
          ).hide();
        } else {
          if (response.message == "Template exist") {
            setErrorMessage(t("Slug already used. please use a unique slug"));
            setIsRequiredFieldFillPending(true);
          }
        }
      } catch (error) {
        console.error(error.message);
        setNotificationString(
          t("Something went wrong, please try again later.")
        );
        setError(true);
      }
    } else {
      setIsRequiredFieldFillPending(true);
    }
  };

  //function for update mail template
  // const updateMailTemplate = async (e) => {
  //   e.preventDefault();

  //   let templateData = {
  //     status: "1",
  //     name: userInputs.name,
  //     slug: userInputs.slug,
  //     subject: userInputs.subject,
  //     ispublic: userInputs.ispublic.toString(),
  //     description: editorData,
  //     actionlinks: actionLinkBlock,
  //     uploads: fileUploadIds,
  //   };

  //   console.log("Mail template data========>", templateData);

  //   if (validate()) {
  //     try {
  //       let requestUrl =
  //         url.API_BASEURL +
  //         url.API_UPDATE_TEMPLATE +
  //         `/${editMailTemplateData}` +
  //         `?token=${token}`;

  //       const response = await putData(requestUrl, templateData);

  //       console.log("response update mail template=========>", response);
  //       if (response.status) {
  //         resetHandler();
  //         setNotificationString(t("Mail template updated successfully"));
  //         setSuccess(true);
  //         setReloadMailTemplateList(true);
  //       }
  //     } catch (error) {
  //       console.error(error.message);
  //       setError(true);
  //     }
  //     bootstrap.Offcanvas.getInstance(
  //       document.querySelector("#createEmailTemplatePopup")
  //     ).hide();
  //   }
  // };

  // function for clear file value
  const resetFile = () => {
    const file = document.getElementById("uploadedMailTemplateFile");
    if (file) {
      file.value = null;
    } else {
      return;
    }
  };

  /* reset */
  const resetHandler = () => {
    resetFile();
    setUserInputs({
      name: "",
      slug: "",
      subject: "",
      description: "",
      ispublic: true,
    });
    setEditorData("");
    setActionLinkBlock([]);
    setUploadedFiles([]);
    setFileUploadIds([]);
    setValidation({
      nameWarning: false,
      slugWarning: false,
      subjectWarning: false,
    });
    setErrorMessage("");
    setIsRequiredFieldFillPending(false);
    setEditMailTemplateData(null);
    setCopyMailTemplateId(null);
  };

  /* use effect hook to assign edit data */
  useEffect(() => {
    if (editMailTemplateData) {
      getTemplateDetails(editMailTemplateData);
    }
  }, [editMailTemplateData]);

  useEffect(() => {
    if (copyMailTemplateId) {
      getTemplateDetails(copyMailTemplateId);
    }
  }, [copyMailTemplateId]);

  //use effect for get survey list without dependency
  useEffect(() => {
    getAllSurvey();
    const options = getOptionsFromColorCodeJson();
    setColorOptions(options);
  }, []);

  return (
    <div
      className="offcanvas offcanvas-end border-0"
      tabIndex="-1"
      id="createEmailTemplatePopup"
      aria-labelledby="createEmailPopupLabel"
    >
      <div className="offcanvas-header p-4">
        {/* ------- form title start ------- */}
        <h3 className="text-secondary mb-0" id="createEmailPopupLabel">
          {t("Save Mail Template")}
        </h3>
        {/* ------- form title end ------- */}

        {/* ------- form close button start ------- */}
        <button
          type="button"
          id="closeCreateMail"
          className="btn-close p-2 bg-white shadow-none shadow-sm m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={resetHandler}
        ></button>
        {/* ------- form close button end ------- */}
      </div>

      {/* ------- mail template form section start ------- */}
      <div className="offcanvas-body p-4 pt-0">
        <form onSubmit={(e) => e.preventDefault()}>
          {/* ------- template name start ------ */}
          <div className="form-group mb-3">
            <label>{t("Template name")}</label>
            <input
              type="text"
              className="form-control"
              placeholder={t("Template Name")}
              value={userInputs.name}
              onChange={(val) => {
                setUserInputs((prevState) => ({
                  ...prevState,
                  name: val.target.value,
                }));
                setValidation((prevState) => ({
                  ...prevState,
                  nameWarning: false,
                }));
                setIsRequiredFieldFillPending(false);
              }}
            />
            {/* name warning */}
            {validation.nameWarning && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>{t("Please enter a template name")}!</span>
                </p>
              </div>
            )}
          </div>
          {/* ------- template name end ------ */}

          {/* ------- template slug start ------ */}
          <div className="form-group mb-3">
            <label>Slug</label>
            <input
              type="text"
              className="form-control"
              placeholder={t("Slug")}
              value={userInputs.slug}
              onChange={(val) => {
                setUserInputs((prevState) => ({
                  ...prevState,
                  slug: val.target.value,
                }));
                setValidation((prevState) => ({
                  ...prevState,
                  slugWarning: false,
                }));
                setErrorMessage("");
                setIsRequiredFieldFillPending(false);
              }}
            />
            {/* stug warning */}
            {validation.slugWarning && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>{t("Please enter a slug")}!</span>
                </p>
              </div>
            )}

            {/* stug warning */}
            {errorMessage == "" ? null : (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>{errorMessage}!</span>
                </p>
              </div>
            )}
          </div>
          {/* ------- template slug end ------ */}

          {/* ------- template subject start ------ */}
          <div className="form-group mb-3">
            <label>{t("Subject")}</label>
            <input
              type="text"
              id="subject"
              className="form-control"
              placeholder={t("Subject")}
              value={userInputs.subject}
              onChange={(val) => {
                setUserInputs((prevState) => ({
                  ...prevState,
                  subject: val.target.value,
                }));
                setValidation((prevState) => ({
                  ...prevState,
                  subjectWarning: false,
                }));
                setIsRequiredFieldFillPending(false);
              }}
            />
            {/* subject warning */}
            {validation.subjectWarning && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>{t("Please enter a subject")}!</span>
                </p>
              </div>
            )}
          </div>
          {/* ------- template subject end ------ */}

          {/* ------- template mail variable select start ------ */}
          <div className="form-group mb-3">
            <div className="row">
              <div className="col-lg-6">
                <label>{t("Mail Variable")}</label>

                <Select
                  className="nosearch_select"
                  placeholder={t("Select mail variables")}
                  options={mailVariables}
                  onChange={(event) => {
                    console.log(event);
                    clipboardCopy(event.value);
                  }}
                />
              </div>
              <div className="col-lg-6">
                <div className="alert alert-light" role="alert">
                  {t(
                    "Select the mail variable you want to add. Next just paste where you want it use it."
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* ------- template mail variable select end ------ */}

          {/* ----- is public section start ----- */}
          {/* <div className="form-group mb-3">
            <label>{t("Is public")}</label>
            <div className="form-check">
              <input
                className="form-check-input "
                type="checkbox"
                name="ispublic"
                id="ispublic"
                checked={userInputs.ispublic}
                onChange={(val) => {
                  setUserInputs((prevState) => ({
                    ...prevState,
                    ispublic: val.target.checked,
                  }));
                }}
              />
            </div>
          </div> */}
          {/* ----- is public section end ----- */}

          {/* ----- action button heading text section start ----- */}
          <div className="form-group mb-3">
            <div className="row">
              <div className="col-6">
                <label>{t("Add Action Buttons in mail")}</label>
              </div>
            </div>
          </div>
          {/* ----- action button heading text section end ----- */}

          {/* ------- form repeater sction for action link start -------- */}
          {actionLinkBlock.map((block, index) => {
            return (
              <div className="form-group p-3 bg-gray-500 mb-3" key={index}>
                <div className="row">
                  {/* ------ action link close button start ------ */}
                  <div className="col-12 d-flex align-items-center justify-content-end">
                    <Link
                      to="#"
                      className="text-secondary mb-2"
                      onClick={() => {
                        onDeleteActionLinkBlock(index);
                      }}
                    >
                      <span className="material-symbols-outlined">close</span>
                    </Link>
                  </div>
                  {/* ------ action link close button end ------ */}
                </div>
                <div className="row">
                  {/* ------ action link name start ------ */}
                  <div className="col-lg-6 mb-3">
                    <label>{t("Button text")}</label>
                    <input
                      type="text"
                      className="form-control bg-white"
                      placeholder={t("Enter name")}
                      value={block.name}
                      onChange={(e) => {
                        changeActionLinkInputs(index, "name", e.target.value);
                        setValidation((prevState) => ({
                          ...prevState,
                          linkNameWarning: false,
                        }));
                      }}
                    />
                    {/* link name warning */}
                    {validation.linkNameWarning && (
                      <div className="error-message mt-2">
                        <p className="d-flex align-items-center gap-1 text-danger">
                          <span className="material-symbols-outlined">
                            warning
                          </span>
                          <span>{t("Please enter a survey name")}!</span>
                        </p>
                      </div>
                    )}
                  </div>
                  {/* ------ action link name end ------ */}

                  {/* ------ action link color start ------ */}
                  <div className="col-lg-6 mb-3">
                    {/* <input
                      type="text"
                      className="form-control bg-white"
                      placeholder={t("Enter colour")}
                      value={block.color}
                      onChange={(e) => {
                        changeActionLinkInputs(index, "color", e.target.value);
                        setValidation((prevState) => ({
                          ...prevState,
                          linkColorWarning: false,
                        }));
                      }}
                    /> */}
                    <label>{t("Button Colour")}</label>
                    <Select
                      className="nosearch_select"
                      placeholder={t("Select color")}
                      options={colorOptions}
                      value={block.colordata}
                      onChange={(value) => {
                        changeActionLinkColorSelect(
                          index,
                          "colordata",
                          "color",
                          value
                        );

                        // setValidation((prevState) => ({
                        //   ...prevState,
                        //   linkSurveyWarning: false,
                        // }));
                      }}
                    />

                    {/* link color warning */}
                    {validation.linkColorWarning && (
                      <div className="error-message mt-2">
                        <p className="d-flex align-items-center gap-1 text-danger">
                          <span className="material-symbols-outlined">
                            warning
                          </span>
                          <span>{t("Please enter a color")}!</span>
                        </p>
                      </div>
                    )}
                  </div>
                  {/* ------ action link color end ------ */}

                  {/* ------ action link survey select start ------ */}
                  <div className="col-lg-6 mb-3">
                    <label>{t("Add Survey")}</label>
                    <Select
                      className="nosearch_select"
                      placeholder={t("Select survey")}
                      options={allSurveyList}
                      value={block.surveyvalue}
                      onChange={(value) => {
                        changeActionLinkSurveySelect(
                          index,
                          "surveyvalue",
                          "surveydata",
                          value
                        );
                        // setValidation((prevState) => ({
                        //   ...prevState,
                        //   linkSurveyWarning: false,
                        // }));
                      }}
                    />
                    {/* link survey warning */}
                    {validation.linkSurveyWarning && (
                      <div className="error-message mt-2">
                        <p className="d-flex align-items-center gap-1 text-danger">
                          <span className="material-symbols-outlined">
                            warning
                          </span>
                          <span>{t("Please enter a survey")}!</span>
                        </p>
                      </div>
                    )}
                  </div>
                  {/* ------ action link survey select end ------ */}

                  {/* ------ action link external start ------ */}
                  <div className="col-lg-6 mb-3">
                    <label>{t("Button Slug")}</label>
                    <input
                      type="text"
                      className="form-control bg-white"
                      placeholder={t("Current Link Slug")}
                      value={block.externallink}
                      onChange={(e) => {
                        changeActionLinkInputs(
                          index,
                          "externallink",
                          e.target.value
                        );
                      }}
                    />
                  </div>
                  {/* ------ action link external end ------ */}
                  {/* <div className="col-lg-12">
                    <div className="d-flex align-items-center gap-2">
                      <button type="submit" className="btn btn-primary">
                        {t("Add")}
                      </button>
                      <Link to="#" className="text-gray">
                        <span className="d-block material-symbols-outlined icon-fill">
                          delete
                        </span>
                      </Link>
                    </div>
                  </div> */}
                </div>
              </div>
            );
          })}
          {/* ------- form repeater sction for action link end -------- */}

          {/* ------- add action link button start -------- */}
          <div className="form-group mb-3">
            <div className="row">
              <div className="col-3">
                <Link
                  onClick={addActionLinkBlock}
                  to="#"
                  className="d-flex align-items-center gap-1 text-primary fw-medium"
                >
                  <span className="d-block material-symbols-outlined">add</span>
                  <span>{t("Add Action Button")}</span>
                </Link>
              </div>
            </div>
          </div>
          {/* ------- add action link button end -------- */}

          {/* ------- editor section start ------- */}
          <div className="form-group mb-3">
            <CKEditor
              className="form-control"
              editor={ClassicEditor}
              config={editorConfig}
              data={editorData}
              onReady={(editor) => {
                editor.editing.view.change((writer) => {
                  writer.setStyle(
                    "height",
                    "200px",
                    editor.editing.view.document.getRoot()
                  );
                });
              }}
              onChange={(event, editor) => {
                let editorContent = editor.getData();
                setEditorData(editorContent);
              }}
            />
            {/* <Editor
              tools={[
                [Bold, Italic, Underline, Strikethrough],
                [Subscript, Superscript],
                [AlignLeft, AlignCenter, AlignRight, AlignJustify],
                [Indent, Outdent],
                [OrderedList, UnorderedList],
                FontSize,
                FontName,
                FormatBlock,
                [Undo, Redo],
                [Link, Unlink, InsertImage, ViewHtml],
                [InsertTable],
                [AddRowBefore, AddRowAfter, AddColumnBefore, AddColumnAfter],
                [DeleteRow, DeleteColumn, DeleteTable],
                [MergeCells, SplitCell],
              ]}
              contentStyle={{
                height: 320,
              }}
              value={editorData}
              onChange={(e) => setEditorData(e.target.value)}
            /> */}
          </div>
          {/* ------- editor section end ------- */}

          {/* ========= file upload start ========== */}
          <div className="form-group mb-4">
            {isUploading ? (
              <div className="d-flex align-items-center gap-1 mb-1">
                <p className="fw-bold">{t("Please wait while uploading")}</p>{" "}
                {[1, 2, 3, 4, 5, 6, 7, 8].map((count, index) => {
                  return (
                    <div
                      key={index}
                      className="spinner-grow spinner-grow-sm"
                      role="status"
                      style={{ height: "6px", width: "6px" }}
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  );
                })}
              </div>
            ) : (
              <label>{t("Attachments")}</label>
            )}

            <input
              type="file"
              className="form-control"
              id="uploadedMailTemplateFile"
              multiple
              onChange={uploadHandler}
            />

            {uploadedFiles.length > 0 && (
              <ul className="uploaded-files d-flex flex-wrap gap-3 p-0 m-0 mt-3">
                {uploadedFiles.map((file, index) => {
                  return (
                    <li
                      className="file d-flex gap-3 align-items-center px-3 py-2 border border-gray rounded-3"
                      key={index}
                    >
                      <Link to="#" onClick={() => downloadFileHandler(file)}>
                        <span className="d-block material-symbols-outlined">
                          download
                        </span>
                      </Link>
                      <p className="fw-medium">{file.name}</p>
                      <Link to="#" onClick={() => closeFileHandler(index)}>
                        <span className="d-block material-symbols-outlined">
                          close
                        </span>
                      </Link>
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
          {/* ========= file upload end ===========*/}

          {/* ------- save/update button start ------- */}
          <div className="action d-flex align-items-center gap-2">
            <button
              type="submit"
              className="btn btn-primary mw-unset"
              onClick={saveMailTemplateHandler}
            >
              {t("Save")}
            </button>
            {isRequiredFieldFillPending && (
              <p className="d-flex align-items-center gap-1 text-danger">
                <span className="material-symbols-outlined">warning</span>
                <span>{t("Please fill the required input fields")}!!!</span>
              </p>
            )}
          </div>
          {/* ------- save/update button end ------- */}
        </form>
      </div>
      {/* ------- mail template form section end ------- */}
    </div>
  );
};

export default CreateMailTemplatePopup;
