/* eslint-disable */
import React, { useEffect } from "react";
import LeftMenu from "components/common/LeftMenu";
import UserMenu from "components/common/UserMenu";
import CategoryBody from "components/CategoryComponents/CategoryBody";
import CustomNotification from "components/common/CustomNotification";
const Category = () => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  useEffect(() => {
    document.title = "Category"; // Update the title when Register component mounts
  }, []);
  return (
    <>
      <UserMenu />
      <LeftMenu />
      {userInfo?.role?.name == "admin" || userInfo?.role?.name == "manager" ? (
        <CategoryBody />
      ) : null}
      {/* ----- custom notification ----- */}
      <CustomNotification />
    </>
  );
};
export default Category;
