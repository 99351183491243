/* eslint-disable */
import { useState } from "react";
import { Link } from "react-router-dom";

/*import url and gateway methods */
import { postData } from "../../utils/Gateway";
import * as url from "../../urlhelpers/UrlHelper";

//import translation function for language translation
import { useTranslation } from "react-i18next";

const SmtpTestForm = () => {
  const { t } = useTranslation(); //for translation

  /*----- UseState to send username and password for Login ----*/
  const [mailHost, setMailHost] = useState('');
  const [mailUsername, setMailUsername] = useState('');
  const [mailPassword, setMailPassword] = useState('');
  const [mailPort, setMailPort] = useState("25");
  const [mailSecure, setMailSecure] = useState(false);
  const [trustSelfSign, setTrustSelfSign] = useState(false);

  const [mailFrom, setMailFrom] = useState('');
  const [mailTo, setMailTo] = useState('vherrero@gcolabora.com');
  const [mailSubject, setMailSubject] = useState('');
  const [mailContent, setMailContent] = useState('');

  const [mailResponseMessage, setMailResponseMessage] = useState('');
  const [transporter, setTransporter] = useState('');

  const [isLoading, setIsLoading] = useState(false);

  // on click handle
  const handelSmtpForm = async (e) => {
        try {
            setIsLoading(true);

            let userData = {
              host: mailHost,
              username: mailUsername,
              password: mailPassword,
              port: mailPort,
              secure: mailSecure,
              selfsigntrust: trustSelfSign,
              mailfrom: mailFrom,
              mailto: mailTo,
              subject: mailSubject,
              content: mailContent
            };

            let requestURL = url.API_BASEURL + url.API_POST_SMTP;

            console.log("request url -->", requestURL);

            const response = await postData(requestURL, userData);

            console.log("response -->", response);

            if (response.status) {
                setMailResponseMessage(response.response);
                setTransporter(JSON.stringify(response.transporter));
                resetLoginData();
            } else {
              
            }
            
            setIsLoading(false);

          } catch (error) {
            console.error(error.message);
        }
  };

  // function for reset the useState containing the email and password text fields
  const resetLoginData = () => {
    setMailHost("");
    setMailUsername("");
    setMailPassword("");

    setMailPort("25");
    setMailSecure(false);
    setMailFrom("");

    setMailTo("");
    setMailSubject("");
    setMailContent("");
  };

  const handleChange = () =>{
    setMailSecure(!mailSecure);
  }

  const handleChangeSelfSign = () =>{
    setTrustSelfSign(!trustSelfSign);
  }

  return (
    <div className="login_rightpnl" style={{ alignItems: 'baseline' }}>
      <div
        action=""
        id="login_form"
        className="login_sec"
        //onSubmit={handelSmtpForm}
      >
        
      
         <div className="error-message mb-4">
            <p className="d-flex align-items-center gap-1 text-danger">
           
            </p>
            <p className="d-flex align-items-center gap-1 text-danger">
               
            </p>
        </div>
    

        {/* ------ email section start ------ */}
        <div className="form-group mb-4">
          <label htmlFor="email" className="text-gray-dark fw-semibold">
            SMTP Server Host
          </label>
          <input
            type="text"
            id="host"
            className="form-control bg-white border-white rounded-3"
            placeholder="Enter Host"
            value={mailHost}
            onChange={(e) => {
              setMailHost(e.target.value);
            }}
            required
          />
        </div>
        {/* ------ email section end ------ */}

        {/* ------ User section start ------ */}
        <div className="form-group mb-4">
          <label htmlFor="email" className="text-gray-dark fw-semibold">
            User (For Authentication)
          </label>
          <input
            type="text"
            id="mailUsername"
            className="form-control bg-white border-white rounded-3"
            placeholder="Enter Host"
            value={mailUsername}
            onChange={(e) => {
              setMailUsername(e.target.value);
            }}
            required
          />
        </div>
        {/* ------ user section end ------ */}

        {/* ------ password section start ------ */}
        <div className="form-group mb-4">
          <label htmlFor="email" className="text-gray-dark fw-semibold">
            Password (for Authentication)
          </label>
          <input
            type="text"
            id="mailPassword"
            className="form-control bg-white border-white rounded-3"
            value={mailPassword}
            onChange={(e) => {
              setMailPassword(e.target.value);
            }}
            required
          />
        </div>
        {/* ------ password section end ------ */}

        {/* ------ port section start ------ */}
        <div className="form-group mb-4">
          <label htmlFor="email" className="text-gray-dark fw-semibold">
            Port
          </label>
          <input
            type="text"
            id="mailport"
            className="form-control bg-white border-white rounded-3"
            value={mailPort}
            onChange={(e) => {
              setMailPort(e.target.value);
            }}
            required
          />
        </div>
        {/* ------ password section end ------ */}

        {/* ------ mail secure section start ------ */}
        <div className="form-group mb-4">
            
     
          <input
            type="checkbox"
            id="issecure"
            className="me-3 custom-checkbox"
            checked={mailSecure}
            onChange={handleChange}
        
          />
          is Secure (true for port 465, rest false )
        </div>
        {/* ------ mail secure section end ------ */}

        {/* ------ mail secure section start ------ */}
        <div className="form-group mb-4">
            
     
          <input
            type="checkbox"
            id="issecure"
            className="me-3 custom-checkbox"
            checked={trustSelfSign}
            onChange={handleChangeSelfSign}
        
          />
          Trust Self Sign (if check, add rejectUnauthorized: false )
        </div>
        {/* ------ mail secure section end ------ */}

        {/* ------ email from start ------ */}
        <div className="form-group mb-4">
          <label htmlFor="email" className="text-gray-dark fw-semibold">
            Email From (default is Username)
          </label>
          <input
            type="email"
            id="emailfrom"
            className="form-control bg-white border-white rounded-3"
            value={mailFrom}
            onChange={(e) => {
              setMailFrom(e.target.value);
            }}
            required
          />
        </div>
        {/* ------ mail from section end ------ */}

        {/* ------ mail to section start ------ */}
        <div className="form-group mb-4">
          <label htmlFor="email" className="text-gray-dark fw-semibold">
            Email To (default is Username)
          </label>
          <input
            type="email"
            id="emailto"
            className="form-control bg-white border-white rounded-3"
            value={mailTo}
            onChange={(e) => {
              setMailTo(e.target.value);
            }}
            required
          />
        </div>
        {/* ------ mail to section end ------ */}

        {/* ------ subject section start ------ */}
        <div className="form-group mb-4">
          <label htmlFor="email" className="text-gray-dark fw-semibold">
            Mail Subject
          </label>
          <input
            type="text"
            id="mailsubject"
            className="form-control bg-white border-white rounded-3"
            value={mailSubject}
            onChange={(e) => {
              setMailSubject(e.target.value);
            }}
            
          />
        </div>
        {/* ------ subject section end ------ */}


        {/* ------ content section start ------ */}
        <div className="form-group mb-4">
          <label htmlFor="email" className="text-gray-dark fw-semibold">
            Mail content
          </label>
          <input
            type="text"
            id="emailto"
            className="form-control bg-white border-white rounded-3"
            value={mailContent}
            onChange={(e) => {
              setMailContent(e.target.value);
            }}
            
          />
        </div>
        {/* ------ content ------ */}

        

        <div className="action d-flex flex-wrap align-items-center justify-content-between gap-3">
          {/* ------ login button section start ------ */}
          <button
            onClick={handelSmtpForm}
            className="btn btn-primary"
            disabled={isLoading ? true : false}
            style={{ cursor: isLoading ? "not-allowed" : "pointer" }}
          >
            TEST
            {isLoading && (
              <div
                className="mx-2 spinner-border spinner-border-sm"
                role="status"
              >
                <span className="visually-hidden">Loading...</span>
              </div>
            )}
          </button>
        </div>

         <div className="error-message mb-4">
            <p className="d-flex align-items-center gap-1 text-danger">
                <span>Transporter : {transporter}</span>
            </p>
            <p className="d-flex align-items-center gap-1 text-danger">
                <span>Response : {mailResponseMessage}</span>
            </p>
        </div>


      </div>
    </div>
  );
};

export default SmtpTestForm;
