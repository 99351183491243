/* eslint-disable */
import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";

//import translation function for language translation
import { useTranslation } from "react-i18next";

//import constext
import { GlobalProvider } from "context/GlobalContext";

/*import url and gateway methods */
import { getData } from "utils/Gateway";
import * as url from "urlhelpers/UrlHelper";

const AgendaHeader = () => {
  const token = localStorage.getItem("token"); // token
  const { t } = useTranslation(); //for translation

  const { setReloadAgenda, setSuccess, setNotificationString } =
    useContext(GlobalProvider);

  return (
    <div className="filter-container d-flex flex-wrap align-items-center gap-2 mb-2">
      <div className="d-flex align-items-center gap-3">
        <p className="mb-0">M : {t("Morning")}</p>
        <p className="mb-0">T : {t("Evening")}</p>
        <p className="mb-0">ER : {t("Educator Required")}</p>
        <p className="mb-0">ET : {t("Educator Total")}</p>
        <p className="mb-0">EA : {t("Educator Assigned")}</p>
      </div>

      {/* ----- refresh icon start ------ */}
      {/* <Link
        to="#"
        className="filter-btn btn btn-primary icon-btn mw-unset ms-auto"
        data-bs-placement="top"
        title="Refresh"
        // onClick={() => {
        //   setReloadAgenda(true);
        //   setSuccess(true);
        //   setNotificationString("The list has been refreshed successfully");
        // }}
      >
        <span className="d-block material-symbols-outlined">refresh</span>
      </Link> */}
      {/* ----- refresh icon end ------ */}

      <Link
        to="#filterCollapse"
        className="filter-btn btn btn-primary icon-btn mw-unset ms-auto"
        data-bs-toggle="collapse"
        role="button"
        aria-expanded="false"
        aria-controls="filterCollapse"
        data-bs-placement="top"
        title="Filter"
      >
        <span className="d-block material-symbols-outlined">filter_alt</span>
      </Link>
    </div>
  );
};

export default AgendaHeader;
