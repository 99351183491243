import AddSpecificAudiencePopup from 'components/SpecificAudienceComponents/AddSpecificAudiencePopup';
import SpecificAudienceBody from 'components/SpecificAudienceComponents/SpecificAudienceBody';
import SpecificAudienceNav from 'components/SpecificAudienceComponents/SpecificAudienceNav';
import LeftMenu from 'components/common/LeftMenu';
import UserMenu from 'components/common/UserMenu';
import React from 'react';

const SpecificAudience = () => {
  /* useState hook to download excel file */
  const [downloadExcel, setDownloadExcel] = React.useState(false);
  React.useEffect(() => {
    document.title = ' Specific Audience'; // Update the title when Register component mounts
  }, []);
  return (
    <>
      <UserMenu />
      <LeftMenu />
      <section className="batch-wrapper py-4">
        <div className="container-fluid px-4">
          <SpecificAudienceNav setDownloadExcel={setDownloadExcel} />
          <SpecificAudienceBody downloadExcel={downloadExcel} />
        </div>
      </section>
      <AddSpecificAudiencePopup />
    </>
  );
};

export default SpecificAudience;
