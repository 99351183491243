/* eslint-disable */
import React, { useState, useEffect, useContext, createRef } from "react";

// kendo react modules ==========================
import "@progress/kendo-theme-material/dist/all.css";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";
// impoting excel colums
import {
  ExcelExport,
  ExcelExportColumn,
} from "@progress/kendo-react-excel-export";
//import translation function for language translation
import { useTranslation } from "react-i18next";

import { Link } from "react-router-dom";

/*import url and gateway methods */
import { getData } from "utils/Gateway";
import * as url from "urlhelpers/UrlHelper";

//import context
import { GlobalProvider } from "context/GlobalContext";

const CourseListArea = ({ downloadExcel }) => {
  const { t } = useTranslation(); //for translation

  const token = localStorage.getItem("token");

  const { reloadCourseList, setReloadCourseList, setEditCourseData, screenHeight} =
    useContext(GlobalProvider);

  // default user data ------------------------------------
  const [courseList, setCourseList] = useState([]);
  const [responseReceived, setResponseReceived] = useState(false);

  // without pagination ------------------------------------
  const initialDataState = {};
  const [dataState, setDataState] = useState();
  const [result, setResult] = useState(process(courseList, initialDataState));

  const onDataStateChange = (event) => {
    console.log("event -->", event);
    setDataState(event.dataState);
    setResult(process(courseList, event.dataState));
  };
  /* Handle the button's onClick event and set it to export the data by using the ExcelExport save method. */
  const _exporter = createRef();
  const exportExcel = () => {
    if (_exporter.current) {
      _exporter.current.save();
    }
  };
  // allow edit of click record --------------------
  const showEditPopup = (courseData) => {
    setEditCourseData(courseData);
    new bootstrap.Offcanvas(document.getElementById("addBatchPopup")).toggle();
  };

  // mark all checkbox as selected ----------------
  const selectAllRecords = (e) =>
    document
      .querySelectorAll('input[name="selectRecord"]')
      .forEach((checkbox) => {
        checkbox.checked = e.target.checked;
      });

  // blocks to replace the Grid HTML ==============================================
  const SelectRecordHeader = () => {
    return (
      <div className="d-flex align-items-center">
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            id="selectAllMain"
            onClick={selectAllRecords}
          />
        </div>
      </div>
    );
  };

  /* Select single row */
  const SelectRecordRow = (props) => {
    return (
      <td>
        <div className="form-check">
          <input
            className="form-check-input "
            type="checkbox"
            value={props.dataItem._id}
            name="selectRecord"
          />
        </div>
      </td>
    );
  };

  /* custom name html */
  const nameHTML = ({ dataItem }) => {
    return (
      <td className="kendoCustomColumn overflow-visible">
        <div className="d-flex align-items-center gap-2">
          <p className="d-flex gap-2 align-items-center text-nowrap">
            <span className="d-block">{dataItem.name}</span>
          </p>

          <div className="dropdown cell-dropdown ms-auto">
            <button
              className=" bg-transparent p-0 border-0"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <span className="d-block material-symbols-outlined">
                more_vert
              </span>
            </button>
            <ul className="dropdown-menu border-0 shadow-lg ">
              <li>
                <Link
                  className="dropdown-item text-dark ms-3"
                  to="#"
                  onClick={() => {
                    showEditPopup(dataItem);
                  }}
                >
                  {t("Edit")}
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </td>
    );
  };

  //function for get all course lists
  const getAllCourseList = async () => {
    try {
      let requestUrl =
        url.API_BASEURL + url.API_GET_ALL_COURSE + `?token=${token}`;

      const response = await getData(requestUrl);

      if (response.status) {
        setCourseList(response.data);
        setResponseReceived(true);
        setResult(process(response.data, initialDataState));
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  useEffect(() => {
    if (reloadCourseList) {
      getAllCourseList();
      setReloadCourseList(false);
    }
  }, [reloadCourseList]);

  useEffect(() => {
    if (responseReceived === false) {
      getAllCourseList();
    }
  }, [responseReceived]);
  /* hook to hit  exportExcel() function*/
  useEffect(() => {
    downloadExcel && exportExcel();
  }, [downloadExcel]);
  return (
    <div className="table-responsive">
      {/* ============ download xls start ========== */}
      <ExcelExport data={courseList} fileName="Courses.xlsx" ref={_exporter}>
        <ExcelExportColumn field="name" title={t("Name")} />
        <ExcelExportColumn field="description" title={t("Comments")} />
        <ExcelExportColumn field="startdate" title={t("Start Date")} />
        <ExcelExportColumn field="enddate" title={t("End Date")} />
      </ExcelExport>

      {/* ============ download xls end ========== */}
      <Grid
        style={{ minWidth: "100%", height: screenHeight }}
        data={result}
        filterable={true}
        sortable={true}
        groupable={true}
        onDataStateChange={onDataStateChange}
        {...dataState}
        //pageable = {true}
        //total = {products.length}
        reorderable
        // resizable
      >
        <GridColumn
          field="id"
          headerCell={SelectRecordHeader}
          cell={SelectRecordRow}
          filterable={false}
          sortable={false}
          width="70px"
        />
        <GridColumn
          field="name"
          title={t("Name")}
          cell={nameHTML}
          width="200px"
        />
        <GridColumn field="description" title={t("Comments")} width="200px" />
        <GridColumn field="startdate" title={t("Start Date")} width="200px" />
        <GridColumn field="enddate" title={t("End Date")} width="200px" />
      </Grid>
    </div>
  );
};

export default CourseListArea;
