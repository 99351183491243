/* eslint-disable */
import React, { useEffect, useState, useRef } from "react";
import ReactDOM from "react-dom";
import LeftMenu from "components/common/LeftMenu";
import UserMenu from "components/common/UserMenu";
import CustomNotification from "components/common/CustomNotification";
import AddLabel from "components/common/AddLabel";
import DailyTaskHeader from "components/DailyTaskComponents/Header/DailyTaskHeader";
import DailyTaskBreadCrumb from "components/DailyTaskComponents/BreadCrumb/DailyTaskBreadCrumb";
import DailyTaskFilter from "components/DailyTaskComponents/Filter/DailyTaskFilter";
import ViewTaskDetailsPopup from "components/DailyTaskComponents/Popup/ViewTaskDetailsPopup";
import DailyTaskList from "components/DailyTaskComponents/DailyTaskList/DailyTaskList";
import ToggleTask from "components/DailyTaskComponents/ToogleTask/ToggleTask";
const DailyTask = () => {
  /* hook to search */
  const [search, setSearch] = useState("");
  /* hook to get number of records */
  const [records, setRecords] = useState(0);
  const blockRefSelected = useRef(null); // this will be used to acccess dom element directly
  /* function for checking record count */
  const checkedRecordCount = (selectCount) => {
    const blockElementSelect = ReactDOM.findDOMNode(blockRefSelected.current);
    if (blockElementSelect) {
      blockElementSelect.innerHTML = selectCount;
    }
  };
  useEffect(() => {
    document.title = "Daily task"; // Update the title when Login component mounts
  }, []);
  return (
    <>
      <UserMenu />
      <LeftMenu />
      <section className="mail-wrapper py-4">
        <div className="container-fluid px-4">
          <DailyTaskBreadCrumb />
          <DailyTaskHeader
            setSearch={setSearch}
            records={records}
            blockRefSelected={blockRefSelected}
          />
          <ToggleTask />
          <DailyTaskFilter />
          <DailyTaskList
            search={search}
            setRecords={setRecords}
            checkedRecordCount={checkedRecordCount}
          />
        </div>
      </section>
      <ViewTaskDetailsPopup />
      <AddLabel module={"task"} />
      {/*----------- custom notification component start -----------*/}
      <CustomNotification />
      {/*----------- custom notification component end -----------*/}
    </>
  );
};
export default DailyTask;
