/* eslint-disable */
import { useState } from "react";
import { Link, useHistory } from "react-router-dom";

/*import url and gateway methods */
import { postData } from "../../../utils/Gateway";
import * as url from "../../../urlhelpers/UrlHelper";

//import translation function for language translation
import { useTranslation } from "react-i18next";

const RegisterRightPanel = () => {
  const { t } = useTranslation(); //for translation

  const history = useHistory();

  // initial for sign up values
  const [firstName, setfirstName] = useState("");
  const [lastName, setlastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [agree, setAgree] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [firstNameWarning, setFirstNameWarning] = useState(false);
  const [lastNameWarning, setLastNameWarning] = useState(false);
  const [emailWarning, setEmailWarning] = useState(false);
  const [emailIsNotValid, setEmailIsNotValid] = useState(false);
  const [passwordWarning, setPasswordWarning] = useState(false);
  const [confirmPasswordWarning, setConfirmPasswordWarning] = useState(false);
  const [matchPassWarning, setMatchPassWarning] = useState(false);
  const [agreeWarning, setAgreeWarning] = useState(false);
  const [accountCreating, setAccountCreating] = useState(false);

  //function for toggle show password
  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  //function for toggle show confirm password
  const toggleConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  // function for complete registration process
  const signUpHandler = async (event) => {
    event.preventDefault();
    if (
      firstName == "" ||
      lastName == "" ||
      email == "" ||
      password == "" ||
      confirmPassword == ""
    ) {
      // first name validation
      if (firstName == "") {
        setFirstNameWarning(true);
      } else {
        setFirstNameWarning(false);
      }
      //last name validation
      if (lastName == "") {
        setLastNameWarning(true);
      } else {
        setLastNameWarning(false);
      }

      //email validation
      if (email == "") {
        setEmailWarning(true);
      } else {
        setEmailWarning(false);
      }

      //password validation
      if (password == "") {
        setPasswordWarning(true);
      } else {
        setPasswordWarning(false);
      }

      // confirm password validation
      if (confirmPassword == "") {
        setConfirmPasswordWarning(true);
      } else {
        setConfirmPasswordWarning(false);
      }
    } else {
      const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

      setFirstNameWarning(false);
      setLastNameWarning(false);
      setEmailWarning(false);
      setPasswordWarning(false);
      setConfirmPasswordWarning(false);
      setAgreeWarning(false);

      if (email.match(mailformat)) {
        setEmailIsNotValid(false);
        if (confirmPassword == password) {
          setMatchPassWarning(false);
          setAccountCreating(true);

          // exectuing code for submit here
          try {
            let requestUrl = url.API_BASEURL + url.API_REGISTARTION;

            let registartionData = {
              firstName,
              lastName,
              email,
              password,
            };

            let response = await postData(requestUrl, registartionData);

            if (response.status) {
              resetData();
              setAccountCreating(false);
              history.push("/emailverification");
              console.log("response otp----->", response.otp);
            }
          } catch (error) {
            console.log(error);
          }
        } else {
          setMatchPassWarning(true);
        }
      } else {
        setEmailIsNotValid(true);
      }
    }
  };

  // const signUpHandler = async (event) => {
  //   event.preventDefault();

  //   setFirstNameWarning(firstName == '');
  //   setLastNameWarning(lastName == '');
  //   setEmailWarning(email == '');
  //   setPasswordWarning(password == '');
  //   setConfirmPasswordWarning(confirmPassword == '');

  //   if (firstName && lastName && email && password && confirmPassword) {
  //     setEmailIsNotValid(
  //       !email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)
  //     );
  //     setMatchPassWarning(confirmPassword != password);
  //     setAgreeWarning(false);
  //     if (!emailIsNotValid && !matchPassWarning) {
  //       setAccountCreating(true);
  //       try {
  //         let response = await postData(
  //           url.API_BaseUrl + url.API_REGISTARTION,
  //           {
  //             firstName,
  //             lastName,
  //             email,
  //             password,
  //           }
  //         );
  //         if (response.status) {
  //           resetData();
  //           setAccountCreating(false);
  //           history.push('/emailverification');
  //           console.log('response otp----->', response.otp);
  //         }
  //       } catch (error) {
  //         console.error(error);
  //       }
  //     }
  //   } else {
  //     setEmailIsNotValid(false);
  //     setMatchPassWarning(false);
  //     setAgreeWarning(false);
  //   }
  // };

  const resetData = () => {
    setEmail("");
    setfirstName("");
    setlastName("");
    setPassword("");
    setConfirmPassword("");
    setAgree(false);
  };

  return (
    <div className="login_rightpnl">
      <form onSubmit={signUpHandler} id="register_form" className="login_sec">
        <div className="form-title mb-4">
          <h3>{t("Create Account")}</h3>
          <h6 className="mb-0">{t("Please enter details to proceed")}</h6>
        </div>
        <div className="form-group mb-4">
          <div className="row">
            {/* ----- first name start ----- */}
            <div className="col-lg-6">
              <label htmlFor="firstName" className="text-gray-dark fw-semibold">
                {t("First Name")}
              </label>
              <input
                type="text"
                id="firstName"
                className="form-control bg-white border-white rounded-3"
                placeholder={t("Enter first name")}
                value={firstName}
                onChange={(e) => {
                  setFirstNameWarning(false);
                  setfirstName(e.target.value);
                }}
                required
              />

              {firstNameWarning && (
                <div className="error-message mt-2">
                  <p className="d-flex align-items-center gap-1 text-danger">
                    <span className="material-symbols-outlined">warning</span>
                    <span>{t("Please enter first name")}!</span>
                  </p>
                </div>
              )}
            </div>
            {/* ----- first name end ----- */}

            {/* ----- surname start ----- */}
            <div className="col-lg-6">
              <label htmlFor="lastName" className="text-gray-dark fw-semibold">
                {t("Last Name")}
              </label>
              <input
                type="text"
                id="lastName"
                className="form-control bg-white border-white rounded-3"
                placeholder={t("Enter last name")}
                value={lastName}
                onChange={(e) => {
                  setLastNameWarning(false);
                  setlastName(e.target.value);
                }}
                required
              />
              {lastNameWarning && (
                <div className="error-message mt-2">
                  <p className="d-flex align-items-center gap-1 text-danger">
                    <span className="material-symbols-outlined">warning</span>
                    <span>{t("Please enter last name")}!</span>
                  </p>
                </div>
              )}
            </div>
            {/* ----- surname end ----- */}
          </div>
        </div>

        {/* ----- email  start ----- */}
        <div className="form-group mb-4">
          <label htmlFor="email" className="text-gray-dark fw-semibold">
            {t("Email")}
          </label>
          <input
            type="email"
            id="email"
            className="form-control bg-white border-white rounded-3"
            placeholder={t("Enter Email")}
            value={email}
            onChange={(e) => {
              setEmailWarning(false);
              setEmail(e.target.value);
            }}
            required
          />

          {emailWarning && (
            <div className="error-message mt-2">
              <p className="d-flex align-items-center gap-1 text-danger">
                <span className="material-symbols-outlined">warning</span>
                <span>{t("Please enter email")}!</span>
              </p>
            </div>
          )}
        </div>
        {/* ----- email end ----- */}

        {/* ----- password  start ----- */}
        <div className="form-group mb-4">
          <label htmlFor="password" className="text-gray-dark fw-semibold">
            {t("Password")}
          </label>
          <div className="password-visibility-toggler position-relative">
            <input
              type={showPassword ? "text" : "password"}
              id="password"
              className="form-control bg-white border-white rounded-3"
              placeholder={t("Enter Password")}
              value={password}
              onChange={(e) => {
                setPasswordWarning(false);
                setPassword(e.target.value);
              }}
              required
            />
            <Link
              onClick={togglePassword}
              to="#"
              className="password-visibility text-gray position-absolute top-50 end-0 translate-middle"
            >
              <span className="d-block material-symbols-outlined">
                visibility
              </span>
            </Link>
          </div>
          {passwordWarning && (
            <div className="error-message mt-2">
              <p className="d-flex align-items-center gap-1 text-danger">
                <span className="material-symbols-outlined">warning</span>
                <span>{t("Please enter password")}!</span>
              </p>
            </div>
          )}
        </div>
        {/* ----- password  start ----- */}

        {/* ----- confirm password  start ----- */}
        <div className="form-group mb-4">
          <label
            htmlFor="reEnterPassword"
            className="text-gray-dark fw-semibold"
          >
            {t("Re-enter Password")}
          </label>
          <div className="password-visibility-toggler position-relative">
            <input
              type={showConfirmPassword ? "text" : "password"}
              id="reEnterPassword"
              className="form-control bg-white border-white rounded-3"
              placeholder={t("Enter Password")}
              value={confirmPassword}
              onChange={(e) => {
                setConfirmPasswordWarning(false);
                setConfirmPassword(e.target.value);
              }}
              required
            />
            <Link
              onClick={toggleConfirmPassword}
              to="#"
              className="password-visibility text-gray position-absolute top-50 end-0 translate-middle"
            >
              <span className="d-block material-symbols-outlined">
                visibility
              </span>
            </Link>
          </div>
          {confirmPasswordWarning && (
            <div className="error-message mt-2">
              <p className="d-flex align-items-center gap-1 text-danger">
                <span className="material-symbols-outlined">warning</span>
                <span>{t("Please enter confirm password")}!</span>
              </p>
            </div>
          )}
        </div>
        {/* ----- confirm password  start ----- */}

        <div className="form-group d-flex align-items-center gap-3 mb-4">
          <div className="form-check mb-0">
            <input
              className="form-check-input"
              type="checkbox"
              value=""
              id="flexCheckDefault"
              required
            />
            <label
              className="form-check-label text-black mb-0"
              htmlFor="flexCheckDefault"
            >
              {t("Agree with the")}
            </label>
          </div>
          <p className="fw-medium">
            <Link to="#">{t("Legal Notice")}</Link>
            <span>&amp;</span>
            <Link to="#">{t("Privacy Policy")}</Link>
          </p>
        </div>
        <div className="form-group mb-4">
          <div className="form-check mb-0">
            <input
              className="form-check-input"
              type="checkbox"
              value=""
              id="agree"
              required
            />
            <label className="form-check-label text-black mb-0" htmlFor="agree">
              {t(
                "I agree to receive commercial information that MPFORALL Business & Collaboration considers appropriate to send me by email"
              )}
            </label>
          </div>
        </div>
        <div className="action d-flex flex-wrap align-items-center justify-content-between gap-3">
          <button type="submit" className="btn btn-primary">
            {t("Create Account")}
          </button>
          <p className="fw-medium">
            {t("Already have an account")}? <Link to="/">{t("Sign In")}</Link>
          </p>
        </div>
      </form>
    </div>
  );
};

export default RegisterRightPanel;
