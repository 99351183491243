import React from "react";
import { Link } from "react-router-dom";

//import translation function for language translation
import { useTranslation } from "react-i18next";

const CategoryBreadCrumb = () => {
  const { t } = useTranslation(); //for translation

  return (
    <nav aria-label="breadcrumb">
      <ul className="breadcrumb">
        <li className="breadcrumb-item">
          <Link to="dashboard.html" className="text-secondary">
            {t("Funbrain")}
          </Link>
        </li>
        <li className="breadcrumb-item text-gray-600" aria-current="page">
          {t("Category")}
        </li>
      </ul>
    </nav>
  );
};

export default CategoryBreadCrumb;
