/* eslint-disable */
import React, { useContext } from "react";

// kendo react modules ==========================
import '@progress/kendo-theme-material/dist/all.css';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { process } from '@progress/kendo-data-query';
// ES2015 module syntax
import {
  ExcelExport,
  ExcelExportColumn,
} from '@progress/kendo-react-excel-export';

/* importing events data */
import events from '../../data/EventRole.json';
//import context
import { GlobalProvider } from "context/GlobalContext";
//import translation function for language translation
import { useTranslation } from 'react-i18next';

const EventRoleBody = ({ downloadExcel }) => {
  const { t } = useTranslation(); //for translation

  const { screenHeight, } =
    useContext(GlobalProvider);
  // without pagination ------------------------------------
  const initialDataState = {};
  const [dataState, setDataState] = React.useState();
  const [result, setResult] = React.useState(process(events, initialDataState));

  const onDataStateChange = event => {
    console.log('event -->', event);
    setDataState(event.dataState);
    setResult(process(events, event.dataState));
  };

  /* Handle the button's onClick event and set it to export the data by using the ExcelExport save method. */
  const _exporter = React.createRef();
  const exportExcel = () => {
    if (_exporter.current) {
      _exporter.current.save();
    }
  };
  // mark all checkbox as selected ----------------
  const selectAllRecords = e => {
    document
      .querySelectorAll('input[name="selectRecord"]')
      .forEach(checkbox => {
        checkbox.checked = e.target.checked;
      });
  };

  // blocks to replace the Grid HTML ==============================================
  const SelectRecordHeader = () => {
    return (
      <div className="d-flex align-items-center">
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            id="selectAllMain"
            onClick={selectAllRecords}
          />
        </div>
      </div>
    );
  };

  /* Select single row */
  const SelectRecordRow = props => {
    return (
      <td>
        <div className="form-check">
          <input
            className="form-check-input "
            type="checkbox"
            value={props.dataItem._id}
            name="selectRecord"
          />
        </div>
      </td>
    );
  };
  /* hook to hit  exportExcel() function*/
  React.useEffect(() => {
    downloadExcel && exportExcel();
  }, [downloadExcel]);
  return (
    <div className="activity_table">
      <div className="table-responsive">
        {/* ============ download xls start ========== */}
        <ExcelExport data={events} fileName="Event roles.xlsx" ref={_exporter}>
          <ExcelExportColumn field="name" title={t("Name")} />
          <ExcelExportColumn field="slug" title={t("Slug")} />
        </ExcelExport>

        {/* ============ download xls end ========== */}
        <Grid
          style={{ minWidth: "100%", height: screenHeight }}
          data={result}
          filterable={true}
          sortable={true}
          groupable={true}
          onDataStateChange={onDataStateChange}
          {...dataState}
          //pageable = {true}
          //total = {products.length}
          reorderable
          // resizable
        >
          <GridColumn
            field="id"
            headerCell={SelectRecordHeader}
            cell={SelectRecordRow}
            filterable={false}
            sortable={false}
            width="70px"
          />
          <GridColumn field="name" title={t("Name")} width="200px" />
          <GridColumn field="slug" title={t("Slug")} width="200px" />
        </Grid>
      </div>
    </div>
  );
};

export default EventRoleBody;
