import React, { useRef, useState } from "react";
import ReactDOM from "react-dom";

import CategoryBreadCrumb from "./BreadCrumb/CategoryBreadCrumb";
import CategoryHeader from "./Header/CategoryHeader";
import CategoryList from "./CategoryList/CategoryList";

const CategoryBody = () => {
  const [downloadExcel, setDownloadExcel] = useState(false);
  const [reloadCategoryList, setreloadCategoryList] = useState(false);

  /* hook to search */
  const [search, setSearch] = useState("");
  /* hook to get number of records */
  const [records, setRecords] = useState(0);

  const blockRefSelected = useRef(null); // this will be used to acccess dom element directly

  /* function for checking record count */
  const checkedRecordCount = (selectCount) => {
    const blockElementSelect = ReactDOM.findDOMNode(blockRefSelected.current);
    if (blockElementSelect) {
      blockElementSelect.innerHTML = selectCount;
    }
  };

  return (
    <section className="mail-wrapper py-2">
      <div className="container-fluid px-4">
        <CategoryBreadCrumb />
        <CategoryHeader
          setSearch={setSearch}
          setDownloadExcel={setDownloadExcel}
          setreloadCategoryList={setreloadCategoryList}
          records={records}
          blockRefSelected={blockRefSelected}
        />
        <CategoryList
          search={search}
          downloadExcel={downloadExcel}
          reloadCategoryList={reloadCategoryList}
          setreloadCategoryList={setreloadCategoryList}
          setRecords={setRecords}
          checkedRecordCount={checkedRecordCount}
        />
      </div>
    </section>
  );
};

export default CategoryBody;
