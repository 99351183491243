/* eslint-disable */
import { useEffect, useState, useContext } from "react";

//import translation function for language translation
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import Select from "react-select";

//import context
import { GlobalProvider } from "context/GlobalContext";

//import api requiremnts
import * as url from "urlhelpers/UrlHelper";
import { getData, postData, putData } from "utils/Gateway";

//import options
import groupOptions from "data/Prod/SalesOrderGroupOption.json";

const CreateSalesOrderPopup = () => {
  //today value
  const todayValue = new Date().toISOString().split("T")[0];

  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const { t } = useTranslation(); //for translation

  const {
    setReloadReservationList,
    /* -------- context for notifications-------- */
    setSuccess,
    setError,
    setNotificationString,
  } = useContext(GlobalProvider);

  const [clientList, setClientList] = useState([]); //client option
  const [salesOrderList, setSalesOrderList] = useState([]);

  const [clientValue, setClientValue] = useState(null);
  const [selectedClient, setSelectedClient] = useState(null);
  const [prevSalesOrder, setPrevSalesOrder] = useState(null);
  const [selectedPrevSalesOrder, setSelectedPrevSalesOrder] = useState(null);
  const [budgetStatus, setBudgetStatus] = useState("");
  const [showSaveButton, setShowSaveButton] = useState(false);

  const [invoiceDate, setInvoiceDate] = useState(todayValue);
  const [dueDate, setDueDate] = useState(todayValue);
  const [discountRate, setDiscountRate] = useState(0);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [isSending, setIsSending] = useState(false);

  /* hook for validation */
  const [validation, setValidation] = useState({
    customerWarning: false,
    discountWarning: false,
    groupWarning: false,
  });

  //function for get all user list
  const getAllClientList = async () => {
    try {
      let requestUrl =
        url.API_BASEURL +
        url.API_GET_ALL_CLIENTS +
        `?token=${token}&iscompany=${true}`;

      const response = await getData(requestUrl);

      console.log(response);

      if (response.status) {
        setClientList(response.data);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  //function for select client
  const clientSelectionHandler = (val) => {
    setClientValue(val);
    setSelectedClient(val.value);
    getPrevSalesOrder(val.value);
  };

  //function for get user previous sales order
  const getPrevSalesOrder = async (clientId) => {
    try {
      let requestUrl =
        url.API_BASEURL +
        url.API_GET_SALES_ORDER_BY_CLIENT +
        `?token=${token}&client=${clientId}`;

      const response = await getData(requestUrl);

      // console.log(response);

      if (response.status) {
        setSalesOrderList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for select previous sales order
  const prevSalesOrderSelectionHandler = (val) => {
    if (val) {
      setPrevSalesOrder(val);
      setSelectedPrevSalesOrder(val.value);
      setBudgetStatus(val.invoicenumber + " : " + val.budgetstatus);
      if (val.budgetstatus == "open") {
        setShowSaveButton(true);
      } else {
        setShowSaveButton(false);
      }
    } else {
      setPrevSalesOrder(null);
      setSelectedPrevSalesOrder(null);
      setBudgetStatus("");
      setShowSaveButton(true);
    }
  };

  /* function for validation */
  const validateForm = () => {
    let isValid = true;

    if (selectedClient === null) {
      setValidation((prevState) => ({ ...prevState, customerWarning: true }));
      isValid = false;
    }

    // if (discountRate === 0 || discountRate < 0) {
    //   setValidation((prevState) => ({ ...prevState, discountWarning: true }));
    //   isValid = false;
    // }

    // if (selectedGroup === null) {
    //   setValidation((prevState) => ({ ...prevState, groupWarning: true }));
    //   isValid = false;
    // }

    return isValid;
  };

  //function for create sales order
  const createSalesOrderHandler = async () => {
    if (validateForm()) {
      try {
        setIsSending(true);
        const checkboxes = document.querySelectorAll(
          'input[name="selectReserveRecord"]'
        );

        let checkBoxArr = [];

        //set checkbox value
        checkboxes.forEach((checkbox) => {
          if (checkbox.checked) {
            checkBoxArr.push(checkbox.value);
          }
        });

        //check condition where reserve selected or not
        if (checkBoxArr.length > 0) {
          if (prevSalesOrder) {
            let salesOrderData = {
              reservations: checkBoxArr,
            };

            let requestUrl =
              url.API_BASEURL +
              url.API_ADD_MORE_SALES_ORDER_ITEM_PREV_BUDGET +
              `/${selectedPrevSalesOrder}?token=${token}&modulename=MOD_RESERVE`;

            const response = await putData(requestUrl, salesOrderData);

            console.log("response in sales order pop up========>", response);
            setIsSending(false);
            if (response.status) {
              resetHandler();
              setReloadReservationList(true);
              setSuccess(true);
              setNotificationString(t("Sales order created successfully"));
            }
          } else {
            let salesOrderData = {
              reservations: checkBoxArr,
              client: selectedClient,
              invoicedate: invoiceDate ? invoiceDate : "",
              duedate: dueDate ? dueDate : "",
              discountpercent: discountRate,
              grouped: selectedGroup ? selectedGroup.value : "0",
              createdsalesdate: todayValue,
            };

            console.log("salesOrderData------>", salesOrderData);

            let requestUrl =
              url.API_BASEURL +
              url.API_CREATE_SALES_ORDER +
              `?token=${token}&modulename=MOD_RESERVE`;

            console.log("url of sales order create======>", requestUrl);

            const response = await postData(requestUrl, salesOrderData);

            console.log("response in sales order pop up========>", response);
            setIsSending(false);

            if (response.status) {
              resetHandler();
              setReloadReservationList(true);
              setSuccess(true);
              setNotificationString(t("Sales order created successfully"));
            }
          }
        } else {
          setError(true);
          setNotificationString(t("Please select reserve"));
        }
      } catch (error) {
        console.log(error.message);
        setError(true);
        setNotificationString(
          t("Something went wrong, please try again later")
        );
      }
      let offCanvasPopup = document.querySelector("#createsalesorder");
      let offCanvas = bootstrap.Offcanvas.getInstance(offCanvasPopup);
      offCanvas.hide();
    }
  };

  //function for reset
  const resetHandler = () => {
    setSalesOrderList([]);
    setPrevSalesOrder(null);
    setSelectedPrevSalesOrder(null);
    setSelectedClient(null);
    setClientValue(null);
    setBudgetStatus("");
    setInvoiceDate(todayValue);
    setDueDate(todayValue);
    setDiscountRate(0);
    setSelectedGroup(null);
    setShowSaveButton(true);
  };

  useEffect(() => {
    getAllClientList();
    setShowSaveButton(true);
  }, []);

  return (
    <div
      className="offcanvas offcanvas-end border-0"
      tabIndex="-1"
      id="createsalesorder"
      aria-labelledby="createsalesorderlabel"
    >
      <div className="offcanvas-header p-4">
        {/* ------- offcanvas heading start -------- */}
        <h3 className="text-secondary mb-0" id="allSurveyPopupLabel">
          {t("Create Sales Order")}
        </h3>
        {/* ------- offcanvas heading end -------- */}

        {/* ------- offcanvas close button start -------- */}
        <button
          type="button"
          id="createsalesorderbutton"
          className="btn-close p-2 bg-white shadow-none shadow-sm m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={resetHandler}
        ></button>
        {/* ------- offcanvas close button end -------- */}
      </div>
      <div className="offcanvas-body px-4 pt-0">
        {/* ------- sales order form start ------- */}
        <form onSubmit={(e) => e.preventDefault()}>
          {/* ------- customer selection start ------- */}
          <div className="form-group mb-4">
            <label htmlFor="selectTemplate">{t("Select Customer")}</label>
            <Select
              placeholder={t("Select Customer")}
              options={clientList}
              value={clientValue}
              onChange={(val) => {
                setValidation((prevState) => ({
                  ...prevState,
                  customerWarning: false,
                }));
                clientSelectionHandler(val);
              }}
            />
            {validation.customerWarning && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>{t("Please select client")}!</span>
                </p>
              </div>
            )}
          </div>
          {/* ------- customer selection end ------- */}

          {/* ----- previous budget section start ----- */}
          {salesOrderList.length > 0 ? (
            <div className="form-group mb-4">
              <label htmlFor="previousbudget">{t("Previous Budget")}</label>
              <Select
                isClearable
                placeholder={t("Select previous budget")}
                options={salesOrderList}
                value={prevSalesOrder}
                onChange={(val) => {
                  prevSalesOrderSelectionHandler(val);
                }}
              />

              {budgetStatus == "" ? null : (
                <div className="error-message mt-2">
                  <p className="d-flex align-items-center gap-1 text-danger">
                    <span>{budgetStatus}!</span>
                  </p>
                </div>
              )}
            </div>
          ) : null}
          {/* ----- previous budget section end ----- */}

          {/* ------- invoice date start ------- */}
          {prevSalesOrder ? null : (
            <div className="form-group mb-4">
              <label htmlFor="invoice date">{t("Order Date")}</label>
              <input
                type="date"
                id="date"
                className="form-control"
                placeholder="DD/MM/YYYY"
                value={invoiceDate}
                onChange={(e) => setInvoiceDate(e.target.value)}
              />
            </div>
          )}
          {/* ------- invoice date end ------- */}

          {/* ------- due date start ------- */}
          {prevSalesOrder ? null : (
            <div className="form-group mb-4">
              <label htmlFor="invoice date">{t("Due Date")}</label>
              <input
                type="date"
                id="date"
                className="form-control"
                placeholder="DD/MM/YYYY"
                value={dueDate}
                onChange={(e) => setDueDate(e.target.value)}
              />
            </div>
          )}
          {/* ------- due date end ------- */}

          {/* ------- invoice discount start ------- */}
          {prevSalesOrder ? null : (
            <div className="form-group mb-4">
              <label htmlFor="discount">{t("Discount")} %</label>
              <input
                type="number"
                id="discount"
                className="form-control"
                placeholder=""
                value={discountRate}
                onChange={(e) => {
                  setValidation((prevState) => ({
                    ...prevState,
                    discountWarning: false,
                  }));
                  setDiscountRate(e.target.value);
                }}
              />
              {validation.discountWarning && (
                <div className="error-message mt-2">
                  <p className="d-flex align-items-center gap-1 text-danger">
                    <span className="material-symbols-outlined">warning</span>
                    <span>{t("Enter discount upper than zero")}!</span>
                  </p>
                </div>
              )}
            </div>
          )}
          {/* ------- invoice discount end ------- */}

          {/* ------- individual/group selection start ------- */}
          {prevSalesOrder ? null : (
            <div className="form-group mb-4">
              <label htmlFor="Individual or group">
                {t("Select Individual or Group")}
              </label>
              <Select
                // options={groupOption}
                options={groupOptions.map((option) => ({
                  ...option,
                  label: t(option.label), // Translate the label using the t function
                }))}
                value={selectedGroup}
                onChange={(val) => {
                  setValidation((prevState) => ({
                    ...prevState,
                    groupWarning: false,
                  }));
                  setSelectedGroup(val);
                }}
              />
              {validation.groupWarning && (
                <div className="error-message mt-2">
                  <p className="d-flex align-items-center gap-1 text-danger">
                    <span className="material-symbols-outlined">warning</span>
                    <span>{t("Please select group")}!</span>
                  </p>
                </div>
              )}
            </div>
          )}
          {/* ------- individual/group selection end ------- */}

          {/* ============ save button section start ================== */}
          {showSaveButton && (
            <div className="action d-flex align-items-center gap-2">
              <button
                type="button"
                className="btn btn-primary"
                disabled={isSending ? true : false}
                style={{ cursor: isSending ? "not-allowed" : "pointer" }}
                onClick={createSalesOrderHandler}
              >
                {t("Save")}
                {isSending && (
                  <div
                    className="mx-2 spinner-border spinner-border-sm"
                    role="status"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                )}
              </button>
            </div>
          )}
          {/* ============ save button section end ================== */}
        </form>
        {/* ------- sales order form end ------- */}
      </div>
    </div>
  );
};

export default CreateSalesOrderPopup;
