/*eslint-disable */
import React, { useEffect } from "react";

import HolidayCalenderBreadCrumb from "components/HolidayCalendarComponents/BreadCrumb/HolidayCalenderBreadCrumb";
import HolidayCalenderHeader from "components/HolidayCalendarComponents/Header/HolidayCalenderHeader";
import HolidayCalendarBody from "components/HolidayCalendarComponents/HolidayCalendayBody/HolidayCalendarBody";
import AddNewHolidayPopup from "components/HolidayCalendarComponents/Popup/AddNewHolidayPopup";
import CustomNotification from "components/common/CustomNotification";
import LeftMenu from "components/common/LeftMenu";
import UserMenu from "components/common/UserMenu";

const HolidayCalendar = () => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  useEffect(() => {
    document.title = "Holiday Calendar";
  }, []);

  return (
    <>
      <UserMenu />
      <LeftMenu />
      {userInfo?.role?.name == "admin" || userInfo?.role?.name == "manager" ? (
        <section className="mail-wrapper py-4">
          <div className="container-fluid px-4">
            <HolidayCalenderBreadCrumb />
            <HolidayCalenderHeader />
            <HolidayCalendarBody />
          </div>
        </section>
      ) : null}

      <AddNewHolidayPopup />
      {/*----------- custom notification component start -----------*/}
      <CustomNotification />
      {/*----------- custom notification component end -----------*/}
    </>
  );
};

export default HolidayCalendar;
