/* eslint-disable */
import React, { useState, useEffect, createRef } from 'react';

// kendo react modules ==========================
import '@progress/kendo-theme-material/dist/all.css';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { process } from '@progress/kendo-data-query';
// ES2015 module syntax
import {
  ExcelExport,
  ExcelExportColumn,
} from '@progress/kendo-react-excel-export';

//import translation function for language translation
import { useTranslation } from 'react-i18next';
//import context
import { GlobalProvider } from "context/GlobalContext";
/* importing groups data  */
import groupsData from '../../data/GroupsData.json';

const GroupsBody = ({ downloadExcel, search }) => {
  const {

    screenHeight,

  } = useContext(GlobalProvider);

  const { t } = useTranslation(); //for translation
  // without pagination ------------------------------------
  const initialDataState = {};
  const [dataState, setDataState] = useState();
  const [result, setResult] = useState(process(groupsData, initialDataState));

  const onDataStateChange = event => {
    console.log('event -->', event);
    setDataState(event.dataState);
    setResult(process(groupsData, event.dataState));
  };

  /* Handle the button's onClick event and set it to export the data by using the ExcelExport save method. */

  const _exporter = createRef();
  const exportExcel = () => {
    if (_exporter.current) {
      _exporter.current.save();
    }
  };

  /* function to search */
  const filterData = () => {
    const filteredData = result.data.filter(item => {
      const name = item.name.toLowerCase();
      const events = item.events.toLowerCase();
      return (
        name.includes(search.toLowerCase()) ||
        events.includes(search.toLowerCase())
      );
    });

    return filteredData;
  };

  // mark all checkbox as selected ----------------
  const selectAllRecords = e => {
    document
      .querySelectorAll('input[name="selectRecord"]')
      .forEach(checkbox => {
        checkbox.checked = e.target.checked;
      });
  };

  // blocks to replace the Grid HTML ==============================================
  const SelectRecordHeader = () => {
    return (
      <div className="d-flex align-items-center">
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            id="selectAllMain"
            onClick={selectAllRecords}
          />
        </div>
      </div>
    );
  };

  /* Select single row */
  const SelectRecordRow = props => {
    return (
      <td>
        <div className="form-check">
          <input
            className="form-check-input "
            type="checkbox"
            value={props.dataItem._id}
            name="selectRecord"
          />
        </div>
      </td>
    );
  };

  useEffect(() => {
    downloadExcel && exportExcel();
  }, [downloadExcel]);

  useEffect(() => {
    filterData();
  }, [search]);

  return (
    <div className="table-responsive">
      <ExcelExport data={groupsData} fileName="Group.xlsx" ref={_exporter}>
        <ExcelExportColumn
          field="name"
          title={t("Name")}
          locked={true}
          width={200}
        />
        <ExcelExportColumn field="events" title={t("Events")} width={350} />
      </ExcelExport>

      {search ? (
        <Grid
          style={{ minWidth: "100%", height: screenHeight }}
          data={filterData()}
          filterable={true}
          sortable={true}
          groupable={true}
          onDataStateChange={onDataStateChange}
          {...dataState}
          //pageable = {true}
          //total = {products.length}
          reorderable
          // resizable
        >
          <GridColumn
            field="id"
            headerCell={SelectRecordHeader}
            cell={SelectRecordRow}
            filterable={false}
            sortable={false}
            width="70px"
          />
          <GridColumn field="name" title={t("Name")} width="200px" />
          <GridColumn field="events" title={t("Events")} width="200px" />
        </Grid>
      ) : (
        <Grid
          style={{ minWidth: "100%", height: screenHeight }}
          data={result}
          filterable={true}
          sortable={true}
          groupable={true}
          onDataStateChange={onDataStateChange}
          {...dataState}
          //pageable = {true}
          //total = {products.length}
          reorderable
          // resizable
        >
          <GridColumn
            field="id"
            headerCell={SelectRecordHeader}
            cell={SelectRecordRow}
            filterable={false}
            sortable={false}
            width="70px"
          />
          <GridColumn field="name" title={t("Name")} width="200px" />
          <GridColumn field="events" title={t("Events")} width="200px" />
        </Grid>
      )}
    </div>
  );
};

export default GroupsBody;
