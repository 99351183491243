/* eslint-disable */

// GET method implementation to fetch data from API
const getData = async (url = "") => {
  let result = await fetch(url, {
    method: "GET",
  });
  return result.json();
};

// POST method implementation to send data to API
const postData = async (url = "", data = {}) => {
  const response = await fetch(url, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  return response.json();
};

// PUT method implementation to send edited/updated data to API
const putData = async (url = "", data = {}) => {
  let result = await fetch(url, {
    method: "PUT",
    body: JSON.stringify(data),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
  return result.json();
};

// DELETE method implementation to send deleted instruction to API
const deleteData = async (url = "") => {
  let result = await fetch(url, {
    method: "DELETE",
  });
  return result.json();
};

/*------ function for upload single file/image --------*/
const uploadFile = async (url, formValues = {}) => {
  const formData = new FormData();

  formData.append("upload", formValues);

  try {
    const response = await fetch(url, {
      method: "post",
      body: formData,
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.log(error.message);
  }
};

/*------ function for upload multiple file/image --------*/
const uploadMultipleFile = async (url, formValues = {}) => {
  const formData = new FormData();

  Object.keys(formValues).forEach((element) => {
    formData.append("uploads", formValues[element]);
  });

  try {
    const response = await fetch(url, {
      method: "post",
      body: formData,
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.log(error.message);
  }
};

/*------- end of the formdata with file uploader --------*/

export {
  postData,
  getData,
  putData,
  deleteData,
  uploadFile,
  uploadMultipleFile,
};
