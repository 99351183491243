/* eslint-disable */
import React, { useState, useContext, useEffect } from "react";

//import translation function for language translation
import { useTranslation } from "react-i18next";

/*import url and gateway methods */
import { postData, putData } from "utils/Gateway";
import * as url from "urlhelpers/UrlHelper";

//import context
import { GlobalProvider } from "context/GlobalContext";

const AddCoursePopup = () => {
  const { t } = useTranslation(); //for translation
  const token = localStorage.getItem("token"); // getting token from local storage
  const { setReloadCourseList, editCourseData, setEditCourseData } =
    useContext(GlobalProvider);
  const [name, setName] = useState(""); // for name
  const [comments, setComments] = useState(""); // for comments
  const [startDate, setStartDate] = useState(""); // for start date
  const [endDate, setEndDate] = useState(""); // for end date
  /* hook for form validation */
  const [validation, setValidation] = useState({
    nameWarning: false,
    commentsWarning: false,
    startDateWarning: false,
    endDateWarning: false,
  });

  /* function for validation */
  const formValidation = () => {
    let isValid = true;
    setValidation((prevState) => ({
      ...prevState,
      nameWarning: name === "",
      commentsWarning: comments === "",
      startDateWarning: startDate === "",
      endDateWarning: endDate === "",
    }));
    isValid = !(
      name === "" ||
      comments === "" ||
      startDate === "" ||
      endDate === ""
    );
    return isValid;
  };

  /* func to save add course  */
  const saveCourseHandler = async (e) => {
    e.preventDefault();
    // console.log(name, comments, startDate, endDate);
    // console.log(typeof startDate);

    if (formValidation()) {
      try {
        const courseData = {
          name,
          description: comments,
          startdate: startDate,
          enddate: endDate,
        };

        let requestURL =
          url.API_BASEURL + url.API_ADD_NEW_COURSE + `?token=${token}`;

        const response = await postData(requestURL, courseData);
        console.log(response);
        if (response.status) {
          resetHandler();
          let offCanvasPopup = document.querySelector("#addBatchPopup");
          let offCanvas = bootstrap.Offcanvas.getInstance(offCanvasPopup);
          offCanvas.hide();
          setReloadCourseList(true);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  /* func to edit course */
  const updateCourse = async (e) => {
    e.preventDefault();

    if (formValidation()) {
      try {
        const courseData = {
          name,
          description: comments,
          startdate: startDate,
          enddate: endDate,
        };

        let requestUrl =
          url.API_BASEURL +
          url.API_UPDATE_COURSE +
          `/${editCourseData._id}` +
          `?token=${token}`;

        const response = await putData(requestUrl, courseData);

        if (response.status) {
          resetHandler();
          bootstrap.Offcanvas.getInstance(
            document.querySelector("#addBatchPopup")
          ).hide();
          setReloadCourseList(true);
        }
      } catch (error) {
        console.error(error.message);
      }
    }
  };
  //function for reset form
  const resetHandler = () => {
    setEditCourseData(null);
    setName("");
    setEndDate("");
    setStartDate("");
    setComments("");
    setValidation({
      nameWarning: false,
      commentsWarning: false,
      startDateWarning: false,
      endDateWarning: false,
    });
  };

  //use effect for edit
  useEffect(() => {
    if (editCourseData) {
      /*------- assign name start -------*/
      editCourseData.name && setName(editCourseData.name);
      /*------- assign name end -------*/

      /*------- assign comments start -------*/
      editCourseData.description && setComments(editCourseData.description);
      /*------- assign comments end -------*/

      /*------- assign startDate start -------*/
      editCourseData.startdate && setStartDate(editCourseData.startdate);
      /*------- assign startDate end -------*/

      /* ------- assign end date start  */
      editCourseData.enddate && setEndDate(editCourseData.enddate);
      /* ------- assign end date end  */
    }
  }, [editCourseData]);

  return (
    <div
      className="offcanvas offcanvas-end border-0"
      tabIndex="-1"
      id="addBatchPopup"
      aria-labelledby="addBatchPopupLabel"
    >
      <div className="offcanvas-header p-4">
        <h3 className="text-secondary mb-0" id="addBatchPopupLabel">
          {t("Add Course")}
        </h3>
        <button
          type="button"
          id="closeCreateMail"
          className="btn-close p-2 bg-white shadow-none shadow-sm m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={resetHandler}
        ></button>
      </div>
      <div className="offcanvas-body p-4 pt-0">
        <form onSubmit={editCourseData ? updateCourse : saveCourseHandler}>
          <div className="form-group mb-4">
            <label htmlFor="name">{t("Name")}</label>
            <input
              type="text"
              id="name"
              placeholder={t("Enter Course Name")}
              className="form-control"
              value={name}
              onChange={(e) => {
                setName(e.target.value), setValidation({ nameWarning: false });
              }}
            />
            {/* name warning */}
            {validation.nameWarning && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>{t("Please enter name")}!</span>
                </p>
              </div>
            )}
          </div>

          <div className="form-group mb-4">
            <label htmlFor="comments">{t("Comments")}</label>
            <textarea
              name="comments"
              id="comments"
              cols="30"
              rows="5"
              className="form-control"
              placeholder={t("Enter Here")}
              value={comments}
              onChange={(e) => {
                setComments(e.target.value),
                  setValidation({ commentsWarning: false });
              }}
            ></textarea>
            {/* comments warning */}
            {validation.commentsWarning && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>{t("Please enter comments")}!</span>
                </p>
              </div>
            )}
          </div>

          <div className="form-group mb-4">
            <div className="row">
              <div className="col-lg-6">
                <label htmlFor="date">{t("Start Date")}</label>
                <input
                  type="date"
                  id="date"
                  className="form-control"
                  placeholder="DD/MM/YYYY"
                  value={startDate}
                  onChange={(e) => {
                    setStartDate(e.target.value),
                      setValidation({ startDateWarning: false });
                  }}
                />
                {/* start date warning */}
                {validation.startDateWarning && (
                  <div className="error-message mt-2">
                    <p className="d-flex align-items-center gap-1 text-danger">
                      <span className="material-symbols-outlined">warning</span>
                      <span>{t("Please enter start date")}!</span>
                    </p>
                  </div>
                )}
              </div>

              <div className="col-lg-6">
                <label htmlFor="date">{t("End Date")}</label>
                <input
                  type="date"
                  id="date"
                  className="form-control"
                  placeholder="DD/MM/YYYY"
                  value={endDate}
                  onChange={(e) => {
                    setEndDate(e.target.value),
                      setValidation({ endDateWarning: false });
                  }}
                />
                {/* end date warning */}
                {validation.endDateWarning && (
                  <div className="error-message mt-2">
                    <p className="d-flex align-items-center gap-1 text-danger">
                      <span className="material-symbols-outlined">warning</span>
                      <span>{t("Please enter end date")}!</span>
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="action">
            <button type="submit" className="btn btn-primary mw-unset">
              {editCourseData ? t("Update") : t("Save")}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddCoursePopup;
