import AddSpecialNeedsPopup from 'components/SpecialNeedsComponents/AddSpecialNeedsPopup';
import SpecialNeedsBody from 'components/SpecialNeedsComponents/SpecialNeedsBody';
import SpecialNeedsNav from 'components/SpecialNeedsComponents/SpecialNeedsNav';
import LeftMenu from 'components/common/LeftMenu';
import UserMenu from 'components/common/UserMenu';
import React from 'react';

const SpecialNeeds = () => {
  /* useState hook to download excel file */
  const [downloadExcel, setDownloadExcel] = React.useState(false);
  React.useEffect(() => {
    document.title = ' Special Needs'; // Update the title when Register component mounts
  }, []);
  return (
    <>
      <UserMenu />
      <LeftMenu />
      <section className="batch-wrapper py-4">
        <div className="container-fluid px-4">
          <SpecialNeedsNav setDownloadExcel={setDownloadExcel} />
          <SpecialNeedsBody downloadExcel={downloadExcel} />
        </div>
      </section>
      <AddSpecialNeedsPopup />
    </>
  );
};

export default SpecialNeeds;
