import ReservationDetailsSection from 'components/ReservationDetailsComponents/ReservationDetailsSection/ReservationDetailsSection';
import LeftMenu from 'components/common/LeftMenu';
import UserMenu from 'components/common/UserMenu';

const ReservationDetails = () => {
  return (
    <>
      <UserMenu />
      <LeftMenu />
      <ReservationDetailsSection />
    </>
  );
};

export default ReservationDetails;
