import React from 'react';

//import translation function for language translation
import { useTranslation } from 'react-i18next';

const AddEventRolePopup = () => {
  const { t } = useTranslation(); //for translation
  return (
    <div
      className="offcanvas offcanvas-end border-0"
      tabIndex="-1"
      id="saveReservationPopup"
      aria-labelledby="saveReservationPopupLabel"
    >
      <div className="offcanvas-header position-relative align-items-start p-4">
        <h3 className="text-secondary mb-0" id="saveReservationPopupLabel">
          <span className="d-block">{t('Add Event Role')}</span>
        </h3>
        <button
          type="button"
          className="btn-close p-2 bg-white shadow-none shadow-sm m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div className="offcanvas-body position-relative p-4 pt-0">
        <form action="">
          <div className="form-group mb-4">
            <label>{t('Name')}</label>
            <input
              type="text"
              value=""
              className="form-control"
              placeholder={t('Enter Here')}
            />
          </div>
          <div className="form-group mb-4">
            <label>{t('Slug')}</label>
            <input
              type="text"
              value=""
              className="form-control"
              placeholder={t('Enter Here')}
            />
          </div>
          <div className="action d-flex align-items-center gap-2">
            <button type="submit" className="btn btn-primary">
              {t('Save')}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddEventRolePopup;
