/* eslint-disable */
import { useState, useEffect, useContext, createRef } from "react";

// kendo react modules ==========================
import "@progress/kendo-theme-material/dist/all.css";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";
import { Link } from "react-router-dom";
// ES2015 module syntax
import {
  ExcelExport,
  ExcelExportColumn,
} from "@progress/kendo-react-excel-export";

import * as url from "urlhelpers/UrlHelper";
import { getData } from "utils/Gateway";

//import context
import { GlobalProvider } from "context/GlobalContext";

//import translation function for language translation
import { useTranslation } from "react-i18next";

const MailTemplateList = ({
  downloadExcel,
  search,
  setRecords,
  checkedRecordCount,
}) => {
  const token = localStorage.getItem("token"); // token
  const { t } = useTranslation(); //for translation

  const {
    reloadMailTemplateList,
    setReloadMailTemplateList,
    setEditMailTemplateData,
    setCopyMailTemplateId,
    screenHeight,
    showMailTemplateNameColumn,
    showMailTemplateSlugColumn,
    showMailTemplateSubjectColumn,
    showMailTemplateLabelColumn,
    showMailTemplateDateColumn,
    searchMailTemplateDate,
    searchMailTemplateName,
    searchMailTemplateSubject,
    searchMailTemplateLabels,
    searchMailTemplateIsPublic,
  } = useContext(GlobalProvider);

  const [templateList, setTemplateList] = useState([]);
  const [responseReceived, setResponseReceived] = useState(false);

  // without pagination ------------------------------------
  const initialDataState = {};
  const [dataState, setDataState] = useState();
  const [result, setResult] = useState(process(templateList, initialDataState));

  const onDataStateChange = (event) => {
    console.log("event -->", event);
    setDataState(event.dataState);
    setResult(process(templateList, event.dataState));
  };
  /* Handle the button's onClick event and set it to export the data by using the ExcelExport save method. */
  const _exporter = createRef();

  const exportExcel = () => {
    if (_exporter.current) {
      _exporter.current.save();
    }
  };

  /* function to get all mail template data */
  const getMailTemplateList = async () => {
    try {
      let requestUrl =
        url.API_BASEURL + url.API_GET_ALL_TEMPLATE + `?token=${token}`;

      //search by date
      if (searchMailTemplateDate != "") {
        requestUrl = requestUrl + `&templatedate=${searchMailTemplateDate}`;
      }

      //search by name
      if (searchMailTemplateName != "") {
        requestUrl = requestUrl + `&templatename=${searchMailTemplateName}`;
      }

      //search by subject
      if (searchMailTemplateSubject != "") {
        requestUrl =
          requestUrl + `&templatesubject=${searchMailTemplateSubject}`;
      }

      //search by label
      if (searchMailTemplateLabels.length > 0) {
        requestUrl = requestUrl + `&templatelabels=${searchMailTemplateLabels}`;
      }

      //search by ispublic
      if (searchMailTemplateIsPublic) {
        requestUrl = requestUrl + `&ispublic=${searchMailTemplateIsPublic}`;
      }

      console.log("url of templatelist ========>>>", requestUrl);

      const response = await getData(requestUrl);

      console.log("get template response ========>>>", response);

      if (response.data) {
        setTemplateList(response.data);
        setResponseReceived(true);
        dataState
          ? setResult(process(response.data, dataState))
          : setResult(process(response.data, initialDataState));
        setRecords(response.data.length);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  /* function to search */
  const filterData = () => {
    if (!result || !result.data) {
      return []; // Return an empty array if result or result.data is undefined
    }

    const filteredData = result.data.filter((item) => {
      const templateName = item?.name?.toLowerCase() || "";
      const slug = item?.slug?.toLowerCase() || "";
      const subject = item?.subject?.toLowerCase() || "";
      const tagstring = item?.tagstring?.toLowerCase() || "";
      const date = item?.created;

      return (
        (templateName && templateName.includes(search.toLowerCase())) ||
        (slug && slug.includes(search.toLowerCase())) ||
        (subject && subject.includes(search.toLowerCase())) ||
        (tagstring && tagstring.includes(search.toLowerCase())) ||
        (date && date.includes(search))
      );
    });
    setRecords(filteredData.length);
    return filteredData;
  };

  /* custom name cell html */
  const customNameHtml = (props) => {
    return (
      <td className="kendoCustomColumn overflow-visible">
        <div className="d-flex align-items-center gap-2">
          <span>{props.dataItem.name} </span>
          <Link
            to="#createEmailTemplatePopup"
            data-bs-toggle="offcanvas"
            className="ml-auto edit_icon"
          >
            <span
              className="d-block material-symbols-outlined icon-md"
              onClick={() => {
                setEditMailTemplateData(props.dataItem._id);
              }}
            >
              edit
            </span>
          </Link>

          {/* -------- dropdown section start -------- */}
          <div className="dropdown cell-dropdown ms-auto mt-2">
            {/* ------ more vert icon start ------ */}
            <button
              className=" bg-transparent p-0 border-0"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <span className="d-block material-symbols-outlined">
                more_vert
              </span>
            </button>
            {/* ------ more vert icon end ------ */}

            {/* ------ dropdown list start ------ */}
            <ul className="dropdown-menu border-0 shadow-lg">
              {/* ----- Copy template start ------ */}
              <li>
                <Link
                  className="dropdown-item"
                  to="#createEmailTemplatePopup"
                  data-bs-toggle="offcanvas"
                  role="button"
                  aria-controls="saveReservationPopup"
                  onClick={() => {
                    setCopyMailTemplateId(props.dataItem._id);
                  }}
                >
                  {t("Copy Mail Template")}
                </Link>
              </li>
              {/* ----- Copy template end ------ */}
            </ul>

            {/* ------ dropdown list end ------ */}
          </div>
          {/* -------- dropdown section end -------- */}
        </div>
      </td>
    );
  };

  // check the selected count and show the count in top ------------------------
  const handelSelectedCount = () => {
    let checkboxes = document.querySelectorAll(
      'input[name="selectMailRecord"]'
    );

    let totalSelected = 0;
    let filteredData = [];
    checkboxes.forEach((checkbox) => {
      if (checkbox.checked) {
        totalSelected++;
        const filteredUser = templateList.find(
          (user) => user._id === checkbox.value
        );
        filteredUser && filteredData.push(filteredUser);
      }
    });

    // Update the content of the block
    checkedRecordCount(totalSelected);
    return filteredData;
  };

  // mark all checkbox as selected ----------------
  const selectAllRecords = (e) => {
    document
      .querySelectorAll('.k-master-row input[name="selectMailRecord"]')
      .forEach((checkbox) => {
        checkbox.checked = e.target.checked;
      });
    handelSelectedCount();
  };

  // blocks to replace the Grid HTML ==============================================
  const SelectRecordHeader = () => {
    return (
      <div className="d-flex align-items-center">
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            id="selectAllMain"
            onClick={selectAllRecords}
          />
        </div>
      </div>
    );
  };

  /* Select single row */
  const SelectRecordRow = (props) => {
    return (
      <td>
        <div className="form-check">
          <input
            className="form-check-input "
            type="checkbox"
            value={props.dataItem._id}
            name="selectMailRecord"
            filtername="selectedrecord"
            onClick={handelSelectedCount}
          />
        </div>
      </td>
    );
  };

  useEffect(() => {
    if (
      searchMailTemplateDate != "" ||
      searchMailTemplateName != "" ||
      searchMailTemplateSubject != "" ||
      searchMailTemplateLabels.length > 0 ||
      searchMailTemplateIsPublic
    ) {
      getMailTemplateList();
    }
  }, [
    searchMailTemplateName,
    searchMailTemplateSubject,
    searchMailTemplateDate,
    searchMailTemplateLabels,
    searchMailTemplateIsPublic,
  ]);

  /* hook to reload list */
  useEffect(() => {
    if (reloadMailTemplateList || !responseReceived) {
      getMailTemplateList();
      checkedRecordCount(0);
      setResult(process(templateList, initialDataState));
      if (reloadMailTemplateList) {
        setReloadMailTemplateList(false);
      }
    }
  }, [reloadMailTemplateList, responseReceived]);

  /* hook to hit  exportExcel() function*/
  useEffect(() => {
    downloadExcel && exportExcel();
  }, [downloadExcel]);

  /* hook to hit filterData function on search */
  useEffect(() => {
    filterData();
  }, [search]);

  /* set records based on onDataStateChange */
  useEffect(() => {
    setRecords(result.data.length);
  }, [result]);

  return (
    <div className="activity_table">
      <div className="table-responsive">
        {/* ============ download xls start ========== */}
        <ExcelExport
          data={
            handelSelectedCount().length > 0
              ? handelSelectedCount()
              : templateList
          }
          fileName="Mail_Template.xlsx"
          ref={_exporter}
        >
          <ExcelExportColumn field="name" title={t("Template Name")} />

          <ExcelExportColumn field="slug" title={t("Slug")} />
          <ExcelExportColumn field="subject" title={t("Subject")} />
          <ExcelExportColumn field="tagstring" title={t("Label")} />
          <ExcelExportColumn field="created" title={t("Date")} />
        </ExcelExport>

        {/* ============ download xls start ========== */}
        {search ? (
          <Grid
            style={{ height: screenHeight }}
            data={filterData()}
            filterable={true}
            sortable={true}
            groupable={true}
            onDataStateChange={onDataStateChange}
            {...dataState}
            //pageable = {true}
            //total = {products.length}
            reorderable
            // resizable
          >
            <GridColumn
              field="id"
              headerCell={SelectRecordHeader}
              cell={SelectRecordRow}
              filterable={false}
              sortable={false}
              width="70px"
            />
            {showMailTemplateNameColumn && (
              <GridColumn
                cell={customNameHtml}
                field="name"
                title={t("Template Name")}
                width="300px"
              />
            )}
            {showMailTemplateSlugColumn && (
              <GridColumn
                field="slug"
                title={t("Slug")}
                //width="200px"
              />
            )}
            {showMailTemplateSubjectColumn && (
              <GridColumn
                field="subject"
                title={t("Subject")}
                //width="200px"
              />
            )}

            {showMailTemplateLabelColumn && (
              <GridColumn
                field="tagstring"
                title={t("Label")}
                //width="200px"
              />
            )}

            {showMailTemplateDateColumn && (
              <GridColumn
                field="created"
                title={t("Date")}
                //width="200px"
              />
            )}
          </Grid>
        ) : (
          <Grid
            style={{ height: screenHeight }}
            data={result}
            filterable={true}
            sortable={true}
            groupable={true}
            onDataStateChange={onDataStateChange}
            {...dataState}
            //pageable = {true}
            //total = {products.length}
            reorderable
            // resizable
          >
            <GridColumn
              field="id"
              headerCell={SelectRecordHeader}
              cell={SelectRecordRow}
              filterable={false}
              sortable={false}
              width="70px"
            />
            {showMailTemplateNameColumn && (
              <GridColumn
                cell={customNameHtml}
                field="name"
                title={t("Template Name")}
                width="300px"
              />
            )}
            {showMailTemplateSlugColumn && (
              <GridColumn
                field="slug"
                title={t("Slug")}
                //width="200px"
              />
            )}
            {showMailTemplateSubjectColumn && (
              <GridColumn
                field="subject"
                title={t("Subject")}
                //width="200px"
              />
            )}

            {showMailTemplateLabelColumn && (
              <GridColumn
                field="tagstring"
                title={t("Label")}
                //width="200px"
              />
            )}

            {showMailTemplateDateColumn && (
              <GridColumn
                field="created"
                title={t("Date")}
                //width="200px"
              />
            )}
          </Grid>
        )}
      </div>
    </div>
  );
};

export default MailTemplateList;
