import React from 'react';
import { Link } from 'react-router-dom';

const HolidayCalenderHeader = () => {
  return (
    <div className="calender_srch_top">
      <div className="calender_addBtn ms-auto mb-2">
        <Link
          to="#addHolidaySchedulePopup"
          data-bs-toggle="offcanvas"
          className="btn btn-primary"
        >
          <i className="material-icons-outlined">add</i>
        </Link>
      </div>
    </div>
  );
};

export default HolidayCalenderHeader;
