/* eslint-disable */
import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";

//import translation function for language translation
import { useTranslation } from "react-i18next";

/*import url and gateway methods */
import { getData, putData, deleteData } from "utils/Gateway";
import * as url from "urlhelpers/UrlHelper";

//import context
import { GlobalProvider } from "context/GlobalContext";

const MaterialKitHeader = ({
  setDownloadExcel,
  setSearch,
  records,
  blockRefSelected,
}) => {
  const token = localStorage.getItem("token");
  const { t } = useTranslation(); //for translation

  /* hook to get the search value */
  const [searchValue, setSearchValue] = useState("");

  /* function to click download xls*/
  const handleDownloadClick = () => {
    setDownloadExcel(true);

    setTimeout(() => setDownloadExcel(false), 500);
  };

  const { setReloadMaterialList, setSuccess, setError, setNotificationString } =
    useContext(GlobalProvider);

  //function for delete
  const deleteHandler = async () => {
    const checkboxes = document.querySelectorAll(
      'input[name="selectMaterialRecord"]'
    );
    checkboxes.forEach(async (checkbox) => {
      if (checkbox.checked) {
        try {
          let requestUrl =
            url.API_BASEURL +
            url.API_DELETE_MATERIAL_KIT +
            `/${checkbox.value}` +
            `?token=${token}`;

          const response = await deleteData(requestUrl);
          if (response.status) {
            setReloadMaterialList(true);
            setSuccess(true);
            setNotificationString(
              t("Material kit has been deleted successfully")
            );
          }
        } catch (error) {
          console.error(error.message);
          setError(true);
          setNotificationString(
            t("Something went wrong, please try again later")
          );
        }
      }
    });
  };

  /* hook to empty search when searchValue gets empty */
  useEffect(() => {
    !searchValue && setSearch("");
  }, [searchValue]);

  return (
    <div className="filter-container d-flex flex-wrap align-items-center gap-2 pb-1">
      {/* <Link
          to="#columnCollapse"
          className="btn btn-primary mw-unset p-2"
          data-bs-toggle="collapse"
          role="button"
          aria-expanded="false"
          aria-controls="columnCollapse"
        >
          <span className="d-block material-symbols-outlined icon-fill">
            visibility
          </span>
        </Link> */}
      <Link
        to="#"
        onClick={handleDownloadClick}
        className="filter-btn btn btn-primary icon-btn mw-unset"
      >
        <span className="d-block material-symbols-outlined">download</span>
      </Link>

      <div className="search-container position-relative d-none d-md-block">
        <form onSubmit={(e) => e.preventDefault()}>
          <input
            type="search"
            placeholder={t("Search here")}
            className="form-control bg-transparent py-2 border-0"
            onChange={(e) => setSearchValue(e.target.value)}
            onKeyDown={(e) => {
              e.key === "Enter" && (e.preventDefault(), setSearch(searchValue));
            }}
          />
          <button
            onClick={() => setSearch(searchValue)}
            type="submit"
            className="bg-transparent text-dark p-0 border-0 position-absolute top-50 translate-middle-y"
          >
            <span className="d-block material-symbols-outlined">search</span>
          </button>
        </form>
      </div>
      {/* ------------- showing records start ----------------- */}
      <p className="text-gray fw-medium ms-auto me-3">
        {t("Showing")}
        <span className="text-black ms-1">{records}</span> {t("Records")} -
        <span className="text-black ms-1" ref={blockRefSelected}>
          {" "}
          0{" "}
        </span>{" "}
        {t("Selected")}
      </p>
      {/* ------------- showing records end ----------------- */}
      {/* ----- refresh icon start ------ */}
      <Link
        to="#"
        className="filter-btn btn btn-primary icon-btn mw-unset"
        onClick={() => {
          setReloadMaterialList(true);
          setSuccess(true);
          setNotificationString(t("The list has been refreshed successfully"));
        }}
      >
        <span className="d-block material-symbols-outlined">refresh</span>
      </Link>
      {/* ----- refresh icon end ------ */}

      {/* <Link
          to="#filterCollapse"
          className="btn btn-primary mw-unset p-2"
          data-bs-toggle="collapse"
          role="button"
          aria-expanded="false"
          aria-controls="filterCollapse"
        >
          <span className="d-block material-symbols-outlined">filter_alt</span>
        </Link> */}
      {/* -------- add material kit popup icon start -------- */}
      <Link
        to="#saveMaterialPopup"
        data-bs-toggle="offcanvas"
        role="button"
        aria-controls="saveReservationPopup"
        className="filter-btn btn btn-primary icon-btn mw-unset"
      >
        <span className="d-block material-symbols-outlined">add</span>
      </Link>
      {/* -------- add material kit popup icon start -------- */}

      <div className="dropdown">
        <button
          className="btn btn-primary dropdown-toggle mw-unset"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          {t("Actions")}
        </button>
        <ul className="dropdown-menu border-0 shadow-lg">
          <li>
            <Link className="dropdown-item" to="#" onClick={deleteHandler}>
              {t("Delete selected")}
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default MaterialKitHeader;
