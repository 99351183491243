/* eslint-disable */
import { useState, useEffect, useContext, createRef, useRef } from "react";
import ReactDOM from "react-dom";
// kendo react modules ==========================
import "@progress/kendo-theme-material/dist/all.css";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";

// impoting excel colums
import {
  ExcelExport,
  ExcelExportColumn,
} from "@progress/kendo-react-excel-export";

//import translation function for language translation
import { useTranslation } from "react-i18next";

//import api requiremnts
import * as url from "urlhelpers/UrlHelper";
import { getData } from "utils/Gateway";

import { formatUtcDate } from "helpers/DateCalculation";

/* importing questions data */
// import questions from "../../data/Questions.json";
import { Link } from "react-router-dom";

//import context
import { GlobalProvider } from "context/GlobalContext";

import ActionLogHeader from "./Header/ActionLogHeader";
import ActionLogBreadCrumb from "./BreadCrumb/ActionLogBreadCrumb";
import ActionLogFilter from "./Filter/ActionLogFilter";

const ActionLogList = () => {
  const {
    reloadActionLogList,
    setreloadActionLogList,
    screenHeight,
    filterActionLogDates,
    setfilterActionLogDates,
  } = useContext(GlobalProvider);

  const todayValue = new Date().toISOString().split("T")[0];

  const { t } = useTranslation(); //for translation
  const token = localStorage.getItem("token");
  // without pagination ------------------------------------
  const [actionLogs, setActionLogs] = useState([]);
  const [responseReceived, setResponseReceived] = useState(false);

  /* useState hook to download excel file */
  const [downloadExcel, setDownloadExcel] = useState(false);
  /* hook to get number of records */
  const [records, setRecords] = useState(0);

  const initialDataState = {};
  const [dataState, setDataState] = useState();
  const [result, setResult] = useState(process(actionLogs, initialDataState));

  /* hook to search */
  const [search, setSearch] = useState("");

  const blockRefSelected = useRef(null); // this will be used to acccess dom element directly

  /* function for checking record count */
  const checkedRecordCount = (selectCount) => {
    const blockElementSelect = ReactDOM.findDOMNode(blockRefSelected.current);
    if (blockElementSelect) {
      blockElementSelect.innerHTML = selectCount;
    }
  };

  // check the selected count and show the count in top and also selected download xls ------------------------
  const handelSelectedCount = () => {
    let checkboxes = document.querySelectorAll(
      'input[name="selectActionsRecord"]'
    );

    let totalSelected = 0;
    let filteredData = [];
    checkboxes.forEach((checkbox) => {
      if (checkbox.checked) {
        totalSelected++;
        const filteredUser = actionLogs.find(
          (action) => action._id === checkbox.value
        );
        filteredUser && filteredData.push(filteredUser);
      }
    });

    // Update the content of the block
    checkedRecordCount(totalSelected);
    return filteredData;
  };

  const onDataStateChange = (event) => {
    console.log("event -->", event);
    setDataState(event.dataState);
    setResult(process(actionLogs, event.dataState));
  };
  /* Handle the button's onClick event and set it to export the data by using the ExcelExport save method. */
  const _exporter = createRef();

  const exportExcel = () => {
    if (_exporter.current) {
      _exporter.current.save();
    }
  };

  /* function to search */
  const filterData = () => {
    if (!result || !result.data) {
      return []; // Return an empty array if result or result.data is undefined
    }

    const filteredData = result.data.filter((item) => {
      const fullname = item?.fullname?.toLowerCase() || "";
      const email = item?.email?.toLowerCase() || "";
      const module = item?.module?.toLowerCase() || "";
      const url = item?.url?.toLowerCase() || "";
      const action = item?.action?.toLowerCase() || "";
      const ipaddress = item?.ipaddress?.toLowerCase() || "";
      const systeminfo = item?.systeminfo?.toLowerCase() || "";
      const actiondateformated = item?.actiondateformated?.toLowerCase() || "";

      return (
        (fullname && fullname.includes(search.toLowerCase())) ||
        (email && email.includes(search.toLowerCase())) ||
        (module && module.includes(search.toLowerCase())) ||
        (url && url.includes(search.toLowerCase())) ||
        (action && action.includes(search.toLowerCase())) ||
        (ipaddress && ipaddress.includes(search.toLowerCase())) ||
        (systeminfo && systeminfo.includes(search.toLowerCase())) ||
        (actiondateformated &&
          actiondateformated.includes(search.toLowerCase()))
      );
    });

    return filteredData;
  };

  // mark all checkbox as selected ----------------
  const selectAllRecords = (e) => {
    document
      .querySelectorAll('.k-master-row input[name="selectActionsRecord"]')
      .forEach((checkbox) => {
        checkbox.checked = e.target.checked;
      });
    handelSelectedCount();
  };

  // blocks to replace the Grid HTML ==============================================
  const SelectRecordHeader = () => {
    return (
      <div className="d-flex align-items-center">
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            id="selectAllMain"
            name="selectAllActionsRecord"
            onClick={selectAllRecords}
          />
        </div>
      </div>
    );
  };

  /* Select single row */
  const SelectRecordRow = (props) => {
    return (
      <td className="form-check h-auto">
        <input
          className="form-check-input "
          type="checkbox"
          value={props.dataItem._id}
          name="selectActionsRecord"
          onClick={handelSelectedCount}
        />
      </td>
    );
  };

  //function for get all action logs
  const getAllActionLogs = async (e) => {
    try {
      let requestUrl =
        url.API_BASEURL + url.API_GET_ACTION_LOGS + `?token=${token}`;

      if (filterActionLogDates && filterActionLogDates !== "") {
        requestUrl += `&filterdates=${filterActionLogDates}`;
      } else {
        requestUrl += `&filterdates=${todayValue}`;
      }

      console.log("action log url =====>>>", requestUrl);

      const response = await getData(requestUrl);

      console.log("action logs response ========>>>", response);

      if (response.data) {
        setActionLogs(response.data);
        setResponseReceived(true);
        dataState
          ? setResult(process(response.data, dataState))
          : setResult(process(response.data, initialDataState));
        setRecords(response.data.length);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  // Call filterData and update the state separately
  useEffect(() => {
    const filteredData = filterData();
    setRecords(filteredData.length);
  }, [search]);

  /* hook to hit  exportExcel() function*/
  useEffect(() => {
    downloadExcel && exportExcel();
  }, [downloadExcel]);

  useEffect(() => {
    if (responseReceived === false) {
      getAllActionLogs();
    }
  }, [responseReceived]);

  useEffect(() => {
    if (reloadActionLogList) {
      getAllActionLogs();
      setreloadActionLogList(false);
      setResult(process(actionLogs, initialDataState));
    }
  }, [reloadActionLogList]);

  /*------ use effect for search -------*/
  useEffect(() => {
    if (filterActionLogDates != "") {
      getAllActionLogs();
    }
  }, [filterActionLogDates]);

  /* hook to hit filterData function on search */
  useEffect(() => {
    filterData();
  }, [search]);

  /* set records based on onDataStateChange */
  useEffect(() => {
    setRecords(result.data.length);
  }, [result]);

  return (
    <div className="tab-content" id="surveyTabContent">
      <div className="container-fluid px-4">
        <ActionLogBreadCrumb />
        <ActionLogHeader
          setSearch={setSearch}
          setDownloadExcel={setDownloadExcel}
          records={records}
          blockRefSelected={blockRefSelected}
        />

        <ActionLogFilter />

        <div className="table-responsive">
          {/* ============ download xls start ========== */}
          <ExcelExport
            data={
              handelSelectedCount().length > 0
                ? handelSelectedCount()
                : actionLogs
            }
            fileName="ActionLogs.xlsx"
            ref={_exporter}
          >
            <ExcelExportColumn field="fullname" title={t("User")} />
            <ExcelExportColumn field="email" title={t("Email")} />
            <ExcelExportColumn field="module" title={t("Module")} />
            <ExcelExportColumn field="action" title={t("Action")} />
            <ExcelExportColumn field="ipaddress" title={t("Ip Address")} />
            <ExcelExportColumn field="url" title={t("Url")} />
            <ExcelExportColumn
              field="systeminfo"
              title={t("System Information")}
            />
            <ExcelExportColumn field="actiondateformated" title={t("Date")} />
          </ExcelExport>
          {/* ============ download xls end ========== */}

          {/* ========= kendo react grid coloumn start ======= */}
          {search ? (
            <Grid
              style={{ height: screenHeight - 50 }}
              data={filterData()}
              filterable={true}
              sortable={true}
              groupable={true}
              onDataStateChange={onDataStateChange}
              {...dataState}
              //pageable = {true}
              //total = {products.length}
              reorderable
              // resizable
            >
              <GridColumn
                field="id"
                headerCell={SelectRecordHeader}
                cell={SelectRecordRow}
                filterable={false}
                sortable={false}
                width="80px"
              />
              <GridColumn field="fullname" title={t("User")} width="280px" />
              <GridColumn field="email" title={t("Email")} width="280px" />
              <GridColumn field="module" title={t("Module")} width="300px" />
              <GridColumn field="action" title={t("Action")} width="280px" />
              <GridColumn
                field="ipaddress"
                title={t("Ip Address")}
                width="300px"
              />
              <GridColumn field="url" title={t("Url")} width="500px" />
              <GridColumn
                field="systeminfo"
                title={t("System Information")}
                width="500px"
              />
              <GridColumn
                field="actiondateformated"
                title={t("Date")}
                width="280px"
              />
            </Grid>
          ) : (
            <Grid
              style={{ height: screenHeight - 50 }}
              data={result}
              filterable={true}
              sortable={true}
              groupable={true}
              onDataStateChange={onDataStateChange}
              {...dataState}
              //pageable = {true}
              //total = {products.length}
              reorderable
              // resizable
            >
              <GridColumn
                field="id"
                headerCell={SelectRecordHeader}
                cell={SelectRecordRow}
                filterable={false}
                sortable={false}
                width="80px"
              />
              <GridColumn field="fullname" title={t("User")} width="280px" />
              <GridColumn field="email" title={t("Email")} width="280px" />
              <GridColumn field="module" title={t("Module")} width="300px" />
              <GridColumn field="action" title={t("Action")} width="280px" />
              <GridColumn
                field="ipaddress"
                title={t("Ip Address")}
                width="300px"
              />
              <GridColumn field="url" title={t("Url")} width="500px" />
              <GridColumn
                field="systeminfo"
                title={t("System Information")}
                width="500px"
              />
              <GridColumn
                field="actiondateformated"
                title={t("Date")}
                width="280px"
              />
            </Grid>
          )}

          {/* ========= kendo react grid coloumn end ======= */}
        </div>
      </div>
    </div>
  );
};

export default ActionLogList;
