/* eslint-disable */
import React, { useContext } from "react";
import { Link } from "react-router-dom";

//import translation function for language translation
import { useTranslation } from "react-i18next";

//import constext
import { GlobalProvider } from "context/GlobalContext";

const AgendaBreadCrumb = () => {
  const { agendaWaitning } = useContext(GlobalProvider);

  // const glowCount = 5;

  const { t } = useTranslation(); //for translation

  return (
    <nav aria-label="breadcrumb">
      <ul className="breadcrumb">
        <li className="breadcrumb-item">
          <Link to="/" className="text-secondary">
            {t("Funbrain")}
          </Link>
        </li>
        <li className="breadcrumb-item text-gray-600" aria-current="page">
          {t("Agenda")}
        </li>

        {agendaWaitning ? (
          <div className="d-flex align-items-center justify-content-center gap-2 mx-auto">
            <b className="text-primary">{t("Please wait for a while")}</b>
            <p className="placeholder-glow" style={{ width: "300px" }}>
              <span className="d-block placeholder"></span>
            </p>
          </div>
        ) : null}
      </ul>
    </nav>
  );
};

export default AgendaBreadCrumb;
