/* eslint-disable */
import React, { useEffect, useState } from "react";

import LeftMenu from "components/common/LeftMenu";
import UserMenu from "components/common/UserMenu";

import ActionLogComponent from "components/ActionLogComponent/ActionLogList";
import CustomNotification from "components/common/CustomNotification";

const ActionLogs = () => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  useEffect(() => {
    document.title = "Action logs";
  }, []);

  return (
    <>
      {/* ------------- menu section start ------------- */}
      <UserMenu />
      <LeftMenu />
      {/* ------------- menu section end ------------- */}

      {userInfo?.role?.name == "admin" || userInfo?.role?.name == "manager" ? (
        <section className="sales-order-wrapper pt-2">
          {/* ---------- ActionLogComponent start ---------------- */}
          <ActionLogComponent />
          {/* ---------- ActionLogComponent end ---------------- */}
        </section>
      ) : null}

      {/*----------- custom notification component start -----------*/}
      <CustomNotification />
      {/*----------- custom notification component end -----------*/}
    </>
  );
};

export default ActionLogs;
