/* eslint-disable */
import React, { useEffect, useContext, useState } from "react";
import Select from "react-select";

//import translation function for language translation
import { useTranslation } from "react-i18next";

/*import url and gateway methods */
import { getData, postData, putData } from "utils/Gateway";
import * as url from "urlhelpers/UrlHelper";

//import context
import { GlobalProvider } from "context/GlobalContext";

const ViewUpdateCostPopup = () => {
  const token = localStorage.getItem("token"); // token
  const { t } = useTranslation(); //for translation

  const {
    setReloadCostList,
    editCostData,
    setEditCostData,
    setSuccess,
    setError,
    setNotificationString,
  } = useContext(GlobalProvider);

  /* hooks for inputs */
  const [userInputs, setUserInputs] = useState({});

  const [selectedConcepts, setSelectedConcepts] = useState([]);

  const [selectedConceptValue, setSelectedConceptValue] = useState(null);

  const [conceptList, setConceptList] = useState([]);

  const [updateTotalTime, setUpdateTotalTime] = useState(false);

  /* concept selection */
  const getAllConcepts = async () => {
    try {
      let requestUrl =
        url.API_BASEURL + url.API_GET_ALL_CONCEPT + `?token=${token}`;

      const response = await getData(requestUrl);

      console.log("concept list response ==>", response);

      if (response.status) {
        setConceptList(response.data);
      }
    } catch (error) {}
  };

  // select multi concept handeler --------------------------
  const conceptSelectionHandler = (val) => {
    setSelectedConceptValue(val);

    setUserInputs((prevState) => ({
      ...prevState,
      concept: val.map((scope) => scope.value),
    }));
  };

  // useEffect(() => {
  //   if (updateTotalTime == true) {
  //     handelTotalTime();
  //   }
  // }, [updateTotalTime]);

  // calculate total time ----------------------------------
  const handelTotalTime = () => {
    console.log(parseInt(userInputs.preparationtime));
    console.log(userInputs);

    let sumTime =
      (isNaN(parseInt(userInputs.arrivaltime))
        ? 0
        : parseInt(userInputs.arrivaltime)) +
      (isNaN(parseInt(userInputs.returntime))
        ? 0
        : parseInt(userInputs.returntime)) +
      (isNaN(parseInt(userInputs.preparationtime))
        ? 0
        : parseInt(userInputs.preparationtime)) +
      (isNaN(parseInt(userInputs.mountingtime))
        ? 0
        : parseInt(userInputs.mountingtime)) +
      (isNaN(parseInt(userInputs.dismantletime))
        ? 0
        : parseInt(userInputs.dismantletime)) +
      (isNaN(parseInt(userInputs.additionaltime))
        ? 0
        : parseInt(userInputs.additionaltime)) +
      (isNaN(parseInt(userInputs.minutes)) ? 0 : parseInt(userInputs.minutes));

    setUserInputs((prevState) => ({
      ...prevState,
      totaltime: sumTime,
    }));

    setUpdateTotalTime(false);
  };

  const validate = () => {
    return true;
  };

  const resetHandler = () => {
    setSelectedConceptValue(null);
    setUserInputs({});
    setEditCostData(null);
  };

  /* function to add label */
  const saveCostHandler = async (e) => {
    e.preventDefault();

    if (validate()) {
      try {
        console.log("User input data------->", userInputs);

        let requestUrl =
          url.API_BASEURL +
          url.API_UPDATE_COST +
          `/${editCostData._id}` +
          `?token=${token}`;

        const response = await putData(requestUrl, userInputs);

        console.log("cost update response", response);

        if (response.status) {
          resetHandler();
          setReloadCostList(true);
          setSuccess(true);
          setNotificationString(t("Reservation cost added successfully"));
        }
      } catch (error) {
        console.error(error.message);
        setError(true);
        setNotificationString("Something went wrong, please try again later");
      }

      bootstrap.Offcanvas.getInstance(
        document.querySelector("#viewUpdateCostPopup")
      ).hide();
    }
  };

  // set update data, all concept values and total time ---
  useEffect(() => {
    if (editCostData) {
      console.log(editCostData);
      setUserInputs(editCostData);

      setUpdateTotalTime(true);

      // all concept in list
      getAllConcepts();

      // set pre selected values in react select
      if (editCostData.concept && editCostData.concept.length > 0) {
        conceptSelectionHandler(editCostData.concept);
      } else {
        conceptSelectionHandler([]);
      }
    }
  }, [editCostData]);

  return (
    <div
      className="offcanvas offcanvas-end border-0"
      tabIndex="-1"
      id="viewUpdateCostPopup"
      aria-labelledby="viewUpdateCostPopupLabel"
    >
      <div className="offcanvas-header align-items-start p-4">
        <h3 className="text-secondary mb-0" id="addCostPopupLabel">
          {t("Reservation Cost")}
        </h3>
        <button
          type="button"
          className="btn-close p-2 bg-white shadow-none shadow-sm m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={resetHandler}
        ></button>
      </div>
      <div className="offcanvas-body p-4 pt-0">
        <ul className="list_stye_none d-flex flex-column gap-2 mb-4">
          <li>
            <span className="text-gray">{t("Educator")}:</span>
            <span className="text-dark"> {editCostData?.educatorname}</span>
          </li>
          <li>
            <span className="text-gray">{t("Client")}:</span>
            <span className="text-dark"> {editCostData?.clientname}</span>
          </li>
          <li>
            <span className="text-gray">{t("Date")}:</span>
            <span className="text-dark"> {editCostData?.activitydate}</span>
          </li>
          <li>
            <span className="text-gray">{t("Time")}:</span>
            <span>
              <span className="text-dark"> {editCostData?.starttime}</span>{" "}
              <span className="text-gray"> - </span>{" "}
              <span className="text-dark"> {editCostData?.endtime}</span>
            </span>
          </li>
          <li>
            <span className="text-gray">{t("Activity")}:</span>
            <span className="text-dark"> {editCostData?.activityname}</span>
          </li>
          <li>
            <span className="text-gray">{t("Reservation")} Id:</span>
            <span className="text-dark"> {editCostData?.customreserveid}</span>
          </li>
        </ul>
        <div className="form-container p-4 bg-gray-500 border border-gray rounded-8">
          <form onSubmit={saveCostHandler}>
            {/* ------- arrival time section start ------- */}
            <div className="form-group mb-4">
              <div className="row">
                <div className="col-lg-6 mb-4 mb-lg-0">
                  <label htmlFor="arrivalTime">
                    {t("Arrival Time")} ({t("minutes")})
                  </label>
                  <p className="d-flex align-items-center gap-1 mb-2">
                    <span className="material-symbols-outlined">home</span>
                    <span>{userInputs.startzipcode}</span>
                    <span className="material-icons-outlined ms-2 me-2">
                      keyboard_double_arrow_right
                    </span>

                    <span className="material-symbols-outlined">
                      location_on
                    </span>

                    <span>{userInputs.endzipcode}</span>
                  </p>
                </div>
                <div className="col-lg-6">
                  <input
                    type="number"
                    className="form-control bg-white"
                    placeholder={t("Enter Time")}
                    value={userInputs.arrivaltime}
                    onChange={(val) => {
                      setUserInputs((prevState) => ({
                        ...prevState,
                        arrivaltime: val.target.value,
                      }));

                      setUpdateTotalTime(true);
                    }}
                  />
                </div>
              </div>
            </div>
            {/* ------- arrival time section end ------- */}

            {/* ------- return time section start ------- */}
            <div className="form-group mb-4">
              <div className="row">
                <div className="col-lg-6 mb-4 mb-lg-0">
                  <label htmlFor="arrivalTime">
                    {t("Return Time")} ({t("minutes")})
                  </label>
                  <p className="d-flex align-items-center gap-1 mb-2">
                    <span className="material-symbols-outlined">
                      location_on
                    </span>
                    <span>{userInputs.endzipcode}</span>
                    <span className="material-icons-outlined  ms-2 me-2">
                      keyboard_double_arrow_right
                    </span>
                    <span className="material-symbols-outlined">home</span>
                    <span>{userInputs.startzipcode}</span>
                  </p>
                </div>
                <div className="col-lg-6">
                  <input
                    type="number"
                    className="form-control bg-white"
                    placeholder={t("Add Time")}
                    value={userInputs.returntime}
                    onChange={(val) => {
                      setUserInputs((prevState) => ({
                        ...prevState,
                        returntime: val.target.value,
                      }));

                      setUpdateTotalTime(true);
                    }}
                  />
                </div>
              </div>
            </div>
            {/* ------- return time section end ------- */}

            {/* ----- activity time start ------ */}
            <div className="form-group mb-4">
              <div className="row">
                <div className="col-lg-6 mb-4 mb-lg-0">
                  <label htmlFor="arrivalTime">
                    {t("Activity Time")} ({t("minutes")})
                  </label>
                </div>
                <div className="col-lg-6">
                  <input
                    type="number"
                    className="form-control bg-white"
                    placeholder={t("Add Time")}
                    value={userInputs.activitytime}
                    onChange={(val) => {
                      setUserInputs((prevState) => ({
                        ...prevState,
                        activitytime: val.target.value,
                      }));

                      setUpdateTotalTime(true);
                    }}
                  />
                </div>
              </div>
            </div>
            {/* ----- activity time end ------ */}

            {/* ------- preparation time section start ------- */}
            <div className="form-group mb-4">
              <div className="row">
                <div className="col-lg-6 mb-4 mb-lg-0">
                  <label htmlFor="arrivalTime">
                    {t("Preparation time")} ({t("minutes")})
                  </label>
                </div>
                <div className="col-lg-6">
                  <input
                    type="number"
                    className="form-control bg-white"
                    placeholder={t("Add Time")}
                    value={userInputs.preparationtime}
                    onChange={(val) => {
                      setUserInputs((prevState) => ({
                        ...prevState,
                        preparationtime: val.target.value,
                      }));

                      setUpdateTotalTime(true);
                    }}
                  />
                </div>
              </div>
            </div>
            {/* ------- preparation time section end ------- */}

            {/* ------- Mounting time section start ------- */}
            <div className="form-group mb-4">
              <div className="row">
                <div className="col-lg-6 mb-4 mb-lg-0">
                  <label htmlFor="arrivalTime">
                    {t("Mounting time")} ({t("minutes")}){" "}
                  </label>
                </div>
                <div className="col-lg-6">
                  <input
                    type="number"
                    className="form-control bg-white"
                    placeholder={t("Add Time")}
                    value={userInputs.mountingtime}
                    onChange={(val) => {
                      setUserInputs((prevState) => ({
                        ...prevState,
                        mountingtime: val.target.value,
                      }));

                      setUpdateTotalTime(true);
                    }}
                  />
                </div>
              </div>
            </div>
            {/* ------- Mounting time section end ------- */}

            {/* ------- Dismantle time section start ------- */}
            <div className="form-group mb-4">
              <div className="row">
                <div className="col-lg-6 mb-4 mb-lg-0">
                  <label htmlFor="arrivalTime">
                    {t("Dismantle time")} ({t("minutes")})
                  </label>
                </div>
                <div className="col-lg-6">
                  <input
                    type="number"
                    className="form-control bg-white"
                    placeholder={t("Add Time")}
                    value={userInputs.dismantletime}
                    onChange={(val) => {
                      setUserInputs((prevState) => ({
                        ...prevState,
                        dismantletime: val.target.value,
                      }));
                      setUpdateTotalTime(true);
                    }}
                  />
                </div>
              </div>
            </div>
            {/* ------- Dismantle time section start ------- */}

            {/* ------- Gap time till next reserve ------- */}
            <div className="form-group mb-4">
              <div className="row">
                <div className="col-lg-6 mb-4 mb-lg-0">
                  <label htmlFor="arrivalTime">
                    {t("Gap time")} ({t("minutes")})
                  </label>
                </div>
                <div className="col-lg-6">{userInputs.gaptime}</div>
              </div>
            </div>
            {/* ------- gap time ends ------- */}

            {/* ------- Office arrival time ------- */}
            <div className="form-group mb-4">
              <div className="row">
                <div className="col-lg-6 mb-4 mb-lg-0">
                  <label htmlFor="arrivalTime">
                    {t("Office arrival time")} ({t("minutes")})
                  </label>
                  <label htmlFor="arrivalTime">
                    <i>{t("Discount from total")}</i>
                  </label>
                </div>
                <div className="col-lg-6">
                  <input
                    type="number"
                    className="form-control bg-white"
                    placeholder={t("Add Time")}
                    value={userInputs.officearrivaltime}
                    onChange={(val) => {
                      setUserInputs((prevState) => ({
                        ...prevState,
                        officearrivaltime: val.target.value,
                      }));
                      setUpdateTotalTime(true);
                    }}
                  />
                </div>
              </div>
            </div>
            {/* ------- Office arrival time ------- */}

            {/* ------- Office return time ------- */}
            <div className="form-group mb-4">
              <div className="row">
                <div className="col-lg-6 mb-4 mb-lg-0">
                  <label htmlFor="arrivalTime">
                    {t("Office return time")} ({t("minutes")})
                  </label>
                  <label htmlFor="arrivalTime">
                    <i>{t("Discount from total")}</i>
                  </label>
                </div>
                <div className="col-lg-6">
                  <input
                    type="number"
                    className="form-control bg-white"
                    placeholder={t("Add Time")}
                    value={userInputs.officereturntime}
                    onChange={(val) => {
                      setUserInputs((prevState) => ({
                        ...prevState,
                        officereturntime: val.target.value,
                      }));
                      setUpdateTotalTime(true);
                    }}
                  />
                </div>
              </div>
            </div>
            {/* ------- Office return time ------- */}

            {/* ------- concept and addtime section start ------- */}
            <div className="form-group mb-4">
              <div className="row">
                <div className="col-lg-6 mb-4 mb-lg-0">
                  <label htmlFor="addTime">{t("Add Concept")}</label>
                  <Select
                    className="nosearch_select"
                    placeholder={t("Select concepts")}
                    isMulti
                    options={conceptList}
                    value={selectedConceptValue}
                    onChange={(val) => {
                      conceptSelectionHandler(val);
                    }}
                  />
                </div>
                <div className="col-lg-6">
                  <label htmlFor="discountTime">{t("Add Time")}</label>
                  <input
                    type="number"
                    className="form-control bg-white"
                    placeholder={t("Add Time")}
                    value={userInputs.additionaltime}
                    onChange={(val) => {
                      setUserInputs((prevState) => ({
                        ...prevState,
                        additionaltime: val.target.value,
                      }));

                      setUpdateTotalTime(true);
                    }}
                  />
                </div>
              </div>
            </div>
            {/* ------- concept and addtime section end ------- */}
            <div className="form-group mb-4">
              <div className="row">
                <div className="col-lg-6 mb-4 mb-lg-0">
                  <h5 className="mb-0">{t("Total Time")}:</h5>
                </div>
                <div className="col-lg-6 mb-4 mb-lg-0">
                  <h5 className="mb-0">
                    {userInputs.totaltime} ({t("minutes")})
                  </h5>
                </div>
              </div>
            </div>
            <div className="action d-flex align-items-center flex-wrap gap-3 justify-content-between">
              {/* ------- total time section start ------- */}

              {/* ------- total time section end ------- */}

              {/* ------- send button start ------- */}
              <button type="submit" className="btn btn-primary mw-unset">
                <span className="d-block material-symbols-outlined icon-fill">
                  send
                </span>
              </button>
              {/* ------- send button end ------- */}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ViewUpdateCostPopup;
