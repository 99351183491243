/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";

//import translation function for language translation
import { useTranslation } from "react-i18next";

/*import url and gateway methods */
import { postData, getData, putData } from "utils/Gateway";
import * as url from "urlhelpers/UrlHelper";

//import context
import { GlobalProvider } from "context/GlobalContext";

//import time calculation
import {
  getTimeDifference,
  differenceToSeconds,
  displayFormatedTime,
  getCurrentTimeInString,
  sumOfTotalWorkingTimes,
} from "helpers/TimeCalculation";
import TimeStopWarningModal from "../Modals/TimeStopWarningModal";

const DailyTaskHeader = ({ setSearch, records, blockRefSelected }) => {
  const { setreloadTaskList, setSuccess, setError, setNotificationString } =
    useContext(GlobalProvider);

  const token = localStorage.getItem("token"); // token
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const { t } = useTranslation(); //for translation

  /* hook to get the search value */
  const [searchValue, setSearchValue] = useState("");

  const refreshHandler = () => {
    setreloadTaskList(true);
    setSuccess(true);
    setNotificationString(t("The list has been refreshed successfully"));
  };

  // timmer ----------------------------------------------------------------
  const [timer, setTimer] = useState(0);
  const [isWorking, setIsWorking] = useState(false);
  const [logId, setLogId] = useState(null);
  const [startTime, setStartTime] = useState("");
  const [workHour, setWorkHour] = useState("00.00.00");

  const getLogDetails = async () => {
    try {
      let requestUrl =
        url.API_BASEURL + url.API_GET_EMPLOYEE_LOG_DETAILS + `?token=${token}`;

      const response = await getData(requestUrl);

      console.log(response);
      if (response.status && response.data) {
        setLogId(response.data._id);
        if (response.data.timerunning == "1") {
          setIsWorking(true);

          setStartTime(response.data.starttime);

          const timeDiffenceResult = getTimeDifference(response.data.starttime);

          const timeDifferenceIntoSec = differenceToSeconds(timeDiffenceResult);
          setTimer(timeDifferenceIntoSec);
        } else {
          setIsWorking(false);
          setTimer(0);
          setStartTime(response.data.endtime);
        }

        setWorkHour(response.data.workhour);
      } else {
        setIsWorking(false);
        setTimer(0);
        setLogId(null);
        setStartTime("");
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  // when component refresh and user has been working already from a while
  // check if there is any entry for the day for this user, if yes - get the difference in seconds from now to start time
  // and setTime(Difference_in_seconds)

  // when user login and start the day
  const logStartingHandler = async () => {
    //
    setIsWorking(true);

    let timeData = {
      starttime: getCurrentTimeInString(),
    };

    try {
      let requestUrl =
        url.API_BASEURL + url.API_SAVE_EMPLOYEE_LOG + `?token=${token}`;

      const response = await postData(requestUrl, timeData);

      if (response.status) {
        getLogDetails();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  // when user about to logout and stop the day
  const logStopingHandler = async () => {
    // show the modal to confirm

    try {
      const endTime = getCurrentTimeInString();
      const timeDifferece = getTimeDifference(startTime);
      const totalWorkHours = sumOfTotalWorkingTimes(workHour, timeDifferece);

      let logData = {
        timerunning: "0",
        endtime: endTime,
        workhour: totalWorkHours,
      };

      // console.log(logData);

      let requestUrl =
        url.API_BASEURL +
        url.API_UPDATE_EMPLOYEE_LOG +
        `/${logId}?token=${token}`;

      const response = await putData(requestUrl, logData);

      // console.log("response in update log-----", response);

      if (response.status) {
        getLogDetails();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  // counter for timer on each second
  useEffect(() => {
    let intervalId;

    if (isWorking) {
      intervalId = setInterval(() => {
        setTimer((prevTimer) => prevTimer + 1);
      }, 1000);
    } else {
      clearInterval(intervalId);
    }

    return () => clearInterval(intervalId);
  }, [isWorking]);

  /* hook to empty search when searchValue gets empty */
  // useEffect(() => {
  //   !searchValue && setSearch("");
  // }, [searchValue]);

  useEffect(() => {
    getLogDetails();
  }, []);

  return (
    <div className="filter-container d-flex flex-wrap align-items-center gap-2 pb-3 border-bottom border-gray">
      {isWorking ? (
        <Link
          to="#"
          data-bs-toggle="modal"
          data-bs-target="#timeStopWarning"
          // onClick={logStopingHandler}
          className="filter-btn btn btn-danger mw-unset d-inline-flex align-items-center gap-1"
        >
          <span className="d-block material-symbols-outlined icon-fill fs-lg">
            stop_circle
          </span>
          <span className="d-block">{t("Stop Working")}</span>
        </Link>
      ) : (
        <Link
          to="#"
          onClick={logStartingHandler}
          className="filter-btn btn btn-success mw-unset d-inline-flex align-items-center gap-1"
        >
          <span className="d-block material-symbols-outlined icon-fill fs-lg">
            play_circle
          </span>
          <span className="d-block">{t("Start Working")}</span>
        </Link>
      )}

      <div className="search-container position-relative d-none d-md-block">
        <p>
          {t("Timer")}: {displayFormatedTime(timer)}
        </p>
      </div>
      {/* ------------- showing records start ----------------- */}
      <p className="text-gray fw-medium ms-auto me-3">
        {t("Showing")}
        <span className="text-black ms-1">{records}</span> {t("Records")} -
        <span className="text-black ms-1" ref={blockRefSelected}>
          {" "}
          0{" "}
        </span>{" "}
        {t("Selected")}
      </p>
      {/* ------------- showing records end ----------------- */}

      {/* <Link
        to="#columnCollapse"
        className="btn btn-primary mw-unset p-2"
        data-bs-toggle="collapse"
        role="button"
        aria-expanded="false"
        aria-controls="columnCollapse"
      >
        <span className="d-block material-symbols-outlined icon-fill">
          summarize
        </span>
      </Link> */}

      {/* ---------- refresh button start -------- */}
      <Link
        to="#"
        className="filter-btn btn btn-primary icon-btn mw-unset"
        onClick={refreshHandler}
      >
        <span
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title={t("Refresh")}
          className="d-block material-symbols-outlined"
        >
          refresh
        </span>
      </Link>
      {/* ---------- refresh button end -------- */}

      <Link
        to="#filterCollapse"
        className="filter-btn btn btn-primary icon-btn mw-unset"
        data-bs-toggle="collapse"
        role="button"
        aria-expanded="false"
        aria-controls="filterCollapse"
      >
        <span
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title={t("Filter")}
          className="d-block material-symbols-outlined"
        >
          filter_alt
        </span>
      </Link>

      <div className="dropdown">
        <button
          className="btn btn-primary dropdown-toggle mw-unset"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          {t("Actions")}
        </button>
        <ul className="dropdown-menu border-0 shadow-lg">
          <li>
            <Link
              className="dropdown-item"
              to="#tags_pnl"
              data-bs-toggle="offcanvas"
            >
              {t("Add Label")}
            </Link>
          </li>
        </ul>
      </div>

      <TimeStopWarningModal onConfirm={logStopingHandler} />
    </div>
  );
};

export default DailyTaskHeader;
