import React from "react";
import { Link } from "react-router-dom";

//import translation function for language translation
import { useTranslation } from "react-i18next";

const DashboardCards = (props) => {
  const { t } = useTranslation(); //for translation

  return (
    <div className="col-lg-4 col-sm-6">
      <div className="dashboard_bxItem">
        <div className="bx_pic">
          <img src={props?.cardImage} alt="img" />
        </div>
        <div className="bx_info">
          <h3>{props?.cardTitle}</h3>
          <Link to={props?.navigateTo} className="btn btn-primary">
            {t("Open Now")}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default DashboardCards;
