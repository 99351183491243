import InstallationsBody from 'components/InstallationsComponents/InstallationsBody';
import InstallationsNav from 'components/InstallationsComponents/InstallationsNav';
import SaveInstallationsPopup from 'components/InstallationsComponents/SaveInstallationsPopup';
import LeftMenu from 'components/common/LeftMenu';
import UserMenu from 'components/common/UserMenu';
import React from 'react';

const Installations = () => {
  /* useState hook to download excel file */
  const [downloadExcel, setDownloadExcel] = React.useState(false);
  React.useEffect(() => {
    document.title = 'Installations'; // Update the title when Register component mounts
  }, []);
  return (
    <>
      <UserMenu />
      <LeftMenu />
      <section className="mail-wrapper py-4">
        <div className="container-fluid px-4">
          <InstallationsNav setDownloadExcel={setDownloadExcel} />
          <InstallationsBody downloadExcel={downloadExcel} />
        </div>
      </section>

      <SaveInstallationsPopup />
    </>
  );
};

export default Installations;
