/* eslint-disable */
import React, { useEffect, useState, useRef } from "react";
import ReactDOM from "react-dom";

import LeftMenu from "components/common/LeftMenu";
import UserMenu from "components/common/UserMenu";
import CustomNotification from "components/common/CustomNotification";
import AddLabel from "components/common/AddLabel";
import DailyTaskFilter from "components/DailyTaskComponents/Filter/DailyTaskFilter";
import EmployeeLogList from "components/EmployeeLogComponents/EmployeeLogList/EmployeeLogList";
import EmployeeLogHeader from "components/EmployeeLogComponents/Header/EmployeeLogHeader";
import EmployeeLogBreadCrumb from "components/EmployeeLogComponents/BreadCrumb/EmployeeLogBreadCrumb";
import EmployeeLogFilter from "components/EmployeeLogComponents/Filter/EmployeeLogFilter";

const EmployeeLogs = () => {
  /* useState hook to download excel file */
  const [downloadExcel, setDownloadExcel] = useState(false);

  /* hook to search */
  const [search, setSearch] = useState("");
  /* hook to get number of records */
  const [records, setRecords] = useState(0);

  const blockRefSelected = useRef(null); // this will be used to acccess dom element directly

  /* function for checking record count */
  const checkedRecordCount = (selectCount) => {
    const blockElementSelect = ReactDOM.findDOMNode(blockRefSelected.current);
    if (blockElementSelect) {
      blockElementSelect.innerHTML = selectCount;
    }
  };

  useEffect(() => {
    document.title = "Employee Logs"; // Update the title when Login component mounts
  }, []);

  return (
    <>
      <UserMenu />
      <LeftMenu />

      <section className="mail-wrapper py-4">
        <div className="container-fluid px-4">
          <EmployeeLogBreadCrumb />
          <EmployeeLogHeader
            setDownloadExcel={setDownloadExcel}
            setSearch={setSearch}
            records={records}
            blockRefSelected={blockRefSelected}
          />

          <EmployeeLogFilter />
          <EmployeeLogList
            downloadExcel={downloadExcel}
            search={search}
            setRecords={setRecords}
            checkedRecordCount={checkedRecordCount}
          />
        </div>
      </section>

      <AddLabel module={"employeelogs"} />

      {/*----------- custom notification component start -----------*/}
      <CustomNotification />
      {/*----------- custom notification component end -----------*/}
    </>
  );
};

export default EmployeeLogs;
