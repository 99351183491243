//function for get current time
const getCurrentTime = () => {
  const now = new Date();
  const hours = String(now.getHours()).padStart(2, "0");
  const minutes = String(now.getMinutes()).padStart(2, "0");
  const timenow = `${hours}:${minutes}`;
  return timenow;
};

//function for calculate and get end time
const getEndTime = (duration, startingTime) => {
  const additionalTime = parseInt(duration, 10);

  let timeParts = startingTime.split(":");
  let hours = parseInt(timeParts[0], 10);
  let minutes = parseInt(timeParts[1], 10);
  let timeDate = new Date();

  timeDate.setHours(hours);
  timeDate.setMinutes(minutes);

  // Calculate the result by adding the number to the time
  let resultDate = new Date(timeDate.getTime() + additionalTime * 60 * 1000);

  // Extract the individual components (hours, minutes) from the result date
  let resultHours = resultDate.getHours();
  let resultMinutes = resultDate.getMinutes();

  // Format the result as a string
  let newHour = resultHours.toString().padStart(2, "0");
  let newMinute = resultMinutes.toString().padStart(2, "0");

  const timeExtended = `${newHour}:${newMinute}`;

  return timeExtended;
};

//function for get time difference(employee log details helpers)
const getTimeDifference = (timeString) => {
  const [givenHours, givenMinutes] = timeString.split(".").map(Number);

  const now = new Date();
  const currentHours = now.getHours();
  const currentMinutes = now.getMinutes();
  const currentSeconds = now.getSeconds();

  let differenceHours = currentHours - givenHours;
  let differenceMinutes = currentMinutes - givenMinutes;

  if (differenceMinutes < 0) {
    differenceHours -= 1;
    differenceMinutes += 60;
  }

  const differenceSeconds = currentSeconds;

  const formattedDifference = `${
    differenceHours < 10 ? "0" + differenceHours : differenceHours
  }.${differenceMinutes < 10 ? "0" + differenceMinutes : differenceMinutes}.${
    differenceSeconds < 10 ? "0" + differenceSeconds : differenceSeconds
  }`;

  return formattedDifference;
};

//convert time duration into seconds
const differenceToSeconds = (timeString) => {
  const [hours, minutes, seconds] = timeString.split(".").map(Number);
  const totalSeconds = hours * 3600 + minutes * 60 + seconds;
  return totalSeconds;
};

// format current time for view purpose
const displayFormatedTime = (timeInSeconds) => {
  const hours = Math.floor(timeInSeconds / 3600);
  const minutes = Math.floor((timeInSeconds % 3600) / 60);
  const seconds = timeInSeconds % 60;

  return `${hours < 10 ? "0" + hours : hours}:${
    minutes < 10 ? "0" + minutes : minutes
  }:${seconds < 10 ? "0" + seconds : seconds}`;
};

//get current time in string eg=hh:mm:ss
const getCurrentTimeInString = () => {
  const now = new Date();
  const hours = now.getHours();
  const minutes = now.getMinutes();

  const formattedHours = hours < 10 ? `0${hours}` : hours;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

  const currentTimeString = `${formattedHours}.${formattedMinutes}`;

  return currentTimeString;
};

//sum of two time units
const sumOfTotalWorkingTimes = (time1, time2) => {
  const [hours1, minutes1, seconds1] = time1.split(".").map(Number);
  const [hours2, minutes2, seconds2] = time2.split(".").map(Number);

  let totalSeconds =
    (hours1 + hours2) * 3600 +
    (minutes1 + minutes2) * 60 +
    (seconds1 + seconds2);

  const hoursResult = Math.floor(totalSeconds / 3600);
  totalSeconds %= 3600;
  const minutesResult = Math.floor(totalSeconds / 60);
  const secondsResult = totalSeconds % 60;

  const formattedResult = `${
    hoursResult < 10 ? "0" + hoursResult : hoursResult
  }.${minutesResult < 10 ? "0" + minutesResult : minutesResult}.${
    secondsResult < 10 ? "0" + secondsResult : secondsResult
  }`;

  return formattedResult;
};

export {
  getCurrentTime,
  getEndTime,
  getTimeDifference,
  differenceToSeconds,
  displayFormatedTime,
  getCurrentTimeInString,
  sumOfTotalWorkingTimes,
};
