/* eslint-disable */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-select';

//import translation function for language translation
import { useTranslation } from 'react-i18next';

const CostToggleColumn = () => {
  const { t } = useTranslation(); //for translation
 const fieldOption = [
   { label: t("Educator"), value: "Educator" },
   { label: t("Date"), value: "Date" },
   { label: t("Reservation"), value: "Reservation" },
   { label: t("Activity"), value: "Activity" },
   { label: t("Client"), value: "Client" },
   { label: t("Location"), value: "Location" },
   { label: t("Zipcode"), value: "Zipcode" },
   { label: t("Start"), value: "Start" },
   { label: t("End"), value: "End" },
   { label: t("Activity time"), value: "Activity time" },
   { label: t("Arrival time"), value: "Arrival time" },
   { label: t("Off. Arrival time"), value: "Off. Arrival time" },
   { label: t("Return time"), value: "Return time" },
   { label: t("Off. Return time"), value: "Off. Return time" },
   { label: t("Preparation time"), value: "Preparation time" },
   { label: t("Mounting time"), value: "Mounting time" },
   { label: t("Dismantle time"), value: "Dismantle time" },
   { label: t("Gap time"), value: "Gap time" },
   { label: t("Concept time"), value: "Concept time" },
   { label: t("Total time"), value: "Total time" },
   { label: t("Time by date"), value: "Time by date" },
   { label: t("Cost per hour"), value: "Cost per hour" },
   { label: t("Cost by date"), value: "Cost by date" },
 ];

  const [field, setField] = useState(null);

  return (
    <div className="collapse mt-3" id="columnCollapse">
      <div className="p-3 bg-gray-200">
        <div className="d-flex gap-3 align-items-center justify-content-between mb-3">
          <h4 className="mb-0">{t('Show Columns')}</h4>
          <Link to="#" className="d-flex gap-1 align-items-center text-black">
            <span className="d-block material-symbols-outlined icon-md">
              sync
            </span>
            <span className="d-block fw-medium">{t('Refresh')}</span>
          </Link>
        </div>
        <form>
          <div className="form-container d-flex flex-wrap gap-2">
            <div className="form-group flex-fill">
              <Select
                className="nosearch_select"
                isMulti
                options={fieldOption}
                value={field}
                onChange={val => setField(val)}
              />
            </div>
            <div className="action">
              <button type="submit" className="btn btn-primary mw-unset">
                {t('Show')}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CostToggleColumn;
