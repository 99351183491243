/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
//import translation function for language translation
import { useTranslation } from "react-i18next";
/* Importing react select */
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
//import options from json file
import ReserveStatus from "data/Prod/ReserveStatus.json";
//import context
import { GlobalProvider } from "context/GlobalContext";
/*import url and gateway methods */
import { getData } from "utils/Gateway";
import * as url from "urlhelpers/UrlHelper";
const BudgetFilter = () => {
  const token = localStorage.getItem("token"); // token
  const { t } = useTranslation(); //for translation
  const todayValue = new Date().toISOString().split("T")[0];
  const {
    setReloadSalesOrderList,
    setSearchSalesOrderDate,
    setSearchSalesOrderDateType,
    setSearchSalesOrderClients,
    setSearchSalesOrderBudgetStatus,
    setSearchSalesOrderDiscount,
  } = useContext(GlobalProvider);
  const [selectedChecked, setSelectedChecked] = useState("");
  const [budgetStatusList, setBudgetStatusList] = useState([]);
  const [fromDate, setFromDate] = useState(todayValue);
  const [toDate, setToDate] = useState(todayValue);
  const [selectedDate, setSelectedDate] = useState(todayValue);
  const [clientList, setClientList] = useState([]); //client option
  const [dateSelectedValue, setDateSelectedValue] = useState(null);
  const [selectedClientValue, setSelectedClientValue] = useState(null);
  const [selectedClientIds, setSelectedClientIds] = useState([]);
  const [budgetStatusValue, setBudgetStatusValue] = useState(null);
  const [selectedBudgetStatus, setSelectedBudgetStatus] = useState([]);
  const [discountRate, setDiscountRate] = useState(0);
  //const get budgte status options
  const getBudgetStatusOptions = async () => {
    try {
      let requestUrl =
        url.API_BASEURL + url.API_GET_BUDGET_STATUS_LIST + `?token=${token}`;
      const response = await getData(requestUrl);
      if (response.status) {
        setBudgetStatusList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  /* function to get all client list */
  const getALlClientList = async () => {
    try {
      let clientRequestUrl =
        url.API_BASEURL +
        url.API_GET_ALL_USER +
        `?token=${token}&isselect=${true}&slug=client`;
      let benificiaryRequestUrl =
        url.API_BASEURL +
        url.API_GET_ALL_USER +
        `?token=${token}&isselect=${true}&slug=beneficiary`;
      const clientResponse = await getData(clientRequestUrl);
      const beneficiaryResponse = await getData(benificiaryRequestUrl);
      if (clientResponse.status && beneficiaryResponse.status) {
        let clientArr = [];
        clientArr = [...clientResponse.data, ...beneficiaryResponse.data];
        setClientList(clientArr);
      }
    } catch (error) {
      console.error(error.message);
    }
  };
  //option for selct date type
  const dateTypeOptions = [
    {
      label: t("Invoice Date"),
      value: "Invoice",
    },
    {
      label: t("Created date"),
      value: "Created",
    },
  ];
  //function for select client
  const clientSelectionHandler = (val) => {
    if (val) {
      setSelectedClientValue(val);
      setSelectedClientIds(val.map((client) => client.value));
    } else {
      setSelectedClientValue(null);
      setSelectedClientIds([]);
    }
  };
  //function for select budget status
  const budgetStatusSelectionHandler = (val) => {
    if (val) {
      setBudgetStatusValue(val);
      setSelectedBudgetStatus(val.map((item) => item.value));
    } else {
      setBudgetStatusValue(null);
      setSelectedBudgetStatus([]);
    }
  };
  //function for select radio
  const radioCheckHandler = (event) => {
    setSelectedChecked(event.target.value);
    setDateWarning(false);
    setDateWarning(false);
  };
  // Hook for date compare validation
  const [dateWarning, setDateWarning] = useState(false);
  //function for search
  const searchHandler = () => {
    /*===== switch statement for date input radio start ======*/
    switch (selectedChecked) {
      case "daterange":
        if (fromDate < toDate) {
          setSearchSalesOrderDate(fromDate + "," + toDate);
        } else {
          setDateWarning(true);
        }
        break;
      case "todayradio":
        setSearchSalesOrderDate(todayValue);
        break;
      case "salesdate":
        setSearchSalesOrderDate(selectedDate);
        break;
      default:
        setSearchSalesOrderDate("");
        break;
    }
    /*===== switch statement for date input radio end ======*/
    //assign data for search
    dateSelectedValue != null &&
      setSearchSalesOrderDateType(dateSelectedValue?.value);
    selectedClientIds.length > 0
      ? setSearchSalesOrderClients(selectedClientIds)
      : setSearchSalesOrderClients([]);
    selectedBudgetStatus.length > 0
      ? setSearchSalesOrderBudgetStatus(selectedBudgetStatus)
      : setSearchSalesOrderBudgetStatus([]);
    discountRate != 0 && setSearchSalesOrderDiscount(discountRate);
  };
  //function for reset
  const resetHandler = () => {
    setSelectedChecked("");
    setFromDate(todayValue);
    setToDate(todayValue);
    setSelectedDate(todayValue);
    setDateSelectedValue({
      label: "Invoice date",
      value: "Invoice",
    });
    setSelectedClientValue(null);
    setSelectedClientIds([]);
    setBudgetStatusValue(null);
    setSelectedBudgetStatus([]);
    setDiscountRate(0);
    setSearchSalesOrderDate("");
    setSearchSalesOrderDateType(null);
    setSearchSalesOrderClients([]);
    setSearchSalesOrderBudgetStatus([]);
    setSearchSalesOrderDiscount(0);
    setReloadSalesOrderList(true);
  };
  useEffect(() => {
    getALlClientList();
    getBudgetStatusOptions();
    setDateSelectedValue({
      label: "Invoice date",
      value: "Invoice",
    });
  }, []);
  return (
    <div className="collapse mt-3" id="filterbudgetCollapse">
      <div className="p-3 bg-gray-200">
        <h4 className="mb-3">{t("Filter")}</h4>
        {/* ------- search form section start ------- */}
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="form-group mb-3">
            <div className="row align-items-center">
              {/* -------- date range section start -------- */}
              <div className="col-lg mb-3 mb-lg-0">
                <div className="d-flex align-items-center">
                  {/* -------- date input radio section start -------- */}
                  <div className="form-check mb-0 me-3">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="daterange"
                      id="dateRange"
                      value="daterange"
                      checked={selectedChecked === "daterange"}
                      onChange={radioCheckHandler}
                    />
                  </div>
                  {/* -------- date input radio section end -------- */}
                  {/* -------- date selection section start -------- */}
                  <div className="field-container d-sm-flex border flex-fill">
                    {/* ------- from date input start ------- */}
                    <input
                      type="date"
                      id="from_date"
                      className="form-control bg-white border-0 mb-3 mb-sm-0"
                      placeholder={t("From date")}
                      value={fromDate}
                      onChange={(e) => {
                        setFromDate(e.target.value), setDateWarning(false);
                      }}
                    />
                    {/* ------- from date input end ------- */}
                    {/* ------- to date input start ------- */}
                    <input
                      type="date"
                      id="to_date"
                      className="form-control bg-white border-0"
                      placeholder={t("To date")}
                      value={toDate}
                      onChange={(e) => {
                        setToDate(e.target.value), setDateWarning(false);
                      }}
                    />
                    {/* ------- to date input end ------- */}
                  </div>
                  {/* -------- date selection section end -------- */}
                </div>
                {/* date compare warning */}
                {dateWarning && (
                  <div className="error-message mt-2">
                    <p className="d-flex align-items-center gap-1 text-danger">
                      <span className="material-symbols-outlined">warning</span>
                      <span>
                        {t("End date should be greater than start date")}!
                      </span>
                    </p>
                  </div>
                )}
              </div>
              {/* -------- date range section end -------- */}
              {/* --------- today section start --------- */}
              <div className="col-lg-auto mb-3 mb-lg-0">
                <div className="form-check mb-0">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="todayradio"
                    id="today"
                    value="todayradio"
                    checked={selectedChecked === "todayradio"}
                    onChange={radioCheckHandler}
                  />
                  <label className="form-check-label mb-0" htmlFor="today">
                    {t("Today")}
                  </label>
                </div>
              </div>
              {/* --------- today section end --------- */}
              {/* --------- date section start --------- */}
              <div className="col-lg">
                <div className="d-flex align-items-center">
                  <div className="form-check mb-0 me-3">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="salesdate"
                      id="multipleDates"
                      value="salesdate"
                      checked={selectedChecked === "salesdate"}
                      onChange={radioCheckHandler}
                    />
                  </div>
                  <input
                    type="date"
                    id="to_date"
                    className="form-control bg-white"
                    placeholder={t("Select Multiple Dates")}
                    value={selectedDate}
                    onChange={(e) => setSelectedDate(e.target.value)}
                  />
                </div>
              </div>
              {/* --------- date section end --------- */}
              {/* ------ select for create date or invoice date start ------- */}
              <div className="col-lg">
                <Select
                  placeholder={t("Select date type")}
                  options={dateTypeOptions}
                  value={dateSelectedValue}
                  onChange={(val) => {
                    setDateSelectedValue(val);
                  }}
                />
              </div>
              {/* ------ select for create date or invoice date start ------- */}
            </div>
          </div>
          {/* ------- clinet,grouped,discount and button section start ------- */}
          <div className="form-group mb-3">
            <div className="row">
              {/* ------ client selection section start -------- */}
              <div className="col-lg-3 mb-3 mb-lg-0">
                <div className="field-container position-relative">
                  <Select
                    className="nosearch_select"
                    placeholder={t("Select clients")}
                    isMulti
                    options={clientList}
                    value={selectedClientValue}
                    onChange={(val) => {
                      clientSelectionHandler(val);
                    }}
                  />
                </div>
              </div>
              {/* ------ client selection section end -------- */}
              {/* ------ group selection section start -------- */}
              <div className="col-lg-3 mb-3 mb-lg-0">
                <div className="field-container position-relative">
                  <Select
                    isMulti
                    placeholder={t("Select Budget Status")}
                    options={budgetStatusList}
                    value={budgetStatusValue}
                    onChange={(val) => {
                      budgetStatusSelectionHandler(val);
                    }}
                  />
                </div>
              </div>
              {/* ------ group selection section end -------- */}
              {/* ------ discount selection section start -------- */}
              <div className="col-lg-3">
                <div className="field-container position-relative">
                  <input
                    type="number"
                    className="form-control bg-white"
                    placeholder={t("Discount")}
                    value={discountRate}
                    onChange={(e) => setDiscountRate(e.target.value)}
                  />
                </div>
              </div>
              {/* ------ discount selection section end -------- */}
              {/* ------ button section start -------- */}
              <div className="col-lg-3">
                <div className="action d-flex gap-3 align-items-center justify-content-end">
                  {/* ------- reset button start ------- */}
                  <Link
                    to="#"
                    className="d-flex gap-1 align-items-center text-black"
                    onClick={resetHandler}
                  >
                    <span className="d-block material-symbols-outlined icon-md">
                      sync
                    </span>
                    <span className="d-block fw-medium">{t("Refresh")}</span>
                  </Link>
                  {/* ------- reset button end ------- */}
                  {/* ------- search button start ------- */}
                  <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={searchHandler}
                  >
                    {t("Filter")}
                  </button>
                  {/* ------- search button start ------- */}
                </div>
              </div>
              {/* ------ button section end -------- */}
            </div>
          </div>
          {/* ------- clinet,grouped,discount and button section start ------- */}
        </form>
        {/* ------- search form section end ------- */}
      </div>
    </div>
  );
};
export default BudgetFilter;
