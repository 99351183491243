/* eslint-disable */
import AcceptMemberShipThanksModal from "components/EventMemberShipComponents/Modal/AcceptMemberShipThanksModal";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

//import api requiremnts
import * as url from "urlhelpers/UrlHelper";
import { getData } from "utils/Gateway";

const EventMemberShipProcess = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const rid = searchParams.get("rid") ? searchParams.get("rid") : "";
  const uid = searchParams.get("uid") ? searchParams.get("uid") : "";
  const mstatus = searchParams.get("mstatus")
    ? searchParams.get("mstatus")
    : "";
  const color = searchParams.get("color") ? searchParams.get("color") : "";
  const mailid = searchParams.get("mail") ? searchParams.get("mail") : "";
  let refurl = searchParams.get("refurl") ? searchParams.get("refurl") : "";

  //function for submit event membership
  const eventMembershipHandler = async () => {
    try {
      let requestUrl =
        url.API_BASEURL +
        url.API_SAVE_EVENT_MEMBERSHIP +
        `?rid=${rid}&uid=${uid}&mstatus=${mstatus}&color=${color}&mail=${mailid}`;

      console.log("Url of membership-------->", requestUrl);

      const response = await getData(requestUrl);

      console.log("Response in membership =======>", response);

      console.log(refurl);

      if (response.status) {
        if (refurl.length > 5) {
          // if there is a refUrl for survey response, redirect to that-----------------
          refurl = refurl + "&color=" + color + "&mail=" + mailid;

          console.log(refurl);
          window.location.href = refurl;
        } else {
          let myModal = new bootstrap.Modal(
            document.getElementById("thanks_membership_modal")
          );
          myModal.show();

          // update message tracker and mark email as valid in
          let requestUrl =
            url.API_BASEURL +
            url.API_MESSAGE_APPROVAL +
            `?mail=${mailid}&color=${color}`;

          console.log("Mail Approval request ------->", requestUrl);

          const response = await getData(requestUrl);

          console.log("Mail approval response =======>", response);
        }
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  useEffect(() => {
    eventMembershipHandler();
  }, []);

  return (
    <>
      <AcceptMemberShipThanksModal mstatus={mstatus} />
    </>
  );
};

export default EventMemberShipProcess;
