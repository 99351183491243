/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";

//import translation function for language translation
import { useTranslation } from "react-i18next";

/*import url and gateway methods */
import { putData } from "utils/Gateway";
import * as url from "urlhelpers/UrlHelper";

//import context
import { GlobalProvider } from "context/GlobalContext";

const ReserveTemplateHeader = ({ setSearch, records, blockRefSelected }) => {
  const {
    setSuccess,
    setError,
    setNotificationString,
    setReloadReserveTemplateList,
  } = useContext(GlobalProvider);

  const token = localStorage.getItem("token"); // token
  const { t } = useTranslation(); //for translation

  /* hook to get the search value */
  const [searchValue, setSearchValue] = useState("");

  //function for delete/de-activate event
  const confirmDelete = () => {
    const result = window.confirm(t("Are you sure you want to delete?"));
    if (result) {
      deActivateReserves();
    }
  };

  const deActivateReserves = () => {
    const checkboxes = document.querySelectorAll(
      'input[name="selectTemplateRecord"]'
    );

    checkboxes.forEach(async (checkbox) => {
      if (checkbox.checked) {
        let reserveData = {
          status: "0",
        };

        try {
          let requestUrl =
            url.API_BASEURL +
            url.API_UPDATE_RESERVE_EVENT +
            `/${checkbox.value}` +
            `?token=${token}`;

          const response = await putData(requestUrl, reserveData);
          if (response.status) {
            setReloadReserveTemplateList(true);
            setSuccess(true);
            setNotificationString(
              t("Reservation template has been deleted successfully")
            );
          }
        } catch (error) {
          console.error(error.message);
          setError(true);
          setNotificationString(
            t("Something went wrong, please try again later")
          );
        }
      }
    });
  };

  /* hook to empty search when searchValue gets empty */
  useEffect(() => {
    !searchValue && setSearch("");
  }, [searchValue]);

  return (
    <div className="filter-container d-flex flex-wrap align-items-center gap-2 pb-1">
      {/* -------- search form section start ------- */}
      {/* <div className="search-container position-relative d-none d-md-block">
        <form onSubmit={(e) => e.preventDefault()}>
          <input
            type="search"
            placeholder={t("Search here")}
            className="form-control bg-transparent py-2 border-0"
            onChange={(e) => setSearchValue(e.target.value)}
            onKeyDown={(e) => {
              e.key === "Enter" && (e.preventDefault(), setSearch(searchValue));
            }}
          />
          <button
            onClick={() => setSearch(searchValue)}
            type="button"
            className="bg-transparent text-dark p-0 border-0 position-absolute top-50 translate-middle-y"
          >
            <span className="d-block material-symbols-outlined">search</span>
          </button>
        </form>
      </div> */}
      {/* -------- search form section end ------- */}

      {/* ------------- showing records start ----------------- */}
      <p className="text-gray fw-medium ms-auto me-3">
        {t("Showing")}
        <span className="text-black ms-1">{records}</span> {t("Records")} -
        <span className="text-black ms-1" ref={blockRefSelected}>
          {" "}
          0{" "}
        </span>{" "}
        {t("Selected")}
      </p>
      {/* ------------- showing records end ----------------- */}

      {/* ----- refresh icon start ------ */}
      <Link
        to="#"
        className="filter-btn btn btn-primary icon-btn mw-unset "
        onClick={() => {
          setReloadReserveTemplateList(true);
          setSuccess(true);
          setNotificationString(t("The list has been refreshed successfully"));
        }}
      >
        <span
          className="d-block material-symbols-outlined"
          data-bs-placement="top"
          title="Refresh"
        >
          refresh
        </span>
      </Link>
      {/* ----- refresh icon end ------ */}

      {/* -------- action dropdown button start ------- */}
      <div className="dropdown">
        <button
          className="btn btn-primary dropdown-toggle mw-unset"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          {t("Actions")}
        </button>
        <ul className="dropdown-menu border-0 shadow-lg">
          {/* -------- delete li start ------- */}
          <li>
            <Link className="dropdown-item" to="#" onClick={confirmDelete}>
              {t("Delete selected")}
            </Link>
          </li>
          {/* -------- delete li end ------- */}
        </ul>
      </div>
      {/* -------- action dropdown button end ------- */}
    </div>
  );
};

export default ReserveTemplateHeader;
