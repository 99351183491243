import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import UserMenu from 'components/common/UserMenu';
import LeftMenu from 'components/common/LeftMenu';

import Inbox from 'components/ConversationComponents/ListConversations/Inbox';

import ViewMailPopup from 'components/ConversationComponents/Popup/ViewMailPopup';
import CreateEmailPopup from 'components/ConversationComponents/Popup/CreateEmailPopup';
import ConversationBreadCrumb from 'components/ConversationComponents/BreadCrumb/ConversationBreadCrumb';
import ConversationHeader from 'components/ConversationComponents/Header/ConversationHeader';

import AddLabel from 'components/common/AddLabel';
import ConversationFilter from 'components/ConversationComponents/Filter/ConversationFilter';
import CustomNotification from 'components/common/CustomNotification';

const Conversation = () => {
  /* useState hook to download excel file */
  const [downloadExcel, setDownloadExcel] = useState(false);

  /* hook to search */
  const [search, setSearch] = useState('');
  /* hook to get number of records */
  const [records, setRecords] = useState(0);

  const blockRefSelected = useRef(null);

  const checkedRecordCount = selectCount => {
    const blockElementSelect = ReactDOM.findDOMNode(blockRefSelected.current);
    if (blockElementSelect) {
      blockElementSelect.innerHTML = selectCount;
    }
  };

  useEffect(() => {
    document.title = 'Mail conversation'; // Update the title when Register component mounts
  }, []);

  return (
    <>
      {/* ------------- menu section strat ------------- */}
      <UserMenu />
      <LeftMenu />
      {/* ------------- menu section end ------------- */}

      <section className="mail-wrapper pt-2">
        <div className="container-fluid px-4">
          {/* -------------- bread crumb start  -------------------- */}
          <ConversationBreadCrumb />
          {/* -------------- bread crumb end  -------------------- */}
          {/* -------------- header start ------------------ */}
          <ConversationHeader
            setDownloadExcel={setDownloadExcel}
            setSearch={setSearch}
            records={records}
            blockRefSelected={blockRefSelected}
          />
          {/* -------------- header end ------------------ */}
          {/* -------------- filter start ------------------ */}
          <ConversationFilter />
          {/* -------------- filter end ------------------ */}
          {/* ---------------- inbox/body start ------------------ */}
          <Inbox
            downloadExcel={downloadExcel}
            search={search}
            setRecords={setRecords}
            checkedRecordCount={checkedRecordCount}
          />
          {/* ---------------- inbox/body start ------------------ */}
        </div>
      </section>
      {/* ----------- view mail popup start ----------------- */}
      <ViewMailPopup />
      {/* ----------- view mail popup end ----------------- */}
      {/* ---------------- create mail popup start ----------------- */}
      <CreateEmailPopup />
      {/* ---------------- create mail popup end ----------------- */}

      {/* --------------- add label popup start ---------------- */}
      <AddLabel module={'conversation'} />
      {/* --------------- add label popup end ---------------- */}
      {/*----------- custom notification component start -----------*/}
      <CustomNotification />
      {/*----------- custom notification component end -----------*/}
    </>
  );
};

export default Conversation;
