/* eslint-disable */
import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";

//import translation function for language translation
import { useTranslation } from "react-i18next";

/*import url and gateway methods */
import { putData } from "utils/Gateway";
import * as url from "urlhelpers/UrlHelper";

//import context
import { GlobalProvider } from "context/GlobalContext";

const QuestionHeader = ({
  setDownloadExcel,
  setSearch,
  records,
  blockRefSelected,
}) => {
  const { t } = useTranslation(); //for translation
  const token = localStorage.getItem("token"); // token
  const {
    setResetQuestionPopup,
    setReloadQuestionList,
    setSuccess,
    setError,
    setNotificationString,
  } = useContext(GlobalProvider);

  /* function to click download xls*/
  const handleDownloadClick = () => {
    setDownloadExcel(true);
    setTimeout(() => setDownloadExcel(false), 500);
  };

  /* hook to get the search value */
  const [searchValue, setSearchValue] = useState("");

  //function for deactivate/update survey
  const deleteQuestion = () => {
    const checkboxes = document.querySelectorAll(
      'input[name="selectQuestionRecord"]'
    );

    checkboxes.forEach(async (checkbox) => {
      if (checkbox.checked) {
        let questionData = {
          status: "0",
        };
        try {
          let requestUrl =
            url.API_BASEURL +
            url.API_UPDATE_QUESTION +
            `/${checkbox.value}` +
            `?token=${token}`;
          const response = await putData(requestUrl, questionData);
          if (response.status) {
            setReloadQuestionList(true);
            setSuccess(true);
            setNotificationString(t("Question has been deleted successfully"));
          }
        } catch (error) {
          console.error(error.message);
          setError(true);
          setNotificationString(
            t("Something went wrong, please try again later")
          );
        }
      }
    });
  };

  /* hook to empty search when searchValue gets empty */
  useEffect(() => {
    !searchValue && setSearch("");
  }, [searchValue]);

  return (
    <div
      className="tab-pane fade show active"
      id="question-tab-pane"
      role="tabpanel"
      aria-labelledby="question-tab"
      tabIndex="0"
    >
      <nav aria-label="breadcrumb">
        <ul className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="dashboard.html" className="text-secondary">
              Funbrain
            </Link>
          </li>
          <li className="breadcrumb-item text-gray-600" aria-current="page">
            {t("Questions")}
          </li>
        </ul>
      </nav>
      <div className="filter-container d-flex flex-wrap align-items-center gap-2 pb-1 border-bottom border-gray">
        {/* <Link
          to="#"
          className="column-collapse btn btn-primary icon-btn mw-unset"
        >
          <span className="d-block material-symbols-outlined icon-fill">
            visibility
          </span>
        </Link> */}
        {/* ------ download button start ------ */}
        <Link
          to="#"
          onClick={handleDownloadClick}
          className="download btn btn-primary icon-btn mw-unset"
          data-bs-placement="top"
          title="Download XLS"
        >
          <span className="d-block material-symbols-outlined">download</span>
        </Link>
        {/* ------ download button end ------ */}

        {/* ------ search section start ------ */}
        <div className="search-container position-relative d-none d-md-block">
          <form action="">
            <input
              type="search"
              placeholder={t("Search here")}
              className="form-control bg-transparent py-2 border-0"
              onChange={(e) => setSearchValue(e.target.value)}
              onKeyDown={(e) => {
                e.key === "Enter" &&
                  (e.preventDefault(), setSearch(searchValue));
              }}
            />
            <button
              onClick={() => setSearch(searchValue)}
              type="button"
              className="bg-transparent text-dark p-0 border-0 position-absolute top-50 translate-middle-y"
            >
              <span className="d-block material-symbols-outlined">search</span>
            </button>
          </form>
        </div>
        {/* ------ search section end ------ */}

        {/* ------------- showing records start ----------------- */}
        <p className="text-gray fw-medium ms-auto me-3">
          {t("Showing")}
          <span className="text-black ms-1">{records}</span> {t("Records")} -
          <span className="text-black ms-1" ref={blockRefSelected}>
            {" "}
            0{" "}
          </span>{" "}
          {t("Selected")}
        </p>
        {/* ------------- showing records end ----------------- */}

        {/* ----- refresh icon start ------ */}
        <Link
          to="#"
          className="filter-btn btn btn-primary icon-btn mw-unset "
          onClick={() => {
            setReloadQuestionList(true);
            setSuccess(true);
            setNotificationString(
              t("The list has been refreshed successfully")
            );
          }}
        >
          <span
            className="d-block material-symbols-outlined"
            data-bs-placement="top"
            title="Refresh"
          >
            refresh
          </span>
        </Link>
        {/* ----- refresh icon end ------ */}

        {/* ------ add new question offcanvas trigger button start ------ */}
        <Link
          to="#saveUpdateQuestionPopup"
          data-bs-toggle="offcanvas"
          role="button"
          aria-controls="saveQuestionPopup"
          className="save-reservation btn btn-primary icon-btn mw-unset"
          onClick={() => {
            setResetQuestionPopup(true);
          }}
        >
          <span
            className="d-block material-symbols-outlined"
            data-bs-placement="top"
            title="Save Question"
          >
            add
          </span>
        </Link>
        {/* ------ add new question offcanvas trigger button end ------ */}

        {/* ------ action button start ------ */}
        <div className="dropdown">
          <button
            className="btn btn-primary dropdown-toggle mw-unset"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {t("Actions")}
          </button>
          <ul className="dropdown-menu border-0 shadow-lg">
            <li>
              <Link
                className="dropdown-item"
                to="#saveQuestionPopup"
                data-bs-toggle="offcanvas"
              >
                {t("Add new")}
              </Link>
            </li>
            {/* ------ delete button start ------ */}
            <li>
              <Link onClick={deleteQuestion} className="dropdown-item" to="#">
                {t("Delete selected")}
              </Link>
            </li>
            {/* ------ delete button end ------ */}
          </ul>
        </div>
        {/* ------ action button end ------ */}
      </div>
    </div>
  );
};

export default QuestionHeader;
