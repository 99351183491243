/* eslint-disable */
import React, { useState, useContext, useEffect } from "react";

// //import translation function for language translation
import { useTranslation } from "react-i18next";

// /*import url and gateway methods */
import { getData } from "utils/Gateway";
import * as url from "urlhelpers/UrlHelper";

/* importing schedular from kendo react schedular */
import {
  Scheduler,
  DayView,
  WeekView,
  MonthView,
  AgendaView,
  SchedulerItem,
} from "@progress/kendo-react-scheduler";

import {
  IntlProvider,
  load,
  LocalizationProvider,
  loadMessages,
} from "@progress/kendo-react-intl";

import likelySubtags from "cldr-core/supplemental/likelySubtags.json";
import currencyData from "cldr-core/supplemental/currencyData.json";
import weekData from "cldr-core/supplemental/weekData.json";
import numbers from "cldr-numbers-full/main/es/numbers.json";
import currencies from "cldr-numbers-full/main/es/currencies.json";
import caGregorian from "cldr-dates-full/main/es/ca-gregorian.json";
import dateFields from "cldr-dates-full/main/es/dateFields.json";
import timeZoneNames from "cldr-dates-full/main/es/timeZoneNames.json";

import ShowAgendaDetails from "../Popup/ShowAgendaDetails";

load(
  likelySubtags,
  currencyData,
  weekData,
  numbers,
  currencies,
  caGregorian,
  dateFields,
  timeZoneNames
);

// import other translations for spainish
import esMessages from "data/Prod/esCalendarMessages.json";
loadMessages(esMessages, "es-ES");

//import constext
import { GlobalProvider } from "context/GlobalContext";

const AgendaBody = () => {
  const { t, i18n } = useTranslation();

  const {
    agendaWaitning,
    setAgendaWaitning,
    filterAgendaIncludeClientId,
    filterAgendaExcludeClientId,
    reloadAgenda,
    setReloadAgenda,
  } = useContext(GlobalProvider);

  const token = localStorage.getItem("token");

  const [agendaData, setAgendaData] = useState([]);

  // set agenda details params
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedShift, setSelectedShift] = useState('');
  const [selectedStarttime, setSelectedStarttime] = useState('');

  const getAllAgenda = async (month, year) => {
    try {
      setAgendaWaitning(true);

      let requestUrl =
        url.API_BASEURL +
        url.API_GET_ALL_AGENDA +
        `?token=${token}&month=${month}&year=${year}`;

      if (filterAgendaIncludeClientId) {
        requestUrl += `&filterincludeclient=${filterAgendaIncludeClientId}`;
      }

      if (filterAgendaExcludeClientId) {
        requestUrl += `&filterexcludeclient=${filterAgendaExcludeClientId}`;
      }

      console.log("url of agenda body---------->", requestUrl);

      const response = await getData(requestUrl);

      console.log("Response in agenda body------->", response);

      if (response.status) {
        setAgendaWaitning(false);
        setAgendaData(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const displayDate = new Date();

  const parseAdjust = (eventDate) => {
    const date = new Date(eventDate);
    //date.setFullYear(currentYear);
    return date;
  };

  // set response to match kendo scheduler event schema --------------------------------
  const kendoCalendarData = agendaData.map((dataItem) => ({
    id: dataItem.id,
    start: parseAdjust(dataItem.start, dataItem.starttime),
    end: parseAdjust(dataItem.end, dataItem.endtime),
    isAllDay: false,
    title: dataItem.title,
    description: dataItem.description,
    ownerId: dataItem.availability,
    date: dataItem.date,
    availability: dataItem.isAvailable,
    starttime: dataItem.starttime,
    endtime: dataItem.endtime,
    color: dataItem.color,
  }));

  /*--------- function for custom schedule ---------*/
  const CustomSchedule = (props) => {
    return (
      <SchedulerItem
        {...props}
        onClick={scheduleItemHandler}
        style={{
          ...props.style,
          background: props.dataItem.color,
        }}
      />
    );
  };



  /*---------- Handel Edit Calendar Schedule data ------------*/
  let scheduleItemHandler = (event) => {

    console.log(event.target.props.dataItem)

    setSelectedDate(event.target.props.dataItem.date);

    setSelectedShift(event.target.props.dataItem.shift);

    setSelectedStarttime(event.target.props.dataItem.starttime);

    const bootstrapModal = new bootstrap.Modal(
            document.getElementById("agendaDetailInfo")
          );
          bootstrapModal.show();

    //assign schedule date in context

      // if (event.target.props.dataItem.availability) {
      //   if (isMassEdit) {
      //     if (editedIdsArr.includes(event.target.props.dataItem.id)) {
      //       const updatedArr = editedIdsArr.filter(
      //         (item) =>
      //           item.toString() !== event.target.props.dataItem.id.toString()
      //       );
      //       setEditedIdsArr(updatedArr);
      //     } else {
      //       setEditedIdsArr([...editedIdsArr, event.target.props.dataItem.id]);
      //     }
      //   } else {
      //     setEditCalendarData([event.target.props.dataItem.id]);

      //     const offcanvas = new window.bootstrap.Offcanvas(
      //       document.getElementById("editSchedulePopup")
      //     );
      //     offcanvas.show();
      //   }
      // }

  };

  const handleDateChange = (e) => {
    const selectedDate = e.value;

    const month = selectedDate.getMonth() + 1; // Get the month name
    const year = selectedDate.getFullYear(); // Get the full year

    getAllAgenda(month, year);
  };

  useEffect(() => {
    if (filterAgendaIncludeClientId || filterAgendaExcludeClientId) {
      const currentDate = new Date();
      const month = currentDate.getMonth() + 1; // Get the current month name
      const year = currentDate.getFullYear(); // Get the current full year

      getAllAgenda(month, year);
    }
  }, [filterAgendaIncludeClientId, filterAgendaExcludeClientId]);

  useEffect(() => {
    if (reloadAgenda) {
      const currentDate = new Date();
      const month = currentDate.getMonth() + 1; // Get the current month name
      const year = currentDate.getFullYear(); // Get the current full year

      getAllAgenda(month, year);
      setReloadAgenda(false);
    }
  }, [reloadAgenda]);

  useEffect(() => {
    const currentDate = new Date();
    const month = currentDate.getMonth() + 1; // Get the current month name
    const year = currentDate.getFullYear(); // Get the current full year

    getAllAgenda(month, year);
  }, []);

  const resetHandler = () =>{
    setSelectedDate('');

    setSelectedShift('');

    setSelectedStarttime('');
  }

  return (
    <>

    <LocalizationProvider language={i18n.language == "en" ? "en-EN" : "es-ES"}>
      <IntlProvider locale={i18n.language == "en" ? "en" : "es"}>
        <Scheduler
          data={agendaWaitning ? null : kendoCalendarData}
          //defaultDate={new Date()}
          item={CustomSchedule}
          onDateChange={handleDateChange}
        >
          <MonthView title={t("Month")} />

          <WeekView title={t("Week")} />
          <DayView title={t("Day")} />
          <AgendaView title={t("Agenda")} />
        </Scheduler>
      </IntlProvider>
    </LocalizationProvider>

    <ShowAgendaDetails 
      selectedDate = {selectedDate}
      selectedShift = {selectedShift}
      selectedStarttime = {selectedStarttime}
      onCloseModal = {resetHandler}
    />

    </>
  );
};

export default AgendaBody;
