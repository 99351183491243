import React, { useEffect } from 'react';

import UserMenu from 'components/common/UserMenu';
import LeftMenu from 'components/common/LeftMenu';
import DashboardBody from 'components/DashboardComponents/DashboardBody';

const DashBoard = () => {
  useEffect(() => {
    document.title = 'Dashboard'; // Update the title when Register component mounts
  }, []);

  return (
    <>
      <UserMenu />
      <LeftMenu />
      <DashboardBody />
    </>
  );
};

export default DashBoard;
