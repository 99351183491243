const calculateTotalPrice = (salesOrderItem) => {
  const activityCost = parseFloat(salesOrderItem.activitycost) || 0;
  const discountPercent = parseFloat(salesOrderItem.discountpercent) || 0;
  const taxPercent = parseFloat(salesOrderItem.taxpercent) || 0;
  const unit = parseFloat(salesOrderItem.unit) || 1;

  // Calculate totalprice as per your business logic
  const totalprice =
    activityCost * unit - activityCost * unit * (discountPercent / 100);

  const discountedAmount = Math.ceil(
    activityCost * unit * (discountPercent / 100)
  );

  //get price after adding tax on total amount
  const totalTax = totalprice * (taxPercent / 100);

  return {
    totalamount: Math.ceil(totalprice + totalTax),
    discountamount: discountedAmount,
    totaltax: totalTax,
  };
};

export default calculateTotalPrice;
