/* eslint-disable */
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

//import translation function for language translation
import { useTranslation } from "react-i18next";

//api url requirement
import * as url from "urlhelpers/UrlHelper";
import { putData } from "utils/Gateway";

//import context
import { GlobalProvider } from "context/GlobalContext";

const CategoryHeader = ({
  setDownloadExcel,
  setreloadCategoryList,
  records,
  blockRefSelected,
  setSearch,
}) => {
  const { setSuccess, setError, setNotificationString } =
    useContext(GlobalProvider);

  const { t } = useTranslation(); //for translation

  const token = localStorage.getItem("token"); // token

  /* hook to get the search value */
  const [searchValue, setSearchValue] = useState("");

  /* function to click download xls*/
  const handleDownloadClick = () => {
    setDownloadExcel(true);

    setTimeout(() => setDownloadExcel(false), 500);
  };

  //function for change status
  const deleteCategoryHandler = async () => {
    const checkboxes = document.querySelectorAll(
      'input[name="selectCategoryRecord"]'
    );

    checkboxes.forEach(async (checkbox) => {
      if (checkbox.checked) {
        try {
          let categoryData = {
            status: "0",
          };

          let requestUrl =
            url.API_BASEURL +
            url.API_UPDATE_CATEGORIY +
            `/${checkbox.value}` +
            `?token=${token}`;

          const response = await putData(requestUrl, categoryData);

          if (response.status) {
            setreloadCategoryList(true);
            setSuccess(true);
            setNotificationString(t("Category has been deleted successfully"));
          }
        } catch (error) {
          console.error(error.message);
          setError(true);
          setNotificationString(
            t("Something went wrong, please try again later")
          );
        }
      }
    });
  };

  /* hook to empty search when searchValue gets empty */
  useEffect(() => {
    !searchValue && setSearch("");
  }, [searchValue]);

  return (
    <div className="filter-container d-flex flex-wrap align-items-center gap-2 pb-3">
      {/* ----- excel download icon start ----- */}
      <Link
        to="#"
        onClick={handleDownloadClick}
        className="download btn btn-primary icon-btn mw-unset"
      >
        <span className="d-block material-symbols-outlined">download</span>
      </Link>
      {/* ----- excel download icon end ----- */}

      {/* ----- search section start ----- */}
      <div className="search-container position-relative d-none d-md-block">
        <form onSubmit={(e) => e.preventDefault()}>
          <input
            type="search"
            placeholder={t("Search here")}
            className="form-control bg-transparent py-2 border-0"
            onChange={(e) => setSearchValue(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                setSearch(searchValue);
              }
            }}
          />
          <button
            type="submit"
            className="bg-transparent text-dark p-0 border-0 position-absolute top-50 translate-middle-y"
            onClick={() => setSearch(searchValue)}
          >
            <span className="d-block material-symbols-outlined">search</span>
          </button>
        </form>
      </div>
      {/* ----- search section end ----- */}

      {/* ------------- showing records start ----------------- */}
      <p className="text-gray fw-medium ms-auto me-3">
        {t("Showing")}
        <span className="text-black ms-1">{records}</span> {t("Records")} -
        <span className="text-black ms-1" ref={blockRefSelected}>
          {" "}
          0{" "}
        </span>{" "}
        {t("Selected")}
      </p>
      {/* ------------- showing records end ----------------- */}

      {/* ----- refresh icon start ----- */}
      <Link
        to="#"
        className="filter-btn btn btn-primary icon-btn mw-unset"
        onClick={() => {
          setreloadCategoryList(true);
        }}
      >
        <span className="d-block material-symbols-outlined">refresh</span>
      </Link>
      {/* ----- refresh icon end ----- */}

      {/* ----- add category icon start ----- */}
      <Link
        to="#addCategoryPopup"
        data-bs-toggle="offcanvas"
        role="button"
        aria-controls="addCategoryPopup"
        className="save-reservation btn btn-primary icon-btn mw-unset"
      >
        <span className="d-block material-symbols-outlined">add</span>
      </Link>
      {/* ----- add category icon end ----- */}

      {/* ----- action dropdown section start ----- */}
      <div className="dropdown">
        {/* ------ action dropdown button start ----- */}
        <button
          className="btn btn-primary dropdown-toggle mw-unset"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          {t("Actions")}
        </button>
        {/* ------ action dropdown button end ----- */}

        {/* ------ action dropdown list section start ----- */}
        <ul className="dropdown-menu border-0 shadow-lg">
          {/* ------ delete section start ----- */}
          <li>
            <Link
              className="dropdown-item"
              to="#"
              onClick={deleteCategoryHandler}
            >
              {t("Delete Category")}
            </Link>
          </li>
          {/* ------ delete section end ----- */}
        </ul>
        {/* ------ action dropdown list section end ----- */}
      </div>
      {/* ----- action dropdown section end ----- */}
    </div>
  );
};

export default CategoryHeader;
